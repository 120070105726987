// @ts-expect-error - unexported types
import { Tcopy } from '@iwoca/login';

export const LOGIN_COPY: Tcopy = {
  password: {
    emailLabel: 'Email address',
    emailPlaceholder: 'Account email address...',
    emailInvalid: 'Please enter your email address',
    passwordPlaceholder: 'Password...',
  },
  passwordless: {
    emailLabel: 'Email address',
    emailDescription:
      "Make sure it's the same email address you used to create your account.",
    emailPlaceholder: 'Account email address...',
    switchLoginMethod: 'log in with email and password',

    sentLink: (
      <>
        <h2 className="text-m">✉️ Check your inbox</h2>
        <p className="text-s font-reg">
          You should see an email from us (take a look in spam if you don’t) -{' '}
          <span className="font-med">
            click the link in the email to login and complete your purchase.
          </span>
        </p>
      </>
    ),
    retry: 'Resend link',
  },
} as const;
