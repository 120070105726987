import { useState } from 'react';

import { GetStateByStateKeyResponse } from '@iwoca/lapi-client/edge';
import { fetchDeleteSellerAccessUser } from '@iwoca/lapi-client/iwocapay';
import { Accordion, Button, Modal } from '@iwoca/orion';

import styles from './AccessUsers.module.css';
import { AccessLevelsTable } from './components/AccessLevelsTable/AccessLevelsTable';
import { AccessUserForm } from './components/AccessUserDetailsForm/AccessUserDetails';
import { AccessUsersTable } from './components/AccessUsersTable/AccessUsersTable';
import {
  useGetSellerAccessUsers,
  useGetStateByStateKey,
} from '../../api/lending/lapiHooks';
import { DynamicPageTemplate } from '../../components/PageTemplate/PageTemplate';
import { createToast } from '../../store/IwToast';
import { useSellerDetails } from '../Dashboard/hooks/useSellerDetails';

export type TDeleteAccessUser = {
  stateKey: string;
  accessUserUuid: string;
  emailAddress: string;
} | null;

export const AccessUsers = () => {
  const applicantDetails = useSellerDetails();
  const [showForm, setShowForm] = useState(false);
  const [deleteModal, setDeleteModal] = useState<TDeleteAccessUser>(null);

  const { refetchAccessUsers } = useGetSellerAccessUsers();
  const { state: customerState } = useGetStateByStateKey();

  const showAccessUserForm = () => setShowForm((prev) => !prev);

  const showChangeSuccessToast = () =>
    createToast('Changes saved successfully');

  const showDeleteSuccessToast = () =>
    createToast('User has successfully been removed');

  const showSendInviteSuccessToast = () =>
    createToast('User has been sent an email to confirm');

  const closeFormAndRefreshTable = async () => {
    showAccessUserForm();
    await refetchAccessUsers();
    showSendInviteSuccessToast();
  };

  const deactivateAccessUser = async ({
    stateKey,
    accessUserUuid,
  }: {
    stateKey: string;
    accessUserUuid: string;
  }) => {
    await fetchDeleteSellerAccessUser({
      stateKey,
      body: { access_user_uuid: accessUserUuid },
    });
    await refetchAccessUsers();
    showDeleteSuccessToast();
  };

  const fullName = `${applicantDetails?.firstName} ${applicantDetails?.lastName}`;

  const getPrimaryEmailAddressForCustomerState = (
    customerState: GetStateByStateKeyResponse['data'] | null | undefined,
  ): string => {
    return (
      customerState?.application?.people
        ?.find(
          (person) =>
            person?.roles?.includes('applicant') &&
            person?.emails?.some((email) => email.type === 'primary'),
        )
        ?.emails?.find((email) => email.type === 'primary')?.email || 'Unknown'
    );
  };

  const loggedInCustomerEmailAddress =
    getPrimaryEmailAddressForCustomerState(customerState);

  return (
    <>
      <Modal
        closeIcon
        id="multi_user_confirmation_modal"
        isOpen={deleteModal !== null}
        onClose={() => setDeleteModal(null)}
        subTitle={`Please confirm that you want to remove ${deleteModal?.emailAddress}`}
        title="Are you sure?"
      >
        <Button
          variant="action"
          className={styles.deleteButton}
          onClick={async () => {
            if (!deleteModal) return;
            await deactivateAccessUser({
              stateKey: deleteModal?.stateKey,
              accessUserUuid: deleteModal?.accessUserUuid,
            });
            setDeleteModal(null);
          }}
        >
          Remove User
        </Button>
        <Button variant="tertiary" onClick={() => setDeleteModal(null)}>
          No, cancel
        </Button>
      </Modal>

      <DynamicPageTemplate>
        <div className={styles.page}>
          <div className={styles.content}>
            <div className={styles.headerContainer}>
              <div>
                <div className={styles.pageHeader}>
                  {applicantDetails?.companyName || ''} users
                </div>
                <div className={styles.subheading}>
                  Owner: {fullName} | {loggedInCustomerEmailAddress}
                </div>
                <div className={styles.introduction}>
                  These are the users that have access to the iwocaPay seller
                  dashboard.
                </div>
              </div>
            </div>
            <div className={styles.accordionWrapper}>
              <Accordion
                small={true}
                title="What are the different access levels?"
                variant="primary"
                frame={false}
                iconPosition="hug"
                className={styles.accordionBackground}
              >
                <AccessLevelsTable />
              </Accordion>
            </div>
            <div className={styles.tableWrapper}>
              <AccessUsersTable
                onDeactivate={(data: TDeleteAccessUser) => setDeleteModal(data)}
                onChange={showChangeSuccessToast}
                onInviteSend={showSendInviteSuccessToast}
              />
            </div>
            {!showForm && (
              <>
                <Button onClick={showAccessUserForm}>Add a new user</Button>
                <div className={styles.buttonDescriptionContainer}>
                  <p className={styles.buttonDescription}>
                    Any users you add will be sent a email to confirm their
                    access and set up their account.
                  </p>
                </div>
              </>
            )}
            {showForm && (
              <AccessUserForm
                onSuccess={closeFormAndRefreshTable}
                onCancel={showAccessUserForm}
              />
            )}
          </div>
        </div>
      </DynamicPageTemplate>
    </>
  );
};
