import styles from './SpendingLimitLandingLink.module.css';
import CopyIcon from '../../../../../assets/CopyIcon.svg?react';
import { CopyButton } from '../../../../../components/CopyButton/CopyButton';
import { copy } from '../../../../../components/util/copy';
import { useSellerHandle } from '../../../../../hooks/useSellerHandle.hook';
import { getUrlFromPath } from '../../../../../utils/getUrlFromPath';

export const CopyLandingLink = () => {
  const { sellerHandle } = useSellerHandle();
  const url = getUrlFromPath(`/pay/spending-account/${sellerHandle}`)!;
  return (
    <div className={styles.linkContainer}>
      <input
        className={styles.landingLinkInput}
        type="text"
        readOnly={true}
        value={url}
        data-testid="copyInputValue"
      />
      <CopyButton
        className={styles.copyButton}
        aria-label="copy link"
        onClick={() => copy(url)}
        title="Copy Spending Limit Landing link"
      >
        <CopyIcon />
      </CopyButton>
    </div>
  );
};
