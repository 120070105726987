export const PayLinkTerminalState = () => {
  return (
    <div className="absolute flex h-full w-full justify-center">
      <div className="h-fit w-fit max-w-lg self-center text-primary-10">
        <h1 className="text-2xl font-med">Oops, this Pay Link has been paid</h1>
        <h2 className="text-l font-reg">
          We do this to make sure you don’t accidentally pay for an order twice.
        </h2>
      </div>
    </div>
  );
};
