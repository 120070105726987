import Cookies from 'js-cookie';

import { runSplitTest } from './runSplitTest';
import SPLIT_TESTS from './split_tests.json';
import { useGetContext } from '../api/lending/lapiHooks';
import { postLendingApiJson } from '../Pages/lendingApiFetch';

export type TBranch = { branchName: string; testPercentage: number };
export type TAnonymousSplitTest = (typeof SPLIT_TESTS)['tests'][number];
export type TSplitTestData = {
  test_name: string;
  branch_name: string;
  created: string;
  fraction: number;
  random_value: number;
};
type TSplitTestBranch = {
  test_name: TSplitTestData['test_name'];
  branch_name: TSplitTestData['branch_name'];
};
type TUserSplitTest = {
  [key: string]: boolean;
}[];

function setSplitTestCookie(data: TSplitTestData, siteURL: string) {
  Cookies.set(`iw-${data['test_name']}`, JSON.stringify(data), {
    domain: siteURL,
    expires: 7665,
  });
}

function executeAnonymousSplitTest(
  test: TAnonymousSplitTest,
  testName: string,
) {
  const randomNumber = Math.random();
  const activeBranch = runSplitTest(test, randomNumber);

  const currentDateTime = new Date();

  return {
    test_name: testName,
    branch_name: activeBranch.branchName,
    created: currentDateTime.toISOString(),
    fraction: activeBranch.testPercentage,
    random_value: randomNumber,
  };
}

async function recordAnonymousSplitTest(data: TSplitTestData) {
  const isProd = window.location.hostname === 'iwoca.co.uk';

  if (isProd) {
    setSplitTestCookie(data, 'https://iwoca.co.uk');
  } else {
    Cookies.set(`iw-${data['test_name']}`, JSON.stringify(data));
  }

  // Add record for this user
  await postLendingApiJson({
    url: '/api/split_tests/add_to_split_test/',
    body: data,
  });
}

export function getSplitTestBranch(
  test: TAnonymousSplitTest,
  existingTests: TUserSplitTest = [],
): TSplitTestBranch {
  const testName = test.name;

  // Check existing split test
  const existingBranch = existingTests.find((test) => test[testName]);
  if (existingBranch) {
    return {
      test_name: testName,
      branch_name: existingBranch ? 'INCLUDE' : 'EXCLUDE',
    };
  }

  // Get existing cookie
  const existingBranchFromCookie = Cookies.get(`iw-${testName}`);
  if (existingBranchFromCookie) {
    const cookie = JSON.parse(existingBranchFromCookie);

    return {
      test_name: testName,
      branch_name: cookie.branch_name,
    };
  }

  // Base case: run split test
  const executedData = executeAnonymousSplitTest(test, testName);
  void recordAnonymousSplitTest(executedData);

  return {
    test_name: testName,
    branch_name: executedData.branch_name,
  };
}

export const useGetSplitTests = ({
  shouldRunTest = false,
}: {
  shouldRunTest: boolean;
}) => {
  const { context, loadingContext } = useGetContext();

  if (!Boolean(Cookies.get('uid'))) return [];
  if (loadingContext) return [];
  if (!shouldRunTest) return [];

  const activeTests = SPLIT_TESTS.tests.filter((test) => test.active);

  const tests = activeTests.map((test) => {
    return getSplitTestBranch(
      test,
      context.user_split_tests as unknown as TUserSplitTest, // type is not correct
    );
  });

  return tests;
};
