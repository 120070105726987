import { useGetContext } from '../api/lending/lapiHooks';

export function useUserGroups() {
  const { context, getContext, loadingContext } = useGetContext();

  return {
    groups: context.groups || [],
    isOwner: context?.groups?.includes('customers') || false,
    isStaff: context?.groups?.includes('team') || false,
    isAdmin: context?.groups?.includes('iwocapay_seller_admin'),
    isEditor: context?.groups?.includes('iwocapay_seller_editor'),
    isContributor: context?.groups?.includes('iwocapay_seller_contributor'),
    isViewer: context?.groups?.includes('iwocapay_seller_viewer'),
    isLoading: loadingContext,
    fetchSiteContext: getContext,
  };
}
