import cn from 'classnames';

import styles from './ProgressIndicator.module.css';

type ProgressType = Array<{
  title: string;
  percentage: number;
  isActive: boolean;
}>;

export const ProgressIndicator = ({ progress }: { progress: ProgressType }) => {
  return (
    <div className={styles.ProgressIndicator}>
      {progress.map(({ title, percentage, isActive }) => {
        return (
          <div key={title} className={styles.progressSection}>
            <div className={styles.progressSectionProgress}>
              <div
                className={styles.progressSectionProgressBar}
                style={{ width: `${percentage}%` }}
              />
            </div>
            <div
              className={cn(styles.progressSectionName, {
                [styles.active]: isActive,
              })}
            >
              {title}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const SECTION_TO_TITLE_MAPPING = {
  details: 'Details',
  choose_product: 'Choose product',
  fraud: 'Security checks',
  offer: 'Offer',
  completed: 'Completed',
} as const;
