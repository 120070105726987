import cn from 'classnames';
import { useParams } from 'react-router-dom';

import styles from './RepaymentSchedule.module.css';
import { useGetSellerByHandle } from '../../../../api/lending/lapiHooks';
import { displayCurrency } from '../../../../utils/DisplayCurrency';
import { TDuration } from '../../utils/PayLinkLanding.types';

export const RepaymentSchedule = ({
  exampleMonthlyRepayment,
  duration,
}: {
  exampleMonthlyRepayment?: string;
  duration: TDuration;
}) => {
  const { sellerHandle } = useParams();
  const { seller } = useGetSellerByHandle(sellerHandle);

  const paymentMonths = getDates(duration);
  if (!exampleMonthlyRepayment) return null;

  if (duration > 3) {
    return (
      <div className={styles.RepaymentSchedule}>
        <RepaymentScheduleSegment
          title="Pay today"
          value={displayCurrency(0)}
        />
        <RepaymentScheduleSegment
          title={`${duration} monthly installments from ${getDisplayMonth(
            paymentMonths[0],
          )}`}
          value={`${exampleMonthlyRepayment} /mo`}
          grow={true}
        />
      </div>
    );
  }

  return (
    <div className={styles.RepaymentSchedule}>
      <RepaymentScheduleSegment title="Pay today" value={displayCurrency(0)} />
      {paymentMonths.map((month, index) => {
        return (
          <RepaymentScheduleSegment
            key={`${month.year}_${month.month}`}
            title={
              seller?.capture_delay_hours
                ? `Month ${index + 1}`
                : getDisplayMonth(month)
            }
            value={exampleMonthlyRepayment}
          />
        );
      })}
    </div>
  );
};

const RepaymentScheduleSegment = ({
  title,
  value,
  grow,
}: {
  title: string;
  value: number | string;
  grow?: boolean;
}) => {
  return (
    <div
      className={cn(styles.RepaymentScheduleSegment, {
        [styles.growSegment]: grow,
      })}
    >
      <div className={styles.segmentTitle}>{title}</div>
      <div className={styles.segmentAmount}>{value}</div>
    </div>
  );
};

const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
] as const;

function getYear(index: number) {
  const d = new Date();
  return index < MONTHS.length ? d.getFullYear() : d.getFullYear() + 1;
}

function getDates(numberOfMonths: TDuration) {
  const d = new Date();
  const initialIndex = d.getMonth();
  const startMonthIndex = initialIndex + 1;
  const endMonthIndex = startMonthIndex + numberOfMonths;

  const months = [];
  for (let month = startMonthIndex; month < endMonthIndex; month++) {
    months.push({
      month: MONTHS[month % 12],
      year: getYear(month),
    });
  }

  return months;
}

function getDisplayMonth(month: ReturnType<typeof getDates>[number]) {
  const d = new Date();
  const year = d.getFullYear();

  if (year !== month.year) {
    return `${month.month} ${month.year}`;
  }

  return month.month;
}
