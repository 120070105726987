import classnames from 'classnames';

import styles from './ProgressBarStateIcon.module.css';
import { Circle } from '../../../../components/svg/Circle';
import { InProgressIcon } from '../../../../components/svg/InProgressIcon';
import { TickCircledIcon } from '../../../../components/svg/TickCircledIcon';
import { ProgressState } from '../ProgressBar.type';

export function ProgressBarStateIcon({
  progressState,
}: {
  progressState: ProgressState;
}) {
  return (
    <>
      {progressState === 'In progress' && (
        <InProgressIcon
          className={styles.progressCircle}
          title="In progress icon"
        />
      )}
      {progressState === 'complete' && (
        <TickCircledIcon
          className={styles.progressCircle}
          title="complete icon"
        />
      )}
      {progressState === 'incomplete' && (
        <Circle
          className={classnames(styles.progressCircle, styles.incompleteCircle)}
          title="incomplete icon"
        />
      )}
    </>
  );
}
