import { useState } from 'react';

import classnames from 'classnames';

import styles from './HelpTextAccordion.module.css';
import Chevron from '../../../components/svg/Chevron.svg?react';
import TelephoneIcon from '../../../components/svg/TelephoneIcon.svg?react';
import constants from '../../../constants.json';
import { TEnabledOptions } from '../../hooks/useEnabledTerms';

export const HelpTextAccordion = ({
  enabledOptions = ['THREE_MONTHS', 'TWELVE_MONTHS', 'PAY_NOW'],
  className,
}: {
  enabledOptions?: TEnabledOptions;
  className?: string;
}) => {
  const [isPayNowPanelOpen, setIsPayNowPanelOpen] = useState(false);
  const [isPayLaterPanelOpen, setIsPayLaterPanelOpen] = useState(false);
  const [isContactPanelOpen, setIsContactPanelOpen] = useState(false);

  const renderPayNowPanel = () => {
    const toggleIsPayNowPanelOpen = () => {
      setIsPayNowPanelOpen(!isPayNowPanelOpen);
    };

    return (
      <>
        <AccordionButton
          isPanelOpen={isPayNowPanelOpen}
          onClick={toggleIsPayNowPanelOpen}
        >
          How Pay Now works
        </AccordionButton>
        {isPayNowPanelOpen && (
          <div>
            <ol className={styles.expandedHelpTextPanel}>
              <li>
                <ListEntryHeader>Select your bank</ListEntryHeader>
                <ListEntryBody>
                  You'll log into your online banking either on mobile or
                  desktop
                </ListEntryBody>
              </li>
              <li>
                <ListEntryHeader>Agree to a Bank Transfer</ListEntryHeader>
                <ListEntryBody>
                  You won't need to fill anything out, simply confirm the
                  details
                </ListEntryBody>
              </li>
              <li>
                <ListEntryHeader>Confirmation</ListEntryHeader>
                <ListEntryBody>
                  You'll see confirmation of your payment today
                </ListEntryBody>
              </li>
            </ol>
          </div>
        )}
      </>
    );
  };

  const renderPayLaterPanel = () => {
    const toggleIsPayLaterPanelOpen = () => {
      setIsPayLaterPanelOpen(!isPayLaterPanelOpen);
    };

    return (
      <>
        <AccordionButton
          isPanelOpen={isPayLaterPanelOpen}
          onClick={toggleIsPayLaterPanelOpen}
        >
          How Pay Later works
        </AccordionButton>
        {isPayLaterPanelOpen && (
          <div className={styles.panel}>
            <ol className={styles.expandedHelpTextPanel}>
              <li>
                <ListEntryHeader>Tell us about your business</ListEntryHeader>
                <ListEntryBody>
                  You'll only need to do this the first time you use iwocaPay
                </ListEntryBody>
              </li>
              <li>
                <ListEntryHeader>See your payment plan</ListEntryHeader>
                <ListEntryBody>
                  You can pay earlier any time (for free) to save on interest
                </ListEntryBody>
              </li>
              <li>
                <ListEntryHeader>Secure your account</ListEntryHeader>
                <ListEntryBody>
                  You'll need your passport or driver's licence to confirm your
                  identity
                </ListEntryBody>
              </li>
              <li>
                <ListEntryHeader>You pay over 90 days</ListEntryHeader>
                <ListEntryBody>Your supplier gets paid up front</ListEntryBody>
              </li>
            </ol>
          </div>
        )}
      </>
    );
  };

  const renderContactPanel = () => {
    const toggleIsContactPanelOpen = () => {
      setIsContactPanelOpen(!isContactPanelOpen);
    };

    return (
      <>
        <AccordionButton
          isPanelOpen={isContactPanelOpen}
          onClick={toggleIsContactPanelOpen}
        >
          Contact
        </AccordionButton>
        {isContactPanelOpen && (
          <div className={styles.panel}>
            <ol className={styles.expandedHelpTextPanel}>
              <li>
                <ListEntryHeader>Need help checking out?</ListEntryHeader>
                <ListEntryBody>
                  <a
                    href={constants.iwocapayTelLink}
                    className={styles.phoneNumber}
                  >
                    <TelephoneIcon /> {constants.iwocapayTelDisplay}
                  </a>
                  <a
                    href="https://www.iwoca.co.uk/iwocapay-buyer-pay-later"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.findoutMore}
                  >
                    Find out more about iwocaPay
                  </a>
                </ListEntryBody>
              </li>
              <li>
                <ListEntryHeader>Want to check the details?</ListEntryHeader>
                <ListEntryBody>
                  We got them directly from your supplier - get in touch with
                  them to double check
                </ListEntryBody>
              </li>
            </ol>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={className}>
      {enabledOptions.includes('PAY_NOW') && renderPayNowPanel()}
      {enabledOptions.includes('THREE_MONTHS' || 'TWELVE_MONTHS') &&
        renderPayLaterPanel()}
      {renderContactPanel()}
    </div>
  );
};

const ListEntryHeader = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.listEntryHeader}>{children}</div>;
};

const ListEntryBody = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.listEntryBody}>{children}</div>;
};

const AccordionButton = ({
  children,
  isPanelOpen,
  onClick,
}: {
  children: React.ReactNode;
  isPanelOpen: boolean;
  onClick: () => void;
}) => {
  return (
    <>
      <button
        className={classnames(styles.accordionButton, {
          [styles.activeAccordionButton]: isPanelOpen,
        })}
        onClick={onClick}
      >
        {children}
        <Chevron
          className={classnames(styles.chevron, {
            [styles.activeChevron]: isPanelOpen,
          })}
        />
      </button>
    </>
  );
};
