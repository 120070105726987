import { useContext } from 'react';

import { fetchGetSeller } from '@iwoca/lapi-client/edge';
import { useQuery } from '@tanstack/react-query';

import { useStateKey } from './useStateKey.hook';
import { ActivationContext } from '../activation/Activation';

export function useSellerHandle(): {
  sellerHandle: string | null;
  error: unknown;
} {
  const { stateKey } = useStateKey();
  const { isOnboarded, isLoading } = useContext(ActivationContext);

  const { data, error } = useQuery({
    queryKey: ['fetchGetSeller'],
    queryFn: () => fetchGetSeller({ stateKey: stateKey! }),
    enabled: Boolean(stateKey),
  });

  if (isLoading) {
    return {
      sellerHandle: null,
      error: null,
    };
  }

  if (!isOnboarded) {
    return { sellerHandle: 'ExampleCompany', error: null };
  }

  return {
    sellerHandle: data?.data.handle ?? null,
    error,
  };
}
