import { Icon } from '@iwoca/orion';

import { useGetStateByStateKey } from '../../../../api/lending/lapiHooks';

export const DirectorsAndShareholders = () => {
  const { state, loadingState } = useGetStateByStateKey();
  if (loadingState || !state) return <></>;

  const application = state.application;
  if (!application?.people) return <></>;

  const peopleAssociated = application.people;

  return (
    <div className="mt-xl grid grid-cols-1 gap-xl">
      {peopleAssociated.map((person, index) => {
        const capitaliseString = (str: string) => {
          return str.charAt(0).toUpperCase() + str.slice(1);
        };

        const fullName = () => {
          const title =
            person.title && person.title !== 'other'
              ? capitaliseString(person.title)
              : '';
          return `${title} ${person.first_name} ${person.last_name}`;
        };

        const roles = person.roles?.map(capitaliseString).join(', ') || '';

        return (
          <div
            className="rounded-s border-[1px] border-structure-80 p-l"
            key={index}
          >
            <h3 className="mb-0 mt-0 text-m font-bold text-primary-10">
              {fullName()}
            </h3>
            <p className="mb-0 mt-0 text-m font-reg text-primary-10">{roles}</p>
            {person.roles && person.roles.includes('guarantor') && (
              <p className="mb-0 mt-m flex items-center text-xs font-med">
                <Icon
                  icon="shieldCheck"
                  className="mr-xs h-[18px] w-[auto] fill-success-drk"
                />
                Personal guarantor
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
};
