import { GetProductOffersResponse } from '@iwoca/lapi-client/edge';

import { RecursiveNonNullable } from '../../utils/typeUtils';

type TDuration =
  RecursiveNonNullable<GetProductOffersResponse>['data']['products'][number]['offers'][number]['duration'];

export function getDurationInMonths(duration: TDuration) {
  if (duration.unit === 'days') {
    return Math.floor(duration.amount / 30);
  }

  if (duration.unit === 'months') {
    return duration.amount;
  }

  if (duration.unit === 'years') {
    return Math.floor(duration.amount * 12);
  }

  throw new Error('Invalid duration unit defined');
}

export type SuggestedProductType = ReturnType<typeof getSuggestedProduct>;

export function getSuggestedProduct(productOffers: GetProductOffersResponse) {
  const suggestedProduct = productOffers?.data?.products?.find(
    (product) => product.is_suggested === true,
  );

  return suggestedProduct;
}
