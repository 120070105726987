// ClientHeight: Height of the container
// ScrollHeight: Maximum scrollable area
// ScrollTop: How far scrolled from top

export function isTopVisible(elementTarget: HTMLElement) {
  return elementTarget.scrollTop === 0;
}

export function isBottomVisible(elementTarget: HTMLElement) {
  return (
    elementTarget.scrollHeight - elementTarget.scrollTop <=
    elementTarget.clientHeight + 120
  );
}
