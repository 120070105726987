import { NormalisedDataType, useGetStateQuery } from './StepValidator';
import { getStepsStatus, STEP_STATUS } from './useStepStatus';
import { normaliseStepData } from '../stateNormalisation';

export function getLatestStep(data: NormalisedDataType) {
  const stepStatus = getStepsStatus(data);
  if (stepStatus === null) {
    return null;
  }

  const latestStep = stepStatus.find(
    ({ status }) => status === STEP_STATUS.INCOMPLETE,
  )?.id;

  if (!latestStep) {
    const lastStep = stepStatus[stepStatus.length - 1].id;
    return lastStep;
  }

  return latestStep;
}

export function useLatestStep() {
  const { data } = useGetStateQuery(normaliseStepData);
  if (!data) return null;

  const latestStep = getLatestStep(data);
  return latestStep;
}
