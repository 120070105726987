import { useGetProfile } from '../../../../api/lending/lapiHooks';

export const AccountInfo = () => {
  const { profile, loadingProfile } = useGetProfile();

  if (loadingProfile) return <></>;

  return (
    <>
      <h3 className="mb-0 text-m font-med">Email address</h3>
      <p className="mt-0 text-m font-reg">{profile.email_address || '-'}</p>
      <h3 className="mb-0 text-m font-med">Password</h3>
      <p className="mt-0 text-m font-reg">********</p>
      <a
        href={`/account/company/${window.location.search}`}
        className="text-m font-reg text-primary-40"
      >
        Change password
      </a>
    </>
  );
};
