import * as Yup from 'yup';

import { CustomerStateCompanyType } from '../api/lending/edge';
import { getIsSoleTrader } from '../Seller/AccountActivation/DirectorsProfile/getIsSoleTrader';

export const tradingNameValidator = Yup.string().when('companyType', {
  is: (value: CustomerStateCompanyType) => value === 'sole_trader',
  then: (schema) => schema.required('Trading name is required'),
  otherwise: (schema) => schema,
});

export const companyNameValidator = Yup.string().when('companyType', {
  is: (value: CustomerStateCompanyType) => value !== 'sole_trader',
  then: (schema) => schema.required('Company name is required'),
  otherwise: (schema) => schema,
});

export const companyNumberValidator = Yup.string().when('companyType', {
  is: (value: CustomerStateCompanyType) => !getIsSoleTrader(value),
  then: (schema) =>
    schema
      .required('Company number is required')
      .test('is-companyNumber', 'Invalid company number', (number) => {
        return number?.length === 8;
      }),
  otherwise: (schema) => schema,
});

export const tradingStartDateValidator = Yup.string()
  .typeError('Trading start date is required')
  .required('Trading start date is required')
  .test(
    'is-date',
    'Not a valid trading start date. Please enter as DD/MM/YYYY',
    (date) =>
      !!(
        date &&
        /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/.test(date)
      ),
  );
