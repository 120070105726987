import React from 'react';

import { fetchPostIwocapaySellerResendInvite } from '@iwoca/lapi-client/edge';
import { fetchPutSellerAccessUser } from '@iwoca/lapi-client/iwocapay';
import { Button, Table } from '@iwoca/orion';
import { ColumnDef } from '@iwoca/orion/lib/components/table/Table';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './AccessUsersTable.module.css';
import { useGetSellerAccessUsers } from '../../../../api/lending/lapiHooks';
import CrossIcon from '../../../../assets/CrossIcon.svg';
import TickIcon from '../../../../assets/TickIcon.svg';
import { HookFormDropDown } from '../../../../components/Dropdown/Dropdown';
import { HookFormInput } from '../../../../components/Input/Input';
import { useStateKey } from '../../../../hooks/useStateKey.hook';
import { ThreeDotOptionDropdown } from '../../../components/ThreeDotOption/ThreeDotOptionDropdown';
import { TDeleteAccessUser } from '../../AccessUsers';

const isFeatureFlag = () => {
  return localStorage.viewerUser === 'true';
};

type TFormValues = {
  accessUserUuid: string | null;
  role: keyof typeof ROLE_TO_NAME | null;
};

const ROLE_TO_NAME = {
  iwocapay_seller_admin: 'Admin',
  iwocapay_seller_editor: 'Editor',
  iwocapay_seller_contributor: 'Contributor',
  iwocapay_seller_viewer: 'Viewer',
} as const;

const getRoleName = ({
  groupName,
}: {
  groupName?: keyof typeof ROLE_TO_NAME;
}) => {
  // @ts-expect-error
  return ROLE_TO_NAME[groupName] || 'Unknown';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getUuid = (row: any) => {
  return row.original.uuid as string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getEmail = (row: any) => {
  return row.original.email as string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAccessLevel = (row: any) => {
  return row.original.accessLevel;
};

const ResendInviteStatus = ({
  stateKey,
  accessUserUuid,
  onResendSuccess,
}: {
  stateKey: string;
  accessUserUuid: string;
  onResendSuccess: () => void;
}) => {
  const resendInvite = async ({
    stateKey,
    accessUserUuid,
  }: {
    stateKey: string;
    accessUserUuid: string;
  }) => {
    await fetchPostIwocapaySellerResendInvite({
      stateKey,
      accessUserUuid,
    });
    onResendSuccess();
  };

  return (
    <div className={styles.inviteStatusContainer}>
      <div className={styles.inviteStatus}>Invite has expired</div>
      <div className={styles.inviteDescription}>
        <button
          className={styles.resendAccessUserLink}
          aria-label="Resend invite"
          type="button"
          onClick={() =>
            resendInvite({
              stateKey,
              accessUserUuid,
            })
          }
        >
          <span className={styles.resendAccessUserText}>Resend invite</span>
        </button>
      </div>
    </div>
  );
};

const InviteSentStatus = () => {
  return (
    <div className={styles.inviteStatusContainer}>
      <div className={styles.inviteStatus}>Invite sent</div>
      <div className={styles.inviteDescription}>
        User emailed to accept invite
      </div>
    </div>
  );
};

export const AccessUsersTable = ({
  onDeactivate,
  onChange,
  onInviteSend,
}: {
  onDeactivate: (data: TDeleteAccessUser) => void;
  onChange: () => void;
  onInviteSend: () => void;
}) => {
  const { accessUsers, refetchAccessUsers, isLoading } =
    useGetSellerAccessUsers();
  const methods = useForm<TFormValues>({
    defaultValues: { accessUserUuid: null, role: null },
  });
  const { stateKey } = useStateKey();

  const { watch, reset } = methods;
  const editingUuid = watch('accessUserUuid');

  if (!accessUsers || !stateKey || isLoading) return null;
  if (accessUsers.length === 0) return null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getInviteStatus = (row: any) => {
    const isNewUser = row.original.isNewUser;
    const hasInvitationExpired = row.original.hasInvitationExpired;

    if (isNewUser) {
      if (!hasInvitationExpired) {
        return <InviteSentStatus />;
      } else {
        return (
          <ResendInviteStatus
            stateKey={stateKey}
            accessUserUuid={getUuid(row)}
            onResendSuccess={onInviteSend}
          />
        );
      }
    }
    return null;
  };

  const closeEditForm = () => {
    reset({ accessUserUuid: null, role: null });
  };

  const tableData = accessUsers.map((accessUser) => {
    return {
      uuid: accessUser.uuid,
      fullName: accessUser.full_name,
      email: accessUser.email,
      accessLevel: accessUser.group_name,
      isEditable: true,
      hasInvitationExpired: accessUser.has_invitation_expired,
      isNewUser: accessUser.is_new_user,
    };
  });

  const columns: ColumnDef<(typeof tableData)[number]>[] = [
    {
      id: 'fullName',
      accessorKey: 'fullName',
      header: 'Name',
      enableSorting: true,
      className: styles.nameCol,
      cell: ({ getValue, row }) => {
        const accessUserUuid = getUuid(row);
        if (accessUserUuid === editingUuid) {
          return (
            <HookFormInput
              name="fullName"
              label="Name"
              disabled={true}
              value={getValue()}
            />
          );
        }
        return getValue();
      },
    },
    {
      id: 'email',
      accessorKey: 'email',
      header: 'Email',
      enableSorting: false,
      className: styles.emailCol,
      cell: ({ getValue, row }) => {
        const accessUserUuid = getUuid(row);
        if (accessUserUuid === editingUuid) {
          return (
            <HookFormInput
              name="email"
              label="Email Address"
              disabled={true}
              value={getValue()}
              className={styles.roleDropDown}
            />
          );
        }
        return (
          <div>
            <div>{getValue()}</div>
            {getInviteStatus(row)}
          </div>
        );
      },
    },
    {
      id: 'accessLevel',
      accessorKey: 'accessLevel',
      header: 'Access Level',
      enableSorting: true,
      className: styles.accessLevelCol,
      cell: ({ getValue, row }) => {
        const accessUserUuid = getUuid(row);
        if (accessUserUuid === editingUuid) {
          return (
            <HookFormDropDown
              name="role"
              labelText="Role"
              className={styles.roleDropDown}
            >
              <option value="iwocapay_seller_admin">Admin</option>
              <option value="iwocapay_seller_editor">Editor</option>
              <option value="iwocapay_seller_contributor">Contributor</option>
              {isFeatureFlag() && (
                <option value="iwocapay_seller_viewer">Viewer</option>
              )}
            </HookFormDropDown>
          );
        }
        return getRoleName({ groupName: getValue() });
      },
    },
    {
      id: 'isEditable',
      accessorKey: 'isEditable',
      header: '',
      className: styles.actionsCol,
      cell: ({ getValue, row }) => {
        const isEditable = getValue();
        const accessUserUuid = getUuid(row);
        const accessUserEmail = getEmail(row);
        if (accessUserUuid === editingUuid) {
          return (
            <div className="flex h-full !items-end">
              <Button
                type="submit"
                variant="tertiary"
                className={styles.submitButton}
                aria-label="save-changes"
              >
                <img src={TickIcon} alt="Tick icon" />
              </Button>
              <Button
                type="button"
                variant="tertiary"
                className={styles.submitButton}
                aria-label="cancel-changes"
                onClick={closeEditForm}
              >
                <img src={CrossIcon} alt="Cross icon" />
              </Button>
            </div>
          );
        }
        if (isEditable) {
          return (
            <ThreeDotOptionDropdown
              iconName={'moreHorizontal'}
              theme="white"
              className={styles.accessUserDropdown}
              options={[
                {
                  onPress: () => {
                    reset({
                      accessUserUuid: accessUserUuid,
                      role: getAccessLevel(row),
                    });
                  },
                  title: 'Change role',
                },
                {
                  onPress: () =>
                    onDeactivate({
                      accessUserUuid: accessUserUuid,
                      emailAddress: accessUserEmail,
                      stateKey: stateKey,
                    }),
                  title: 'Remove',
                  isAlert: true,
                },
              ]}
            />
          );
        }
      },
    },
  ];
  const handleSubmit = async ({
    accessUserUuid,
    role,
  }: {
    accessUserUuid: string;
    role: keyof typeof ROLE_TO_NAME;
  }) => {
    await fetchPutSellerAccessUser({
      stateKey,
      body: {
        access_user_uuid: accessUserUuid,
        group_name: role,
      },
    });
    closeEditForm();
    await refetchAccessUsers();
    onChange();
  };
  // @ts-expect-error
  const onSubmit = methods.handleSubmit(handleSubmit);

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <Table
            className={styles.manageAccessUsersTable}
            columns={columns}
            data={tableData}
            maxHeight={300}
            data-testid="access-users-table"
          />
        </form>
      </FormProvider>
    </div>
  );
};
