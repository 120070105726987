import { useContext, useEffect, useRef } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { SellerXeroContext } from './Xero.context';
import { contextLoadedSuccessfully } from './Xero.helpers';
import { buildQueryString } from '../../Buyer/utils/queryParams';

export function EvaluateXeroStep({ children }: { children: React.ReactNode }) {
  const sellerXeroContext = useContext(SellerXeroContext);
  const navigate = useNavigate();
  const location = useLocation();

  const xeroContextLoaded = contextLoadedSuccessfully(sellerXeroContext);
  const xeroAccount =
    contextLoadedSuccessfully(sellerXeroContext) &&
    sellerXeroContext.xeroAccount;

  const alreadyRedirectedRef = useRef(false);

  const currentPathName = location.pathname;
  const queryString = buildQueryString();

  useEffect(() => {
    if (!alreadyRedirectedRef.current && xeroContextLoaded) {
      alreadyRedirectedRef.current = true;
      if (isAuthorisationStep(currentPathName)) {
        navigate(currentPathName + queryString); // leverage the fact that navigate will cause a rerender
      } else if (!xeroAccount || !xeroAccount.tenant.tenant_id) {
        if (currentPathName === '/pay/xero/connect') {
          navigate(currentPathName + queryString, { replace: true });
        } else {
          navigate('/pay/integrations/xero/' + queryString, { replace: true });
        }
      } else if (!xeroAccount.payment_account.payment_account_id) {
        navigate('/pay/xero/payment-account/' + queryString, { replace: true });
      } else if (!xeroAccount.payment_service.payment_service_id) {
        navigate('/pay/xero/invoice-branding-theme/' + queryString, {
          replace: true,
        });
      } else {
        navigate('/pay/xero/account/' + queryString, { replace: true });
      }
    }
  }, [xeroAccount, xeroContextLoaded, navigate, currentPathName, queryString]);

  if (alreadyRedirectedRef.current) {
    // we only want to render children once we know the useEffect has been able to decide what page the user should be onz
    return <>{children}</>;
  } else {
    return null;
  }
}

function isAuthorisationStep(pathname: string) {
  return /\/pay\/xero\/authorisation\//.test(pathname);
}
