import { Button } from '@iwoca/orion';
import { useFormikContext } from 'formik';

import styles from './PrivacyPolicy.module.css';
import { Checkbox } from '../../../../Buyer/components/Checkbox/Checkbox';
import { Policy } from '../../../../Buyer/Signup/steps/DirectorInfo/Policy/Policy';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { CrossSellFormValues } from '../../utils/CrossSellCard.types';

export const PrivacyPolicy = () => {
  const { values, isValid, isSubmitting } =
    useFormikContext<CrossSellFormValues>();

  return (
    <div>
      <div className={styles.scrollableContainer}>
        <Policy className={styles.policyContent}></Policy>
      </div>
      <Checkbox
        disabled={isSubmitting}
        name="policyAgreed"
        labelText="I'm happy with this"
      />
      <Button
        defaultChecked={values.policyAgreed}
        disabled={!isValid || isSubmitting}
        className={styles.button}
      >
        {isSubmitting ? (
          <>
            <div className={styles.isSubmittingText}>Submitting...</div>
            <LoadingSpinner />
          </>
        ) : (
          'Submit Application'
        )}
      </Button>
    </div>
  );
};
