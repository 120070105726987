import styles from './ExploreIntegrationsButton.module.css';
import hamburgerMenuSVG from '../../assets/hamburgerMenu.svg';

export function ExploreIntegrationsButton({
  integrationListOpen,
  handleClick,
}: {
  integrationListOpen: boolean;
  handleClick: () => void;
}) {
  return (
    <button onClick={handleClick} className={styles.exploreIntegrationsButton}>
      {integrationListOpen ? 'Hide integrations' : 'See integrations list'}
      <img
        src={hamburgerMenuSVG}
        alt="Hamburger Menu Icon"
        className={styles.hamburgerMenuSVG}
      />
    </button>
  );
}
