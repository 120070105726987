const WHITE_LISTED_PARAMS = [
  'orderId',
  'payLinkId',
  'state_key',
  'spending_limit_request_uuid',
  'overlay',
  'offerId',
];

export const buildQueryString = (
  newQueryParams?: Record<string, string>,
  addCurrentParams = true,
) => {
  const newSearchParams = new URLSearchParams(newQueryParams);

  const currentSearchParams = addCurrentParams
    ? buildWhiteListedSearchParams()
    : new URLSearchParams();

  const allSearchParams = new URLSearchParams({
    ...Object.fromEntries(newSearchParams),
    ...Object.fromEntries(currentSearchParams),
  });

  const queryString = allSearchParams.toString();

  return queryString ? '?' + queryString : '';
};

const buildWhiteListedSearchParams = () => {
  const params = new URLSearchParams(window.location.search);
  const hydratedSearchParams = new URLSearchParams();

  for (const WHITE_LISTED_PARAM of WHITE_LISTED_PARAMS) {
    const param = params.get(WHITE_LISTED_PARAM);
    if (param) hydratedSearchParams.append(WHITE_LISTED_PARAM, param);
  }
  return hydratedSearchParams;
};
