import { Button } from '@iwoca/orion';

import styles from './IndustriesModal.module.css';
import { Modal } from '../../../../components/Modal/Modal';

export const IndustriesModal = ({
  showRiskyCompaniesModalOpen,
  onClickModal,
}: {
  showRiskyCompaniesModalOpen: boolean;
  onClickModal: () => void;
}) => {
  return (
    <Modal
      isModalOpen={showRiskyCompaniesModalOpen}
      isClosable={true}
      testPrefix={''}
      closeModal={onClickModal}
    >
      <div className={styles.wrap}>
        <span>List of Restricted Industries:</span>
        <ul className={styles.restrictedList}>
          <li>
            Power generation including (without limitation) nuclear power,
            uranium enrichment, coal-fired power plants and hydropower projects
            (but excluding the operation of solar power facilities);
          </li>
          <li>
            The transportation, management and/or treatment of hazardous
            materials, waste or the material bi-products of
            industrial/manufacturing operations;
          </li>
          <li>
            Activities or industries materially involving technologies connected
            with oil shale and/or shale gas;
          </li>
          <li>Deep-sea mining and precious metal extraction;</li>
          <li>Diamond processing and/or trading;</li>
          <li>
            Forestry, the operation of rubber plantations and/or palm oil
            plantations;
          </li>
          <li>
            Weapons manufacture including (without limitation) nuclear weapons,
            cluster munitions and anti-personnel landmines;
          </li>
          <li>Gambling (including on-line gaming);</li>
          <li>Adult entertainment (including on-line adult entertainment);</li>
          <li>
            Hunting and/or trading in exotic animals, ivory or animal fur.
          </li>
        </ul>
        <Button onClick={onClickModal} variant="primary">
          Close
        </Button>
      </div>
    </Modal>
  );
};
