import { useEffect, useState } from 'react';

import styles from './SpendingLimitBar.module.css';

export const SpendingLimitBar = ({
  newPercentageSpent,
  prevPercentageSpent,
  animationDelayInMs,
}: {
  newPercentageSpent: number;
  prevPercentageSpent: number;
  animationDelayInMs: number;
}) => {
  const [fillProgressBar, setFillProgressBar] = useState(false);

  useEffect(() => {
    const amountBarTimeout = setTimeout(() => {
      setFillProgressBar(true);
    }, animationDelayInMs);

    return () => clearTimeout(amountBarTimeout);
  });

  return (
    <div className={styles.progressBar}>
      <span
        className={styles.progressBarStatic}
        style={{
          width: `${prevPercentageSpent}%`,
        }}
      ></span>
      {fillProgressBar && (
        <span
          className={styles.progressBarFill}
          style={{
            width: `${newPercentageSpent}%`,
          }}
        ></span>
      )}
    </div>
  );
};
