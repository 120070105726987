import { fetchGetPaymentMethod } from '@iwoca/lapi-client/edge';
import { useQuery } from '@tanstack/react-query';

import { useStateKey } from './useStateKey.hook';

export function usePaymentMethod() {
  const { stateKey } = useStateKey();

  const { data, isLoading } = useQuery({
    queryKey: ['fetchGetPaymentMethod'],
    queryFn: () => fetchGetPaymentMethod({ stateKey: stateKey! }),
    enabled: Boolean(stateKey),
  });

  const firstPaymentMethod = data?.data[0];

  return {
    bankDetails: firstPaymentMethod?.bank_details || {
      account_number: undefined,
      sort_code: undefined,
    },
    isLoading: isLoading,
  };
}
