import { tracking } from '@iwoca/frontend-tracking-library';
import { Button } from '@iwoca/orion';

import styles from './CrossSellCardIntro.module.css';
import CrossSellHands from '../../assets/crossSellHands.svg';

export const CrossSellCardIntro = () => {
  return (
    <div className={styles.container}>
      <img src={CrossSellHands} alt="" />
      <div className={styles.title}>
        Borrow <b>£1,000-£500,000</b> with iwoca today
      </div>
      <div className={styles.body}>
        It only takes a few minutes to find out how much you can borrow.
      </div>
      <Button
        type="submit"
        variant="secondary"
        className={styles.button}
        onClick={() => tracking.ampli.clickedXSellBanner()}
      >
        See what you can borrow
      </Button>
    </div>
  );
};
