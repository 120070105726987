export const InProgressIcon = ({ title = 'In progress icon', ...props }) => {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <circle
        cx="8"
        cy="8"
        r="7"
        fill="white"
        stroke="#009967"
        strokeWidth="2"
      />
      <circle
        cx="8"
        cy="8"
        r="2"
        fill="#009967"
        stroke="#009967"
        strokeWidth="2"
      />
    </svg>
  );
};
