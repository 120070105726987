export const LaptopIcon = ({ title = 'Laptop icon', ...props }) => {
  return (
    <svg
      {...props}
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        d="M40.1973 7.99994H8.19727C6.54297 7.99994 5.19727 9.34565 5.19727 10.9999V34.9999C5.19727 35.5521 5.64457 35.9999 6.19727 35.9999H42.1973C42.75 35.9999 43.1973 35.5521 43.1973 34.9999V10.9999C43.1973 9.34565 41.8516 7.99994 40.1973 7.99994Z"
        fill="#2B4959"
      />
      <path
        d="M1.19727 33V35C1.19727 37.7568 3.44047 40 6.19727 40H42.1973C44.9541 40 47.1973 37.7568 47.1973 35V33H1.19727Z"
        fill="#E6E6E6"
      />
      <path
        d="M40.1973 10.9999H8.19727V29.9999H40.1973V10.9999Z"
        fill="white"
      />
      <path
        d="M27.1973 36H21.1973C20.0928 36 19.1973 35.1045 19.1973 34V33H29.1973V34C29.1973 35.1045 28.3018 36 27.1973 36Z"
        fill="#B3B3B3"
      />
      <path
        d="M35.1202 15.6179C35.0188 15.3732 34.8239 15.1783 34.5792 15.077C34.457 15.0265 34.3274 15 34.1973 15H28.1973C27.645 15 27.1973 15.4473 27.1973 16C27.1973 16.5527 27.645 17 28.1973 17H31.7832L24.1973 24.5859L18.9043 19.293C18.5137 18.9024 17.8809 18.9024 17.4902 19.293L13.4902 23.293C13.0996 23.6836 13.0996 24.3164 13.4902 24.707C13.8809 25.0976 14.5137 25.0976 14.9043 24.707L18.1973 21.4141L23.4902 26.707C23.6855 26.9023 23.9414 27 24.1973 27C24.4531 27 24.709 26.9023 24.9043 26.707L33.1973 18.4141V22C33.1973 22.5527 33.645 23 34.1973 23C34.7495 23 35.1973 22.5527 35.1973 22V16C35.1973 15.8698 35.1707 15.7401 35.1202 15.6179Z"
        fill="#6A9A5C"
      />
    </svg>
  );
};
