import * as Yup from 'yup';

export const shareholderValidator = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  dateOfBirth: Yup.string()
    .required('Date of birth is required')
    .test(
      'is-DOB',
      'Not a valid date of birth',
      (value) =>
        !!(value && /^([0-3][0-9])\/([0-1][0-9])\/([0-9]{4})$/.test(value)),
    ),
  address: Yup.object().shape({
    manual: Yup.boolean(),
    buildingNumber: Yup.string().when('manual', {
      is: true,
      then: (schema) => schema.required('Building number is required'),
      otherwise: (schema) => schema,
    }),
    streetName: Yup.string().when('manual', {
      is: true,
      then: (schema) => schema.required('Street name is required'),
      otherwise: (schema) => schema,
    }),
    town: Yup.string().when('manual', {
      is: true,
      then: (schema) => schema.required('Town is required'),
      otherwise: (schema) => schema,
    }),
    postcode: Yup.string()
      .required('Post code is required')
      .test(
        'is-postcode',
        'Not a valid postcode',
        (postcode) =>
          !!postcode && postcode.length >= 5 && postcode.length <= 8,
      ),
  }),
});
