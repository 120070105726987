import { useState } from 'react';

import { Button, Icon } from '@iwoca/orion';

import styles from './CheckoutPreview.module.css';
import { TEnabledOptions } from '../../../../Buyer/hooks/useEnabledTerms';
import { PaymentDetails } from '../../../../Buyer/PayLinkLanding/PaymentDetails/PaymentDetails';
import {
  TBuyerInterest,
  TPricingPromotions,
  TRepresentativeInterest,
} from '../../../../Buyer/PayLinkLanding/utils/PayLinkLanding.types';

export const CheckoutPreview = ({
  sellerName,
  amount,
  reference,
  pricingPromotions,
  representativeInterest,
}: {
  sellerName: string;
  amount?: number | string;
  reference?: string;
  pricingPromotions: TPricingPromotions;
  representativeInterest: TRepresentativeInterest;
}) => {
  const [hidden, setHidden] = useState(true);

  const enabledOptions = ['THREE_MONTHS', 'PAY_NOW'];

  if (representativeInterest.TWELVE_MONTHS !== undefined) {
    enabledOptions.push('TWELVE_MONTHS');
  }

  // Construct buyerInterest only using rep rate (no formal offers in preview)
  const buyerInterest = {
    THREE_MONTHS: {
      rate: representativeInterest.THREE_MONTHS,
      type: 'representative',
      isSellerPays: representativeInterest.THREE_MONTHS === 0,
    },
    ...(representativeInterest?.TWELVE_MONTHS !== undefined && {
      TWELVE_MONTHS: {
        rate: representativeInterest.TWELVE_MONTHS,
        type: 'representative',
        isSellerPays: representativeInterest.TWELVE_MONTHS === 0,
      },
    }),
  } as TBuyerInterest;

  return (
    <>
      <Button
        variant="secondary"
        className="my-m"
        onClick={() => setHidden((prev) => !prev)}
        data-ocularid="toggleCustomerPreview"
      >
        {hidden ? 'Show' : 'Hide'} customer preview <Icon icon="eyeOutline" />
      </Button>
      <div className="relative mt-2xl">
        {hidden && (
          <span className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 text-structure-40">
            Preview will show here
          </span>
        )}

        <div
          className={`${styles.previewWrapper} ${
            hidden ? 'blur-lg grayscale' : ''
          }`}
          data-testid="checkoutPreview"
        >
          <PaymentDetails
            isPreview={true}
            sellerName={sellerName!}
            pricingPromotions={pricingPromotions}
            buyerInterest={buyerInterest}
            initialValues={{ amount: (amount || '').toString(), reference }}
            disableAmount={Boolean(amount || '')}
            disableReference={Boolean(reference)}
            enabledOptions={enabledOptions as TEnabledOptions}
            payLinkType="preview"
          />
        </div>
      </div>
    </>
  );
};
