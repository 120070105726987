import { useCallback, useState, useEffect } from 'react';

import { useGetSellerBranding } from '../../api/lending/lapiHooks';
import { useSellerPricing } from '../../hooks/useSellerPricing';

export function usePreview() {
  const { sellerPricing } = useSellerPricing();
  const { sellerBranding } = useGetSellerBranding();

  const [previewValuesState, setPreviewValuesState] = useState({
    amount: '',
    reference: '',
    pricing_3m: '',
    pricing_12m: '',
    sellerName: '',
  });

  const setPreviewValues = useCallback(
    (name: string, value: unknown) => {
      setPreviewValuesState((prevValues) => {
        if (!Object.keys(prevValues).includes(name)) return prevValues;

        return {
          ...prevValues,
          [name]: value,
        };
      });
    },
    [setPreviewValuesState],
  );

  const setPreviewValuesFromEvent = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPreviewValues(e.target.name, e.target.value);
  };

  useEffect(() => {
    if (sellerPricing?.product_pricing_options !== undefined) {
      setPreviewValues(
        'pricing_3m',
        sellerPricing?.product_pricing_options.THREE_MONTHS.find(
          ({ is_enabled }) => is_enabled,
        )?.pricing_type,
      );
      setPreviewValues(
        'pricing_12m',
        sellerPricing?.product_pricing_options.TWELVE_MONTHS?.find(
          ({ is_enabled }) => is_enabled,
        )?.pricing_type,
      );
    }
  }, [sellerPricing, setPreviewValues]);

  const pricingDetails_3m =
    sellerPricing?.product_pricing_options.THREE_MONTHS.find(
      ({ pricing_type }) => pricing_type === previewValuesState.pricing_3m,
    )!;

  const pricingConfig_3m = previewValuesState.pricing_3m
    ? {
        type: previewValuesState.pricing_3m,
        promotions: pricingDetails_3m.buyer.promotions,
        representativeInterest: pricingDetails_3m.buyer.representative_interest,
      }
    : null;

  const pricingDetails_12m =
    sellerPricing?.product_pricing_options.TWELVE_MONTHS?.find(
      ({ pricing_type }) => pricing_type === previewValuesState.pricing_12m,
    )!;

  const pricingConfig_12m =
    previewValuesState.pricing_12m && pricingDetails_12m
      ? {
          type: previewValuesState.pricing_12m,
          promotions: pricingDetails_12m.buyer.promotions,
          representativeInterest:
            pricingDetails_12m.buyer.representative_interest,
        }
      : null;

  return {
    previewValues: {
      amount: previewValuesState.amount,
      reference: previewValuesState.reference,
      sellerName: sellerBranding?.trading_name || '',
      pricing_3m: pricingConfig_3m,
      pricing_12m: pricingConfig_12m,
    },
    setPreviewValuesFromEvent,
    setPreviewValues,
  };
}
