import { Modal } from '@iwoca/orion';

import { useGetContext, useGetProfile } from '../../api/lending/lapiHooks';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Draw } from '../components/Draw';
import { Login } from '../components/Login/Login';

export const LoginPanel = ({
  open,
  close,
}: {
  open: boolean;
  close: () => void;
}) => {
  const { isMobile } = useIsMobile();
  const { getContext } = useGetContext();
  const { getProfile } = useGetProfile();

  const login = (
    <Login
      onSuccessfulLogin={async () => {
        await getContext();
        await getProfile();
        close?.();
        scrollTo(0, 0);
      }}
    />
  );

  return isMobile ? (
    <Draw open={open} close={close} title="Log in to your iwoca account">
      {login}
    </Draw>
  ) : (
    <Modal
      isOpen={open}
      onClose={close}
      closeIcon
      title="Log in to your iwoca account"
    >
      {login}
    </Modal>
  );
};
