import { useState, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { HowWeUseYourInformation } from './HowWeUseYourInformation/HowWeUseYourInformation';
import { XeroRedirect } from './XeroRedirect/XeroRedirect';
import { buildQueryString } from '../../../Buyer/utils/queryParams';
import { SellerXeroContext } from '../Xero.context';
import styles from '../Xero.module.css';

type XeroLinkStatus = 'howWeUseYourInfo' | 'xeroRedirect';

export function Connect() {
  const [status, setStatus] = useState<XeroLinkStatus>('howWeUseYourInfo');
  const sellerXeroContext = useContext(SellerXeroContext);
  const navigate = useNavigate();
  const queryString = buildQueryString();

  return (
    <div className={styles.sellerXero}>
      {status === 'howWeUseYourInfo' && (
        <HowWeUseYourInformation
          onNextClick={() => setStatus('xeroRedirect')}
          onBackClick={() => navigate('/pay/integrations/xero' + queryString)}
        />
      )}
      {status === 'xeroRedirect' &&
        !sellerXeroContext.loading &&
        !sellerXeroContext.error && (
          <XeroRedirect stateKey={sellerXeroContext.stateKey!} />
        )}
    </div>
  );
}
