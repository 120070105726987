import * as Yup from 'yup';

export const requestCallbackValidator = Yup.object().shape({
  callbackPhoneNumber: Yup.string()
    .required('Phone number is required')
    .test(
      'is-phoneNumber',
      'Not a valid phone number',
      (value) =>
        !!(
          value &&
          value.length > 9 &&
          value.length < 14 &&
          /^\+[0-9]*|^0[0-9]*$/.test(value)
        ),
    ),
});
