import { TGetFundingRequirementsResponse } from '../../../../api/lending/edge';
import { TVisibilityFunction } from '../../routes';

type TFundingRequirementType =
  TGetFundingRequirementsResponse['data'][number]['type'];

const WHITELISTED_REQUIREMENT_TYPES: TFundingRequirementType[] = [
  'check_mla_covers_post_funding_balance',
  'signed_documents_requirement',
];

/* We display Give Us a Call when there is an existing
  non-optional, incomplete funding requirement. */
export const isGiveUsACallVisible = ({
  fundingRequirement,
}: Partial<
  Parameters<TVisibilityFunction>[0]
>): ReturnType<TVisibilityFunction> => {
  if (!fundingRequirement) {
    return true;
  }

  for (const requirement of fundingRequirement) {
    if (WHITELISTED_REQUIREMENT_TYPES.includes(requirement.type!)) {
      continue;
    }

    if (!requirement.is_optional && requirement.status !== 'completed') {
      return true;
    }
  }

  return false;
};
