import styles from './LoadingScreen.module.css';
import IwocaIcon from '../svg/IwocaPayIconCompact.svg?react';

export function LoadingScreen() {
  return (
    <div className={styles.loaderOverlay}>
      <IwocaIcon
        className={styles.iwocaPayIcon}
        viewBox="0 0 55 35"
        width="200"
        height="100"
        title="iwocaPay icon"
      />
      <div className={styles.loadingWrapper}>
        <h1 className={styles.loadingText}>Loading</h1>
        <div className={styles.dotFlashing}></div>
      </div>
    </div>
  );
}
