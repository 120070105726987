const PAYNOW_IWOCAPAY_EMAIL_TOKEN = '3cc1dd1f-0c8c-4a6e-9ef0-f3705af6c657';

const _getKey = async (password: string, salt: string): Promise<CryptoKey> => {
  const encoder = new TextEncoder();
  const keyMaterial = await window.crypto.subtle.importKey(
    'raw',
    encoder.encode(password),
    'PBKDF2',
    false,
    ['deriveKey'],
  );
  return await window.crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: encoder.encode(salt),
      iterations: 100000,
      hash: 'SHA-256',
    },
    keyMaterial,
    { name: 'AES-GCM', length: 256 },
    true,
    ['encrypt', 'decrypt'],
  );
};

const _convertArrayBufferToBase64 = (buffer: ArrayBuffer): string => {
  const binary = String.fromCharCode(...new Uint8Array(buffer));
  return btoa(binary);
};

const _convertBase64ToArrayBuffer = (base64: string): ArrayBuffer => {
  const binary = atob(base64);
  const bytes = Uint8Array.from(binary, (char) => char.charCodeAt(0));
  return bytes.buffer;
};

const _encrypt = async (
  text: string,
  password: string,
  salt: string,
): Promise<string> => {
  const key = await _getKey(password, salt);
  const iv = window.crypto.getRandomValues(new Uint8Array(12));
  const encoder = new TextEncoder();
  const encryptedContent = await window.crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    key,
    encoder.encode(text),
  );
  return (
    _convertArrayBufferToBase64(iv.buffer) +
    _convertArrayBufferToBase64(encryptedContent)
  );
};

const _decrypt = async (
  encryptedText: string,
  password: string,
  salt: string,
): Promise<string> => {
  const key = await _getKey(password, salt);
  const data = _convertBase64ToArrayBuffer(encryptedText);
  const iv = new Uint8Array(data.slice(0, 12));
  const encData = new Uint8Array(data.slice(12));
  const decryptedContent = await window.crypto.subtle.decrypt(
    { name: 'AES-GCM', iv },
    key,
    encData,
  );
  return new TextDecoder().decode(decryptedContent);
};

const _extractPaymentId = (url: string): string | null => {
  const match = url.match(/#payment_id=([^&]*)/);
  return match ? match[1] : null;
};

export const encryptPayNowEmail = async ({
  emailAddress,
  authURI,
}: {
  emailAddress: string;
  authURI: string;
}): Promise<string | null> => {
  try {
    const paymentId = _extractPaymentId(authURI);
    if (!paymentId) return null;
    return await _encrypt(emailAddress, PAYNOW_IWOCAPAY_EMAIL_TOKEN, paymentId);
  } catch {
    return null;
  }
};

export const decryptPayNowEmail = async ({
  paymentId,
  encryptedString,
}: {
  paymentId: string;
  encryptedString: string;
}): Promise<string | null> => {
  try {
    return await _decrypt(
      encryptedString,
      PAYNOW_IWOCAPAY_EMAIL_TOKEN,
      paymentId,
    );
  } catch {
    return null;
  }
};
