import { useState } from 'react';

import classnames from 'classnames';

import styles from './Accordion.module.css';
import Chevron from '../../../../../components/svg/Chevron.svg?react';

export function Accordion({
  title,
  children,
}: {
  title: string;
  children: React.ReactChild;
}) {
  const [isPanelDisplayed, setIsPanelDisplayed] = useState(false);
  return (
    <>
      <button
        className={styles.accordion}
        onClick={() => setIsPanelDisplayed(!isPanelDisplayed)}
      >
        {title}
        <Chevron
          className={classnames(styles.chevron, {
            [styles.activeChevron]: isPanelDisplayed,
          })}
        />
      </button>
      {isPanelDisplayed && <div className={styles.panel}>{children}</div>}
    </>
  );
}
