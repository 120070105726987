import {
  useGetSellerByHandle,
  useGetSellerEnabledProducts,
  usePutSellerEnabledProducts,
} from '../../../api/lending/lapiHooks';
import { useSellerHandle } from '../../../hooks/useSellerHandle.hook';
import { useSellerPricing } from '../../../hooks/useSellerPricing';
import { TSellerProduct } from '../utils/Paylinks.types';

export const usePayIn12 = () => {
  const { sellerHandle } = useSellerHandle();
  const { fetchSeller } = useGetSellerByHandle(sellerHandle || undefined);
  const { fetchSellerPricing } = useSellerPricing();
  const { sellerEnabledProducts, loadingSellerEnabledProducts } =
    useGetSellerEnabledProducts();
  const { putSellerEnabledProducts } = usePutSellerEnabledProducts();

  const enabledProducts = sellerEnabledProducts?.enabled_products || [];
  const payIn12Enabled = enabledProducts.includes('TWELVE_MONTHS');

  const togglePayIn12 = async () => {
    const newEnabledProducts: TSellerProduct[] = payIn12Enabled
      ? enabledProducts.filter(
          (enabledProduct) => enabledProduct !== 'TWELVE_MONTHS',
        )
      : enabledProducts.concat(['TWELVE_MONTHS']);
    await putSellerEnabledProducts({
      enabled_products: newEnabledProducts,
    });
    await fetchSeller();
    await fetchSellerPricing();
  };

  return {
    payIn12Enabled,
    togglePayIn12,
    isLoading: loadingSellerEnabledProducts,
  };
};
