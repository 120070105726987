import { AlertBox, Icon, Modal } from '@iwoca/orion';
import cn from 'classnames';

import { Draw } from '../../../../../../Buyer/components/Draw';
import { iwocapayTelDisplay } from '../../../../../../constants.json';
import { useIsMobile } from '../../../../../../hooks/useIsMobile';

export const PayLinkStatusModal = ({
  open,
  close,
}: {
  open: boolean;
  close: () => void;
}) => {
  const { isMobile } = useIsMobile();

  return isMobile ? (
    <Draw
      open={open}
      close={close}
      title={
        <>
          <h1 className="text-2xl font-bold">Paylink statuses</h1>
          <h2 className="text-s font-reg">
            This is the journey that your customers will go through when
            applying for iwocaPay.
          </h2>
        </>
      }
    >
      <PayLinkStatus />
    </Draw>
  ) : (
    <Modal
      closeIcon
      headerBar
      id="modal_id"
      isOpen={open}
      onClose={close}
      subTitle="This is the journey that your customers will go through when applying for iwocaPay."
      title="Paylink statuses"
    >
      <PayLinkStatus />
    </Modal>
  );
};

const Status = ({
  name,
  description,
  information,
  className,
}: {
  name: string;
  description: string;
  information?: string;
  className?: string;
}) => {
  return (
    <div className={cn('flex gap-l', className)}>
      <div className="flex-shrink-0 flex-grow-0 basis-[100px] text-s font-med">
        {name}
      </div>
      <div className="flex flex-col gap-s text-xs font-reg">
        <div>{description}</div>
        {information && (
          <div className="flex gap-xs">
            <Icon
              icon="infoCircleOutline"
              height={16}
              width={16}
              className="flex-shrink-0"
            />
            {information}
          </div>
        )}
      </div>
    </div>
  );
};

const PayLinkStatus = () => {
  return (
    <>
      <div className="flex flex-col">
        <Status
          name="Not started"
          description="Your customer has clicked to pay with iwocaPay, but hasn’t yet
              entered their email address and started their application."
          information="We can’t contact the customer yet"
          className="mx-[-24px] border-b border-b-secondary-90 px-[24px] pb-l pt-s"
        />
        <Status
          name="Started"
          description="The customer has entered their email address and started their application. They haven’t yet submitted their information."
          information="We have their email address"
          className="mx-[-24px] border-b border-b-secondary-90 px-[24px] py-l"
        />
        <div className="mx-[-24px] border-b border-b-secondary-90 px-[24px] py-l">
          <Status
            name="Partially approved"
            description="We've approved your customer to use Pay Later - but for less than they need to pay for this order."
            information={`If you’d like assistance with this transaction, please get in touch with us on ${iwocapayTelDisplay}`}
            className="pb-l"
          />
          <div className="flex items-center gap-s">
            <div className="w-full border-b border-b-secondary-90" />
            <div className="text-xs font-reg text-secondary-40">or</div>
            <div className="w-full border-b border-b-secondary-90" />
          </div>
          <Status
            name="Eligible"
            description="The customer’s application has been accepted and they need to complete our security checks."
            information="We can call them or email them"
            className="pt-l"
          />
        </div>
        <Status
          name="You’ve been paid! 🙌"
          description="The customer has completed the process and we’ve sent the funds to your account."
          className="mx-[-24px] mb-m border-b border-b-secondary-90 px-[24px] py-l"
        />
      </div>
      <AlertBox
        className="mb-xs mt-l flex gap-s text-s font-reg"
        variant="connect"
      >
        <Icon
          icon="alertCircle"
          height={20}
          width={20}
          className="flex-shrink-0"
        />
        <div>
          <b className="font-bold">Please note: - </b>At all stages, whilst we
          might be able to contact the customer, please continue to encourage
          your customer to complete the process.
        </div>
      </AlertBox>
    </>
  );
};
