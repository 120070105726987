import { Button } from '@iwoca/orion';
import classnames from 'classnames';

import styles from './CancelButton.module.css';
import { BuyerPayLink } from '../../../Seller/PayLinks/utils/Paylinks.types';

export const CancelButton = ({
  payLink,
  ...props
}: {
  payLink: BuyerPayLink;
} & React.HTMLAttributes<HTMLButtonElement>) => {
  return (
    <Button
      {...props}
      className={classnames(styles.cancelButton, props.className)}
      variant="tertiary"
      href={payLink.cancel_url}
    >
      Cancel order
    </Button>
  );
};
