import { useGetContext } from '../api/lending/lapiHooks';

export function useStateKey() {
  const { context, getContext, loadingContext } = useGetContext();

  return {
    stateKey: context.stateKey || null,
    fetchSiteContext: getContext,
    loadingStateKey: loadingContext,
  };
}
