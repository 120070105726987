import React from 'react';

import { Card } from '@iwoca/orion';

import { CopyLandingLink } from './CopyLandingLink/SpendingLimitLandingLink';
import styles from './SpendingLimitBanner.module.css';
import PersonIcon from '../../../../assets/PersonIcon.png';
import { MAX_AMOUNT_PAY_LATER } from '../../../../Buyer/PayLinkLanding/PaymentDetails/PaymentDetails';
import { TickIcon } from '../../../../components/svg/TickIcon';
import { displayCurrency } from '../../../../utils/DisplayCurrency';

const BenefitItem = ({ children }: { children: React.ReactNode }) => {
  return (
    <li className={styles.benefit}>
      <span className={styles.benefitIcon}>
        <TickIcon
          title="Approved application tick"
          className={styles.statusIcon}
        />
      </span>
      <p className={styles.benefitContent}>{children}</p>
    </li>
  );
};

export const SpendingLimitBanner = () => {
  const maxAmountText = displayCurrency(MAX_AMOUNT_PAY_LATER, 0);

  return (
    <Card className={styles.cardContainer}>
      <div className={styles.innerContainer}>
        <div>
          <img
            src={PersonIcon}
            className={styles.personIcon}
            alt="Person icon"
          />
        </div>
        <div>
          <div className={styles.heading}>
            Give your trade customers a spending limit up to {maxAmountText}
          </div>
          <div className={styles.subheading}>
            Now your trade customers can access their spending limit before they
            are at the checkout with their iwocaPay Account.
          </div>
          <ul className={styles.benefits}>
            <BenefitItem>
              Outsource risk, admin and checks to the experts (us)
            </BenefitItem>
            <BenefitItem>
              Increase average order value and customer loyalty
            </BenefitItem>
            <BenefitItem>
              Slicker checkout experience for new and existing customers
            </BenefitItem>
          </ul>
          <CopyLandingLink />
        </div>
      </div>
    </Card>
  );
};
