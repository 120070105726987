import { useEffect, useState } from 'react';

import { GetBuyerSpendingLimitResponse } from '@iwoca/lapi-client/iwocapay';
import { Button, Icon, Spinner } from '@iwoca/orion';

import styles from './Success.module.css';
import { displayCurrency } from '../../../../../utils/DisplayCurrency';
import { useEcomPostMessage } from '../../../../hooks/useEcomPostMessage';
import { useCurrentPayLink } from '../../../hooks/useCurrentPayLink';
import { DISPLAY_TYPE_ENUM, TDisplayType } from '../DrawDownModal';
import { SpendingLimitBar } from '../SpendingLimitBar/SpendingLimitBar';

type TSpendingLimit =
  GetBuyerSpendingLimitResponse['data']['active_spending_limit'];

export const ECOM_REDIRECT_DELAY_IN_SECONDS = 5;

export const Success = ({
  finishDrawDown,
  displayType,
  redirectUrl,
  spendingLimit,
}: {
  finishDrawDown: () => void;
  displayType: TDisplayType;
  redirectUrl: string;
  spendingLimit?: TSpendingLimit;
}) => {
  const { payLink } = useCurrentPayLink();
  const [redirectCount, setRedirectCount] = useState(
    ECOM_REDIRECT_DELAY_IN_SECONDS,
  );
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    if (showFooter) return;

    const footerTimeout = setTimeout(() => {
      setShowFooter(true);
    }, 1500);

    return () => clearTimeout(footerTimeout);
  }, [showFooter]);

  useEffect(() => {
    if (displayType !== DISPLAY_TYPE_ENUM.ECOMMERCE) return;
    if (!showFooter) return;

    if (redirectCount === 0) {
      window.location.href = redirectUrl;
      return;
    }

    const redirectTimout = setTimeout(() => {
      setRedirectCount((prevCount) => prevCount - 1);
    }, 1000);
    return () => clearTimeout(redirectTimout);
  }, [showFooter, redirectCount, redirectUrl, displayType]);

  useEcomPostMessage({
    enabled: displayType === DISPLAY_TYPE_ENUM.OVERLAY,
    status: 'SUCCESSFUL',
    redirectUrl,
  });

  const getFooter = () => {
    return (
      <div className={styles.footer}>
        {displayType === DISPLAY_TYPE_ENUM.OVERLAY && (
          <>
            You can now safely close this window and return to{' '}
            {payLink?.seller_trading_name}.
          </>
        )}
        {displayType === DISPLAY_TYPE_ENUM.ECOMMERCE && (
          <>
            <Spinner className={styles.redirectSpinner} /> Taking you back to{' '}
            {payLink?.seller_trading_name}... {redirectCount}s
          </>
        )}
        {displayType === DISPLAY_TYPE_ENUM.DEFAULT && (
          <Button
            variant="primary"
            className={styles.drawDownModalButton}
            onClick={finishDrawDown}
          >
            Go to the dashboard
          </Button>
        )}
      </div>
    );
  };

  if (!payLink || !spendingLimit) return null;

  // Calculate the new spent amount on the FE so we don't have to wait for the BE to update
  const updatedSpentAmount = spendingLimit.spent_amount + payLink.amount;
  const updatedPercentageSpent =
    (updatedSpentAmount / spendingLimit.max_amount) * 100;

  return (
    <div className={styles.successWrapper}>
      <div>
        <div className={styles.titleWrapper}>
          <Icon icon="checkCircleOutline" className={styles.tickIcon} />
          <div className={styles.title}>Payment complete</div>
          <div className={styles.subtitle}>
            We've sent the money to {payLink?.seller_trading_name}
          </div>
        </div>
        <div className={styles.spendingLimitWrapper}>
          <div className={styles.totalSpent}>
            <div>Total spent:</div>
            <div className={styles.limitAmount}>
              {displayCurrency(updatedSpentAmount)}
            </div>
          </div>
          <div className={styles.totalLimit}>
            <div>Total spending limit:</div>
            <div className={styles.limitAmount}>
              {displayCurrency(spendingLimit.max_amount)}
            </div>
          </div>
        </div>
        <SpendingLimitBar
          newPercentageSpent={updatedPercentageSpent}
          prevPercentageSpent={spendingLimit.spent_amount_percentage}
          animationDelayInMs={500}
        />
      </div>
      {showFooter && getFooter()}
    </div>
  );
};
