import { ReactNode } from 'react';

import styles from './MobileCard.module.css';
import { Card } from '../../../../../Buyer/components/Card/Card';
import { PayMeLink } from '../../../../../components/PayMeLink/PayMeLink';

export const MobileCard = ({
  reference,
  amount,
  dateCreated,
  payLinkPackageUrl,
}: {
  reference: string;
  amount: string;
  dateCreated: string;
  payLinkPackageUrl: string;
}) => {
  return (
    <Card className={styles.mobileCard}>
      <div className={styles.mobileCardInfo}>
        <MobileCardInfo title="Ref" value={reference} />
        <MobileCardInfo title="Amount" value={amount} />
        <MobileCardInfo title="Date" value={dateCreated} />
      </div>

      <div>
        <strong className={styles.infoTitle}>Pay later terms</strong>
        <PayMeLink payMeUrl={payLinkPackageUrl} />
      </div>
    </Card>
  );
};

const MobileCardInfo = ({
  title,
  value,
}: {
  title: string;
  value: string | ReactNode;
}) => {
  return (
    <div>
      <strong className={styles.infoTitle}>{title}</strong>
      <span className={styles.infoValue}>{value}</span>
    </div>
  );
};
