import React from 'react';

import styles from './PageNotFound.module.css';
import { useGetContext } from '../../api/lending/lapiHooks';
import { ErrorBoundary } from '../../App';
import { SubmitButton } from '../../Buyer/components/Button/Button';
import { buildQueryString } from '../../Buyer/utils/queryParams';
import { DynamicPageTemplate } from '../../components/PageTemplate/PageTemplate';

export const PageNotFound = () => {
  const {
    context: { isLoggedIn },
  } = useGetContext();

  return (
    <div className={styles.App}>
      <ErrorBoundary>
        <div className={styles.upperContainer}>
          <DynamicPageTemplate>
            <div className={styles.positioning}>
              <h1 className={styles.header}>404</h1>
              <p className={styles.subtitle}>
                Oops! Looks like we couldn’t find what you were looking for.
                Please check the URL or go back and try again.
              </p>
              {isLoggedIn && (
                <a href={`/account/${buildQueryString()}`}>
                  <SubmitButton className={styles.button}>
                    Go to dashboard
                  </SubmitButton>
                </a>
              )}
            </div>
          </DynamicPageTemplate>
        </div>
      </ErrorBoundary>
    </div>
  );
};
