import styles from './HelpBox.module.css';
import {
  iwocapayTelDisplay,
  iwocapayTelLink,
} from '../../../../src/constants.json';
import TelephoneIcon from '../../../components/svg/TelephoneIcon.svg?react';

export const HelpBox = () => {
  return (
    <div className={styles.helpBox}>
      <h4 className={styles.title}>Need some help?</h4>
      <div className={styles.contactUs}>
        <span>Give us a call on </span>
        <a href={iwocapayTelLink} className={styles.phoneLink}>
          <TelephoneIcon className={styles.phoneIcon} />
          <span>{iwocapayTelDisplay}</span>
        </a>
      </div>
      <div className={styles.openingHours}>Mon - Fri (9am - 6pm)</div>
      <a
        href="https://www.iwoca.co.uk/iwocapay-buyer-pay-later"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.findoutMore}
      >
        Find out more about iwocaPay
      </a>
    </div>
  );
};
