import { AccountInfo } from './components/AccountInfo/AccountInfo';
import { BusinessDetails } from './components/BusinessDetails/BusinessDetails';
import { DirectorsAndShareholders } from './components/DirectorsAndShareholders/DirectorsAndShareholders';
import { ManageAccountBlock } from './components/ManageAccountBlock/ManageAccountBlock';
import { PayLaterPricingSettings } from './components/PayLaterPricingSettings/PayLaterPricingSettings';
import { PaymentMethod } from './components/PaymentMethod/PaymentMethod';
import { HideFor } from '../../components/ConditionalDisplay/ConditionalDisplay';
import { SafeLink } from '../../components/SafeLink/SafeLink';

export type TOptionLink = {
  title: string;
  onClick: () => void;
};

export const ManageAccount = () => {
  return (
    <div className="mb-4xl max-w-2xl text-primary-10">
      <div className="text-left sm:pl-m sm:pr-m">
        <h1 className="text-4xl">Manage account</h1>
        <div className="grid grid-cols-1 gap-4xl">
          <HideFor editor contributor>
            <ManageAccountBlock
              title="Choose who pays for Pay Later"
              subtitle={[
                <>
                  These settings will apply for Universal links, integrations
                  and API.
                </>,
                <>
                  It’ll also be the default for Package and Custom links - head
                  to the{' '}
                  <SafeLink to="/pay/pay-links/custom">Pay links page</SafeLink>{' '}
                  to make changes on these links.
                </>,
              ]}
            >
              <PayLaterPricingSettings />
            </ManageAccountBlock>
          </HideFor>

          <ManageAccountBlock
            title="Your profile info"
            subtitle={[
              <>
                Contact your account manager if you wish to change this info.
              </>,
            ]}
          >
            <AccountInfo />
          </ManageAccountBlock>

          <HideFor contributor editor admin>
            <ManageAccountBlock
              title="Your iwoca business details"
              subtitle={[
                <>This is what information we have on you and your business.</>,
              ]}
              link={{
                title: 'Edit this info',
                onClick: () =>
                  (window.location.href = `/account/company/${window.location.search}`),
              }}
            >
              <BusinessDetails />
            </ManageAccountBlock>

            <ManageAccountBlock
              title="Business bank details"
              subtitle={[<>This is where we'll send your funds:</>]}
            >
              <PaymentMethod />
            </ManageAccountBlock>

            <ManageAccountBlock
              title="Directors and shareholders"
              subtitle={[
                <>
                  These are the details of all the directors and major
                  shareholders with 25% or more shares in your business.
                </>,
              ]}
            >
              <DirectorsAndShareholders />
            </ManageAccountBlock>
          </HideFor>
        </div>
      </div>
    </div>
  );
};
