import { useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { STEPS, StepType } from './Steps';
import { NormalisedDataType, useGetStateQuery } from './StepValidator';
import { normaliseStepData } from '../stateNormalisation';

export const STEP_STATUS = {
  ACTIVE: 'active',
  COMPLETE: 'complete',
  INCOMPLETE: 'incomplete',
} as const;

type TStepStatusOptions = (typeof STEP_STATUS)[keyof typeof STEP_STATUS];

export type TStepStatus = {
  id: StepType;
  status: TStepStatusOptions;
};

export function getStepsStatus(data: NormalisedDataType) {
  const status: TStepStatus[] = [];

  if (!data) {
    return null;
  }

  for (const { identifier, validator } of STEPS) {
    const isValidStep = validator(data[identifier]);
    status.push({
      id: identifier,
      status: isValidStep ? STEP_STATUS.COMPLETE : STEP_STATUS.INCOMPLETE,
    });
  }

  return status;
}

type StepsStatusType = Awaited<ReturnType<typeof getStepsStatus>>;
export function useStepsStatus() {
  const [stepsStatus, setStepsStatus] = useState<{
    [key: string]: StepsStatusType | TStepStatus;
    details: TStepStatus[];
    fraud: TStepStatus[];
    offer: TStepStatus[];
  }>({
    details: [],
    fraud: [],
    offer: [],
  });
  const { stepname } = useParams();
  const { data } = useGetStateQuery(normaliseStepData);
  const location = useLocation();

  const updateStepStatus = () => {
    const currentStepsStatus: typeof stepsStatus = {
      details: [],
      fraud: [],
      offer: [],
    };

    const detailsStepStatus = getStepsStatus(data);
    if (detailsStepStatus === null) return null;

    const currentStep = detailsStepStatus.find(({ id }) => id === stepname);
    if (currentStep !== undefined) {
      currentStep.status = STEP_STATUS.ACTIVE;
    }
    currentStepsStatus.details = detailsStepStatus;

    setStepsStatus(currentStepsStatus);
  };

  useEffect(() => {
    void updateStepStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, location.pathname]);

  return stepsStatus;
}

export function useStepStatus(stepId: StepType) {
  const stepsStatus = useStepsStatus();
  const currentStep = stepsStatus.details?.find(({ id }) => id === stepId);

  return currentStep?.status;
}
