import { useEffect, useState } from 'react';

import {
  fetchPostOnfidoCheck,
  GetStateResponse,
} from '@iwoca/lapi-client/edge';
import { Button } from '@iwoca/orion';
import classnames from 'classnames';

import styles from './IDCheck.module.css';
import {
  IDCheckPendingModal,
  ID_CHECK_PENDING_MODAL,
} from './IDCheckPendingModal';
import { PhoneNumberForm } from './PhoneNumberForm';
import { TooManyAttempts } from './TooManyAttempts';
import { TGetFundingRequirementsResponse } from '../../../../api/lending/edge';
import AlertIcon from '../../../../components/svg/AlertIcon.svg?react';
import Onfido from '../../../../components/svg/Onfido.svg';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import { useStateKey } from '../../../../hooks/useStateKey.hook';
import { RecursiveNonNullable } from '../../../../utils/typeUtils';
import { Accordion } from '../../../components/Accordion/Accordion';
import { Card } from '../../../components/Card/Card';
import { useIsOverlay } from '../../../hooks/useIsOverlay';
import { useModal } from '../../../store/ModalProvider';

export type CustomerPhone =
  RecursiveNonNullable<GetStateResponse>['data']['application']['people'][number]['phones'][number];

export type CustomerStatus =
  RecursiveNonNullable<TGetFundingRequirementsResponse>['data'][0]['resolution_methods'][0]['items'][0]['status'];

export const IDCheckNotComplete = ({
  applicantStatus,
  applicantUID,
}: {
  applicantStatus: CustomerStatus;
  applicantUID: string | undefined;
}) => {
  const { stateKey } = useStateKey();
  const { openModal } = useModal(ID_CHECK_PENDING_MODAL);
  const [isTooManyAttempts, setIsTooManyAttempts] = useState(false);
  const isDesktop = useMediaQuery('(min-width:600px)');
  const isOverlay = useIsOverlay();

  const [isForceShowOnfidoSMS, setIsForceShowOnfidoSMS] = useState(false);
  const showOnfidoSMSInput = isDesktop || isForceShowOnfidoSMS || isOverlay;

  const submitButtonText =
    applicantStatus === 'error' ? 'Try again' : 'Go to ID check';

  useEffect(() => {
    if (applicantStatus === 'pending') {
      openModal();
    }
  }, [applicantStatus, openModal]);

  const redirectToIDCheck = async () => {
    try {
      const {
        data: { verify_url: verifyUrl },
      } = await fetchPostOnfidoCheck({
        stateKey: stateKey!,
        personUid: applicantUID!,
      });
      openModal();
      window.open(verifyUrl);
    } catch (_) {
      setIsTooManyAttempts(true);
    }
  };

  if (isTooManyAttempts) {
    return <TooManyAttempts />;
  }

  return (
    <Card title="ID check">
      <IDCheckPendingModal
        onClickForceShowOnfidoSMS={() => setIsForceShowOnfidoSMS(true)}
        showOnfidoSMSInput={showOnfidoSMSInput}
      />
      <Accordion
        className={styles.expandingMessage}
        title="We need to confirm it's really you"
      >
        <div className={styles.expandingMessageBody}>
          We just need to do a quick and simple ID check to check you are who
          you say you are. It stops fraudsters paying for things under your
          name.
        </div>
      </Accordion>
      <div className={styles.stepDescription}>
        We'll need you to take a photo of <b>one</b> of the <br /> following
        documents:
        <ul className={styles.documentsList}>
          <li>Passport</li>
          <li>Driving license</li>
        </ul>
      </div>
      {applicantStatus === 'error' && (
        <div className={styles.errorMessage}>
          <AlertIcon className={styles.errorIcon} />
          <div className={styles.errorText}>
            <p className={styles.bottomSpacer}>
              Seems something wasn't quite right with your ID check. Please try
              again.
            </p>
            <p className={styles.zeroBottomSpacer}>
              Make sure the corners are aligned in the capture area, that you're
              in a well-lit envronment and that the picture is clearly visible.
            </p>
          </div>
        </div>
      )}
      {!showOnfidoSMSInput ? (
        <div className={styles.mobileAction}>
          <Button
            variant="primary"
            className={classnames(
              styles.submitButton,
              styles.mobileVerificationButton,
            )}
            onClick={redirectToIDCheck}
          >
            {submitButtonText}
          </Button>
          <div className={styles.stepFooter}>
            Secured and powered by{' '}
            <img src={Onfido} alt="Onfido logo" className={styles.onfidoIcon} />
          </div>
        </div>
      ) : (
        <div className={styles.desktopAction}>
          <PhoneNumberForm
            applicantUID={applicantUID}
            applicantStatus={applicantStatus}
            onError={() => setIsTooManyAttempts(true)}
          />
        </div>
      )}
    </Card>
  );
};
