import classnames from 'classnames';

import styles from './ProgressBar.module.css';
import { ProgressState } from './ProgressBar.type';
import { ProgressBarStateIcon } from './ProgressBarStateIcon/ProgressBarStateIcon';

export function ProgressBar({
  totalSteps,
  currentStep,
}: {
  totalSteps: number;
  currentStep: number;
}) {
  const getProgressBarItems = () => {
    const progressBarItems = [];

    for (let step = 1; step <= totalSteps; step++) {
      let progressState: ProgressState = 'In progress';

      if (step > currentStep) {
        progressState = 'incomplete';
      } else if (step < currentStep) {
        progressState = 'complete';
      }

      if (step === totalSteps) {
        progressBarItems.push(
          <ProgressBarStateIcon key={step} progressState={progressState} />,
        );
      } else {
        progressBarItems.push(
          <ProgressBarStep key={step} progressState={progressState} />,
        );
      }
    }
    return progressBarItems;
  };

  return <div className={styles.progressBar}>{getProgressBarItems()}</div>;
}

function ProgressBarStep({ progressState }: { progressState: ProgressState }) {
  return (
    <>
      <ProgressBarStateIcon progressState={progressState} />

      <div className={styles.progressBarConnector}>
        <div
          className={classnames(styles.progressBarCompletedConnector, {
            [styles.progressComplete]: progressState === 'complete',
          })}
        ></div>
      </div>
    </>
  );
}
