import styles from './BackButton.module.css';
import { LeftArrowIcon } from '../../../components/svg/LeftArrowIcon';

export const BackButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button className={styles.backButton} onClick={onClick}>
      <LeftArrowIcon className={styles.backButtonArrow} />
      Go back
    </button>
  );
};
