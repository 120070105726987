import styles from './IDCheck.module.css';
import { iwocapayTelDisplay } from '../../../../../src/constants.json';
import { Card } from '../../../components/Card/Card';

export const IDCheckError = () => {
  return (
    <Card title="Whoops, something went wrong">
      <p className={styles.cardText}>
        Sorry about that, it seems something isn't quite right. We'll be in
        touch as soon as possible.
      </p>
      <p className={styles.cardText}>
        Give us a call on {iwocapayTelDisplay} if you'd like an update.
      </p>
    </Card>
  );
};
