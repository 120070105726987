import React from 'react';

import styles from './StepCard.module.css';
import TickIcon from '../../../../assets/TickIcon.svg';
import { Card } from '../../../components/Card/Card';

export const StepCard = ({
  title,
  isCompleted,
  children,
}: {
  title: string;
  isCompleted?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Card
      title={title}
      icon={
        isCompleted ? (
          <img src={TickIcon} className={styles.tickIcon} alt="Tick icon" />
        ) : null
      }
    >
      {children}
    </Card>
  );
};
