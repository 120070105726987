import { useContext } from 'react';

import { fetchGetSellerPricing } from '@iwoca/lapi-client/iwocapay';
import { useQuery } from '@tanstack/react-query';

import { exampleSellerPricing } from './exampleSellerPricing';
import { useStateKey } from './useStateKey.hook';
import { ActivationContext } from '../activation/Activation';

export function useSellerPricing() {
  const { stateKey } = useStateKey();
  const { isOnboarded, isLoading } = useContext(ActivationContext);

  const { data, refetch } = useQuery({
    queryKey: ['fetchGetSellerPricing'],
    queryFn: () => fetchGetSellerPricing({ stateKey: stateKey! }),
    enabled: Boolean(stateKey),
  });

  if (isLoading)
    return {
      sellerPricing: null,
      fetchSellerPricing: refetch,
    };

  if (!isOnboarded) {
    return {
      sellerPricing: exampleSellerPricing,
      fetchSellerPricing: refetch,
    };
  }

  if (!data?.data)
    return {
      sellerPricing: null,
      fetchSellerPricing: refetch,
    };

  return {
    sellerPricing: data.data,
    fetchSellerPricing: refetch,
  };
}
