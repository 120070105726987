import { StripeElementStyle } from '@stripe/stripe-js';

export const stripeStyles: StripeElementStyle = {
  base: {
    fontFamily: 'Aesop, sans-serif',
    fontSize: '14px',
    fontSmoothing: 'antialiased',
    fontWeight: 400,
    '::placeholder': {
      // --color-structure-60.
      color: 'rgb(153 153 153)',
    },
  },
  invalid: {
    // --coral35.
    color: 'rgb(175 13 4)',
  },
};
