import {
  GetSellerSpendingLimitRequestResponse,
  LapiError,
} from '@iwoca/lapi-client/iwocapay';
import fetchMock from 'fetch-mock';

import { lendingApiFetchJson } from '../Pages/lendingApiFetch';
import { STATE_KEY } from '../Pages/state.testHelpers';

export type TSpendingLimitRequests = NonNullable<
  GetSellerSpendingLimitRequestResponse['data']
>;

export async function fetchGetSellerSpendingLimitRequest({
  stateKey,
  query,
}: {
  stateKey: string;
  query: string;
}): Promise<GetSellerSpendingLimitRequestResponse> {
  const res = await lendingApiFetchJson(
    `/api/iwocapay/seller/${stateKey}/spending_limit_request/${query}`,
  );

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }

  return res.json();
}

export const setupUserActionPostSuccess = ({
  stateKey = STATE_KEY,
}: {
  stateKey?: string;
} = {}) => {
  fetchMock.postOnce(`/api/iwocapay/shared/${stateKey}/user_action/`, {
    status: 201,
    body: {
      data: {
        message: 'Success',
      },
    },
  });
};

export const setupFetchPostSharedPayLinkPackagePayLink = ({
  pay_link_package_id,
  amount,
  reference,
}: {
  pay_link_package_id: string;
  amount: number;
  reference: string;
}) => {
  const pay_link_id = '86e0ff26-a5e7-43b8-8726-cc1102fdd09f';
  fetchMock.postOnce(
    `/api/iwocapay/shared/pay_link_package/${pay_link_package_id}/pay_link/`,
    {
      status: 201,
      body: {
        data: {
          amount: amount,
          id: pay_link_id,
          reference: reference,
        },
      },
    },
  );

  return {
    pay_link_id,
  };
};

export const setupFetchPostSharedPayLinkPayNow = ({
  pay_link_id,
  callback_url,
}: {
  pay_link_id: string;
  callback_url: string;
}) => {
  fetchMock.postOnce(`/api/iwocapay/shared/pay_link/${pay_link_id}/pay_now/`, {
    status: 201,
    body: {
      data: {
        payment_provider_auth_uri: callback_url,
      },
    },
  });
};

export const setupFetchPostSharedPayLinkPayNow500 = ({
  pay_link_id,
}: {
  pay_link_id: string;
}) => {
  fetchMock.postOnce(`/api/iwocapay/shared/pay_link/${pay_link_id}/pay_now/`, {
    status: 500,
    body: {},
  });
};

export const setupPayNowCheckoutUniversalLink = ({
  sellerHandle,
  amount = 1000,
  reference = 'something nice',
}: {
  sellerHandle: string;
  amount?: number;
  reference?: string;
}) => {
  const pay_link_id = '86e0ff26-a5e7-43b8-8726-cc1102fdd09f';
  fetchMock.postOnce(
    `/api/iwocapay/shared/universal_pay_link/${sellerHandle}/pay_link/`,
    {
      status: 201,
      body: {
        data: {
          amount: amount,
          id: pay_link_id,
          reference: reference,
        },
      },
    },
  );

  return {
    pay_link_id,
  };
};
