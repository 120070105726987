import styles from './IntegrationsTitle.module.css';
import { Integration } from '../../utils/integrations.types';

export const IntegrationsTitle = ({
  integration,
}: {
  integration: Integration;
}) => {
  return (
    <div className={styles.container}>
      <img
        src={integration.company_logo_svg}
        alt="Logo"
        className={styles.logoSVG}
      />
      <div className={styles.integrationTitle}>
        <div className={styles.integrationType}>{integration.company_type}</div>
        <h2 className={styles.integrationName}>{integration.company_name}</h2>
      </div>
    </div>
  );
};
