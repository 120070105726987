import React, { useRef, useState } from 'react';

import {
  fetchGetSellerPayLinkDownload,
  fetchPutSellerPayLink,
} from '@iwoca/lapi-client/iwocapay';
import { Button, Input, Pagination } from '@iwoca/orion';
import classnames from 'classnames';
import { useSearchParams } from 'react-router-dom';

import DownloadIcon from './assets/downloadIcon.svg';
import { MobilePaylinks } from './components/MobilePaylinks/MobilePaylinks';
import { PayLinkContentSwitcher } from './components/PayLinkContentSwitcher/PayLinkContentSwitcher';
import {
  PayLinkColumns,
  PayLinkTable,
} from './components/PayLinkTable/PayLinkTable';
import styles from './Payments.module.css';
import { normalisePayLinkTableData } from './utils/utils';
import { DynamicPageTemplate } from '../../components/PageTemplate/PageTemplate';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useSellerHandle } from '../../hooks/useSellerHandle.hook';
import { useStateKey } from '../../hooks/useStateKey.hook';
import { downloadAsAttachment } from '../../Pages/lendingApiFetch';
import { PayLinkStatus, usePayLinks } from '../hooks/usePaylinks.hook';

export const Payments = () => {
  const { stateKey } = useStateKey();
  const { sellerHandle } = useSellerHandle();
  const [searchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const searchRef = useRef<HTMLInputElement>(null);
  const { isMobile } = useIsMobile();
  const [loadingPaymentsDownload, setLoadingPaymentsDownload] = useState(false);
  const [errorPaymentsDownload, setErrorPaymentsDownload] = useState(false);

  const status = searchParams.get('status') as PayLinkStatus | 'ARCHIVED';
  const showingArchived = status === 'ARCHIVED';

  const { payLinks, isLoading, metadata, fetchPayLinks } = usePayLinks({
    page: currentPage,
    searchQuery: searchQuery,
    status,
  });
  const {
    metadata: regularPayLinkMetadata,
    fetchPayLinks: refetchRegularMetadata,
  } = usePayLinks();
  const {
    metadata: archivedPayLinkMetadata,
    fetchPayLinks: refetchArchivedMetadata,
  } = usePayLinks({
    status: 'ARCHIVED',
  });

  const handleAddSelectedRow = (payLinkIds: string[]) => {
    const newRows = [...new Set([...selectedRows, ...payLinkIds])];
    setSelectedRows(newRows);
  };

  const handleRemoveSelectedRow = (payLinkIds: string[]) => {
    const newRows = selectedRows.filter((paylinkId) => {
      return payLinkIds.indexOf(paylinkId) < 0;
    });
    setSelectedRows(newRows);
  };

  const handleArchive = async () => {
    const response = await fetchPutSellerPayLink({
      stateKey: stateKey!,
      body: {
        pay_link_ids: selectedRows,
        archive: !showingArchived,
      },
    });

    if (response.success) {
      setSelectedRows([]);
      await fetchPayLinks();
      await refetchRegularMetadata();
      await refetchArchivedMetadata();
    }
  };

  const handleDownload = async () => {
    setErrorPaymentsDownload(false);
    setLoadingPaymentsDownload(true);

    try {
      const response = await fetchGetSellerPayLinkDownload({
        stateKey: stateKey!,
      });
      await downloadAsAttachment(response);
    } catch (error) {
      setErrorPaymentsDownload(true);
    } finally {
      return setLoadingPaymentsDownload(false);
    }
  };

  const payLinkColumns: PayLinkColumns[] = normalisePayLinkTableData({
    payLinks,
    sellerHandle: sellerHandle || '',
    selectedRows: selectedRows,
    handleAddSelectedRow: handleAddSelectedRow,
    handleRemoveSelectedRow: handleRemoveSelectedRow,
    refreshTable: () => fetchPayLinks(),
  });

  return (
    <DynamicPageTemplate wideContent={true}>
      <div
        className={classnames(styles.widePage, {
          [styles.bottomPadding]: selectedRows.length > 0,
        })}
      >
        {selectedRows.length > 0 && (
          <div className={styles.archivePrompt}>
            <span>
              {selectedRows.length} item{selectedRows.length !== 1 ? 's' : ''}{' '}
              selected.
            </span>
            <Button variant="tertiary" onClick={() => setSelectedRows([])}>
              Deselect all
            </Button>
            <Button onClick={handleArchive} className="w-[160px]">
              {showingArchived ? 'Unarchive' : 'Archive'}
            </Button>
          </div>
        )}
        <div className="w-full">
          <div className="flex items-center justify-between">
            <div className="text-4xl font-bold text-primary-10">Payments</div>
            <div>
              <Button
                variant="tertiary"
                className="!text-s font-bold text-primary-40"
                onClick={handleDownload}
                disabled={loadingPaymentsDownload}
              >
                {loadingPaymentsDownload ? (
                  'Downloading...'
                ) : (
                  <div className="flex gap-s">
                    <div>Download .csv</div>
                    <img src={DownloadIcon} alt="" />
                  </div>
                )}
              </Button>
              {errorPaymentsDownload && (
                <div className="text-xs text-error">Something went wrong</div>
              )}
            </div>
          </div>

          <div className={styles.paylinkToolbar}>
            <PayLinkContentSwitcher
              metadata={regularPayLinkMetadata}
              archivedMetadata={archivedPayLinkMetadata}
              isMobile={isMobile}
              onChange={() => {
                setCurrentPage(1);
                if (selectedRows.length !== 0) setSelectedRows([]);
              }}
            />
            <Input
              className="m:mb-m"
              placeholder="Search paylinks..."
              iconLeft="zoomOutline"
              ref={searchRef}
              onKeyDown={(event: React.KeyboardEvent) => {
                if (event.key === 'Enter')
                  setSearchQuery(searchRef.current!.value);
              }}
            />
          </div>

          {isMobile ? (
            <MobilePaylinks paylinks={payLinkColumns} isLoading={isLoading} />
          ) : (
            <PayLinkTable
              payLinkColumns={payLinkColumns}
              loadingState={isLoading}
            />
          )}

          <div>
            {metadata?.total_pages && metadata.total_pages > 1 && (
              <Pagination
                className="mx-auto mt-2xl"
                page={currentPage}
                count={metadata?.total_pages}
                onPageChange={(currentPage) => {
                  document.body.scrollTop =
                    document.documentElement.scrollTop = 0;
                  setCurrentPage(currentPage);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </DynamicPageTemplate>
  );
};
