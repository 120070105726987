import styles from './LoadingGraphic.module.css';

export const LoadingGraphic = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="262"
      height="251"
      viewBox="0 0 262 251"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="path1"
        d="M232 123.722C232 179.371 186.785 224.491 131 224.491C75.215 224.491 30 179.371 30 123.722C30 68.0729 75.215 22.9529 131 22.9529C186.785 22.9529 232 68.0729 232 123.722Z"
        stroke="#4D809E"
        strokeWidth="4"
      />
      <ellipse rx="13" fill="#FFA0A0">
        <animateMotion dur="10s" repeatCount="indefinite">
          <mpath xlinkHref="#path1" />
        </animateMotion>
      </ellipse>
      <g className={styles.pulse}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M213.5 110.751C239.734 110.751 261 89.5321 261 63.3575C261 37.1828 239.734 15.9641 213.5 15.9641C187.266 15.9641 166 37.1828 166 63.3575C166 89.5321 187.266 110.751 213.5 110.751Z"
          fill="#EEF3F7"
          stroke="white"
        />
        <path
          d="M233.707 85.0226H194.927C193.571 85.0226 192.475 83.9288 192.475 82.5753V43.9005C192.475 42.547 193.571 41.4532 194.927 41.4532H233.688C235.045 41.4532 236.141 42.547 236.141 43.9005V82.5753C236.16 83.9288 235.063 85.0226 233.707 85.0226ZM197.399 80.1095H231.254V46.3292H197.399V80.1095Z"
          fill="#FB534A"
        />
        <path
          d="M223.359 62.4796V55.49C223.359 55.1377 223.006 54.8967 222.671 55.0264L218.75 56.658C218.565 56.7321 218.453 56.9175 218.453 57.1215V69.358C218.453 69.562 218.565 69.7288 218.75 69.8215L222.671 71.4531C223.006 71.5828 223.359 71.3604 223.359 70.9896V62.4796Z"
          fill="#FB534A"
        />
        <path
          d="M210.183 57.1215V69.358C210.183 69.562 210.071 69.7288 209.886 69.8215L205.965 71.4531C205.63 71.5828 205.277 71.3604 205.277 70.9896V55.49C205.277 55.1377 205.63 54.8967 205.965 55.0264L209.886 56.658C210.053 56.7321 210.183 56.9175 210.183 57.1215Z"
          fill="#FB534A"
        />
      </g>
      <g className={styles.delayedPulse}>
        <ellipse cx="51.5" cy="177.601" rx="51.5" ry="50.8856" fill="#1AB4D7" />
        <path
          d="M26.9701 177.065L34.9844 169.06C35.2501 168.79 35.3984 168.432 35.3984 168.054C35.3984 167.264 34.7542 166.623 33.9618 166.623C33.5758 166.623 33.2139 166.773 32.941 167.049C32.9402 167.05 24.9339 175.023 24.9339 175.023L16.891 167.037C16.6192 166.77 16.2591 166.623 15.8772 166.623C15.0858 166.623 14.4414 167.264 14.4414 168.052C14.4414 168.437 14.5956 168.798 14.8723 169.07L22.8855 177.054L14.8765 185.049C14.5949 185.325 14.4414 185.687 14.4414 186.074C14.4414 186.863 15.0858 187.504 15.8772 187.504C16.2597 187.504 16.6202 187.356 16.891 187.086L24.9214 179.095L32.9214 187.057C33.2051 187.349 33.5711 187.505 33.9618 187.505C34.7534 187.505 35.3976 186.863 35.3976 186.074C35.3976 185.693 35.2495 185.335 34.98 185.065L26.9701 177.065Z"
          fill="white"
        />
        <path
          d="M74.6143 177.063C74.6143 178.497 75.7842 179.663 77.2249 179.663C78.6622 179.663 79.8326 178.497 79.8326 177.063C79.8326 175.629 78.6622 174.463 77.2249 174.463C75.7842 174.463 74.6143 175.629 74.6143 177.063Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.2224 187.555C71.4164 187.555 66.6929 182.849 66.6929 177.065C66.6929 171.28 71.4164 166.573 77.2224 166.573C83.0282 166.573 87.7542 171.28 87.7542 177.065C87.7542 182.849 83.0282 187.555 77.2224 187.555ZM77.2224 169.535C73.0551 169.535 69.6655 172.913 69.6655 177.065C69.6655 181.216 73.0551 184.593 77.2224 184.593C81.3875 184.593 84.778 181.216 84.778 177.065C84.778 172.913 81.3875 169.535 77.2224 169.535Z"
          fill="white"
        />
        <path
          d="M65.9447 166.751L65.503 166.749C64.1767 166.749 62.8976 167.166 61.8292 167.986C61.6885 167.345 61.1127 166.862 60.4268 166.862C59.6374 166.862 59.0048 167.492 59.0029 168.28C59.0029 168.283 59.0077 185.951 59.0077 185.951C59.0098 186.738 59.6538 187.377 60.4437 187.377C61.2335 187.377 61.8773 186.738 61.8794 185.949C61.8794 185.946 61.88 175.084 61.88 175.084C61.88 171.462 62.2124 170 65.3256 169.612C65.6134 169.577 65.9263 169.582 65.9276 169.582C66.7795 169.553 67.3849 168.97 67.3849 168.181C67.3849 167.392 66.7386 166.751 65.9447 166.751Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.4265 168.21C53.8689 169.75 55.4728 172.148 56.0916 175.067C56.3971 176.72 55.2912 178.165 53.2364 178.208C53.2364 178.208 39.1117 178.211 38.2482 178.211C38.2511 178.279 38.2582 178.354 38.268 178.431C38.3426 178.845 38.4497 179.247 38.5885 179.634C39.3835 181.707 41.6185 184.612 45.7952 184.659C47.0712 184.652 48.2716 184.331 49.3249 183.77C50.1527 183.31 50.8435 182.716 51.4003 182.103C51.5852 181.883 51.7531 181.663 51.9036 181.449C52.6039 180.556 53.4938 180.72 54.0239 181.125C54.6524 181.605 54.7585 182.61 54.1865 183.317C54.1768 183.328 54.1671 183.339 54.1574 183.349C54.1504 183.357 54.1435 183.364 54.1365 183.372C53.3124 184.402 52.4615 185.262 51.443 185.915C50.4617 186.541 49.3967 186.986 48.296 187.253C47.0152 187.583 45.7782 187.657 44.5074 187.503C40.225 186.981 36.6331 183.829 35.5709 179.666C35.3588 178.829 35.2568 177.979 35.2568 177.136C35.2568 173.776 36.8806 170.514 39.6934 168.52C43.1419 166.077 47.8563 165.952 51.4265 168.21ZM38.376 175.25C38.3744 175.28 38.3728 175.309 38.3728 175.338H53.151C52.36 171.994 49.3481 169.504 45.7506 169.504C42.196 169.504 39.2114 171.934 38.3777 175.218C38.3772 175.229 38.3766 175.239 38.376 175.25Z"
          fill="white"
        />
      </g>
      <g clipPath="url(#clip0)">
        <path
          d="M165.209 204.723L137.536 212.121C137.104 212.236 136.736 212.518 136.513 212.904C136.289 213.291 136.229 213.75 136.344 214.18L145.286 247.476C145.325 247.623 145.404 247.756 145.514 247.862C145.623 247.968 145.76 248.042 145.908 248.077C146.057 248.111 146.212 248.105 146.357 248.059C146.502 248.012 146.632 247.927 146.733 247.813L150.373 243.679L155.596 245.442C155.754 245.495 155.924 245.5 156.084 245.457C156.245 245.414 156.39 245.325 156.499 245.2L160.139 241.067L165.363 242.83C165.52 242.884 165.69 242.889 165.851 242.846C166.012 242.803 166.156 242.714 166.266 242.589L169.906 238.456L175.13 240.219C175.274 240.268 175.429 240.277 175.578 240.245C175.727 240.213 175.864 240.141 175.976 240.037C176.087 239.933 176.168 239.801 176.21 239.655C176.252 239.509 176.253 239.354 176.214 239.207L167.273 205.912C167.157 205.481 166.875 205.114 166.488 204.891C166.101 204.668 165.641 204.607 165.209 204.723Z"
          fill="#EEF3F7"
        />
        <path
          d="M159.761 233.161L149.994 235.772C149.778 235.83 149.548 235.8 149.354 235.688C149.161 235.577 149.02 235.393 148.962 235.178C148.904 234.962 148.934 234.733 149.046 234.54C149.158 234.347 149.342 234.206 149.558 234.148L159.324 231.537C159.54 231.479 159.77 231.509 159.964 231.621C160.157 231.732 160.299 231.916 160.356 232.131C160.414 232.347 160.384 232.576 160.272 232.769C160.16 232.962 159.976 233.103 159.761 233.161Z"
          fill="#052C92"
        />
        <path
          d="M166.272 231.42L164.645 231.855C164.429 231.913 164.199 231.883 164.005 231.771C163.812 231.66 163.67 231.476 163.613 231.261C163.555 231.046 163.585 230.816 163.697 230.623C163.809 230.43 163.993 230.289 164.208 230.231L165.836 229.796C166.052 229.738 166.282 229.769 166.476 229.88C166.669 229.992 166.81 230.175 166.868 230.391C166.926 230.606 166.896 230.835 166.784 231.029C166.672 231.222 166.488 231.363 166.272 231.42Z"
          fill="#052C92"
        />
        <path
          d="M158.452 228.289L148.686 230.9C148.47 230.957 148.24 230.927 148.046 230.816C147.853 230.704 147.711 230.521 147.654 230.305C147.596 230.09 147.626 229.86 147.738 229.667C147.85 229.474 148.034 229.333 148.249 229.276L158.016 226.664C158.232 226.607 158.462 226.637 158.656 226.748C158.849 226.86 158.99 227.044 159.048 227.259C159.106 227.474 159.076 227.704 158.964 227.897C158.852 228.09 158.668 228.231 158.452 228.289Z"
          fill="#052C92"
        />
        <path
          d="M164.963 226.548L163.336 226.983C163.12 227.041 162.89 227.011 162.696 226.899C162.503 226.788 162.361 226.604 162.304 226.389C162.246 226.173 162.276 225.944 162.388 225.751C162.499 225.558 162.683 225.417 162.899 225.359L164.527 224.924C164.743 224.866 164.973 224.896 165.167 225.008C165.36 225.119 165.501 225.303 165.559 225.518C165.617 225.734 165.587 225.963 165.475 226.156C165.363 226.349 165.179 226.49 164.963 226.548Z"
          fill="#052C92"
        />
        <path
          d="M155.298 223.039C157.995 222.318 159.595 219.552 158.873 216.861C158.15 214.17 155.378 212.573 152.681 213.294C149.984 214.015 148.383 216.781 149.106 219.472C149.829 222.163 152.601 223.76 155.298 223.039Z"
          fill="#FB534A"
        />
      </g>
      <path
        d="M117 7.81583H85C83.3457 7.81583 82 9.13054 82 10.7467V34.194C82 34.7335 82.4473 35.171 83 35.171H119C119.553 35.171 120 34.7335 120 34.194V10.7467C120 9.13054 118.654 7.81583 117 7.81583Z"
        fill="#2B4959"
      />
      <path
        d="M78 32.24V34.1939C78 36.8872 80.2432 39.0788 83 39.0788H119C121.757 39.0788 124 36.8872 124 34.1939V32.24H78Z"
        fill="#E6E6E6"
      />
      <path d="M117 10.7467H85V29.3091H117V10.7467Z" fill="white" />
      <path
        d="M104 35.1709H98C96.8955 35.1709 96 34.296 96 33.217V32.24H106V33.217C106 34.296 105.105 35.1709 104 35.1709Z"
        fill="#B3B3B3"
      />
      <path
        d="M111.923 15.2582C111.822 15.0191 111.627 14.8288 111.382 14.7298C111.26 14.6804 111.13 14.6545 111 14.6545H105C104.448 14.6545 104 15.0915 104 15.6315C104 16.1715 104.448 16.6085 105 16.6085H108.586L101 24.0197L95.707 18.8486C95.3164 18.467 94.6836 18.467 94.293 18.8486L90.293 22.7565C89.9023 23.1381 89.9023 23.7564 90.293 24.138C90.6836 24.5196 91.3164 24.5196 91.707 24.138L95 20.9209L100.293 26.0919C100.488 26.2828 100.744 26.3782 101 26.3782C101.256 26.3782 101.512 26.2828 101.707 26.0919L110 17.99V21.4933C110 22.0333 110.448 22.4703 111 22.4703C111.552 22.4703 112 22.0333 112 21.4933V15.6315C112 15.5043 111.973 15.3776 111.923 15.2582Z"
        fill="#699FE3"
      />
      <defs>
        <clipPath id="clip0">
          <rect
            width="40.4395"
            height="40.3609"
            fill="white"
            transform="matrix(0.966071 -0.258277 0.259362 0.96578 131.402 212.02)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
