export const LeftArrowIcon = ({ title = 'Left arrow icon', ...props }) => {
  return (
    <svg
      {...props}
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        d="M0.361158 6.71014L5.00741 11.4771L6.43943 10.0814L3.47814 7.04314L12.0634 7.15324L12.0891 5.15341L3.50379 5.0433L6.54201 2.08202L5.14626 0.650006L0.37929 5.29626C0.18943 5.48137 0.0808621 5.7343 0.0774618 5.99945C0.0740615 6.26459 0.176107 6.52023 0.361158 6.71014Z"
        fill="#FB534A"
      />
    </svg>
  );
};
