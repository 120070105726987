import { useGetExampleRepaymentSchedule } from '../../../api/lending/lapiHooks';
import { displayCurrency } from '../../../utils/DisplayCurrency';
import {
  MAX_AMOUNT_PAY_LATER,
  MIN_AMOUNT_PAY_LATER,
} from '../PaymentDetails/PaymentDetails';
import { TDuration } from '../utils/PayLinkLanding.types';

export function useRepaymentSchedule({
  amount,
  representativeInterest,
  duration,
  pricingPromotions,
}: {
  amount?: string;
  representativeInterest: number;
  pricingPromotions: string[];
  duration?: TDuration;
}) {
  const amountWithinLimits = Boolean(
    amount &&
      Number(amount) >= MIN_AMOUNT_PAY_LATER &&
      Number(amount) <= MAX_AMOUNT_PAY_LATER,
  );

  const { exampleRepaymentSchedule } = useGetExampleRepaymentSchedule({
    amount: amount!, // Only used when enabled. Therfore can be assumed to be defined when needed
    representativeInterest,
    pricingPromotions,
    enabled: amountWithinLimits,
    duration: duration,
  });

  const exampleMonthlyRepayment =
    exampleRepaymentSchedule &&
    displayCurrency(exampleRepaymentSchedule.exampleMonthlyRepayment);

  const totalInterest =
    exampleRepaymentSchedule &&
    displayCurrency(exampleRepaymentSchedule.totalInterest);

  return {
    exampleMonthlyRepayment,
    totalInterest,
  };
}
