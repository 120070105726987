import { InputHTMLAttributes } from 'react';

import { Label, Radio } from '@iwoca/orion';
import cn from 'classnames';
import { useField } from 'formik';
import { camelCase } from 'lodash';
import { useFormContext } from 'react-hook-form';

import styles from './RadioButton.module.css';
import { InputError } from '../../../../components/InputError/InputError';
import { extractError } from '../../../../utils/ReactHookForm';

export const RadioButton = ({
  name,
  labelText,
  options,
  labelDescriptionText,
  className,
  compact,
  ...props
}: {
  name: string;
  labelText: string;
  labelDescriptionText?: string;
  options: readonly {
    label: string;
    value: string | number | readonly string[] | undefined;
  }[];
  className?: string;
  compact?: boolean;
} & InputHTMLAttributes<HTMLInputElement>) => {
  const [{ onChange, ...field }, { error, touched }] = useField(name);
  return (
    <>
      <fieldset
        className={cn(className, styles.RadioButton, {
          [styles.compact]: compact,
        })}
      >
        <legend className={styles.parentLabel}>{labelText}</legend>
        {labelDescriptionText && (
          <p className={styles.descriptionText}>{labelDescriptionText}</p>
        )}
        {options.map(({ label, value }) => {
          const id = camelCase(`${name}_${label}`);
          const checked = value === field.value;

          return (
            <div
              className={cn(styles.radioButtonInput, {
                [styles.checked]: checked,
              })}
              key={id}
            >
              <Radio
                {...field}
                {...props}
                onChange={(e) => {
                  props?.onChange?.(e);
                  onChange(e);
                }}
                checked={checked}
                id={id}
                name={name}
                value={value}
                className={styles.radioInput}
              />
              <Label className={styles.label} htmlFor={id}>
                {label}
              </Label>
            </div>
          );
        })}
        <InputError isVisible={touched} error={error} />
      </fieldset>
    </>
  );
};

export const HookFormRadioButton = ({
  name,
  labelText,
  options,
  labelDescriptionText,
  className,
  compact,
  onChange,
}: {
  name: string;
  labelText: string;
  labelDescriptionText?: string;
  options: readonly {
    label: string;
    value: string | number | readonly string[] | undefined;
  }[];
  className?: string;
  compact?: boolean;
} & InputHTMLAttributes<HTMLInputElement>) => {
  const { register, formState, watch } = useFormContext();
  const currentValue = watch(name);
  const error = extractError(formState.errors[name]);

  return (
    <fieldset
      className={cn(className, styles.RadioButton, {
        [styles.compact]: compact,
      })}
    >
      <legend className={styles.parentLabel}>{labelText}</legend>
      {labelDescriptionText && (
        <p className={styles.descriptionText}>{labelDescriptionText}</p>
      )}
      {options.map(({ label, value }) => {
        const id = camelCase(`${name}_${label}`);
        const isChecked = value === currentValue;

        return (
          <div
            className={cn(styles.radioButtonInput, {
              [styles.checked]: isChecked,
            })}
            key={id}
          >
            <Radio
              {...register(name, {
                onChange: (e) => {
                  onChange?.(e);
                },
              })}
              id={id}
              value={value}
              checked={isChecked}
              className={styles.radioInput}
            />
            <Label className={styles.label} htmlFor={id}>
              {label}
            </Label>
          </div>
        );
      })}
      <p className={styles.error}>{error}</p>
    </fieldset>
  );
};
