export const RoundedArrow = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="20"
      height="15"
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.41421 6.58579C8.63316 7.36684 7.36683 7.36683 6.58579 6.58579L6.99382e-07 -1.39876e-06L16 0L9.41421 6.58579Z"
        fill="white"
      />
    </svg>
  );
};
