import { useState } from 'react';

import classnames from 'classnames';

import styles from './PersonalGuarantee.module.css';
import { usePersonalGuaranteeDocument } from './usePersonalGuaranteeDocument';
import { useGetFundingRequirement } from '../../../../api/lending/lapiHooks';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { isBottomVisible, isTopVisible } from '../../../../utils/scroll';
import { Accordion } from '../../../components/Accordion/Accordion';
import { SubmitButton } from '../../../components/Button/Button';
import { Card } from '../../../components/Card/Card';
import { Divider } from '../../components/Divider/Divider';
import { useNavigateToNextRequirement } from '../../hooks/useNavigateToNextRequirement';

export const PersonalGuarantee = () => {
  const { goToNextRequirement, nextRequirementName } =
    useNavigateToNextRequirement();
  const { fetchFundingRequirements } = useGetFundingRequirement({
    queryOptions: {
      enabled: false,
    },
  });
  const [canSign, setCanSign] = useState(false);

  const { isMobile } = useIsMobile();

  const {
    signPersonalGuaranteeDocument,
    isPersonalGuaranteeSigned,
    isSigningPersonalGuaranetee,
    isLoadingPersonalGuarantee,
  } = usePersonalGuaranteeDocument();

  const isLoading = isSigningPersonalGuaranetee || isLoadingPersonalGuarantee;
  const isDisabled = !isPersonalGuaranteeSigned && (isLoading || !canSign);

  const handleSignDocument = async () => {
    if (!isPersonalGuaranteeSigned) {
      await signPersonalGuaranteeDocument();
      await fetchFundingRequirements();
    }

    goToNextRequirement();
  };

  const getSignButtonContent = () => {
    if (isLoading) {
      return <LoadingSpinner />;
    }

    if (isPersonalGuaranteeSigned) {
      return `Continue to ${nextRequirementName}`;
    }

    return 'Sign personal guarantee';
  };

  const onScrollBottom = () => {
    setCanSign(true);
  };

  return (
    <Card title="Sign your Personal Guarantee">
      <p className={styles.introText}>
        Please read the entire agreement and click 'Sign personal guarantee' to
        continue.
      </p>
      <Accordion title="Why am I doing this?" className={styles.accordion}>
        We ask for this commitment because we offer unsecured credit, which
        means it's not backed by any assets. Once a director of the business
        signs a personal guarantee it makes them personally responsible for
        repaying the loan.
      </Accordion>
      <Divider />
      <PersonalGuaranteeDocument onScrollBottom={onScrollBottom} />
      <SubmitButton
        className={styles.submitButton}
        onClick={handleSignDocument}
        disabled={!isMobile && isDisabled}
      >
        {getSignButtonContent()}
      </SubmitButton>
    </Card>
  );
};

type TScrollState = 'top' | 'in-between' | 'bottom';
const PersonalGuaranteeDocument = ({
  onScrollBottom,
}: {
  onScrollBottom: () => void;
}) => {
  const { personalGuaranteeContent } = usePersonalGuaranteeDocument();
  const [scrollState, setScrollState] = useState<TScrollState>('top');

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const top = isTopVisible(e.target as HTMLDivElement);
    const bottom = isBottomVisible(e.target as HTMLDivElement);

    if (top) {
      setScrollState('top');
    }

    if (bottom) {
      setScrollState('bottom');
      onScrollBottom();
    }

    if (scrollState === 'in-between') return;

    setScrollState('in-between');
  };

  if (!personalGuaranteeContent)
    return <div className={styles.documentWrapper}>Loading...</div>;

  return (
    <div
      data-testid="pg-document-wrapper"
      onScroll={handleScroll}
      className={classnames(styles.documentWrapper, 'fs-exclude', {
        [styles.documentWrapperTop]: scrollState === 'top',
        [styles.documentWrapperInBetween]: scrollState === 'in-between',
        [styles.documentWrapperBottom]: scrollState === 'bottom',
      })}
      dangerouslySetInnerHTML={{ __html: personalGuaranteeContent }}
    />
  );
};
