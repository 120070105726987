import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { fetchGetSellerSpendingLimitRequest } from '../../../api/iwocapay';
import { useStateKey } from '../../../hooks/useStateKey.hook';

type TSpendingLimitQueryParams = {
  page?: number;
};

export const useGetSellerSpendingLimitRequest = ({
  page,
}: TSpendingLimitQueryParams = {}) => {
  const { stateKey } = useStateKey();

  const query = buildSellerSpendingLimitParams({
    page: page || 1,
  });

  const { data, isLoading } = useQuery({
    queryKey: ['fetchGetSellerSpendingLimitRequest', query],
    queryFn: () =>
      fetchGetSellerSpendingLimitRequest({
        stateKey: stateKey!,
        query: query,
      }),
    placeholderData: keepPreviousData,
    enabled: Boolean(stateKey),
  });

  return {
    spendingLimitRequests: data?.data || [],
    metadata: data?.meta,
    isLoading: isLoading,
    paginationLinks: data?.links,
    totalCount: data?.meta?.count,
  };
};

export const buildSellerSpendingLimitParams = ({
  page,
}: TSpendingLimitQueryParams) => {
  const params = new URLSearchParams();
  if (page && page !== 1) {
    params.append('page', `${page}`);
  }
  if (params.getAll.length === 0) return '';

  return `?${params.toString()}`;
};
