import { useGetSplitTests } from './SplitTest';

export const useGetStep0SplitTest = ({
  shouldRunTest,
}: {
  shouldRunTest: boolean;
}) => {
  const splitTests = useGetSplitTests({ shouldRunTest });

  if (!shouldRunTest) return { isInStep0SplitTest: false };

  const isInStep0SplitTest =
    splitTests.find(
      ({ test_name }) =>
        test_name == 'iwocapay_buyer_step0_ecommerce_august2024',
    )?.branch_name == 'INCLUDE';

  return { isInStep0SplitTest };
};
