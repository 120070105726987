import React from 'react';

import cn from 'classnames';

import { SignupProgressIndicator } from './components/SignupProgressIndicator';
import styles from './SignupLayout.module.css';
import { useIsErrorStep } from './steps/useRedirectToLatestStep';
import { useCurrentPayLink } from '../Checkout/hooks/useCurrentPayLink';
import { CancelButton } from '../components/CancelButton/CancelButton';
import { HelpBox } from '../components/HelpBox/HelpBox';

export const SignupLayout = ({ children }: { children: React.ReactNode }) => {
  const { payLink } = useCurrentPayLink();

  return (
    <div className={cn(styles.Signup, 'text-left')}>
      <SignupProgressIndicator />
      <div className={styles.steps}>
        {payLink?.cancel_url && (
          <CancelButton className={styles.cancelButton} payLink={payLink} />
        )}
        {children}
        {!useIsErrorStep() && <HelpBox />}
      </div>
    </div>
  );
};
