import { GetStateByStateKeyResponse } from '@iwoca/lapi-client/edge';
import { v4 as uuidv4 } from 'uuid';

import { RecursiveNonNullable } from '../../utils/typeUtils';

type TCustomerStateAddress = Omit<
  Required<
    RecursiveNonNullable<GetStateByStateKeyResponse>['data']['application']['people'][number]['residential_addresses'][number]
  >,
  | 'date_from'
  | 'flat_number'
  | 'monthly_housing_expenses'
  | 'property_value'
  | 'residential_status'
  | 'street_line_2'
>;

export type TFormValuesAddress = {
  buildingNumber: string;
  buildingName: string;
  streetName: string;
  town: string;
  postcode: string;
  equifaxToken: string;
};

/**
 * Takes customer state and extracts address in a format
 * that can be used in the <AddressInput /> component
 */
export function normaliseAddress(
  customerState?: GetStateByStateKeyResponse['data'] | null,
): TFormValuesAddress {
  const residential_address =
    customerState?.application?.people?.[0]?.residential_addresses?.[0];

  return {
    buildingName: residential_address?.house_name || '',
    buildingNumber: residential_address?.house_number || '',
    streetName: residential_address?.street_line_1 || '',
    town: residential_address?.town || '',
    postcode: residential_address?.postcode || '',
    equifaxToken: residential_address?.equifax_token || '',
  };
}

/**
 * Takes form values generated by <AddressInput /> and turns into
 * format used by customer state
 */
export function denormaliseAddress(formValues: {
  address: TFormValuesAddress;
}): TCustomerStateAddress {
  return {
    uid: uuidv4(),
    house_number: formValues.address!.buildingNumber,
    street_line_1: formValues.address!.streetName,
    town: formValues.address!.town,
    postcode: formValues.address!.postcode,
    house_name: formValues.address!.buildingName,
    equifax_token: formValues.address!.equifaxToken,
    country: 'GB',
  };
}
