import React from 'react';

import { WebhooksTable } from './WebhooksTable';
import styles from '../Webhooks/Webhooks.module.css';

export const WebhooksPage = () => {
  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <div className={styles.headerContainer}>
          <div>
            <h2 className={styles.pageHeader}>Webhook Logs</h2>
            <WebhooksTable />
          </div>
        </div>
      </div>
    </div>
  );
};
