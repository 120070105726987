export const LinkedIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Linked with Xero</title>
      <path
        d="M24.4132 14.0733C26.8013 11.675 27.265 7.97267 25.5377 5.07368C25.5493 5.06205 25.5493 5.06205 25.5493 5.06205C25.2247 4.52655 24.8421 4.02585 24.4132 3.59507C23.2539 2.43083 21.7353 1.6741 20.0775 1.47613C17.817 1.20836 15.5911 1.97683 13.9798 3.59507L11.6613 5.92355C9.76011 7.83294 9.04137 10.6272 9.77169 13.1303C9.81803 13.3632 10.3745 14.8999 10.5831 14.9931C10.8961 15.517 11.2556 15.9944 11.6613 16.4018C12.4959 17.2401 13.4813 17.8106 14.5246 18.1598V18.1832L16.1592 20.4069L19.2544 18.1832L19.2544 18.1366C20.2862 17.7989 21.2716 17.2285 22.0947 16.4018L24.4132 14.0733ZM19.1966 13.4912L16.7042 12.455L14.6059 13.4447L14.5595 13.468C13.9798 12.8858 13.5973 12.1058 13.551 11.2908C13.5046 10.4758 13.7944 9.60265 14.5596 8.83418L16.8781 6.5057C18.1417 5.23669 20.2283 5.25996 21.5035 6.51733C21.5151 6.52897 21.5151 6.52897 21.5268 6.54066C22.8599 7.87948 22.7555 9.91703 21.5152 11.1627L19.1966 13.4912Z"
        fill="#699FE3"
      />
      <path
        d="M18.9832 18.3052C17.4612 18.8158 15.801 18.8158 14.279 18.3052L12.0306 20.568C10.5086 22.0998 8.58319 21.7168 7.4302 20.5564C6.26563 19.4076 5.87369 17.4813 7.41868 15.9265L9.72466 13.6057C10.9815 12.3409 13.0338 12.3641 14.3136 13.6057L14.3482 13.6406C15.5012 14.7778 17.4152 15.149 18.9257 13.6289L18.9717 13.5825C18.8218 13.1067 18.6028 12.631 18.3376 12.1784L18.3491 12.1669C18.0263 11.633 17.6342 11.1457 17.2076 10.7164C16.4005 9.90409 15.4435 9.28908 14.3713 8.92937C13.8985 8.75525 13.4028 8.65088 12.9069 8.59279C11.7885 8.46523 10.6817 8.58125 9.66699 8.92942C8.6177 9.28908 7.64924 9.89249 6.8421 10.7048L4.53606 13.0256C2.64517 14.9285 1.93038 17.7134 2.64523 20.2199C2.70284 20.4404 3.25626 21.972 3.46384 22.0649C3.77512 22.587 4.1326 23.0628 4.53612 23.4689C7.39552 26.3467 12.0537 26.3467 14.9131 23.4689L17.2191 21.1482C18.0378 20.3243 18.6258 19.338 18.9832 18.3052Z"
        fill="#246AC1"
      />
      <path
        d="M27.5259 2.76599L20.8089 9.49109C20.1763 10.1244 19.1412 10.1244 18.5086 9.49109C17.876 8.85775 17.876 7.82134 18.5086 7.188L25.2255 0.462835C25.8419 -0.154278 26.9094 -0.154278 27.5258 0.462835C28.1584 1.09618 28.1584 2.13264 27.5259 2.76599Z"
        fill="#699FE3"
      />
      <path
        d="M9.951 20.4192L2.85106 27.5107C2.19822 28.1628 1.12986 28.1628 0.477079 27.5107C-0.159026 26.8754 -0.159026 25.7749 0.477079 25.1396L7.57708 18.0481C8.22992 17.3961 9.29822 17.3961 9.95106 18.0481C10.6039 18.7002 10.6039 19.7672 9.951 20.4192Z"
        fill="#246AC1"
      />
    </svg>
  );
};
