import { IDCheckComplete } from './IDCheckComplete';
import { IDCheckError } from './IDCheckError';
import { IDCheckNotComplete } from './IDCheckNotComplete';
import { TGetFundingRequirementsResponse } from '../../../../api/lending/edge';
import { useGetFundingRequirement } from '../../../../api/lending/lapiHooks';

enum IDCheckStatus {
  not_completed,
  completed,
  error,
}

enum APPLICANT_STATUSES {
  required,
  error,
  pending,
  completed,
}
enum APPLICANT_PENDING_STATUSES {
  required,
  error,
  pending,
}

type GuarantorType = NonNullable<
  ReturnType<typeof getGuarantorsFromFundingRequirements>
>[0];

export const IDCheck = () => {
  const { fundingRequirement } = useGetFundingRequirement();

  const guarantors = getGuarantorsFromFundingRequirements(fundingRequirement);

  // In Iwocapay we always have just one guarantor who is the applicant
  const applicant = getApplicantFromGuarantors(guarantors);
  const idCheckStatus = getIDCheckStatus(guarantors);

  if (idCheckStatus === IDCheckStatus.error) {
    return <IDCheckError />;
  }
  if (idCheckStatus === IDCheckStatus.not_completed) {
    return (
      <IDCheckNotComplete
        applicantStatus={applicant!.status!}
        applicantUID={applicant!.name}
      />
    );
  }
  return <IDCheckComplete />;
};

export function getApplicantFromGuarantors(
  guarantors: GuarantorType[] | undefined,
) {
  // In Iwocapay we always have just one guarantor who is the applicant
  return guarantors && guarantors[0];
}

export function getGuarantorsFromFundingRequirements(
  fundingRequirements: TGetFundingRequirementsResponse['data'] | null,
) {
  const fraudCheckRequirement = fundingRequirements?.filter(
    (requirement) => requirement.type === 'fraud_check_requirement',
  )[0];

  const onfidoResolutionMethod =
    fraudCheckRequirement?.resolution_methods?.filter(
      (resolution_method) => resolution_method.type === 'onfido_check',
    )[0];

  const guarantors = onfidoResolutionMethod?.items;

  return guarantors;
}

function getIDCheckStatus(
  guarantors: GuarantorType[] | undefined,
): IDCheckStatus {
  const isMultipleGuarantors = guarantors?.length && guarantors?.length > 1;

  const applicant = getApplicantFromGuarantors(guarantors);
  if (
    isMultipleGuarantors ||
    !applicant ||
    !Object.values(APPLICANT_STATUSES).includes(applicant.status)
  ) {
    return IDCheckStatus.error;
  }

  if (Object.values(APPLICANT_PENDING_STATUSES).includes(applicant.status)) {
    return IDCheckStatus.not_completed;
  }

  return IDCheckStatus.completed;
}
