export const ServerErrorIcon = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="557"
      height="341"
      viewBox="0 0 557 341"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M498.403 130.023C499.657 133.757 502.685 140.231 504.769 136.255C507.374 131.285 518.996 100.139 517.087 81.193C520.741 67.8578 531.8 37.827 546.803 24.3853L497.547 6.02254C483.429 18.7756 453.767 51.7047 448.067 81.3964C440.941 118.511 440.973 157.14 461.559 148.981C462.708 149.397 468.586 150.414 471.803 147.055C473.362 148.164 477.3 148.895 480.575 142.952C483.195 145.1 490.429 145.521 498.403 130.023ZM490.28 125.45C487.205 125.628 494.995 100.956 499.274 88.5984L499.507 122.174C497.712 123.192 493.354 125.272 490.28 125.45Z"
        fill="#EDCA6C"
      />
      <mask
        id="mask0"
        maskUnits="userSpaceOnUse"
        x="438"
        y="0"
        width="111"
        height="161"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M498.404 130.024C499.658 133.758 502.686 140.232 504.77 136.256C507.375 131.286 518.997 100.14 517.088 81.1939C520.742 67.8587 531.801 37.828 546.804 24.3863L497.548 6.02352C483.43 18.7766 453.768 51.7057 448.068 81.3973C440.942 118.512 440.974 157.14 461.56 148.982C462.709 149.398 468.587 150.415 471.804 147.056C473.363 148.165 477.3 148.896 480.576 142.953C483.196 145.101 490.43 145.522 498.404 130.024ZM490.281 125.451C487.206 125.629 494.996 100.957 499.275 88.5993L499.508 122.175C497.713 123.193 493.355 125.273 490.281 125.451Z"
          fill="#EDCA6C"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M498.909 126.161C499.078 124.937 499.128 123.733 499.026 122.973L499.776 122.873C499.891 123.732 499.831 125.014 499.659 126.264C499.487 127.513 499.197 128.785 498.827 129.618L498.136 129.311C498.463 128.574 498.741 127.385 498.909 126.161Z"
        fill="#EDCA6C"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M482.179 97.5471C472.896 118.241 476.818 134.868 479.886 140.535L479.22 140.895C476.035 135.011 472.114 118.136 481.489 97.2374L482.179 97.5471Z"
        fill="#EDCA6C"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M464.293 123.673C464.473 132.696 466.59 141.203 471.412 146.03L470.876 146.565C465.843 141.525 463.717 132.768 463.537 123.688C463.355 114.59 465.123 105.064 467.984 98.3923L468.679 98.6904C465.867 105.248 464.114 114.666 464.293 123.673Z"
        fill="#EDCA6C"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M456.823 101.069C452.128 118.931 452.284 131.329 459.757 145.428L459.088 145.782C451.498 131.462 451.366 118.854 456.092 100.876L456.823 101.069Z"
        fill="#EDCA6C"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M521.626 136.498C526.493 148.083 526.658 161.634 523.845 171.479L518.389 169.92C520.87 161.233 520.729 149.013 516.394 138.696C512.122 128.528 504.214 119.521 490.28 118.116L491.666 113.017C508.021 114.666 516.695 124.764 521.626 136.498Z"
        fill="#6A9A5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M437.348 129.901C439.346 126.905 442.064 124.578 443.814 123.576L444.159 130.221C443.593 130.545 443.949 130.23 442.069 133.05C440.25 135.776 438.065 139.785 435.659 145.392C430.847 156.603 425.225 174.033 419.884 199.985C417.681 210.686 415.751 221.278 413.885 231.514C410.123 252.154 406.624 271.346 401.694 287.062C394.311 310.594 383.245 327.948 361.82 330.655C344.248 332.876 330.271 324.235 319.745 313.711C311.042 305.011 304.382 294.694 299.788 287.579C298.848 286.122 297.994 284.8 297.227 283.653L301.944 280.498C302.776 281.743 303.68 283.14 304.657 284.653C309.269 291.786 315.531 301.474 323.757 309.698C333.686 319.624 346.013 326.933 361.108 325.025C378.975 322.767 389.019 308.504 396.279 285.363C401.106 269.978 404.493 251.382 408.209 230.984C410.088 220.67 412.05 209.895 414.326 198.841C419.719 172.637 425.439 154.817 430.444 143.154C432.946 137.324 435.287 132.99 437.348 129.901Z"
        fill="#6A9A5C"
      />
      <path
        d="M203.404 318.383L32.3784 293.401L29.5635 281.908L225.291 253.134L366.731 266.913C369 277.288 365.785 281.233 363.894 281.908C331.607 290.014 262.495 307.927 244.338 314.735C226.182 321.544 209.484 320.004 203.404 318.383Z"
        fill="#699FE3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M350.212 272.234C350.323 272.637 350.086 273.054 349.683 273.164C317.648 281.95 250.569 300.557 238.278 304.762C225.795 309.032 210.421 308.18 204.282 307.225C204.281 307.225 204.281 307.225 204.28 307.225L69.328 286.962C68.9147 286.9 68.63 286.515 68.6921 286.102C68.7541 285.688 69.1394 285.404 69.5527 285.466L204.509 305.729L204.513 305.73C210.533 306.666 225.632 307.489 237.788 303.33C250.137 299.105 317.284 280.481 349.283 271.705C349.686 271.594 350.102 271.831 350.212 272.234Z"
        fill="#EEF3F7"
      />
      <path
        d="M218.808 297.714L104.117 279.476L227.32 257.997L324.586 269.75L218.808 297.714Z"
        fill="#EEF3F7"
      />
      <path
        d="M1.23589 142.635L30.2996 286.467L225.849 253.018C220.816 216.856 201.4 141.903 199.588 125.105C198.033 110.685 185.634 108.601 182.036 108.688C133.974 109.855 31.13 118.558 14.824 120.238C-1.48206 121.917 0.29235 139.638 1.23589 142.635Z"
        fill="#699FE3"
      />
      <path
        d="M58.3169 262.488L33.8487 141.478L184.74 128.27L205.704 238.723L58.3169 262.488Z"
        fill="#213845"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9741 133.114C14.6038 136.386 15.1839 140.323 15.9378 143.623L15.94 143.633L41.2316 269.335L39.748 269.633L14.4604 143.951C13.7062 140.647 13.0061 136.284 14.5783 132.53C16.1939 128.672 20.0904 125.692 27.9601 124.921L28.1078 126.427C20.5773 127.165 17.3026 129.942 15.9741 133.114Z"
        fill="#EEF3F7"
      />
      <path
        d="M522.373 168.71C501.622 162.682 489.627 190.35 486.224 204.937L541.047 218.697C543.504 204.408 543.124 174.738 522.373 168.71Z"
        fill="#699FE3"
      />
      <path
        d="M541.025 218.699C539.865 223.375 526.653 224.122 511.516 220.366C496.378 216.61 485.048 209.775 486.208 205.099C487.368 200.423 500.58 199.676 515.717 203.432C530.855 207.188 542.185 214.023 541.025 218.699Z"
        fill="#2B63CA"
      />
      <path
        d="M118.53 124.57C118.53 127.384 116.249 129.666 113.435 129.666C110.62 129.666 108.339 127.384 108.339 124.57C108.339 121.756 110.62 119.475 113.435 119.475C116.249 119.475 118.53 121.756 118.53 124.57Z"
        fill="#EEF3F7"
      />
      <path
        d="M83.8975 202.297L80.5 183.802L159.011 175.119L162.03 191.728L83.8975 202.297Z"
        fill="#F7F7F7"
      />
      <path
        d="M85.4044 200.034L83.1396 186.351L87.2916 185.502L89.9338 199.185L85.4044 200.034Z"
        fill="#FB534A"
      />
      <path
        d="M91.6358 199.183L89.3711 185.501L93.5231 184.651L96.1652 198.334L91.6358 199.183Z"
        fill="#FB534A"
      />
      <path
        d="M500.23 208.317L507.894 210.342L500.704 237.553L493.04 235.528L500.23 208.317Z"
        fill="#699FE3"
      />
      <path
        d="M500.691 237.607C500.548 238.134 498.719 238.095 496.607 237.521C494.495 236.947 492.899 236.055 493.042 235.528C493.186 235 495.014 235.039 497.126 235.613C499.238 236.187 500.834 237.08 500.691 237.607Z"
        fill="#699FE3"
      />
      <path
        d="M507.895 210.344C507.752 210.871 505.923 210.833 503.811 210.259C501.699 209.685 500.103 208.792 500.246 208.265C500.39 207.738 502.218 207.776 504.33 208.35C506.442 208.924 508.038 209.817 507.895 210.344Z"
        fill="#699FE3"
      />
      <path
        d="M518.354 213.246L526.018 215.271L518.828 242.481L511.164 240.456L518.354 213.246Z"
        fill="#699FE3"
      />
      <path
        d="M518.807 242.538C518.664 243.065 516.836 243.027 514.723 242.453C512.611 241.879 511.015 240.986 511.159 240.459C511.302 239.932 513.13 239.97 515.242 240.544C517.354 241.119 518.95 242.011 518.807 242.538Z"
        fill="#699FE3"
      />
      <path
        d="M526.017 215.272C525.874 215.799 524.046 215.76 521.933 215.186C519.821 214.612 518.225 213.72 518.369 213.193C518.512 212.666 520.34 212.704 522.452 213.278C524.564 213.852 526.16 214.745 526.017 215.272Z"
        fill="#699FE3"
      />
      <path
        d="M169.279 274.356L237.099 287.019"
        stroke="#BFD4E0"
        strokeWidth="2"
      />
      <path
        d="M209.351 264.172L278.465 274.919"
        stroke="#BFD4E0"
        strokeWidth="2"
      />
      <path
        d="M238.224 262.818C236.873 262.593 181.285 273.887 158.303 280.547"
        stroke="#BFD4E0"
        strokeWidth="2"
      />
      <path
        d="M272.415 269.165C271.065 268.94 220.964 281.767 197.982 288.427"
        stroke="#BFD4E0"
        strokeWidth="2"
      />
    </svg>
  );
};
