import { GetStateResponse } from '@iwoca/lapi-client/edge';
import { cloneDeep, merge, set } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  BUSINESS_DETAILS_STEP_ID,
  COMPANY_TYPE_STEP_ID,
  DIRECTOR_INFO_STEP_ID,
  StepType,
} from './steps/Steps';
import { denormaliseAddress } from '../../components/AddressInput/normalisation';
import { formatCurrency } from '../../components/Input/formatCurrency';

export function normaliseStepData(customerState: GetStateResponse) {
  const applicant = customerState.data.application?.people?.[0];

  return {
    [COMPANY_TYPE_STEP_ID]: {
      companyType: customerState.data.application?.company?.type || '',
    },
    [BUSINESS_DETAILS_STEP_ID]: {
      companyType: customerState.data.application?.company?.type || '',
      companyName:
        customerState.data.application?.company?.registered_company_name || '',
      companyNumber:
        customerState.data.application?.company?.company_number || '',
      tradingName: customerState.data.application?.company?.trading_name || '',
      tradingStartDate: customerState.data.application?.company
        ?.trading_from_date
        ? normaliseDate(
            customerState.data.application?.company?.trading_from_date,
          )
        : undefined,
      last12MonthsTurnover: formatCurrency(
        String(
          customerState.data.application?.company?.last_12_months_turnover
            ?.amount,
        ),
      ),
      agreeSoleTraderPilotDeclaration:
        //@ts-expect-error
        !!customerState.data.ui?.agreeSoleTraderPilotDeclaration || undefined,
      title: applicant?.title,
      firstName: applicant?.first_name,
      lastName: applicant?.last_name,
      isDirector: applicant?.roles?.includes('director') ? 'true' : '',
      isVatRegistered: customerState.data.application?.company?.vat_status
        ?.is_vat_registered
        ? customerState.data.application?.company?.vat_status?.is_vat_registered.toString()
        : '',
    },
    [DIRECTOR_INFO_STEP_ID]: {
      companyType: customerState.data.application?.company?.type || '',
      title: applicant?.title,
      firstName: applicant?.first_name,
      lastName: applicant?.last_name,
      dateOfBirth: applicant?.date_of_birth
        ? normaliseDate(applicant?.date_of_birth)
        : undefined,
      phoneNumber: applicant?.phones?.[0].number,
      isShareholder: applicant?.roles?.includes('shareholder') ? 'true' : '',
      address: {
        buildingName: applicant?.residential_addresses?.[0].house_name || '',
        buildingNumber:
          applicant?.residential_addresses?.[0].house_number || '',
        streetName: applicant?.residential_addresses?.[0].street_line_1 || '',
        town: applicant?.residential_addresses?.[0].town || '',
        postcode: applicant?.residential_addresses?.[0]?.postcode || '',
        equifaxToken:
          applicant?.residential_addresses?.[0]?.equifax_token || '',
      },
      privacyPolicyAgreed: applicant?.privacy_policy?.agreed,
    },
  };
}

function denormaliseCompanyTypeStep(
  prevState: GetStateResponse,
  normalisedStepData: ReturnType<
    typeof normaliseStepData
  >[typeof COMPANY_TYPE_STEP_ID],
) {
  const newState = cloneDeep(prevState);
  set(
    newState,
    'data.application.company.type',
    normalisedStepData.companyType,
  );
  return newState;
}

function denormaliseBusinessDetailsStep(
  prevState: GetStateResponse,
  normalisedStepData: ReturnType<
    typeof normaliseStepData
  >[typeof BUSINESS_DETAILS_STEP_ID],
) {
  const newState = cloneDeep(prevState);

  if (normalisedStepData.isDirector === 'false') {
    if (prevState.data.application?.people) {
      const modifiedApplicant = prevState.data.application.people[0];

      if (modifiedApplicant) {
        const applicantIndex =
          prevState.data.application.people.indexOf(modifiedApplicant);

        modifiedApplicant.first_name = normalisedStepData.firstName;
        modifiedApplicant.last_name = normalisedStepData.lastName;
        modifiedApplicant.title = normalisedStepData.title;

        const directorRoleIndex = modifiedApplicant.roles?.indexOf('director');
        if (directorRoleIndex! > -1) {
          modifiedApplicant.roles?.splice(directorRoleIndex!, 1);
        }

        set(
          newState,
          `data.application.people[${applicantIndex}]`,
          modifiedApplicant,
        );
      }
    }
  }

  if (normalisedStepData.isDirector === 'true') {
    const modifiedApplicant = prevState.data.application?.people?.filter(
      (people) => {
        return people.roles?.includes('applicant');
      },
    )[0];

    if (modifiedApplicant && prevState.data.application?.people) {
      const applicantIndex =
        prevState.data.application.people.indexOf(modifiedApplicant);
      modifiedApplicant.roles?.push('director');

      set(
        newState,
        `data.application.people[${applicantIndex}]`,
        modifiedApplicant,
      );
    }
  }

  const company = {
    company_number: normalisedStepData.companyNumber || undefined,
    last_12_months_turnover: {
      amount: normalisedStepData.last12MonthsTurnover
        ? parseInt(
            String(normalisedStepData.last12MonthsTurnover).replace(/,/g, ''),
            10,
          )
        : undefined,
    },
    trading_name: normalisedStepData.tradingName || undefined,
    registered_company_name: normalisedStepData.companyName || undefined,
    trading_from_date: normalisedStepData.tradingStartDate
      ? denormaliseDate(normalisedStepData.tradingStartDate)
      : undefined,
    type: prevState.data.application?.company?.type,
  };

  set(newState, 'data.application.company', company);

  const agreeSoleTraderPilotDeclaration =
    normalisedStepData.agreeSoleTraderPilotDeclaration || undefined;

  if (agreeSoleTraderPilotDeclaration) {
    set(
      newState,
      'data.ui.agreeSoleTraderPilotDeclaration',
      new Date().toISOString(),
    );
  }

  return newState;
}

function denormaliseDirectorInfoStep(
  prevState: GetStateResponse,
  normalisedStepData: ReturnType<
    typeof normaliseStepData
  >[typeof DIRECTOR_INFO_STEP_ID],
) {
  const newState = cloneDeep(prevState);
  const newApplicant = newState.data.application?.people?.[0] || {};

  const applicantState = {
    uid: uuidv4(),
    title: normalisedStepData.title,
    first_name: normalisedStepData.firstName,
    last_name: normalisedStepData.lastName,
    phones: [
      {
        uid: uuidv4(),
        type: 'primary',
        number: normalisedStepData.phoneNumber,
      },
    ],
    date_of_birth: denormaliseDate(normalisedStepData.dateOfBirth!),
    roles: denormaliseRoles(prevState?.data?.application?.people?.[0]?.roles, {
      shareholder: normalisedStepData.isShareholder === 'true',
      director: normalisedStepData.companyType !== 'sole_trader',
    }),
    residential_addresses: [denormaliseAddress(normalisedStepData)],
    privacy_policy: {
      agreed: normalisedStepData.privacyPolicyAgreed,
      datetime: new Date().toISOString(),
    },
  };

  merge(newApplicant, applicantState);

  set(newState, 'data.application.people[0]', newApplicant);

  return newState;
}

function denormaliseRoles(
  prevRoles: string[] = [],
  roleChanges: { [key: string]: boolean },
) {
  const changableRoles = Object.keys(roleChanges);
  const newRoles = prevRoles.filter((role) => !changableRoles.includes(role));

  for (const changableRole of changableRoles) {
    if (!roleChanges[changableRole]) continue;
    newRoles.push(changableRole);
  }

  return newRoles;
}

export function denormaliseDate(dob: string) {
  return dob.split('/').reverse().join('-');
}

export function normaliseDate(dob: string) {
  return dob.split('-').reverse().join('/');
}

export function denormaliseStepData<S extends StepType>(stepId: S) {
  const stepDenormaliser = {
    [COMPANY_TYPE_STEP_ID]: denormaliseCompanyTypeStep,
    [BUSINESS_DETAILS_STEP_ID]: denormaliseBusinessDetailsStep,
    [DIRECTOR_INFO_STEP_ID]: denormaliseDirectorInfoStep,
  };

  return stepDenormaliser[stepId];
}
