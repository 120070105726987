export function getIwocaPayMeUrl(
  sellerHandle: string,
  payLinkPackageId?: string,
) {
  const origin = window.location.origin;
  const payLinkPackageSuffix = payLinkPackageId
    ? `/package/${payLinkPackageId}/`
    : '';

  if (origin && origin !== 'https://www.iwoca.co.uk') {
    return `${origin}/pay/me/${sellerHandle}${payLinkPackageSuffix}`;
  }

  return `https://iwocaPay.me/${sellerHandle}${payLinkPackageSuffix}`;
}
