import React from 'react';

import { GetSellerPayLinkResponse } from '@iwoca/lapi-client/iwocapay';
import { Icon } from '@iwoca/orion';
import classnames from 'classnames';

import styles from './PayLinkSource.module.css';
import LightningBolt from '../../../../../Integrations/assets/lightningBolt.svg';
import MagentoLogo from '../../../../../Integrations/assets/magentoLogo.svg';
import OpenCartLogo from '../../../../../Integrations/assets/opencartLogo.svg';
import QuickBooksLogo from '../../../../../Integrations/assets/quickBooksLogo.svg';
import ShopifyLogo from '../../../../../Integrations/assets/shopifyLogo.svg';
import WooCommLogo from '../../../../../Integrations/assets/wooLogo.svg';
import XeroLogo from '../../../../../Integrations/assets/xeroLogo.svg';
import { PayLinkColumns } from '../../PayLinkTable';

export const PayLinkSource = ({
  payLink,
  className = null,
}: {
  payLink: PayLinkColumns;
  className?: string | null;
}): JSX.Element => {
  const names: {
    [k: GetSellerPayLinkResponse['data'][number]['source']]:
      | string
      | JSX.Element;
  } = {
    Unknown: 'Unknown',
    iwocaPay: 'Custom',
    Xero: 'Xero',
    iwocaPayMe: 'Universal',
    iwocaPay_pay_link_package: 'Package',
    iwocaPay_ecommerce_api: 'API',
    iwocaPay_woocommerce_extension: 'WooComm',
    iwocaPay_quickbooks: 'Quickbooks',
    iwocaPay_magento_extension: 'Magento',
    iwocaPay_opencart_extension: 'OpenCart',
    iwocaPay_shopify_payment_app: (
      <div>
        Shopify
        <div className={styles.shopifyPaymentId}>
          {payLink.shopify_payment_id}
        </div>
      </div>
    ),
  };

  const icons: {
    [k: GetSellerPayLinkResponse['data'][number]['source']]: JSX.Element;
  } = {
    Unknown: <Icon icon={'questionMarkOutline'} />,
    iwocaPay: <Icon icon={'adjust'} />,
    Xero: <img src={XeroLogo} alt="Xero logo" />,
    iwocaPayMe: <Icon icon={'wallet'} />,
    iwocaPay_pay_link_package: <Icon icon={'packageOpen'} />,
    iwocaPay_ecommerce_api: <Icon icon={'basketOutline'} />,
    iwocaPay_woocommerce_extension: (
      <img src={WooCommLogo} alt="WooCommerce logo" />
    ),
    iwocaPay_quickbooks: <img src={QuickBooksLogo} alt="Quickbooks logo" />,
    iwocaPay_magento_extension: <img src={MagentoLogo} alt="Magento logo" />,
    iwocaPay_opencart_extension: <img src={OpenCartLogo} alt="Opencart logo" />,
    iwocaPay_shopify_payment_app: <img src={ShopifyLogo} alt="Shopify logo" />,
  };

  if (payLink.funded_via === 'PAY_NOW') {
    return (
      <div className={classnames(styles.source, className)}>
        <img src={LightningBolt} alt="" />
        <div>Pay Now</div>
      </div>
    );
  }

  return (
    <div className={classnames(styles.source, className)}>
      {icons[payLink.source] || '?'}
      <div>{names[payLink.source] || 'Unknown'}</div>
    </div>
  );
};
