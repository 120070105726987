import { useGetSellerPackagePayLinks } from '../../../api/lending/lapiHooks';

export function usePackageLinks({
  includeDeleted = false,
}: {
  includeDeleted?: boolean;
} = {}) {
  const { data: packageLinksApiData, refetch } = useGetSellerPackagePayLinks();

  let packageLinks = packageLinksApiData?.data || [];

  if (!includeDeleted) {
    packageLinks = packageLinks.filter(
      (packageLink) => !packageLink.deleted_at,
    );
  }

  return {
    packageLinks: packageLinks,
    fetchPackageLinks: refetch,
  };
}
