import styles from '../Xero.module.css';

export function PillLink({
  href,
  children,
}: {
  href: string;
  children: React.ReactChild;
}) {
  return (
    <a
      href={href}
      className={styles.pill}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}
