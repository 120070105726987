import { createRoot } from 'react-dom/client';

import './utils/logger';
import './index.css';

import { App } from './App';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);
