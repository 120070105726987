import { usePostLoanDocumentsSigned } from '../../../../api/lending/lapiHooks';
import { useLoanDocument } from '../../hooks/useLoanDocument';

export function useFixedSumLoanAgreement() {
  const {
    loanDocument,
    downloadLoanDocument,
    canSignLoanDocument,
    isLoanDocumentSigned,
  } = useLoanDocument('master_loan_agreement');

  // use edge for signing the loan document - this endpoint supports multiple formal offers
  const { signLoanDocument } = usePostLoanDocumentsSigned({
    documentId: loanDocument?.document_id,
  });

  return {
    fixedSumLoanAgreement: loanDocument,
    downloadFixedSumLoanAgreement: downloadLoanDocument,
    signFixedSumLoanAgreement: signLoanDocument,
    canSignFixedSumLoanAgreement: canSignLoanDocument,
    isFixedSumLoanAgreementSigned: isLoanDocumentSigned,
  };
}
