import classNames from 'classnames';
import ReactModal from 'react-modal';

import styles from './DecisionModal.module.css';
import { DecisionModalIcon, DecisionModalIconType } from './DecisionModalIcon';
import { ErrorModal } from './ErrorModal';
import { useModal } from '../../../store/ModalProvider';
import { buildQueryString } from '../../../utils/queryParams';
import { DecisionErrorTypes } from '../../ApprovalRequirements';
import { DecisionStatus, useDecision } from '../../DecisionProvider';

export const DECISION_MODAL_ID = 'decisionModal';

export const DecisionModal = () => {
  const { isOpen } = useModal(DECISION_MODAL_ID);
  const { decisionError, decisionStatus } = useDecision();

  const handleFatalError = () => {
    const queryString = buildQueryString();
    window.location.href = `/pay/signup/error${queryString}`;
  };

  return (
    <ReactModal
      className={classNames(styles.Modal, {
        [styles.ErrorModal]: decisionStatus === DecisionStatus.ERROR,
      })}
      overlayClassName={styles.Overlay}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
    >
      {(() => {
        if (decisionStatus === DecisionStatus.APPROVED) {
          return (
            <>
              <DecisionModalIcon type={DecisionModalIconType.APPROVAL} />

              <p className={styles.approved}>Approved</p>
              <p className={styles.description}>Redirecting to overview...</p>
            </>
          );
        }

        if (decisionStatus === DecisionStatus.DECLINED) {
          return (
            <>
              <div className={styles.textBody}>
                Sorry, we can’t fund your application at this time
              </div>

              <p className={styles.description}>Redirecting to overview...</p>
            </>
          );
        }

        if (
          decisionStatus === DecisionStatus.ERROR &&
          (decisionError ===
            DecisionErrorTypes.DIRECTORS_COMPANY_MISMATCH_ERROR ||
            decisionError === DecisionErrorTypes.INCOMPLETE_CREDIT_CHECK_ERROR)
        ) {
          return <ErrorModal />;
        } else if (
          decisionStatus === DecisionStatus.ERROR ||
          decisionStatus === DecisionStatus.UNKNOWN
        ) {
          handleFatalError();
        }

        let description = '';
        if (decisionStatus === DecisionStatus.CHECK_CREDIT_PROFILE) {
          description = 'Checking credit profile';
        }

        if (decisionStatus === DecisionStatus.SUBMITTING) {
          description = 'Submitting application';
        }
        return (
          <>
            <DecisionModalIcon type={DecisionModalIconType.LOADING} />

            <div className={styles.descriptionWrapper}>
              <p className={styles.description}>{description}</p>
            </div>
          </>
        );
      })()}
    </ReactModal>
  );
};
