import { useUserGroups } from '../../hooks/useUserGroups';

type TUserRoles = {
  owner?: boolean;
  staff?: boolean;
  admin?: boolean;
  editor?: boolean;
  contributor?: boolean;
  viewer?: boolean;
};

type TConditionalDisplay = TUserRoles & {
  children: React.ReactNode | React.ReactNode[];
  fallback?: React.ReactNode | React.ReactNode[];
};

export const HideFor = ({
  owner,
  staff,
  admin,
  editor,
  contributor,
  viewer,
  children,
  fallback,
}: TConditionalDisplay) => {
  const {
    isOwner,
    isStaff,
    isAdmin,
    isEditor,
    isContributor,
    isViewer,
    isLoading,
  } = useUserGroups();

  if (isLoading) return null;

  if (
    (owner && isOwner) ||
    (staff && isStaff) ||
    (admin && isAdmin) ||
    (editor && isEditor) ||
    (contributor && isContributor) ||
    (viewer && isViewer)
  ) {
    if (fallback) {
      return fallback;
    }
    return null;
  }

  return children;
};

export const ShowFor = ({
  owner,
  staff,
  admin,
  editor,
  contributor,
  viewer,
  children,
  fallback,
}: TConditionalDisplay) => {
  const {
    isOwner,
    isStaff,
    isAdmin,
    isEditor,
    isContributor,
    isViewer,
    isLoading,
  } = useUserGroups();

  if (isLoading) return null;

  if (
    (owner && isOwner) ||
    (staff && isStaff) ||
    (admin && isAdmin) ||
    (editor && isEditor) ||
    (contributor && isContributor) ||
    (viewer && isViewer)
  ) {
    return children;
  }

  if (fallback) {
    return fallback;
  }

  return null;
};
