import { Card } from '@iwoca/orion';

import styles from './SellerDashboardCard.module.css';
import { LinkWithQuery } from '../../../../components/LinkWithQuery/LinkWithQuery';
import chevronRight from '../../../../components/svg/ChevronRight.svg';

export const SellerDashboardCard = ({
  icons,
  title,
  link,
  children,
  externalLink,
}: {
  icons?: Array<{ icon: string; alt: string }>;
  title?: string;
  link?: { text: string; href: string };
  children?: React.ReactNode;
  externalLink?: boolean;
}) => {
  return (
    <Card className={styles.card}>
      {icons && (
        <div className={styles.icons}>
          {icons.map((icon) => (
            <img
              key={icon.alt}
              src={icon.icon}
              alt={icon.alt}
              className={styles.icon}
            ></img>
          ))}
        </div>
      )}
      {title && <div className={styles.title}>{title}</div>}
      {children && <div className={styles.body}>{children}</div>}
      {link &&
        (externalLink ? (
          <a
            className={styles.link}
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.text}
            <img src={chevronRight} alt="arrow" className={styles.chevron} />
          </a>
        ) : (
          <LinkWithQuery className={styles.link} to={link.href}>
            {link.text}
            <img src={chevronRight} alt="arrow" className={styles.chevron} />
          </LinkWithQuery>
        ))}
    </Card>
  );
};
