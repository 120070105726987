import { InputHTMLAttributes, ReactNode } from 'react';

import { Select } from '@iwoca/orion';
import cn from 'classnames';
import { useField } from 'formik';
import { useFormContext } from 'react-hook-form';

import styles from './Dropdown.module.css';
import { extractError } from '../../utils/ReactHookForm';
import { InputError } from '../InputError/InputError';

export const Dropdown = ({
  name,
  labelText,
  children,
  disabled,
  className,
  placeholder,
}: {
  name: string;
  labelText: string;
  children: ReactNode;
  placeholder?: string;
} & InputHTMLAttributes<HTMLSelectElement>) => {
  const [field, { touched, error }] = useField(name);

  return (
    <div className={cn(styles.Dropdown, className)}>
      <Select
        {...field}
        disabled={disabled}
        name={name}
        id={name}
        className={cn(styles.select, 'fs-mask')}
        label={labelText}
      >
        {placeholder! && (
          <option value="" selected disabled>
            {placeholder}
          </option>
        )}
        {children}
      </Select>
      <InputError isVisible={touched} error={error} />
    </div>
  );
};

export const HookFormDropDown = ({
  name,
  labelText,
  children,
  disabled,
  className,
  placeholder,
}: {
  name: string;
  labelText: string;
  children: ReactNode;
  placeholder?: string;
} & InputHTMLAttributes<HTMLSelectElement>) => {
  const { register, formState } = useFormContext();
  const error = extractError(formState.errors[name]);

  return (
    <div className={cn(styles.Dropdown, className)}>
      <Select
        {...register(name)}
        disabled={disabled}
        name={name}
        id={name}
        className={cn(styles.select, 'fs-mask')}
        label={labelText}
      >
        {placeholder! && (
          <option value="" selected disabled>
            {placeholder}
          </option>
        )}
        {children}
      </Select>
      <InputError isVisible={true} error={error} />
    </div>
  );
};
