import { StylesConfig } from 'react-select';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MultiDropdownStyle: StylesConfig<any, true> = {
  multiValue: (base, state) => {
    return state.data.isFixed
      ? {
          ...base,
          borderRadius: '4px',
          backgroundColor: '#143b6b',
          opacity: '0.5',
        }
      : {
          ...base,
          borderRadius: '4px',
          backgroundColor: '#143b6b',
        };
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? {
          ...base,
          fontWeight: 'bold',
          color: 'white',
          paddingRight: 6,
        }
      : { ...base, color: 'white' };
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed
      ? { ...base, display: 'none' }
      : { ...base, color: 'white' };
  },
};
