import React, { useState } from 'react';

import {
  fetchPutIwocapaySellerPaymentCapturedCancel,
  fetchPutIwocapaySellerPaymentCapturedFund,
} from '@iwoca/lapi-client/edge';
import { CheckBox, Spinner } from '@iwoca/orion';
import classnames from 'classnames';

import styles from './PayLinkRow.module.css';
import { copy } from '../../../../../../components/util/copy';
import { useStateKey } from '../../../../../../hooks/useStateKey.hook';
import { createToast } from '../../../../../../store/IwToast';
import { PayLinkSource } from '../../../PayLinkTable/components/PayLinkSource/PayLinkSource';
import {
  InteractiveStatus,
  PayLinkStatus,
} from '../../../PayLinkTable/components/PayLinkStatus/PayLinkStatus';
import { PayLinkColumns } from '../../../PayLinkTable/PayLinkTable';
import { mapPayLinkStatusToFriendly } from '../../../PayLinkTable/utils/payLinkStatusMaps';

export const PayLinkRow = ({ payLink }: { payLink: PayLinkColumns }) => {
  const parsedStatus = mapPayLinkStatusToFriendly(payLink.status);

  return (
    <div className={styles.container}>
      <div
        className={classnames(styles.card, {
          [styles.greenRow]: parsedStatus === "You've been paid 🙌",
          [styles.redRow]: parsedStatus === 'Declined',
          [styles.yellowRow]: parsedStatus === 'Pending funds',
          [styles.cancelledRow]: parsedStatus === 'Cancelled',
        })}
      >
        <CheckBox
          checked={payLink.selectedRows.includes(payLink.paylink_id)}
          onChange={(event) => {
            const isChecked = event.target.checked;
            isChecked
              ? payLink.handleAddSelectedRow([payLink.paylink_id])
              : payLink.handleRemoveSelectedRow([payLink.paylink_id]);
          }}
        />
        <div>
          <div className={styles.infoContainer}>
            <div className={styles.reference}>{payLink.reference}</div>
            <div className={styles.amount}>{payLink.amount}</div>
            <div className={styles.companyName}>{payLink.buyerName || ''}</div>
            <div className={styles.paymentTerm}>{payLink.productDuration}</div>
            <PayLinkSource payLink={payLink} className={styles.mobileSources} />
          </div>
          <div className={styles.status}>
            <PayLinkStatus
              payLinkColumn={payLink}
              onCopyLink={() => copy(payLink.payLinkLink)}
              showDropdown={false}
            />
          </div>
        </div>
      </div>
      {parsedStatus === 'Pending funds' && <ActiveOptions payLink={payLink} />}
    </div>
  );
};

type TActionButtonText = {
  primaryButtonText: string;
  secondaryButtonText: string;
  onClickPrimary: () => void;
  onClickSecondary: () => void;
};

const ActiveOptions = ({ payLink }: { payLink: PayLinkColumns }) => {
  const { stateKey } = useStateKey();
  const [interactiveState, setInteractiveState] = useState<
    Exclude<InteractiveStatus, null> | 'default'
  >('default');

  const cancelPaylink = async () => {
    if (!stateKey) return null;

    setInteractiveState('cancelling');
    try {
      await fetchPutIwocapaySellerPaymentCapturedCancel({
        stateKey: stateKey,
        payLinkId: payLink.paylink_id,
      });
      payLink.refreshTable();
    } catch (err) {
      setInteractiveState('default');
      createToast(
        'Unable to cancel Pay Link, please contact your account manager.',
        {
          variant: 'failure',
        },
      );
    }
  };

  const fundPayLink = async () => {
    if (!stateKey) return null;

    setInteractiveState('funding');
    try {
      await fetchPutIwocapaySellerPaymentCapturedFund({
        stateKey,
        payLinkId: payLink.paylink_id,
      });
      payLink.refreshTable();
    } catch (err) {
      setInteractiveState('default');
      createToast('Unable to fund, please contact your account manager.', {
        variant: 'failure',
      });
    }
  };

  const defaultState = {
    primaryButtonText: 'Accept',
    secondaryButtonText: 'Cancel',
    onClickPrimary: () => setInteractiveState('funding-confirmation'),
    onClickSecondary: () => setInteractiveState('cancellation-confirmation'),
  };

  const INTERACTIVE_STATUS_TO_BUTTON_OPTIONS = {
    'cancellation-confirmation': {
      primaryButtonText: 'Confirm cancellation',
      secondaryButtonText: 'Cancel',
      onClickPrimary: () => cancelPaylink(),
      onClickSecondary: () => setInteractiveState('default'),
    },
    'funding-confirmation': {
      primaryButtonText: 'Get paid now',
      secondaryButtonText: 'Cancel',
      onClickPrimary: () => fundPayLink(),
      onClickSecondary: () => setInteractiveState('default'),
    },
    default: defaultState,
    funding: null,
    cancelling: null,
  } as const satisfies Record<
    Exclude<InteractiveStatus, null> | 'default',
    TActionButtonText | null
  >;

  const ActiveButtonOptions = () => {
    const buttonOptions =
      INTERACTIVE_STATUS_TO_BUTTON_OPTIONS[interactiveState];

    if (buttonOptions === null)
      return (
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      );

    return (
      <>
        <button
          className={styles.activeOrderButton}
          onClick={() => buttonOptions!.onClickSecondary()}
        >
          {buttonOptions.secondaryButtonText}
        </button>
        <button
          className={classnames(styles.activeOrderButton, {
            [styles.dangerButton]:
              interactiveState === 'cancellation-confirmation',
            [styles.greenButton]: interactiveState === 'funding-confirmation',
          })}
          onClick={() => buttonOptions!.onClickPrimary()}
        >
          {buttonOptions.primaryButtonText}
        </button>
      </>
    );
  };

  return (
    <div className={classnames(styles.activeOptions, styles.twoOptions)}>
      <ActiveButtonOptions />
    </div>
  );
};
