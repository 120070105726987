import { Button } from '@iwoca/orion';

import ErrorCircle from '../../../../../components/svg/ErrorCircle.svg';
import styles from '../DrawDownModal.module.css';
import { IwocaToBankIllustration } from '../IwocaToBankIllustration/IwocaToBankIllustration';

export const Failed = ({ finishDrawDown }: { finishDrawDown: () => void }) => {
  return (
    <div className={styles.failedWrapper}>
      <div className={styles.title}>
        Unfortunately, we have not been able to process the payment.
      </div>
      <IwocaToBankIllustration
        icon={
          <span className={styles.processingDirectionError}>
            <img
              alt="Error"
              src={ErrorCircle}
              className={styles.ErrorCircleSVG}
            />
          </span>
        }
      />
      <Button
        variant="primary"
        className={styles.drawDownModalButton}
        onClick={finishDrawDown}
      >
        Close
      </Button>
    </div>
  );
};
