import { useState } from 'react';

import cn from 'classnames';
import { Form, Formik, useField } from 'formik';
import { useNavigate } from 'react-router-dom';

import styles from './BusinessDetails.module.css';
import { businessDetailsValidator } from './businessDetailTypeValidator';
import { CompanyInput } from '../../../../components/CompanyInput/CompanyInput';
import { Dropdown } from '../../../../components/Dropdown/Dropdown';
import { formatCurrency } from '../../../../components/Input/formatCurrency';
import { Input } from '../../../../components/Input/Input';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { SubmitButton } from '../../../components/Button/Button';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { InlineButton } from '../../../components/InlineButton/InlineButton';
import { useSoleTraderPilot } from '../../../hooks/useSoletraderPilot';
import { buildQueryString } from '../../../utils/queryParams';
import { IndustriesModal } from '../../components/IndustriesModal/IndustriesModal';
import { RadioButton } from '../../components/RadioButton/RadioButton';
import { StepCard } from '../../components/StepCard/StepCard';
import { denormaliseDate } from '../../stateNormalisation';
import { BUSINESS_TYPE_OPTIONS } from '../BusinessType/BusinessType';
import { BUSINESS_DETAILS_STEP_ID, DIRECTOR_INFO_STEP_ID } from '../Steps';
import { useNavigateToStep } from '../useNavigateToStep';
import { useStepData } from '../useStepData';
import { useStepStatus } from '../useStepStatus';

export const BusinessDetails = () => {
  const { stepData, updateStepData, isUpdatingStepData } = useStepData(
    BUSINESS_DETAILS_STEP_ID,
  );

  const stepStatus = useStepStatus(BUSINESS_DETAILS_STEP_ID);
  const isActive = stepStatus === 'active';
  const isCompleted = stepStatus === 'complete';
  const isSoleTraderPilot = useSoleTraderPilot();

  const goToCurrentStep = useNavigateToStep(BUSINESS_DETAILS_STEP_ID);
  const goToNextStep = useNavigateToStep(DIRECTOR_INFO_STEP_ID);

  const navigate = useNavigate();
  const queryString = buildQueryString();

  const handleSubmit = async (values: typeof stepData) => {
    await updateStepData(values);

    if (values.isDirector === 'false')
      return navigate(`/pay/signup/speak-to-director${queryString}`);

    goToNextStep();
  };

  return (
    <StepCard title="2. Your business info" isCompleted={isCompleted}>
      <Formik
        initialValues={stepData}
        onSubmit={async (values) => {
          await handleSubmit(values);
        }}
        enableReinitialize={!isUpdatingStepData}
        validationSchema={businessDetailsValidator}
      >
        {({ values }) => {
          const isSoleTrader = values.companyType === 'sole_trader';
          const showSoleTraderFlow = isSoleTrader && isSoleTraderPilot;

          if (isActive) {
            return (
              <Form className={styles.form} autoComplete="off">
                <CompanyInput />
                {!isSoleTrader && <AreYouADirector />}
                {values.isDirector !== 'false' && (
                  <Input
                    className={styles.signupInput}
                    name="last12MonthsTurnover"
                    label="Last 12 months turnover:"
                    labelDescriptionText="Enter the full amount. e.g. £30,000"
                    placeholder="Enter amount..."
                    formatter={formatCurrency}
                  />
                )}
                {showSoleTraderFlow && <SoleTraderIndustryQuestion />}
                <SubmitButton
                  disabled={isUpdatingStepData}
                  className={styles.button}
                >
                  {!isUpdatingStepData ? 'Continue' : <LoadingSpinner />}
                </SubmitButton>
              </Form>
            );
          }

          if (isCompleted) {
            return (
              <div className={cn(styles.completedDetails, 'fs-mask')}>
                {showSoleTraderFlow ? (
                  <>
                    <p>{values.tradingName}</p>
                    <p>
                      {
                        BUSINESS_TYPE_OPTIONS.find(
                          ({ value }) => value === values.companyType,
                        )?.label
                      }
                    </p>
                    {values.tradingStartDate && (
                      <p>
                        Trading start date:{' '}
                        {getFormattedDate(values.tradingStartDate)}
                      </p>
                    )}
                    <p className={styles.turnoverDetails}>
                      Last 12 months turnover: £{values.last12MonthsTurnover}
                    </p>
                  </>
                ) : (
                  <>
                    <p>{values.companyName}</p>
                    <p>{values.companyNumber}</p>
                    <p>
                      {
                        BUSINESS_TYPE_OPTIONS.find(
                          ({ value }) => value === values.companyType,
                        )?.label
                      }
                    </p>
                    {values.tradingStartDate && (
                      <p>
                        Trading start date:{' '}
                        {getFormattedDate(values.tradingStartDate)}
                      </p>
                    )}
                    <p className={styles.turnoverDetails}>
                      Last 12 months turnover: £{values.last12MonthsTurnover}
                    </p>
                  </>
                )}
                <InlineButton onClick={goToCurrentStep}>Edit</InlineButton>
              </div>
            );
          }
          return null;
        }}
      </Formik>
    </StepCard>
  );
};

const AreYouADirector = () => {
  const [field] = useField('isDirector');

  return (
    <div>
      <RadioButton
        labelText="Are you a director of the company?"
        name="isDirector"
        options={[
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ]}
        className={styles.directorRatio}
        compact={true}
      />
      {field.value === 'false' && (
        <>
          <Dropdown
            name="title"
            labelText="Title"
            className={styles.directorTitle}
          >
            <option value=""></option>
            <option value="mr">Mr</option>
            <option value="ms">Ms</option>
            <option value="mrs">Mrs</option>
            <option value="miss">Miss</option>
            <option value="sir">Sir</option>
            <option value="professor">Professor</option>
            <option value="doctor">Doctor</option>
            <option value="lord">Lord</option>
            <option value="lady">Lady</option>
            <option value="baron">Baron</option>
            <option value="baroness">Baroness</option>
            <option value="reverend">Reverend</option>
          </Dropdown>
          <Input
            label="First Name"
            labelDescriptionText=""
            name="firstName"
            className={styles.signupInput}
          />
          <Input
            label="Last Name"
            labelDescriptionText=""
            name="lastName"
            className={styles.signupInput}
          />
        </>
      )}
    </div>
  );
};

const SoleTraderIndustryQuestion = () => {
  const [showRiskyCompaniesModalOpen, setShowRiskyCompaniesModalOpen] =
    useState(false);

  const onClickModal = () => {
    setShowRiskyCompaniesModalOpen(!showRiskyCompaniesModalOpen);
  };

  return (
    <>
      <IndustriesModal
        onClickModal={onClickModal}
        showRiskyCompaniesModalOpen={showRiskyCompaniesModalOpen}
      />
      <Checkbox
        name="agreeSoleTraderPilotDeclaration"
        labelText={
          <span data-testid="agreeSoleTraderPilotDeclaration">
            I confirm that I will use these funds for business purposes & the
            funds will not be used to participate in any of the following
            industries:
            <InlineButton onClick={onClickModal}>See industries</InlineButton>
          </span>
        }
      />
    </>
  );
};

export const getFormattedDate = (dateString: string | null = '') => {
  const date = new Date(dateString ? denormaliseDate(dateString) : '');
  const formatter = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: 'short',
  });
  return formatter.format(date);
};
