import React from 'react';

import { Card } from '@iwoca/orion';

import styles from './PaymentActivity.module.css';
import { LinkWithQuery } from '../../../../components/LinkWithQuery/LinkWithQuery';
import chevronRight from '../../../../components/svg/ChevronRight.svg';
import { usePayLinks } from '../../../hooks/usePaylinks.hook';
import { PayLinkSource } from '../../../Payments/components/PayLinkTable/components/PayLinkSource/PayLinkSource';
import { PayLinkColumns } from '../../../Payments/components/PayLinkTable/PayLinkTable';
import { mapPayLinkStatusToFriendly } from '../../../Payments/components/PayLinkTable/utils/payLinkStatusMaps';
import { normalisePayLinkTableData } from '../../../Payments/utils/utils';

export const PaymentActivity = () => {
  const { payLinks } = usePayLinks({ orderBy: 'last_updated' });
  const normalisedPayLinks = normalisePayLinkTableData({
    payLinks: payLinks,
    selectedRows: [],
    handleRemoveSelectedRow: () => {},
    handleAddSelectedRow: () => {},
    sellerHandle: '',
  });

  // Endpoint doesn't support custom page sizes, for now we can just do this on FE
  const PAGE_SIZE = 5;

  return (
    <Card className={styles.wrap}>
      <div className={styles.header}>
        <div className={styles.heading}>Recent payment statuses</div>
        <LinkWithQuery
          className={styles.seeAllPaymentsLink}
          to="/pay/payments/"
        >
          See all payments
          <img src={chevronRight} alt="arrow" className={styles.chevron} />
        </LinkWithQuery>
      </div>
      <div>
        {normalisedPayLinks.slice(0, PAGE_SIZE).map((paylink) => (
          <PayLinkInfo key={paylink.paylink_id} paylink={paylink} />
        ))}
      </div>
    </Card>
  );
};

const PayLinkInfo = ({ paylink }: { paylink: PayLinkColumns }) => {
  return (
    <div key={`payment__${paylink.paylink_id}`} className={styles.paymentCard}>
      <div className={styles.paymentDetails}>
        <div>
          <div className={styles.reference}>{paylink.reference}</div>
          <div className={styles.businessName}>{paylink.buyerName}</div>
        </div>
        <div className={styles.rightHandInfo}>
          <div className={styles.amount}>{paylink.amount}</div>
        </div>
      </div>
      <div className={styles.paymentInfo}>
        <div className={styles.paylinkStatus}>
          {mapPayLinkStatusToFriendly(paylink.status)}
        </div>
        <div>
          <div className={styles.rightHandInfo}>
            <div className={styles.statusPillLabel}>
              <PayLinkSource payLink={paylink} className={styles.statusIcon} />
            </div>
            <div className={styles.createdText}>{paylink.date}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
