import classnames from 'classnames';

import styles from './StatusInformation.module.css';
import { Card } from '../../../../Buyer/components/Card/Card';
import { Divider } from '../../../../components/Divider/Divider';
import { ExclamationMarkCircledIcon } from '../../../../components/svg/ExclamationMarkCircledIcon';
import { QuestionMarkCircleIcon } from '../../../../components/svg/QuestionMarkCircledIcon';
import { ThreeDotsCircledIcon } from '../../../../components/svg/ThreeDotsCircledIcon';
import { TickCircledIcon } from '../../../../components/svg/TickCircledIcon';

export function StatusInformation({
  isPayLink = true,
}: {
  isPayLink?: boolean;
}) {
  return (
    <>
      <QuestionMarkCircleIcon className={styles.questionMark} />
      <Card className={styles.statusInformationDropdown}>
        <h4>What these mean</h4>
        <Divider />
        <ul>
          {isPayLink && (
            <li>
              <TickCircledIcon className={styles.statusIcon} />
              You've been paid
            </li>
          )}
          <li>
            <TickCircledIcon className={styles.statusIcon} fill="#ffbf00" />
            Your customer's eligible
          </li>
          <li>
            <ExclamationMarkCircledIcon className={styles.statusIcon} />
            Your customer isn't eligible
          </li>
          <li>
            <ThreeDotsCircledIcon
              className={classnames(styles.statusIcon, styles.darkThreeDots)}
            />
            Your customer's started signing up
          </li>
          {isPayLink && (
            <li>
              <ThreeDotsCircledIcon className={styles.statusIcon} />
              Your customer hasn't signed up yet
            </li>
          )}
        </ul>
      </Card>
    </>
  );
}
