import { usePostLoanDocumentsSigned } from '../../../../api/lending/lapiHooks';
import { useLoanDocument } from '../../hooks/useLoanDocument';

export function usePersonalGuaranteeDocument() {
  const {
    loanDocument,
    downloadLoanDocument,
    loanDocumentContent,
    isLoanDocumentSigned,
    isSigningLoanDocument,
    isLoadingLoanDocument,
  } = useLoanDocument('guarantee');

  // use edge for signing the loan document - this endpoint supports multiple formal offers
  const { signLoanDocument } = usePostLoanDocumentsSigned({
    documentId: loanDocument?.document_id,
  });

  return {
    personalGuaranteeDocument: loanDocument,
    downloadPersonalGuaranteeDocument: downloadLoanDocument,
    signPersonalGuaranteeDocument: signLoanDocument,
    personalGuaranteeContent: loanDocumentContent,
    isPersonalGuaranteeSigned: isLoanDocumentSigned,
    isSigningPersonalGuaranetee: isSigningLoanDocument,
    isLoadingPersonalGuarantee: isLoadingLoanDocument,
  };
}
