import styles from './SignupError.module.css';
import {
  iwocapayTelDisplay,
  iwocapayTelLink,
} from '../../../../constants.json';

export const SignupError = () => {
  return (
    <div className={styles.errorScreen}>
      <h2 className={styles.errorHeadline}>We need to run a few more checks</h2>
      <p>Leave it with us - you don’t need to do anything.</p>
      <p>
        We’ll be in touch as soon as possible. If this payment is urgent, give
        us a call on{' '}
        <a href={iwocapayTelLink} className={styles.link}>
          {iwocapayTelDisplay}
        </a>{' '}
        Mon-Fri (9am - 6pm).
      </p>
    </div>
  );
};
