import Skeleton from 'react-loading-skeleton';

import styles from './LoadingCard.module.css';
import { Card } from '../Card/Card';

export const LoadingCard = () => {
  return (
    <Card
      title={
        <Skeleton
          height="20px"
          containerClassName={styles.headerContainerClass}
        />
      }
    >
      <div data-testid="card-loading-skeleton">
        <Skeleton width="90%" />
        <Skeleton width="70%" />
        <Skeleton width="80%" />
      </div>
    </Card>
  );
};
