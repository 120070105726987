import {
  PayLink,
  TBuyerInterest,
  TDuration,
  TFormalOfferInterest,
  TPricingOption,
  TPricingPromotions,
  TRepresentativeInterest,
} from './PayLinkLanding.types';
import { FormalOffer } from '../../../Seller/PayLinks/utils/Paylinks.types';
import { mapDurationToTerm } from '../../hooks/useEnabledTerms';
import { getDurationInMonths } from '../../utils/product';

export const getPricingPromotions = ({
  productPricing,
}: {
  productPricing: PayLink['product_pricing'];
}) => {
  if (!productPricing.TWELVE_MONTHS) {
    return {
      THREE_MONTHS: productPricing.THREE_MONTHS.promotions,
    } as TPricingPromotions;
  }

  return {
    THREE_MONTHS: productPricing.THREE_MONTHS.promotions,
    TWELVE_MONTHS: productPricing.TWELVE_MONTHS.promotions,
  } as TPricingPromotions;
};

const getRepresentativeInterest = ({
  productPricing,
}: {
  productPricing: PayLink['product_pricing'];
}) => {
  if (!productPricing.TWELVE_MONTHS) {
    return {
      THREE_MONTHS: productPricing.THREE_MONTHS.representative_interest,
    } as TRepresentativeInterest;
  }

  return {
    THREE_MONTHS: productPricing.THREE_MONTHS.representative_interest,
    TWELVE_MONTHS: productPricing.TWELVE_MONTHS.representative_interest,
  } as TRepresentativeInterest;
};

export const shouldRedirectToCheckout = ({
  paymentOption,
  payLinkAmount,
  formalOffers,
}: {
  paymentOption: TPricingOption | null;
  payLinkAmount: number;
  formalOffers?: FormalOffer[];
}) => {
  if (!paymentOption || !formalOffers) return false;

  const duration = mapPricingOptionToDuration({ pricingOption: paymentOption });

  const availableCredit = formalOffers.find(
    (formalOffer) =>
      formalOffer.duration &&
      getDurationInMonths(formalOffer.duration) === duration,
  )?.available_credit;

  if (!availableCredit || availableCredit < payLinkAmount) return false;

  return true;
};

export const mapPricingOptionToDuration = ({
  pricingOption,
}: {
  pricingOption: TPricingOption;
}) => {
  switch (pricingOption) {
    case 'payLater3':
      return 3 as TDuration;
    case 'payLater12':
      return 12 as TDuration;
    default:
      return null;
  }
};

export const getRelevantFormalOfferId = ({
  paymentOption,
  formalOffers,
}: {
  paymentOption: TPricingOption | null;
  formalOffers?: FormalOffer[];
}) => {
  if (!paymentOption) return undefined;

  const duration = mapPricingOptionToDuration({ pricingOption: paymentOption });

  const offerId = formalOffers?.find(
    (formalOffer) =>
      formalOffer.duration &&
      getDurationInMonths(formalOffer.duration) === duration,
  )?.offer_id;

  return offerId;
};

export const getBuyerInterest = ({
  productPricing,
  formalOffers,
}: {
  productPricing: PayLink['product_pricing'];
  formalOffers?: FormalOffer[];
}) => {
  const repRates = getRepresentativeInterest({ productPricing });
  const formalOfferRates = getFormalOfferInterest({ formalOffers });

  const THREE_MONTHS = {
    rate: formalOfferRates?.THREE_MONTHS || repRates.THREE_MONTHS,
    type: formalOfferRates?.THREE_MONTHS ? 'formalOffer' : 'representative',
    isSellerPays: repRates.THREE_MONTHS === 0,
  };

  const TWELVE_MONTHS = {
    rate: formalOfferRates?.TWELVE_MONTHS || repRates.TWELVE_MONTHS,
    type: formalOfferRates?.TWELVE_MONTHS ? 'formalOffer' : 'representative',
    isSellerPays: repRates.TWELVE_MONTHS === 0,
  };

  return {
    THREE_MONTHS: THREE_MONTHS,
    // we only want to return TWELVE_MONTHS if it's been enabled by the seller
    ...(repRates.TWELVE_MONTHS !== undefined && {
      TWELVE_MONTHS: TWELVE_MONTHS,
    }),
  } as TBuyerInterest;
};

const getFormalOfferInterest = ({
  formalOffers,
}: {
  formalOffers?: FormalOffer[];
}) => {
  if (!formalOffers) return null;

  const formalOfferRates = {
    THREE_MONTHS: undefined,
    TWELVE_MONTHS: undefined,
  } as TFormalOfferInterest;

  for (const formalOffer of formalOffers) {
    if (
      formalOffer.duration &&
      formalOffer.product_type === 'iwocapay' &&
      formalOffer.is_valid === true
    ) {
      const durationInMonths = getDurationInMonths(formalOffer?.duration);
      const term = mapDurationToTerm(durationInMonths);
      if (term) {
        formalOfferRates[term] = formalOffer.interest_rates?.[0].rate;
      }
    }
  }

  return formalOfferRates;
};
