import classNames from 'classnames';

import styles from './HelpModal.module.css';
import constants from '../../../../constants.json';
import { Modal } from '../../../Modal/Modal';
import ChatBubbleIcon from '../../../svg/ChatBubbleIcon.svg?react';
import EnvelopeIcon from '../../../svg/EnvelopeIcon.svg?react';
import FaqIcon from '../../../svg/FaqIcon.svg?react';
import HelpIcon from '../../../svg/HelpIcon.svg?react';
import PhoneIcon from '../../../svg/PhoneIcon.svg?react';

export const HelpModal = ({
  isOpen,
  closeModal,
}: {
  isOpen: boolean;
  closeModal: () => void;
}) => {
  return (
    <Modal
      isModalOpen={isOpen}
      closeModal={closeModal}
      isClosable={true}
      testPrefix="helpModal"
    >
      <>
        <div className={styles.title} data-testid="help-modal">
          <div className={styles.titleText}>
            <span>Need help?</span>
          </div>
          <div className={styles.titleLogo}>
            <HelpIcon className={styles.helpSVG} />
          </div>
        </div>
        <div className={styles.helpModalContent}>
          <div className={styles.toplevel}>
            <div>
              <div className={styles.helpModalContact}>
                <PhoneIcon className={styles.helpModalContactLogoSVG} />
                <div className={styles.phoneContact}>
                  <span>Call us at</span>
                  &nbsp;
                  <a href={constants.iwocapayTelLink}>
                    <span>{constants.iwocapayTelDisplay}</span>
                  </a>
                </div>
              </div>
              <div
                className={classNames(
                  styles.helpModalContact,
                  styles.chatContact,
                )}
              >
                <ChatBubbleIcon className={styles.helpModalContactLogoSVG} />
                <div>
                  <span>You can also</span>
                  &nbsp;
                  <button onClick={closeModal} className={styles.chatButton}>
                    <span>Chat to us!</span>
                  </button>
                </div>
              </div>
            </div>
            <span className={styles.modalVerticalDivider} />
            <div className={styles.extraInfo}>
              <span>Monday to Friday</span>
              <span>9am - 6pm</span>
              <span className={styles.lightText}>
                (excluding bank holidays)
              </span>
            </div>
          </div>
          <div className={styles.lowerHelpSection}>
            <div className={styles.helpModalContact}>
              <EnvelopeIcon className={styles.helpModalContactLogoSVG} />
              <div>
                <span>Email at</span>
                &nbsp;
                <a href={constants.contactUsEmailLink}>
                  <span>{constants.contactUsEmailDisplay}</span>
                </a>
              </div>
            </div>
            <div className={styles.faqSection}>
              <FaqIcon className={styles.helpModalContactLogoSVG} />
              <div>
                <span>Find answers to common questions in our</span>
                &nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://support.iwoca.co.uk/en/collections/2145896-what-s-iwocapay"
                >
                  <span>FAQs</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};
