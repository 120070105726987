export const formatDate = (value: string) => {
  if (!value) return '';

  const cleanValue = value.replace(/\D/g, '');

  const day = cleanValue.slice(0, 2);
  const month = cleanValue.slice(2, 4);
  const year = cleanValue.slice(4, 8);

  return [day, month, year].filter(Boolean).join('/');
};
