import { Card } from '@iwoca/orion';
import cn from 'classnames';

import { LinkWithQuery } from '../../../components/LinkWithQuery/LinkWithQuery';
import { usePayLinks } from '../../hooks/usePaylinks.hook';

export const SellerDashboardStatusSummary = () => {
  const { statusAggregate } = usePayLinks();

  const BASE_PAYMENTS_ROUTE = '/pay/payments/';
  return (
    <>
      {/* Prefixing the class with "!" to override the padding coming from Orion */}
      <Card className="mb-2xl w-full !p-[0px] m:mb-xl">
        <div className="w-full border-b border-secondary-90 p-l text-left text-l font-bold">
          Your payment statuses
        </div>
        <div className="grid w-full grid-cols-4 content-between m:grid-cols-2">
          <div className="border-r border-secondary-90 p-s sm:border-b">
            <SummaryCard
              number={statusAggregate?.SIGNED_UP}
              text="Signed up"
              className="!text-structure-10"
              link={BASE_PAYMENTS_ROUTE}
              query={{ status: 'SIGNED_UP' }}
            />
          </div>
          <div className="border-r border-secondary-90 p-s sm:border-b sm:border-r-0">
            <SummaryCard
              number={statusAggregate?.DECLINED}
              text="Declined"
              className="!text-error"
              link={BASE_PAYMENTS_ROUTE}
              query={{ status: 'DECLINED' }}
            />
          </div>
          <div className="border-r border-secondary-90 p-s">
            <SummaryCard
              number={statusAggregate?.APPROVED}
              text="Approved"
              className="!text-success-drk"
              link={BASE_PAYMENTS_ROUTE}
              query={{ status: 'APPROVED' }}
            />
          </div>
          <div className="p-s">
            <SummaryCard
              number={statusAggregate?.PAID}
              text="Paid"
              className="!text-success-drk"
              link={BASE_PAYMENTS_ROUTE}
              query={{ status: 'PAID' }}
            />
          </div>
        </div>
      </Card>
    </>
  );
};

const SummaryCard = ({
  text,
  link,
  number,
  query = null,
  className,
}: {
  text: string;
  link: string;
  number?: number;
  query?: Record<string, string> | null;
  className?: string;
}) => {
  const cardNumber = typeof number === 'number' ? number.toString() : '';

  return (
    <LinkWithQuery className="no-underline" to={link} newQueryParams={query}>
      <Card
        className={cn(
          'cursor-pointer !items-center gap-s rounded-s !border-none !p-s text-primary-40 hover:bg-primary-97',
          className,
        )}
      >
        <div className="min-h-[2rem] text-2xl font-med text-primary-10">
          {cardNumber}
        </div>
        <div className="text-m font-med">{text}</div>
      </Card>
    </LinkWithQuery>
  );
};
