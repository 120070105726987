import { SVGAttributes } from 'react';

export const IDCheckCompleteIcon = (props: SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <svg
      {...props}
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.0101 10C30.0101 12.6522 28.9565 15.1957 27.0811 17.0711C25.2058 18.9464 22.6622 20 20.0101 20C17.3579 20 14.8144 18.9464 12.939 17.0711C11.0636 15.1957 10.0101 12.6522 10.0101 10C10.0101 7.34784 11.0636 4.8043 12.939 2.92893C14.8144 1.05357 17.3579 0 20.0101 0C22.6622 0 25.2058 1.05357 27.0811 2.92893C28.9565 4.8043 30.0101 7.34784 30.0101 10ZM26.0101 10C26.0101 8.4087 25.3779 6.88258 24.2527 5.75736C23.1275 4.63214 21.6014 4 20.0101 4C18.4188 4 16.8927 4.63214 15.7674 5.75736C14.6422 6.88258 14.0101 8.4087 14.0101 10C14.0101 11.5913 14.6422 13.1174 15.7674 14.2426C16.8927 15.3679 18.4188 16 20.0101 16C21.6014 16 23.1275 15.3679 24.2527 14.2426C25.3779 13.1174 26.0101 11.5913 26.0101 10ZM0.0100788 36C0.0100788 27.514 4.68208 24 11.5301 24.006C11.5761 24.006 10.0381 25.654 10.0381 28.13C10.0381 28.13 4.01008 28.238 4.01008 36H15.5661L19.6541 40H4.01008C-0.399921 40 0.0100788 36 0.0100788 36ZM36.0101 36C36.0101 33.502 35.4181 31.588 34.2341 30.256L37.2581 27.26C39.0581 29.258 39.9761 32.172 40.0101 36C40.0101 38.666 38.6761 40 36.0101 40H24.4741L28.4941 36H36.0101ZM36.5981 16.586C36.7826 16.395 37.0033 16.2426 37.2473 16.1378C37.4913 16.033 37.7537 15.9778 38.0193 15.9755C38.2848 15.9732 38.5482 16.0238 38.794 16.1244C39.0398 16.2249 39.2631 16.3734 39.4509 16.5612C39.6387 16.749 39.7872 16.9723 39.8877 17.2181C39.9883 17.4639 40.0389 17.7272 40.0366 17.9928C40.0343 18.2584 39.9791 18.5208 39.8743 18.7648C39.7695 19.0088 39.6171 19.2295 39.4261 19.414L23.4261 35.414C23.051 35.7889 22.5424 35.9996 22.0121 35.9996C21.4817 35.9996 20.9731 35.7889 20.5981 35.414L14.5961 29.412C14.2318 29.0348 14.0302 28.5296 14.0347 28.0052C14.0393 27.4808 14.2496 26.9792 14.6204 26.6084C14.9913 26.2375 15.4929 26.0272 16.0173 26.0227C16.5417 26.0181 17.0469 26.2197 17.4241 26.584L22.0121 31.172L36.5981 16.586Z"
        fill="#00B378"
      />
    </svg>
  );
};
