export function TickIcon({ title = 'Tick icon', fill = '#00B67A', ...props }) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      fill="None"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path d="M12 24a12 12 0 100-24 12 12 0 000 24z" fill={fill} />
      <path
        d="M7.1 13.9l3 2.6L16.5 9"
        stroke="#fff"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
