import { useContext } from 'react';

import {
  GetSellerPayLinkResponse,
  fetchGetSellerPayLink,
} from '@iwoca/lapi-client/iwocapay';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { ActivationContext } from '../../activation/Activation';
import { useStateKey } from '../../hooks/useStateKey.hook';

export type PayLinkStatus = keyof NonNullable<
  GetSellerPayLinkResponse['meta']['totals_by_status']
>;

type TPayLinkQueryParams = {
  page?: number;
  search?: { searchValue: string; searchFields: string[] };
  status?: PayLinkStatus | 'ARCHIVED';
  orderBy?: 'last_updated';
  includeDeleted?: boolean;
  ignoreStatus?: boolean;
};

export const usePayLinks = ({
  page,
  orderBy,
  searchQuery,
  status,
  includeDeleted = false,
}: Omit<TPayLinkQueryParams, 'search'> & {
  searchQuery?: string;
} = {}) => {
  const { isOnboarded, isLoading } = useContext(ActivationContext);
  const { stateKey } = useStateKey();

  const payLinkStatus = status === 'ARCHIVED' ? undefined : status;

  const {
    data,
    refetch: fetchPayLinks,
    isLoading: loadingFinal,
    isFetching: isFetching,
  } = useQuery({
    queryKey: ['fetchGetSellerPayLink', page, searchQuery, status, orderBy],
    queryFn: () =>
      fetchGetSellerPayLink({
        stateKey: stateKey!,
        query: {
          page: page || 1,
          search: searchQuery,
          search_fields: searchQuery
            ? ['reference', 'business_name', 'shopify_payment_id'].join(',')
            : undefined,
          is_archived: status === 'ARCHIVED',
          status: payLinkStatus,
          order_by: orderBy,
        },
      }),
    enabled: !isLoading,
    placeholderData: keepPreviousData,
  });

  const payLinksResponse = isOnboarded ? data : examplePayLinksResponse;

  let payLinks = payLinksResponse?.data || [];
  if (!includeDeleted)
    payLinks = payLinks.filter((payLinks) => !payLinks.deleted_at);

  return {
    payLinks: payLinks,
    metadata: payLinksResponse?.meta,
    fetchPayLinks,
    isLoading: isLoading || loadingFinal || isFetching,
    paginationLinks: payLinksResponse?.links,
    totalCount: payLinksResponse?.meta?.count,
    statusAggregate: payLinksResponse?.meta?.totals_by_status,
  };
};

export const buildSellerPaylinkSearchParams = ({
  page,
  search,
  status,
  orderBy,
}: TPayLinkQueryParams) => {
  const params = new URLSearchParams();
  if (search) {
    params.append('search', search.searchValue);
    params.append('search_fields', search.searchFields.join(','));
  }
  if (page) {
    params.append('page', `${page}`);
  }
  params.append('is_archived', (status === 'ARCHIVED').toString());
  if (status && status !== 'ARCHIVED') params.append('status', status);
  if (orderBy) params.append('order_by', orderBy);

  if (params.getAll.length === 0) return '';

  return `?${params.toString()}`;
};

const examplePayLinksResponse: GetSellerPayLinkResponse = {
  meta: {
    count: 1,
    total_pages: 1,
    totals_by_status: {
      APPROVED: 1,
      DECLINED: 1,
      NOT_SIGNED_UP: 1,
      PAID: 1,
      SIGNED_UP: 1,
      CANCELLED: 0,
      SCHEDULED_FOR_CAPTURE: 0,
      PARTIALLY_APPROVED: 0,
    },
  },
  links: {
    first:
      'https://www.iwoca.co.uk/api/lending/edge/pay_link/?page=1&is_archived=false',
    last: 'https://www.iwoca.co.uk/api/lending/edge/pay_link/?page=1&is_archived=false',
    self: 'https://www.iwoca.co.uk/api/lending/edge/pay_link/?page=1&is_archived=false',
  },
  data: [
    {
      id: '1',
      reference: 'Example payment',
      amount: 999,
      created: '2022-11-19T12:00:00Z',
      buyer_name: 'Business name',
      seller_name: 'Your business name',
      funding_date: '2022-11-20T12:00:00Z',
      source: 'iwocaPay_ecommerce_api',
      order: {
        order_id: '1',
        status: 'COMPLETE',
      },
      status: 'PAID',
      product_duration: 'THREE_MONTHS',
      shopify_payment_id: '',
    },
    {
      id: '2',
      reference: 'Example payment',
      amount: 980,
      created: '2022-11-18T12:00:00Z',
      buyer_name: 'Business name',
      seller_name: 'Your business name',
      approval_date: '2022-11-18T12:00:00Z',
      source: 'iwocaPay_woocommerce_extension',
      // @ts-expect-error
      order: null,
      status: 'SIGNED_UP',
      // @ts-expect-error
      product_duration: null,
    },
    {
      id: '3',
      reference: 'Example payment',
      amount: 8000,
      created: '2022-11-18T12:00:00Z',
      funding_date: '2022-11-18T12:00:00Z',
      buyer_name: 'Business name',
      seller_name: 'Your business name',
      source: 'iwocaPay',
      // @ts-expect-error
      order: null,
      status: 'APPROVED',
      // @ts-expect-error
      product_duration: null,
    },
    {
      id: '4',
      reference: 'Example payment',
      amount: 3010,
      created: '2022-11-16T12:00:00Z',
      buyer_name: 'Business name',
      seller_name: 'Your business name',
      funding_date: '2022-11-17T12:00:00Z',
      source: 'Xero',
      // @ts-expect-error
      order: null,
      status: 'DECLINED',
      // @ts-expect-error
      product_duration: null,
    },
    {
      id: '5',
      reference: 'Example payment',
      amount: 300,
      created: '2022-11-10T12:00:00Z',
      buyer_name: 'Business name',
      seller_name: 'Your business name',
      source: 'iwocaPayMe',
      // @ts-expect-error
      order: null,
      status: 'NOT_SIGNED_UP',
      // @ts-expect-error
      product_duration: null,
    },
  ],
};
