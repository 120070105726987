import { useContext } from 'react';

import { ActivationContext } from '../../../activation/Activation';
import {
  useGetSellerBranding,
  useGetStateByStateKey,
} from '../../../api/lending/lapiHooks';

export const useSellerDetails = ():
  | {
      initials?: string;
      firstName?: string;
      lastName?: string;
      companyName?: string;
      tradingName?: string;
    }
  | undefined => {
  const { state: customerState } = useGetStateByStateKey();
  const { sellerBranding } = useGetSellerBranding();

  const { isOnboarded } = useContext(ActivationContext);
  if (!isOnboarded) {
    return defaultDetails;
  }

  const application = customerState?.application;
  if (!application) return;

  const people = customerState?.application?.people;
  if (!people) return;

  const applicant = people.find((person) =>
    person.roles?.includes('applicant'),
  );
  if (!applicant) return;

  if (!sellerBranding) return;

  const firstName = capitaliseFirstLetters(applicant?.first_name);
  const lastName = capitaliseFirstLetters(applicant?.last_name);

  const initials =
    firstName && lastName && firstName.charAt(0) + lastName.charAt(0);

  const companyName = capitaliseFirstLetters(
    application?.company?.registered_company_name,
  );

  const tradingName = sellerBranding.trading_name;

  return {
    initials: initials,
    firstName: firstName,
    lastName: lastName,
    companyName: companyName,
    tradingName: tradingName,
  };
};

const defaultDetails = {
  initials: '',
  firstName: 'Your name',
  lastName: '',
  companyName: 'Your business name',
  tradingName: 'Your trading name',
};

const capitaliseFirstLetters = (toCapitalise: string | undefined) => {
  if (toCapitalise === undefined) return;
  const words = toCapitalise.split(' ');
  const capitalisedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1),
  );
  return capitalisedWords.join(' ');
};
