import { Dispatch, SetStateAction } from 'react';

import cn from 'classnames';
import { useFormikContext } from 'formik';

import styles from './HeaderControls.module.css';
import { CrossSellFormValues } from '../../utils/CrossSellCard.types';
import { CrossSellSteps, decrementStep } from '../../utils/stepHandler';

export const HeaderControls = ({
  step,
  setStep,
}: {
  step: CrossSellSteps;
  setStep: Dispatch<SetStateAction<CrossSellSteps>>;
}) => {
  const { resetForm, setFieldValue, isSubmitting } =
    useFormikContext<CrossSellFormValues>();
  return (
    <div className={styles.container}>
      {(step === 'APPLICANT_INFORMATION' || step === 'PRIVACY_POLICY') && (
        <button
          className={cn(styles.backButton, {
            [styles.fadedButton]: isSubmitting,
          })}
          disabled={isSubmitting}
          onClick={async () => {
            if (step === 'PRIVACY_POLICY') {
              await setFieldValue('policyAgreed', false);
            }
            setStep((currStep) => decrementStep(currStep));
          }}
        >
          Back
        </button>
      )}

      {step !== 'INTRODUCTION' && (
        <button
          className={cn(styles.cancelButton, {
            [styles.fadedButton]: isSubmitting,
          })}
          disabled={isSubmitting}
          onClick={() => {
            resetForm();
            setStep('INTRODUCTION');
          }}
        >
          Cancel
        </button>
      )}
    </div>
  );
};
