import styles from './IntegrationsHeader.module.css';

export function IntegrationsHeader() {
  return (
    <div className={styles.content}>
      <div>
        <h1 className={styles.pageTitle}>
          Integrate iwocaPay seamlessly in a few clicks
        </h1>
        <div className={styles.headerBody}>
          We’ve partnered with the biggest names in ecommerce and accounting
          software to make accepting payments with iwocaPay easy.
        </div>
      </div>
      <div className={styles.faqLinkWrapper}>
        Need some help?
        <a
          className={styles.faqLink}
          href="https://support.iwoca.co.uk/en/collections/2302041-frequently-asked-questions"
          target="_blank"
          rel="noreferrer"
        >
          Check out our FAQs
        </a>
      </div>
    </div>
  );
}
