import React from 'react';

import { Button, Card } from '@iwoca/orion';
import RiveComponent, { Alignment, Fit, Layout } from '@rive-app/react-canvas';

// @ts-ignore
import CelebrationRive from './Components/Celebration.riv';
import styles from './DelayedPaylinkComplete.module.css';
import { useEcomPostMessage } from '../../../hooks/useEcomPostMessage';
import { useIsOverlay } from '../../../hooks/useIsOverlay';
import { navigateToAccountPages } from '../../../utils/accountPages';
import { useCurrentEcommerceOrder } from '../../hooks/useCurrentEcommerceOrder';
import { useCurrentPayLink } from '../../hooks/useCurrentPayLink';

export const DelayedPaylinkComplete = () => {
  const { ecommerceOrder } = useCurrentEcommerceOrder();
  const { payLink, loadingPayLink } = useCurrentPayLink();

  const isOverlay = useIsOverlay();
  useEcomPostMessage({
    enabled: isOverlay,
    status: 'SUCCESSFUL',
    redirectUrl: ecommerceOrder?.redirect_url,
  });

  if (loadingPayLink) return null;

  const handleRedirect = () => {
    if (ecommerceOrder && !isOverlay) {
      window.location.href = ecommerceOrder.redirect_url;
      return;
    }
    navigateToAccountPages();
  };

  return (
    <>
      <Card className={styles.container}>
        <RiveComponent
          src={CelebrationRive}
          className={styles.celebrationRive}
          layout={
            new Layout({ fit: Fit.Contain, alignment: Alignment.TopCenter })
          }
        />
        <h1 className={styles.header}>You're all set!</h1>
        <p className={styles.bodyText}>
          Everything is now set up and ready to go. Once{' '}
          {payLink?.seller_trading_name} confirm the order, your payment
          schedule will begin. There’s nothing left for you to do.
        </p>
        <hr className={styles.horizontalLine} />
        <p className={styles.subText}>
          We will send you an email once {payLink?.seller_trading_name} have
          confirmed the order and your payment schedule will begin. Your first
          payment will be 30 days after that date.
        </p>
        {isOverlay && (
          <p className={styles.overlayText}>
            You can now safely close this window and return to{' '}
            {payLink?.seller_trading_name}.
          </p>
        )}

        {!isOverlay && (
          <Button className={styles.submitButton} onClick={handleRedirect}>
            {ecommerceOrder
              ? `Continue to ${payLink?.seller_trading_name}`
              : 'Continue to dashboard'}
          </Button>
        )}
      </Card>
    </>
  );
};
