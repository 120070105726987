import { useState } from 'react';

import { fetchPostRepaymentRegisterRepaymentMethod } from '@iwoca/lapi-client/edge';
import { Label } from '@iwoca/orion';
import { Form, Formik } from 'formik';
import { Frames, CardNumber, ExpiryDate, Cvv } from 'frames-react';

import { Auth3DSecureModal } from './Auth3DSecureModal/Auth3DSecureModal';
import { CheckoutFormValues } from './CheckoutAddPaymentMethodForm.types';
import { validateCheckoutAddCardForm } from './validateFormFields';
import { Input } from '../../../../../components/Input/Input';
import { InputError } from '../../../../../components/InputError/InputError';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { useStateKey } from '../../../../../hooks/useStateKey.hook';
import { getRuntimeEnvironment } from '../../../../../utils/getRuntimeEnvironment';
import { SubmitButton } from '../../../../components/Button/Button';

const CHECKOUT_COM_PRODUCTION_KEY = 'pk_m6yjih7mvailyyn4fmgunatoiao';
const CHECKOUT_COM_STAGING_KEY = 'pk_sbox_y67mbbuym66rphki3ktkvbjugmt';

export const CheckoutComAddPaymentMethodForm = () => {
  const publicKey =
    getRuntimeEnvironment() === 'production'
      ? CHECKOUT_COM_PRODUCTION_KEY
      : CHECKOUT_COM_STAGING_KEY;

  const { stateKey } = useStateKey();
  const [postcode, setPostcode] = useState('');

  const [auth3DSRedirectUrl, setAuth3DSRedirectUrl] = useState<string | null>();

  const handleAuth3DSecureComplete = () => {
    setAuth3DSRedirectUrl(null);
  };

  const handleSubmitAddCheckoutCard = async () => {
    if (!stateKey) {
      throw new Error('No statekey provided');
    }

    const response = await Frames.submitCard();

    const registerRepaymentMethodResponse =
      await fetchPostRepaymentRegisterRepaymentMethod({
        stateKey: stateKey,
        body: {
          data: {
            token: response.token,
          },
        },
      });

    setAuth3DSRedirectUrl(
      registerRepaymentMethodResponse.data.checkout_3ds_redirect_link,
    );
  };

  const initialValues: CheckoutFormValues = {
    billingPostcode: '',
    frames: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => validateCheckoutAddCardForm(values)}
      onSubmit={handleSubmitAddCheckoutCard}
    >
      {({
        errors,
        touched,
        isValid,
        isValidating,
        dirty,
        isSubmitting,
        validateForm,
      }) => (
        <Form>
          <Frames
            config={{
              publicKey: publicKey,
              cardholder: { billingAddress: { zip: postcode } },
              localization: {
                cardNumberPlaceholder: '1234 1234 1234 1234',
                expiryMonthPlaceholder: 'MM',
                expiryYearPlaceholder: 'YY',
                cvvPlaceholder: 'CVC',
              },
              style: {
                base: {
                  height: '43px',
                  borderRadius: '10px',
                  border: '1px solid rgba(204, 204, 204, 1)',
                  fontWeight: '400',
                  fontFamily: 'Aesop, sans-serif',
                  fontSize: '14px',
                  padding: '0.5rem',
                  width: 'calc(100% - 2px)',
                },
                focus: {
                  borderColor: 'rgb(105, 159, 227)',
                  borderWidth: '3px',
                },
                invalid: {
                  color: 'rgb(175 13 4)',
                },
                placeholder: {
                  base: {
                    color: '#8e8e8e',
                  },
                },
              },
            }}
            cardValidationChanged={async () => {
              await validateForm();
            }}
          >
            <div className="grid h-[100px] grid-cols-5 gap-l">
              <div className="col-span-3">
                <Label className="mb-m">Card number</Label>
                <CardNumber />
              </div>

              <div>
                <Label className="mb-m">Expiry</Label>
                <ExpiryDate />
              </div>

              <div>
                <Label className="mb-m">CVC</Label>
                <Cvv />
              </div>
            </div>
          </Frames>

          <div className="mb-2xl">
            <Input
              label="Billing postcode"
              name="billingPostcode"
              placeholder="e.g. N6 6NG"
              showError={false}
              onChange={(e) => {
                setPostcode(e.target.value);
              }}
            />
            <InputError
              isVisible={touched.billingPostcode || false}
              error={errors.billingPostcode}
            />
          </div>

          <SubmitButton
            disabled={isValidating || isSubmitting || !(isValid && dirty)}
          >
            {isSubmitting ? <LoadingSpinner /> : 'Add debit card now'}
          </SubmitButton>

          <InputError isVisible={!!errors.frames} error={errors.frames} />

          {auth3DSRedirectUrl && (
            <Auth3DSecureModal
              auth3DSRedirectUrl={auth3DSRedirectUrl}
              onAuth3DSecureComplete={handleAuth3DSecureComplete}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};
