import { datadogLogs, Logger } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { getRuntimeEnvironment } from './getRuntimeEnvironment';

const isProductionBuild = import.meta.env.MODE === 'production';
const environment = getRuntimeEnvironment();

if (isProductionBuild) {
  datadogLogs.init({
    clientToken:
      environment === 'production'
        ? 'pubc933c94adb762f629064542c6ab22c83'
        : 'pubac7b29fbb6ff39e65d3e25f88eff673e',
    site: 'datadoghq.com',
    service: 'circe',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    env: environment,
    version: import.meta.env.VITE_RELEASE,
  });

  datadogRum.init({
    applicationId:
      environment === 'production'
        ? '63418b60-011f-4078-96e4-ec877f32093d'
        : '7adf23e5-7a18-44d9-b405-5a4feb7154e4',
    clientToken:
      environment === 'production'
        ? 'pub1dbe977eb2ab1c7b671227d3a52e2582'
        : 'pubc47263e51c79774776d172a3a3451daa',
    site: 'datadoghq.com',
    service: 'circe',
    env: 'uk',
    version: import.meta.env.VITE_RELEASE,
    trackUserInteractions: true,
    allowedTracingUrls:
      environment === 'production'
        ? ['https://www.iwoca.co.uk']
        : ['https://stage.iwoca-dev.co.uk'],
  });
}

export default (isProductionBuild
  ? datadogLogs.logger
  : { ...console }) as Logger;
