import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext<{
  openModalId: string | null;
  setOpenModalId: (id: string | null) => void;
}>({ openModalId: null, setOpenModalId: () => {} });

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [openModalId, setOpenModalId] = useState<string | null>(null);

  return (
    <ModalContext.Provider
      value={{ openModalId: openModalId, setOpenModalId: setOpenModalId }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export function useModal(modalId: string) {
  const { openModalId, setOpenModalId } = useContext(ModalContext);
  const isOpen = openModalId === modalId;
  const openModal = () => {
    setOpenModalId(modalId);
  };
  const closeModal = () => setOpenModalId(null);

  return {
    isOpen,
    openModal,
    closeModal,
  };
}
