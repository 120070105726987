import { tracking } from '@iwoca/frontend-tracking-library';
import { Card } from '@iwoca/orion';

import styles from './ANTCard.module.css';
import { buildQueryString } from '../../../../Buyer/utils/queryParams';
import { useOnce } from '../../../../hooks/useOnce';
import { displayCurrency } from '../../../../utils/DisplayCurrency';
import { getFormattedDate } from '../../../../utils/DisplayDate';
import iwocaLogo from '../../assets/iwocaLogoWhite.svg';
import { useApprovedNotTakenOffers } from '../../hooks/useApprovedNotTakenOffers';
import { SanitisedFormalOffers } from '../../utils/Dashboard.types';

const UTM_PARAMS = {
  utm_source: 'internal-website',
  utm_medium: 'iwocapay',
  utm_campaign: 'sellers_ant_card',
} as const;

export const ANTCard = () => {
  const { approvedNotTakenOffers } = useApprovedNotTakenOffers();

  useOnce({
    conditionFn: approvedNotTakenOffers?.length === 1,
    fn: () => tracking.ampli.displayedAntBanner(),
  });

  if (!approvedNotTakenOffers) return null;

  if (approvedNotTakenOffers.length === 1) {
    const [offer] = approvedNotTakenOffers;
    const dateString = getFormattedDate(offer.timeout, 'long');

    return (
      <Card className={styles.cardContainer}>
        <div className={styles.innerContainer}>
          <img src={iwocaLogo} className={styles.logo} alt="" />
          <div>
            <div className={styles.validUntil}>
              Offer valid until {dateString}
            </div>
            <div className={styles.title}>
              You're <b>approved</b> for a{' '}
              <b>{displayCurrency(offer.available_cash, 0)}</b> loan from iwoca.
            </div>
            <div>Hit the button to access these funds today.</div>
            <a
              href={`/account/${buildQueryString(UTM_PARAMS)}`}
              onClick={() => tracking.ampli.clickedAntBanner()}
              className={styles.button}
            >
              Access funds
            </a>
          </div>
        </div>
      </Card>
    );
  }

  if (approvedNotTakenOffers.length > 1) {
    const timeout = getSoonestTimeout(approvedNotTakenOffers);
    const dateString = getFormattedDate(timeout, 'long');
    return (
      <Card className={styles.cardContainer}>
        <div className={styles.innerContainer}>
          <img src={iwocaLogo} className={styles.logo} alt="" />
          <div>
            <div className={styles.validUntil}>
              First offer valid until {dateString}
            </div>
            <div className={styles.title}>
              You've been <b>approved</b> for more than one loan with iwoca.
            </div>
            <div>
              Hit the button to see your offers and choose the one that works
              best for you.
            </div>
            <a
              href={`/account/${buildQueryString()}`}
              className={styles.button}
            >
              See your offers
            </a>
          </div>
        </div>
      </Card>
    );
  }
  return null;
};

const getSoonestTimeout = (offers: SanitisedFormalOffers) => {
  const offerWithSoonestTimeout = offers?.reduce((prev, curr) =>
    prev.timeout < curr.timeout ? prev : curr,
  );
  return offerWithSoonestTimeout.timeout;
};
