import { useCallback, useEffect, useState } from 'react';

import styles from './XeroRedirect.module.css';
import { buildQueryString } from '../../../../Buyer/utils/queryParams';
import { Button } from '../../../../components/Button/Button';
import { IwocaXeroErrorGraphic } from '../../../../components/svg/Graphics/IwocaXeroErrorGraphic';
import { postLendingApiJson } from '../../../../Pages/lendingApiFetch';
import { LoadingGraphic } from '../../LoadingGraphic/LoadingGraphic';
import sellerXeroStyles from '../../Xero.module.css';

type XeroLoginApiResponse = {
  data: {
    authorization_url: string;
  };
};

export const XeroRedirect = ({ stateKey }: { stateKey: string }) => {
  const [hasError, setHasError] = useState(false);

  const redirectToXeroLogin = useCallback(async () => {
    setHasError(false);

    const queryString = buildQueryString();
    const response = await postLendingApiJson({
      url: `/api/lending/edge/xero/login/iwocapay_seller/${stateKey}/`,
      body: {
        data: {
          redirect_to: `/pay/xero/authorisation/${queryString}`,
        },
      },
    });

    try {
      const payload = (await response.json()) as XeroLoginApiResponse;
      window.location.href = payload.data.authorization_url;
    } catch (error) {
      setHasError(true);
    } finally {
      setHasError(!response.ok);
    }
  }, [stateKey]);

  useEffect(() => {
    void redirectToXeroLogin();
  }, [redirectToXeroLogin]);

  return (
    <div className={sellerXeroStyles.contentContainer}>
      <div className={sellerXeroStyles.contentSm}>
        <div className={styles.contentInner}>
          {hasError ? (
            <>
              <div className={styles.textContent}>
                <h1>We weren’t able to redirect you, please try again</h1>
              </div>
              <div className={styles.errorGraphicContainer}>
                <IwocaXeroErrorGraphic />
              </div>
              <Button
                className={sellerXeroStyles.button}
                onClick={redirectToXeroLogin}
              >
                Try Again!
              </Button>
            </>
          ) : (
            <>
              <div className={styles.textContent}>
                <h1>You’re being redirected to Xero!</h1>
              </div>
              <div className={styles.loadingGraphicContainer}>
                <LoadingGraphic />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
