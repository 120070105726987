import { SVGAttributes } from 'react';

export function QuestionMarkCircleIcon(props: SVGAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      {...props}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="8" cy="8.24242" rx="8" ry="8.24242" fill="#143B6B" />
      <path
        d="M7.06725 9.4445C7.06725 9.59277 7.18744 9.71297 7.33572 9.71297H8.90372C8.92713 9.71297 8.94611 9.69399 8.94611 9.67058C8.94611 9.28907 9.41239 9.00647 10.1118 8.48367C11.0032 7.79131 11.3461 7.07068 11.3461 6.01094C11.3461 4.51318 10.0844 3.14258 8.30153 3.14258C7.06824 3.14258 6.0456 3.70185 5.38929 4.42262C5.06628 4.77734 5.19934 5.31291 5.59421 5.58538C6.03213 5.88756 6.62481 5.7411 7.06153 5.43718C7.43979 5.17394 7.86261 5.05011 8.26039 5.05011C8.90496 5.05011 9.46725 5.45988 9.46725 6.12398C9.46725 6.83047 8.86382 7.09894 8.24668 7.5511C7.53353 8.05977 7.06725 8.59671 7.06725 9.4445ZM7.97925 13.3443C8.61169 13.3443 9.12439 12.8316 9.12439 12.1992V12.1298C9.12439 11.4974 8.61169 10.9847 7.97925 10.9847C7.3468 10.9847 6.83411 11.4974 6.83411 12.1298V12.1992C6.83411 12.8316 7.3468 13.3443 7.97925 13.3443Z"
        fill="white"
      />
    </svg>
  );
}
