import { getUrlFromPath } from './getUrlFromPath';

export const getPayLinkUrl = (sellerName: string, payLinkId: string) => {
  return getUrlFromPath(getPayLinkUrlPath(sellerName, payLinkId))!;
};

export const getPayLinkUrlPath = (sellerName: string, payLinkId: string) => {
  const cleanedSellerName =
    sellerName
      .toLowerCase()
      .replace(/[^a-z0-9 ]+/gi, ' ')
      .trim()
      .replace(/\s+/g, '-') || 'me';

  return `/pay/to/${cleanedSellerName}/${payLinkId}`;
};
