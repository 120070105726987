import { useParams } from 'react-router-dom';

import { WooCommerceStep1, WooCommerceStep2 } from './WooCommerceSteps';
import { useGetIwocapayIntegrationInterest } from '../../../../api/lending/lapiHooks';
import externalLinkSVG from '../../../../assets/externalLink.svg';
import tickSVG from '../../assets/tick.svg';
import { useGetIntegrationStatus } from '../../hooks/useGetIntegrationStatus.hook';
import { WooCommerce } from '../../Integrations';
import bodyStyles from '../IntegrationBody.module.css';
import { IntegrationsTitle } from '../IntegrationsTitle/IntegrationsTitle';
import { RegisterInterestButton } from '../RegisterInterestButton/RegisterInterestButton';

export function WooCommerceBody() {
  const { integrationParam } = useParams();

  const { integrationStatus } = useGetIntegrationStatus({
    integrationId: WooCommerce.id,
  });
  const isConnected = integrationStatus === 'CONNECTED';

  const { iwocapayIntegrationInterest, loadingIwocapayIntegrationInterest } =
    useGetIwocapayIntegrationInterest();
  const interestRegistered =
    WooCommerce.id && iwocapayIntegrationInterest?.includes(WooCommerce.id);

  const userHasInteracted = isConnected || interestRegistered;

  if (integrationParam !== WooCommerce.id) {
    return null;
  }
  if (loadingIwocapayIntegrationInterest) {
    return null;
  }
  return (
    <div className={bodyStyles.container}>
      <div className={bodyStyles.header}>
        <IntegrationsTitle integration={WooCommerce} />
        <div className={bodyStyles.connectButtonWrapper}>
          {userHasInteracted && (
            <div className={bodyStyles.requestSent}>
              <img
                src={tickSVG}
                alt="Tick Icon"
                className={bodyStyles.tickSVG}
              />
              {!isConnected && 'Request sent'}
              {isConnected && 'Connected'}
            </div>
          )}
          <RegisterInterestButton integrationName={WooCommerce.id} />
        </div>
      </div>
      <div className={bodyStyles.body}>
        <div>
          {!userHasInteracted && (
            <p>
              A fully customizable, open-source ecommerce platform built on
              WordPress. Build exactly the ecommerce site you want for yourself,
              or others.
              <br />
              <br />
              <b>
                When you connect iwocaPay to WooCommerce it'll automatically:
              </b>
              <ul>
                <li>
                  add iwocaPay as a payment method on your WooCommerce checkout
                </li>
                <li>
                  reconcile all iwocaPay payments with your WooCommerce store
                  data.
                </li>
              </ul>
            </p>
          )}
          {userHasInteracted && (
            <div>
              <p>
                <b>We’ve got your request to access the WooCommerce plugin.</b>
                <br />
                Not long now till you’ve added iwocaPay to your store checkout.
                <br />
                <br />
                Here’s what happens next:
              </p>
              <div className={bodyStyles.stepContainer}>
                <WooCommerceStep1 />
                <WooCommerceStep2 />
              </div>
            </div>
          )}

          <div>
            <a
              href={
                'https://support.iwoca.co.uk/en/articles/6759707-integrating-iwocapay-with-woocommerce'
              }
              target="_blank"
              rel="noreferrer"
              className={bodyStyles.faqLink}
            >
              WooCommerce FAQs
              <img
                src={externalLinkSVG}
                alt="External Link Icon"
                className={bodyStyles.externalLinkIcon}
              />
            </a>
          </div>
        </div>
      </div>
      <div className={bodyStyles.footer}>
        <RegisterInterestButton integrationName={WooCommerce.id} />
      </div>
    </div>
  );
}
