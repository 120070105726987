import { Dialog } from '@headlessui/react';
import { Button, Radio } from '@iwoca/orion';
import cn from 'classnames';
import { useSearchParams } from 'react-router-dom';

import styles from './FilterDrawer.module.css';
import { PayLinkStatus } from '../../../hooks/usePaylinks.hook';

const FILTER_OPTIONS = [
  { status: 'NOT_SIGNED_UP', label: 'Not started' },
  { status: 'SIGNED_UP', label: 'Started' },
  { status: 'DECLINED', label: 'Declined' },
  { status: 'APPROVED', label: 'Eligible' },
  { status: 'SCHEDULED_FOR_CAPTURE', label: 'Pending' },
  { status: 'PAID', label: 'Paid' },
] as const satisfies ReadonlyArray<{ status: PayLinkStatus; label: string }>;

export const FilterDrawer = ({
  isOpen,
  setIsOpen,
  selectedStatusFilter,
  setSelectedStatusFilter,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedStatusFilter: string;
  setSelectedStatusFilter: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const onStatusClick = (status: PayLinkStatus) => {
    if (selectedStatusFilter === status) {
      setSelectedStatusFilter('');
    } else {
      setSelectedStatusFilter(status);
    }
  };

  return (
    <Dialog static open={isOpen} onClose={() => setIsOpen(false)}>
      <Dialog.Panel
        className={cn(styles.outerContainer, {
          [styles.outerContainerClosed]: !isOpen,
        })}
      >
        <div className={styles.header}>
          <Dialog.Title className={styles.headerTitle}>Filters</Dialog.Title>
          <button
            className={styles.cancelButton}
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </button>
        </div>
        <div className={styles.filterList}>
          <div className={styles.filterListTitle}>Status</div>
          {FILTER_OPTIONS.map((option) => (
            <div
              key={`filter__${option.label}`}
              className={styles.filterListOption}
            >
              <Radio
                label={option.label}
                checked={selectedStatusFilter === option.status}
                onChange={() => onStatusClick(option.status)}
                id={`checkbox__${option.label}`}
              />
            </div>
          ))}
        </div>
        <div className={styles.footer}>
          <Button
            className={styles.applyFilterButton}
            onClick={() => {
              setIsOpen(false);
              if (selectedStatusFilter !== '') {
                searchParams.set('status', selectedStatusFilter);
                setSearchParams(searchParams);
              }
            }}
          >
            Apply filters
          </Button>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};
