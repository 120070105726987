import { useGetSellerConnection } from '../../../api/lending/lapiHooks';
import { IntegrationName } from '../utils/integrations.types';

export const useGetIntegrationStatus = ({
  integrationId,
}: {
  integrationId: IntegrationName | null;
}) => {
  const { iwocapaySellerConnection, loadingIwocapaySellerConnection } =
    useGetSellerConnection();

  if (integrationId === null) {
    return {
      integrationStatus: undefined,
      loadingIwocapaySellerConnection: false,
    };
  }

  const integrationStatus = iwocapaySellerConnection?.[integrationId];

  return {
    integrationStatus: integrationStatus,
    loadingIwocapaySellerConnection: loadingIwocapaySellerConnection,
  };
};
