import { useEffect, useRef } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { STEPS } from './Steps';
import { useGetStateQuery } from './StepValidator';
import { useLatestStep } from './useLatestStep';
import { usePathname } from '../../../hooks/usePathname';
import { buildQueryString } from '../../utils/queryParams';
import { SIGNUP_BASE_PATH } from '../routes';
import { normaliseStepData } from '../stateNormalisation';

export function useRedirectToLatestStep() {
  const { data } = useGetStateQuery(normaliseStepData);

  const hadRedirectedRef = useRef(false);
  const navigate = useNavigate();

  const isExcludedFromRedirect = useIsExcludedFromRedirect();
  const isValidStep = useIsValidStep();
  const latestStep = useLatestStep();

  const shouldRedirect = !isValidStep;

  useEffect(() => {
    if (isExcludedFromRedirect) return;
    if (!shouldRedirect) return;
    if (!data) return;
    if (hadRedirectedRef.current) return;
    hadRedirectedRef.current = true;

    const queryString = buildQueryString();
    navigate(`${SIGNUP_BASE_PATH}details/${latestStep}/${queryString}`);
  }, [shouldRedirect, data, navigate, latestStep, isExcludedFromRedirect]);
}

function getStepIndex(substring: string): number {
  return STEPS.findIndex(({ identifier }) => {
    return substring.includes(identifier);
  });
}

export function useIsErrorStep() {
  const location = useLocation();
  return location.pathname === '/pay/signup/error';
}

const EXCLUDED_STEPS = [
  '/pay/signup/error/',
  '/pay/signup/speak-to-director/',
  '/pay/signup/choose-product/',
];

function useIsExcludedFromRedirect() {
  const pathname = usePathname();
  const isExcluded = EXCLUDED_STEPS.includes(pathname);

  return isExcluded;
}

function useIsValidStep() {
  const location = useLocation();
  const latestStep = useLatestStep();
  if (latestStep === null) return true;

  const doesStepExist = checkStepExists(location.pathname);
  if (!doesStepExist) return false;

  const latestStepIndex = getStepIndex(latestStep);
  const currentStepIndex = getStepIndex(location.pathname);
  const isAllowedToAccessStep = latestStepIndex >= currentStepIndex;

  return isAllowedToAccessStep;
}

function checkStepExists(pathname: string) {
  const currentStep = getStepIndex(pathname);

  return currentStep !== -1;
}
