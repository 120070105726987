import { fetchPutState, GetStateResponse } from '@iwoca/lapi-client/edge';
import { useMutation } from '@tanstack/react-query';

import { StepType } from './Steps';
import { useGetStateQuery } from './StepValidator';
import { useGetStateByStateKey } from '../../../api/lending/lapiHooks';
import { useStateKey } from '../../../hooks/useStateKey.hook';
import { denormaliseStepData, normaliseStepData } from '../stateNormalisation';

export function useStepData<S extends StepType>(stepId: S) {
  const { stateKey } = useStateKey();
  const { state: customerState } = useGetStateByStateKey();
  const { data: stepData, refetch: refetchStepData } = useGetStateQuery(
    (data) => normaliseStepData(data)[stepId],
  );

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (state: GetStateResponse) => {
      if (!stateKey) {
        throw new Error('No statekey provided');
      }

      return fetchPutState({ stateKey, body: state });
    },
    onSuccess: async () => {
      await refetchStepData();
    },
  });

  const denormaliser = denormaliseStepData(stepId);

  const updateStepData = (data: ReturnType<typeof normaliseStepData>[S]) => {
    // @ts-expect-error
    const newState = denormaliser({ data: customerState! }, data)!;
    return mutateAsync(newState);
  };

  return {
    stepData,
    updateStepData,
    refetchStepData,
    isUpdatingStepData: isPending,
  };
}
