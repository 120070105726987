import * as Yup from 'yup';

export const addressValidator = Yup.object().shape({
  manual: Yup.boolean(),
  buildingNumber: Yup.string().when('manual', {
    is: true,
    then: (schema) => schema.required('Building number is required'),
    otherwise: (schema) => schema,
  }),
  streetName: Yup.string().when('manual', {
    is: true,
    then: (schema) => schema.required('Street name is required'),
    otherwise: (schema) => schema,
  }),
  town: Yup.string().when('manual', {
    is: true,
    then: (schema) => schema.required('Town is required'),
    otherwise: (schema) => schema,
  }),
  postcode: Yup.string()
    .required('Post code is required')
    .test(
      'is-postcode',
      'Not a valid postcode',
      (postcode) => !!postcode && postcode.length >= 5 && postcode.length <= 8,
    ),
});
