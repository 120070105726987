import React from 'react';

import { Button } from '@iwoca/orion';
import RiveComponent, { Alignment, Fit, Layout } from '@rive-app/react-canvas';

import styles from './SpendingLimitApproved.module.css';
import { useGetBuyerSpendingLimitRequest } from '../../../../api/lending/lapiHooks';
// @ts-ignore
import AccountSuccessTickRive from '../../../../assets/AccountSuccessTickRive.riv';
import DirectorCheckIcon from '../../../../assets/DirectorCheckIcon.svg';
import ExternalLinkIcon from '../../../../assets/ExternalLinkIcon.svg';
import IDCheckIcon2 from '../../../../assets/IDCheckIcon2.svg';
import LinkCardPaymentsIcon from '../../../../assets/LinkCardPaymentsIcon.svg';
import { LoadingScreen } from '../../../../components/LoadingScreen/LoadingScreen';
import { displayCurrency } from '../../../../utils/DisplayCurrency';
import { roundTo } from '../../../../utils/roundTo';
import { useFormalOfferWithDuration } from '../../../Checkout/hooks/useFormalOfferWithDuration';
import {
  redirectToMyAccount,
  redirectToSecurityChecks,
} from '../../useRedirectToAccountPages';

export const SpendingLimitApproved = () => {
  const { formalOffer, loadingFormalOffer } = useFormalOfferWithDuration(3);
  const { spendingLimitRequest, loadingSpendingLimitRequest } =
    useGetBuyerSpendingLimitRequest();

  if (loadingFormalOffer || loadingSpendingLimitRequest) {
    return <LoadingScreen />;
  }
  if (!formalOffer) {
    redirectToMyAccount();
  }

  const latestFormalOfferAvailableCredit = formalOffer?.available_credit || 0;
  const latestFormalOfferInterestRate = formalOffer?.interest_rates?.[0].rate
    ? roundTo(formalOffer?.interest_rates?.[0].rate * 100, 2).toString()
    : null;

  const sellerProductPricingType3m =
    spendingLimitRequest?.data?.seller_product_pricing_type_3m;

  const interestRateText =
    sellerProductPricingType3m &&
    sellerProductPricingType3m.startsWith('SELLER_PAYS')
      ? 'interest-free'
      : 'at an interest rate of ' +
        latestFormalOfferInterestRate +
        '% per month';

  return (
    <div className={styles.wrap}>
      <div className={styles.container}>
        <div className={styles.body}>
          <RiveComponent
            src={AccountSuccessTickRive}
            className={styles.successAnimation}
            layout={
              new Layout({
                fit: Fit.Contain,
                alignment: Alignment.TopCenter,
              })
            }
          />
          <div className={styles.greatNews}>Great news!</div>
          <div className={styles.approvedText}>You can spend up to</div>
          <div className={styles.approvedAmount}>
            {displayCurrency(latestFormalOfferAvailableCredit)}
          </div>
          <div className={styles.interestText}>{interestRateText}</div>

          <div className={styles.linkWrap}>
            <a
              href={
                'https://support.iwoca.co.uk/en/articles/3696498-paying-with-pay-later'
              }
              className={styles.link}
            >
              How interest works with iwocaPay
              <div style={{ display: 'flex' }}>
                <img
                  src={ExternalLinkIcon}
                  alt=""
                  className={styles.externalLinkIcon}
                />
              </div>
            </a>
          </div>
          <div className={styles.disclaimer}>
            We'll reassess your spending limit every 30 days - so your available
            amount might change after that.
          </div>
          <hr className={styles.horizontalLine} />
          <div className={styles.introText}>
            A few quick checks and you can start using your spending limit:
          </div>
          <ul className={styles.checkList}>
            <li className={styles.checkItem}>
              <img src={IDCheckIcon2} alt="ID Check Icon" />
              <div className={styles.checkText}>ID check</div>
            </li>
            <li className={styles.checkItem}>
              <img src={DirectorCheckIcon} alt="Director Check Icon" />
              <div className={styles.checkText}>
                Director and Shareholder Information
              </div>
            </li>
            <li className={styles.checkItem}>
              <img src={LinkCardPaymentsIcon} alt="Link Card Payments Icon" />
              <div className={styles.checkText}>
                Link your Debit card for monthly payments
              </div>
            </li>
          </ul>
          <div className={styles.buttons}>
            <Button
              className={styles.continueButton}
              onClick={() => redirectToSecurityChecks(formalOffer?.offer_id!)}
            >
              Take me to the next step
            </Button>
            <Button
              variant="secondary"
              className={styles.laterButton}
              onClick={redirectToMyAccount}
            >
              I'll do this later
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
