import styles from './IntegrationTickAlert.module.css';
import tickSVG from '../../assets/tick.svg';

export const IntegrationTickAlert = ({ text }: { text: string }) => {
  return (
    <div className={styles.container}>
      <img src={tickSVG} alt="Tick Icon" className={styles.tickSVG} />
      {text}
    </div>
  );
};
