import { useState } from 'react';

import { AddPaymentMethodForm } from './AddPaymentMethodForm/AddPaymentMethodForm';
import { useGetFundingRequirement } from '../../../../api/lending/lapiHooks';
import { Button } from '../../../components/Button/Button';
import { Card } from '../../../components/Card/Card';
import { getRequirementByResolutionMethod } from '../../helpers/requirements';
import { useNavigateToNextRequirement } from '../../hooks/useNavigateToNextRequirement';

export const AddPaymentMethod = () => {
  const { fundingRequirement } = useGetFundingRequirement();
  const addPaymentMethodFundingRequirement = getRequirementByResolutionMethod(
    fundingRequirement!,
    'add_default_payment_method',
  )!;
  const canSkip = addPaymentMethodFundingRequirement.is_optional === true;

  const { goToNextRequirement } = useNavigateToNextRequirement();
  const [showAddPaymentMethodForm, setShowAddPaymentMethodForm] =
    useState(false);

  return (
    <Card title="Set up automatic payments today">
      <p>Add a debit card now to make your monthly payments automatically.</p>

      <div>
        {showAddPaymentMethodForm ? (
          <AddPaymentMethodForm />
        ) : (
          <Button onClick={() => setShowAddPaymentMethodForm(true)}>
            Add debit card now
          </Button>
        )}
      </div>

      {canSkip && (
        <div>
          <Button variant="tertiary" onClick={() => goToNextRequirement()}>
            Do this later &gt;
          </Button>
        </div>
      )}
    </Card>
  );
};
