import { useGetFormalOffers } from '../../../api/lending/lapiHooks';
import { SanitisedFormalOffers } from '../utils/Dashboard.types';

export const useApprovedNotTakenOffers = (): {
  approvedNotTakenOffers: SanitisedFormalOffers;
  loadingApprovedNotTaken: boolean;
} => {
  const { formalOffers, loadingFormalOffers } = useGetFormalOffers();

  const sanitisedOffers = formalOffers?.formal_offers.filter((offer) => {
    if (!offer.max_amount) return false;
    if (!offer.available_cash) return false;
    if (!offer.timeout) return false;
    return true;
  });

  const validOffers = sanitisedOffers?.filter(
    (offer) => offer.is_valid === true,
  );

  const notIwocapayOffers = validOffers?.filter(
    (offer) => offer.product_type !== 'iwocapay',
  );

  const notTakenOffers = notIwocapayOffers?.filter((offer) => {
    return Math.abs(offer.max_amount! - offer.available_cash!) < 1;
  });

  return {
    approvedNotTakenOffers: notTakenOffers as SanitisedFormalOffers,
    loadingApprovedNotTaken: loadingFormalOffers,
  };
};
