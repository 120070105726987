import { tracking } from '@iwoca/frontend-tracking-library';

import { TEnabledOptions } from '../Buyer/hooks/useEnabledTerms';

function getEnvironment() {
  return ['www.iwoca.co.uk', 'www.iwoca.de'].includes(window.location.host)
    ? 'production'
    : 'development';
}

export const initialise = () => {
  tracking.initialise({
    environment: getEnvironment(),
  });
};

export function identify(data: { stateKey: string; isImpersonating: boolean }) {
  tracking.identifyCustomer({ ...data, country: 'UK' });
}

export const OPTIONS_TO_TRACKING = {
  THREE_MONTHS: 'pay_in_3',
  TWELVE_MONTHS: 'pay_in_12',
  PAY_NOW: 'pay_now',
} as const;

export const trackSelectedCheckoutOption = ({
  payLinkId,
  visibleOptions,
  selectedOption,
}: {
  payLinkId: string;
  visibleOptions: TEnabledOptions;
  selectedOption: TEnabledOptions[number];
}) => {
  tracking.ampli.selectedCheckoutOption({
    pay_link_id: payLinkId,
    visible_payment_options: visibleOptions.map(
      (option) => OPTIONS_TO_TRACKING[option],
    ),
    selected_option: OPTIONS_TO_TRACKING[selectedOption],
  });
};

export const trackSelectedOffer = ({
  payLinkId,
  offerIds,
  offerId,
}: {
  payLinkId: string;
  offerIds: string[];
  offerId: string;
}) => {
  tracking.ampli.selectedOffer({
    pay_link_id: payLinkId,
    offer_ids: offerIds,
    offer_id: offerId,
  });
};
