import {
  fetchGetStateByStateKey,
  fetchPutState,
  GetStateResponse,
} from '@iwoca/lapi-client/edge';
import { cloneDeep, set } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export async function markSignupAsCompleted({
  stateKey,
}: {
  stateKey: string;
}) {
  const data = await fetchGetStateByStateKey({ stateKey: stateKey! });

  if (!data) {
    throw new Error('Could not get state data');
  }

  const newState = addSignupCompletedToUiState({ state: data! });

  await fetchPutState({ stateKey, body: newState });
}

function addSignupCompletedToUiState({ state }: { state: GetStateResponse }) {
  const newState = cloneDeep(state);
  set(newState, 'data.ui.activeStep.id', 'myAccount');
  return newState;
}

export function createState(
  emailAddress: string,
  marketingOptIn: boolean,
  paylinkId?: string,
) {
  return {
    application: {
      people: [
        {
          last_name: '',
          uid: uuidv4(),
          roles: ['applicant'],
          first_name: '',
          emails: [
            {
              email: emailAddress,
              marketing_opt_in: {
                agreed: marketingOptIn,
              },
              uid: uuidv4(),
              type: 'primary',
            },
          ],
        },
      ],
    },
    ui: paylinkId
      ? {
          iwocapay_paylink_id: paylinkId,
        }
      : {},
  };
}
