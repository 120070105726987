import { useState } from 'react';

import { Pagination } from '@iwoca/orion';

import { SpendingLimitTable } from './components/SpendingLimitTable/SpendingLimitTable';
import { useGetSellerSpendingLimitRequest } from './hooks/useGetSellerSpendingLimitRequest.hook';
import styles from './SpendingLimitRequests.module.css';
import { DynamicPageTemplate } from '../../components/PageTemplate/PageTemplate';

export const SpendingLimitRequests = () => {
  const [page, setPage] = useState(1);

  const { spendingLimitRequests, isLoading, metadata } =
    useGetSellerSpendingLimitRequest({ page });

  if (isLoading) return null;

  return (
    <DynamicPageTemplate>
      <div className={styles.page}>
        <div className={styles.content}>
          <div className={styles.pageHeader}>Spending Limits</div>
          <div className={styles.paymentsActions}></div>
          <SpendingLimitTable spendingLimitRequests={spendingLimitRequests} />
        </div>

        {metadata?.count! > 0 && (
          <div className={styles.pagination}>
            <Pagination
              count={metadata?.total_pages || 0}
              page={page}
              onPageChange={(page) => setPage(page)}
            />
          </div>
        )}
      </div>
    </DynamicPageTemplate>
  );
};
