import { ComponentProps, useRef } from 'react';

import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { getRuntimeEnvironment } from '../../../../../utils/getRuntimeEnvironment';

const STRIPE_PRODUCTION_KEY = 'pk_live_JZdjfhxdg1MvSpaVEZJCizGp';
const STRIPE_STAGING_KEY = 'pk_test_bO0dWx9Zspp5jH4EVmJfvzzJ';

export const StripeProvider = (
  props: ComponentProps<typeof ElementsConsumer>,
) => {
  const stripePromiseRef = useRef(
    loadStripe(
      getRuntimeEnvironment() === 'production'
        ? STRIPE_PRODUCTION_KEY
        : STRIPE_STAGING_KEY,
    ),
  );

  return (
    <Elements stripe={stripePromiseRef.current}>
      <ElementsConsumer {...props} />
    </Elements>
  );
};
