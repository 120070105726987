import classnames from 'classnames';

import styles from './SpendingLimitStatus.module.css';
import { ExclamationMarkCircledIcon } from '../../../../components/svg/ExclamationMarkCircledIcon';
import { ThreeDotsCircledIcon } from '../../../../components/svg/ThreeDotsCircledIcon';
import { TickIcon } from '../../../../components/svg/TickIcon';

export type SpendingLimitStatusText =
  | 'SIGNED_UP'
  | 'APPROVED'
  | 'DECLINED'
  | undefined;

const StatusIcon = ({
  status,
  className,
}: {
  status: SpendingLimitStatusText;
  className?: string;
}) => {
  const classes = classnames(styles.statusIcon, className);

  switch (status) {
    case 'SIGNED_UP':
      return (
        <ThreeDotsCircledIcon
          title="Signed up three dots icon"
          className={classnames(classes, styles.darkThreeDots)}
        />
      );
    case 'APPROVED':
      return (
        <TickIcon
          title="Approved application tick"
          className={classes}
          fill="#ffbf00"
        />
      );
    case 'DECLINED':
      return <ExclamationMarkCircledIcon className={classes} />;
    default:
      return <>Unknown</>;
  }
};

export const getStatusDescriptionText = (status: SpendingLimitStatusText) => {
  switch (status) {
    case 'SIGNED_UP':
      return 'Signed up';
    case 'APPROVED':
      return 'Approved';
    case 'DECLINED':
      return 'Declined';
    default:
      return 'Unknown';
  }
};
export const SpendingLimitStatus = ({
  slrStatus,
}: {
  slrStatus: SpendingLimitStatusText;
}) => {
  return (
    <div className={styles.status}>
      <StatusIcon status={slrStatus!} className={styles.statusIcon} />
      <div className={classnames(styles.statusText, styles.statusGrid)}>
        <div>{getStatusDescriptionText(slrStatus!)}</div>
      </div>
    </div>
  );
};
