import styles from './IDCheck.module.css';
import { iwocapayTelDisplay } from '../../../../../src/constants.json';
import { Button } from '../../../components/Button/Button';
import { Card } from '../../../components/Card/Card';
import { navigateToAccountPages } from '../../../utils/accountPages';

export const TooManyAttempts = () => {
  return (
    <Card title="Whoops, too many attempts">
      <p className={styles.cardText}>
        Sorry about that, it seems something isn't quite right. We'll be in
        touch as soon as possible.
      </p>
      <p className={styles.cardText}>
        Give us a call on {iwocapayTelDisplay} if you'd like an update.
      </p>
      <Button onClick={navigateToAccountPages} className={styles.submitButton}>
        Go to your iwocaPay dashboard
      </Button>
    </Card>
  );
};
