import { ReactElement, ReactNode, useEffect, useState } from 'react';

import { Navigate, RouteObject, useLocation } from 'react-router-dom';

import { SELLER_ROUTES } from './seller.routes';
import { useUserGroups } from '../hooks/useUserGroups';
import { PermissionDenied } from '../Pages/FailurePages/PermissionDenied/PermissionDenied';

export type NewRoutesType = {
  readonly path: string;
  readonly element?: ReactElement;
  readonly lazy?: () => Promise<{ element: ReactElement }>;
  readonly children?: ReadonlyArray<NewRoutesType>;
  readonly accessibleByGroups?: ReadonlyArray<string>;
};

export type NewRoutesWrapperType = {
  readonly wrapper: React.ReactNode;
  readonly paths: ReadonlyArray<NewRoutesType>;
};

export type NewRoutesWrapperArrayType = ReadonlyArray<NewRoutesWrapperType>;

type TWildcard = `:${string}` | '*';
type ReplaceWildcard<S extends string> =
  S extends `${infer Head}${TWildcard}/${infer Tail}`
    ? `${Head}${string}/${ReplaceWildcard<Tail>}`
    : S;

export type ValidRoutePaths =
  (typeof SELLER_ROUTES)[number]['paths'][number]['path'] extends infer R extends
    string
    ? `/pay/${ReplaceWildcard<R>}`
    : never;

export const GroupPermissionEnforcement = ({
  children,
  accessibleByGroups,
}: {
  children: ReactNode;
  accessibleByGroups?: ReadonlyArray<string>;
}) => {
  const { groups, isLoading, isStaff } = useUserGroups();
  if (isLoading) return;

  if (
    accessibleByGroups &&
    groups &&
    !accessibleByGroups.some((group) => groups.includes(group)) &&
    !isStaff
  )
    return <PermissionDenied />;

  return <>{children}</>;
};

export const normaliseContainerRoutes = (
  unNormalisedWrapperRouteArray: NewRoutesWrapperArrayType,
): RouteObject[] => {
  return unNormalisedWrapperRouteArray
    .map((route) => {
      const wrapper: NewRoutesWrapperType['wrapper'] = route.wrapper;
      const routes: NewRoutesWrapperType['paths'] = route.paths;
      return routes.map((element) => ({
        path: element.path,
        element: !element.accessibleByGroups ? (
          wrapper
        ) : (
          <GroupPermissionEnforcement
            accessibleByGroups={element.accessibleByGroups}
          >
            {wrapper}
          </GroupPermissionEnforcement>
        ),
        children: [
          {
            path: '',
            element: element.element,
            lazy: element.lazy,
          },
        ],
      })) as RouteObject;
    })
    .flat(1);
};

export const SafeNavigate = ({ to }: { to: string }) => {
  const location = useLocation();
  const [url, setURL] = useState<string | null>(null);

  useEffect(() => {
    const updatedURL = location.search
      ? `${to.replace(/\/$/, '')}${location.search}`
      : to;

    if (updatedURL !== url) {
      setURL(updatedURL);
    }
  }, [location, to, url]);

  if (!url === null) return;
  return <Navigate to={url!} />;
};
