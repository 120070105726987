import { DynamicPageTemplate } from '../../../components/PageTemplate/PageTemplate';
import { GenericError } from '../GenericError';

export const XeroPayLinkError = () => {
  return (
    <>
      <DynamicPageTemplate>
        <GenericError errorText="it seems like something went wrong" />
      </DynamicPageTemplate>
    </>
  );
};
