import { Button } from '@iwoca/orion';
import { useFormikContext } from 'formik';

import styles from './ApplicantInformation.module.css';
import { RadioButton } from '../../../../Buyer/Signup/components/RadioButton/RadioButton';
import { CrossSellFormValues } from '../../utils/CrossSellCard.types';

export const ApplicantInformation = () => {
  const { isValid } = useFormikContext<CrossSellFormValues>();

  return (
    <div>
      <RadioButton
        labelText="Are you a director of the business?"
        name="isDirector"
        options={[
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ]}
        compact={true}
        className={styles.radio}
      />
      <RadioButton
        labelText="Are you a shareholder of the business?"
        name="isShareholder"
        options={[
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ]}
        compact={true}
        className={styles.radio}
      />
      <RadioButton
        labelText="Are you VAT registered?"
        name="isVatRegistered"
        options={[
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ]}
        compact={true}
        className={styles.radio}
      />
      <Button
        disabled={!isValid}
        className={styles.button}
        type="submit"
        variant="secondary"
      >
        Confirm selections
      </Button>
    </div>
  );
};
