import styles from './PaymentError.module.css';
import { Button } from '../../../components/Button/Button';
import UnhappyCritter from '../../../components/svg/Critters/UnhappyCritter.svg?react';
import { StatusTemplate } from '../StatusTemplate/StatusTemplate';

export function PaymentError() {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <StatusTemplate>
      <h2>Oops, something went wrong.</h2>
      <UnhappyCritter />
      <Button onClick={refreshPage} className={styles.button}>
        Try again?
      </Button>
    </StatusTemplate>
  );
}
