import { useEffect } from 'react';

import ReactModal from 'react-modal';

import styles from './Auth3DSecureModal.module.css';

export const Auth3DSecureModal = ({
  auth3DSRedirectUrl,
  onAuth3DSecureComplete,
}: {
  auth3DSRedirectUrl: string;
  onAuth3DSecureComplete: () => void;
}) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handle3DSCompleteEvent = (event: any) => {
      if (event.data === '3DS-authentication-complete') {
        onAuth3DSecureComplete();
      }
    };

    window.addEventListener('message', handle3DSCompleteEvent);
    return () => window.removeEventListener('message', handle3DSCompleteEvent);
  }, [onAuth3DSecureComplete]);

  return (
    <ReactModal
      className={styles.modal}
      overlayClassName={styles.overlay}
      isOpen={true}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
    >
      <iframe
        title="3D Secure"
        src={auth3DSRedirectUrl}
        width={600}
        height={500}
        className={styles.iframe}
      />
    </ReactModal>
  );
};
