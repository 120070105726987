import { useGetContext, useGetProfile } from '../api/lending/lapiHooks';
export const RequireAuthenticatedSeller = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    context: { isLoading: loadingContext, isLoggedIn },
  } = useGetContext();
  const {
    profile: { user_type },
    loadingProfile,
  } = useGetProfile();

  if (loadingProfile || loadingContext) {
    return null;
  }

  /*
  the following conditions prevent redirect loops if someone is in a strange state. Specifically:
    - if a staff member is logged in, but not impersonating, redirect them to login, as they are then sent to
      backyard to impersonate someone.
    - if an access_user is logged in, but not with the state_key present in the url, redirect to my_account
      which will redirect them with the correct state key.
   */
  const params = new URLSearchParams(window.location.search);
  const stateKey = params.get('state_key');

  if (!isLoggedIn && user_type === 'staff') {
    window.location.pathname = '/login/';
    return null;
  }

  if (!stateKey && user_type === 'seller_access_user') {
    window.location.pathname = '/my_account/';
    return null;
  }

  if (!isLoggedIn) {
    window.location.href = `/login/?redirect_to=${window.location.pathname}`;
    return null;
  }

  return <>{children}</>;
};
