import { v4 as uuidv4 } from 'uuid';

import {
  normaliseDate,
  denormaliseDate,
} from '../../../Signup/stateNormalisation';

export type Address = {
  buildingNumber: string;
  buildingName: string;
  streetName: string;
  town: string;
  postcode: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const normalizePerson = (person: any) => {
  return {
    uid: person?.uid,
    title: person?.title,
    firstName: person?.first_name,
    lastName: person?.last_name,
    dateOfBirth: person?.date_of_birth
      ? normaliseDate(person?.date_of_birth)
      : undefined,
    phoneNumber: person?.phones?.[0].number,
    roles: person?.roles,
    address: {
      buildingName: person?.residential_addresses?.[0].house_name || '',
      buildingNumber: person?.residential_addresses?.[0].house_number || '',
      streetName: person?.residential_addresses?.[0].street_line_1 || '',
      town: person?.residential_addresses?.[0].town || '',
      postcode: person?.residential_addresses?.[0]?.postcode || '',
      equifaxToken: person?.residential_addresses?.[0]?.equifax_token || '',
    },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const denormalisePerson = (values: any) => {
  return {
    uid: uuidv4(),
    title: values.title,
    first_name: values.firstName,
    last_name: values.lastName,
    phones: [
      {
        uid: uuidv4(),
        type: 'primary',
        number: values.phoneNumber,
      },
    ],
    date_of_birth: denormaliseDate(values.dateOfBirth),
    roles: ['shareholder'],
    residential_addresses: [
      {
        uid: uuidv4(),
        house_number: values.address.buildingNumber,
        street_line_1: values.address.streetName,
        town: values.address.town,
        postcode: values.address.postcode,
        house_name: values.address.buildingName,
        equifax_token: values.address.equifaxToken,
        country: 'GB',
      },
    ],
  };
};

export const normalizeAddress = (address: Address) => {
  const { buildingNumber, buildingName, streetName, town, postcode } = address;

  return [buildingNumber, buildingName, streetName, town, postcode]
    .filter(Boolean)
    .join(', ');
};
