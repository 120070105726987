import { GetSellerPricingResponse } from '@iwoca/lapi-client/iwocapay';
import { AlertBox, Icon } from '@iwoca/orion';

import { ShowFor } from '../../../../components/ConditionalDisplay/ConditionalDisplay';
import { useSellerPricing } from '../../../../hooks/useSellerPricing';

type TNormalisedPricing = {
  productKey: 'THREE_MONTHS' | 'TWELVE_MONTHS';
  tagline: string;
  details: string;
  activated: boolean;
  type: 'Free for you' | 'Free for your customers' | 'Deactivated' | undefined;
};
type TPricingOptions =
  GetSellerPricingResponse['data']['product_pricing_options'];
type TPricingOption = TPricingOptions['THREE_MONTHS'][number];

export const PricingOptionDescriptions = () => {
  const { sellerPricing } = useSellerPricing();

  const normalisePricing = ({
    pricing,
  }: {
    pricing: TPricingOptions | undefined;
  }): TNormalisedPricing[] => {
    if (!pricing) return [];

    const products = ['THREE_MONTHS', 'TWELVE_MONTHS'] as const;
    return products.map((productKey) => {
      const pricingOptions = pricing[productKey] || [];
      const enabledPricing = pricingOptions.find(
        (product: TPricingOption) => product.is_enabled,
      );

      type TaglineDict = {
        [key: string]: string;
      };
      const tagline: TaglineDict = {
        THREE_MONTHS: 'Pay in 3',
        TWELVE_MONTHS: 'Pay in 12',
      } as const;

      const type = {
        SELLER_FREE_BUYER_PAYS: 'Free for you',
        SELLER_PAYS_BUYER_FREE: 'Free for your customers',
      } as const;

      const details = (): TNormalisedPricing['details'] => {
        const termLength = productKey === 'TWELVE_MONTHS' ? '12' : '3';
        if (!enabledPricing)
          return `Your customers can’t spread the cost over ${termLength} months.`;
        if (enabledPricing?.pricing_type === 'SELLER_PAYS_BUYER_FREE')
          return `Your customers can Pay in ${termLength} for free - you pay a fixed ${parseFloat(
            (enabledPricing.seller.fee * 100).toFixed(2),
          )}% fee of the total transaction value at the end of the month.`;
        if (enabledPricing?.pricing_type === 'SELLER_FREE_BUYER_PAYS')
          return `Your customers make ${termLength} monthly payments including interest at a representative rate of ${parseFloat(
            (enabledPricing.buyer.representative_interest * 100).toFixed(2),
          )}% - you don’t pay a penny.`;

        return 'Unknown';
      };

      return {
        productKey: productKey,
        tagline: tagline[productKey] || 'Unknown',
        details: details(),
        type: enabledPricing?.pricing_type
          ? type[enabledPricing.pricing_type]
          : 'Deactivated',
        activated: enabledPricing?.is_enabled || false,
      };
    });
  };

  return (
    <>
      <ShowFor editor contributor>
        <AlertBox
          variant="connect"
          className="grid grid-cols-[max-content,1fr] items-center gap-s text-s font-reg text-primary-10"
        >
          <Icon icon="infoCircleOutline" />
          You don’t have permission to change these settings.
        </AlertBox>
      </ShowFor>
      {normalisePricing({
        pricing: sellerPricing?.product_pricing_options,
      }).map((term, index) => {
        return (
          <div key={index} className={term.activated ? '' : 'opacity-60'}>
            <h3 className="mb-0 mt-0 flex items-center text-m font-bold text-primary-10">
              {`${term.tagline} - ${term.type}`}
              {!term.activated && <Icon icon="banOutline" className="ml-xs" />}
            </h3>
            <p className="mb-0 mt-[0px] text-s font-reg text-primary-10">
              {term.details}
            </p>
          </div>
        );
      })}
    </>
  );
};
