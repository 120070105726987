import React, { useState } from 'react';

import {
  fetchPutIwocapaySellerPaymentCapturedCancel,
  fetchPutIwocapaySellerPaymentCapturedFund,
} from '@iwoca/lapi-client/edge';
import { Button, Icon, Spinner } from '@iwoca/orion';
import classnames from 'classnames';

import styles from './PayLinkStatus.module.css';
import { useStateKey } from '../../../../../../hooks/useStateKey.hook';
import { createToast } from '../../../../../../store/IwToast';
import { ThreeDotOptionDropdown } from '../../../../../components/ThreeDotOption/ThreeDotOptionDropdown';
import { PayLinkColumns } from '../../PayLinkTable';
import tableStyles from '../../PayLinkTable.module.css';
import {
  STATUS_TO_COLOUR,
  STATUS_TO_TOOLTIP,
  TDashboardPaylinkStatus,
  mapPayLinkStatusToFriendly,
} from '../../utils/payLinkStatusMaps';

export type InteractiveStatus =
  | 'cancellation-confirmation'
  | 'cancelling'
  | 'funding-confirmation'
  | 'funding'
  | null;

const INTERACTIVE_STATUS_TO_ACTION_TEXT = {
  'cancellation-confirmation': 'Are you sure you want to cancel?',
  cancelling: '',
  'funding-confirmation': 'Accept and get paid now?',
  funding: 'Sending funds...',
} as const satisfies Record<Exclude<InteractiveStatus, null>, string>;

export const PayLinkStatus = ({
  payLinkColumn,
  onCopyLink,
  onRefreshTable = () => {},
  showDropdown = true,
}: {
  payLinkColumn: PayLinkColumns;
  onCopyLink: () => void;
  onRefreshTable?: () => void;
  showDropdown?: boolean;
}) => {
  const { stateKey } = useStateKey();
  const parsedStatus = mapPayLinkStatusToFriendly(payLinkColumn.status);
  const statusBarColours = STATUS_TO_COLOUR[parsedStatus];
  const [interactiveState, setInteractiveState] =
    useState<InteractiveStatus>(null);

  const cancelPaylink = async () => {
    if (!stateKey) return null;

    setInteractiveState('cancelling');
    try {
      await fetchPutIwocapaySellerPaymentCapturedCancel({
        stateKey: stateKey,
        payLinkId: payLinkColumn.paylink_id,
      });
      onRefreshTable();
    } catch (err) {
      setInteractiveState(null);
      createToast('Unable to cancel, please contact your account manager.', {
        variant: 'failure',
      });
    }
  };

  const fundPayLink = async () => {
    if (!stateKey) return null;

    setInteractiveState('funding');
    try {
      await fetchPutIwocapaySellerPaymentCapturedFund({
        stateKey,
        payLinkId: payLinkColumn.paylink_id,
      });
      onRefreshTable();
    } catch (err) {
      setInteractiveState(null);
      createToast(
        'Unable to send funds, please contact your account manager.',
        {
          variant: 'failure',
        },
      );
    }
  };

  if (interactiveState) {
    const getActionText = (): string => {
      return INTERACTIVE_STATUS_TO_ACTION_TEXT[interactiveState];
    };

    const ActionButtons = () => {
      if (interactiveState === 'cancelling' || interactiveState === 'funding') {
        return (
          <div className={styles.actionLoadingSpinner}>
            <Spinner />
          </div>
        );
      }

      return (
        <>
          <Button
            variant={'secondary-alt'}
            onClick={() =>
              interactiveState === 'cancellation-confirmation'
                ? cancelPaylink()
                : fundPayLink()
            }
            data-testid="confirm-cancel"
          >
            <Icon icon={'checkDefault'} />
          </Button>
          <Button
            variant={'secondary-alt'}
            onClick={() => setInteractiveState(null)}
          >
            <Icon icon={'timesDefault'} />
          </Button>
        </>
      );
    };

    return (
      <div className={styles.actionConfirmation}>
        <div className={styles.actionText}>{getActionText()}</div>
        <div className={styles.actionButtons}>
          <ActionButtons />
        </div>
      </div>
    );
  }

  const drawdownOptions = [
    {
      onPress: () => setInteractiveState('funding-confirmation'),
      title: 'Accept now',
    },
    {
      onPress: () => setInteractiveState('cancellation-confirmation'),
      title: 'Cancel',
    },
  ];

  return (
    <div className={styles.status}>
      <div className={styles.statusTop}>
        <div
          className={classnames({
            [styles.cancelledLabel]: parsedStatus === 'Cancelled',
            [styles.declinedLabel]: parsedStatus === 'Declined',
            [styles.paidLabel]: parsedStatus === "You've been paid 🙌",
            [styles.capturedLabel]: parsedStatus === 'Pending funds',
          })}
        >
          <PayLinkStatusText status={parsedStatus} />
        </div>
        {parsedStatus === 'Not started' && (
          <div className={styles.rightActionable}>
            <Button
              variant="tertiary"
              className={styles.copyPaylink}
              onClick={() => {
                onCopyLink();
                createToast(
                  <span>
                    Pay Link <i>"{payLinkColumn.reference}"</i> copied
                  </span>,
                );
              }}
            >
              Copy paylink
              <Icon className={styles.copyIcon} icon="copyOutline" />
            </Button>
          </div>
        )}
        {parsedStatus === 'Pending funds' && showDropdown && (
          <ThreeDotOptionDropdown
            options={drawdownOptions}
          ></ThreeDotOptionDropdown>
        )}
      </div>
      <div
        className={classnames({
          [styles.progressIndicator]: statusBarColours.length > 0,
          [styles.visualPadding]: parsedStatus === "You've been paid 🙌",
        })}
      >
        {statusBarColours.map((colour, index) => (
          <div
            key={index}
            className={classnames(styles.progressPip, colour)}
          ></div>
        ))}
      </div>
      <BottomText status={parsedStatus} payLink={payLinkColumn} />
    </div>
  );
};

const PayLinkStatusText = ({ status }: { status: TDashboardPaylinkStatus }) => {
  const [showToolTip, setShowToolTip] = useState<boolean>(false);

  const tooltipContent =
    STATUS_TO_TOOLTIP?.[status as keyof typeof STATUS_TO_TOOLTIP]; // Workaround due to weird type narrowing

  return (
    <div
      onFocus={() => setShowToolTip(true)}
      onMouseOver={() => setShowToolTip(true)}
      onMouseLeave={() => setShowToolTip(false)}
    >
      {showToolTip && tooltipContent && (
        <div>
          <div className={tableStyles.toolTip}>
            {tooltipContent}
            <svg
              width="16"
              height="9"
              viewBox="0 0 16 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={tableStyles.tooltipArrow}
            >
              <path
                d="M9.41421 7.5438C8.63316 8.32484 7.36683 8.32484 6.58579 7.54379L7.01564e-07 0.958006L16 0.958008L9.41421 7.5438Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      )}
      <>{status}</>
    </div>
  );
};

const BottomText = ({
  status,
  payLink,
}: {
  status: TDashboardPaylinkStatus;
  payLink: PayLinkColumns;
}) => {
  if (status === 'Pending funds' && payLink.capture_by) {
    return (
      <p className={classnames(styles.subtitle, styles.subtitleUnderBar)}>
        {`Will be paid on ${new Date(payLink.capture_by).toLocaleDateString(
          'en-GB',
        )}`}
      </p>
    );
  }

  if (status === 'Declined' && payLink.declineDate) {
    const declineDate = new Date(payLink.declineDate);
    declineDate.setDate(declineDate.getDate() + 30); // 30 days after decline

    const dayDifference = Math.ceil(
      (declineDate.getTime() - new Date().getTime()) / (1000 * 3600 * 24),
    );

    return (
      <p className={classnames(styles.subtitle, styles.subtitleUnderBar)}>
        They can try again{' '}
        {dayDifference > 0 ? `in ${dayDifference} days` : 'now'}
      </p>
    );
  }

  if (status === 'Eligible' && payLink.formal_offer_expiry_date) {
    const expiryDate = new Date(payLink.formal_offer_expiry_date);
    const dayDifference = Math.ceil(
      (expiryDate.getTime() - new Date().getTime()) / (1000 * 3600 * 24),
    );

    return (
      <p className={classnames(styles.subtitle, styles.subtitleUnderBar)}>
        {dayDifference > 0
          ? `Expires in ${dayDifference} day${dayDifference == 1 ? '' : 's'}`
          : `Offer has expired`}
      </p>
    );
  }

  if (status === 'Partially approved') {
    return (
      <p className={classnames(styles.subtitle, styles.subtitleUnderBar)}>
        Approval amount less than order value
      </p>
    );
  }

  return null;
};
