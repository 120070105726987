import { useGetStateByStateKey } from '../../../../api/lending/lapiHooks';
import { formatCurrency } from '../../../../components/Input/formatCurrency';
import { GridField } from '../GridField/GridField';

export const BusinessDetails = () => {
  const { state, loadingState } = useGetStateByStateKey();
  if (loadingState || !state) return <></>;

  const application = state.application;
  if (!application?.company) return <></>;

  const companyDetails = application.company;
  type TCompanyTypes = Exclude<typeof companyDetails.type, undefined>;

  const prettyCompanyType: { [K in TCompanyTypes]: string } = {
    limited_liability_company: 'Limited company',
    limited_liability_partnership: 'Limited partnership',
    sole_trader: 'Sole trader',
    ordinary_partnership: 'Ordinary partnership',
    public_limited_company: 'Public limited company',
    other: 'Other',
  };

  const businessDetails = {
    'Business Name': companyDetails?.registered_company_name,
    'Trading Name':
      companyDetails?.trading_name || companyDetails?.registered_company_name,
    'Registered Number': companyDetails?.company_number,
    'Company Type':
      companyDetails?.type && prettyCompanyType[companyDetails?.type],
    Website: companyDetails.website,
    'Last 12 months turnover':
      companyDetails.last_12_months_turnover?.amount &&
      `£${formatCurrency(`${companyDetails.last_12_months_turnover!.amount}`)}`,
    'Trading start date': companyDetails.trading_from_date,
  } as const;

  return (
    <>
      <h3>Details</h3>
      <div className="grid grid-cols-2 gap-m sm:grid-cols-1">
        {Object.keys(businessDetails).map((key) => {
          const value = businessDetails[key as keyof typeof businessDetails];
          return <GridField key={key} header={key} value={value || '-'} />;
        })}
      </div>
    </>
  );
};
