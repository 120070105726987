import { businessDetailsValidator } from './BusinessDetails/businessDetailTypeValidator';
import { businessTypeValidator } from './BusinessType/businessTypeValidator';
import { directorInfoValidator } from './DirectorInfo/directorInfoValidator';

function createValidatorFromYupSchema(
  validator:
    | typeof businessTypeValidator
    | typeof businessDetailsValidator
    | typeof directorInfoValidator,
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (data: any) => {
    try {
      validator.validateSync(data);
    } catch {
      return false;
    }

    return true;
  };
}

export const COMPANY_TYPE_STEP_ID = 'business-type';
export const BUSINESS_DETAILS_STEP_ID = 'business-details';
export const DIRECTOR_INFO_STEP_ID = 'director-info';

export type StepType =
  | typeof COMPANY_TYPE_STEP_ID
  | typeof BUSINESS_DETAILS_STEP_ID
  | typeof DIRECTOR_INFO_STEP_ID;

export const STEPS = [
  {
    identifier: COMPANY_TYPE_STEP_ID,
    validator: createValidatorFromYupSchema(businessTypeValidator),
  },
  {
    identifier: BUSINESS_DETAILS_STEP_ID,
    validator: createValidatorFromYupSchema(businessDetailsValidator),
  },
  {
    identifier: DIRECTOR_INFO_STEP_ID,
    validator: createValidatorFromYupSchema(directorInfoValidator),
  },
] as const;
