export const GridField = ({
  header,
  value,
}: {
  header: string;
  value: string;
}) => {
  return (
    <div>
      <div className="text-m font-med">{header}</div>
      <div className="text-m font-reg">{value}</div>
    </div>
  );
};
