export const getFormattedDate = (
  dateString: string | null = '',
  format: 'short' | 'long' = 'short',
) => {
  const date = dateString ? new Date(dateString) : new Date();

  if (format === 'long') {
    const formatter = new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    return formatter.format(date);
  }

  const formatter = new Intl.DateTimeFormat('en-GB', {
    year: '2-digit',
    month: 'short',
    day: 'numeric',
  });
  return formatter.format(date);
};
