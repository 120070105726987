import { useEffect, useState } from 'react';

import cn from 'classnames';
import ReactModal from 'react-modal';

import styles from './DrawDownModal.module.css';
import { Failed } from './Failed/Failed';
import { Loading } from './Loading/Loading';
import { Success } from './Success/Success';
import {
  useGetBuyerSpendingLimit,
  usePostFundSeller,
} from '../../../../api/lending/lapiHooks';
import { useIsOverlay } from '../../../hooks/useIsOverlay';
import { useModal } from '../../../store/ModalProvider';
import { navigateToAccountPages } from '../../../utils/accountPages';
import { useCurrentFormalOfferDuration } from '../../hooks/useCurrentFormalOfferDuration';

export const DRAW_DOWN_MODAL = 'DrawDownModal';

export const DISPLAY_TYPE_ENUM = {
  DEFAULT: 'DEFAULT',
  ECOMMERCE: 'ECOMMERCE',
  OVERLAY: 'OVERLAY',
} as const;
export type TDisplayType =
  (typeof DISPLAY_TYPE_ENUM)[keyof typeof DISPLAY_TYPE_ENUM];

export const DrawDownModal = () => {
  const { isOpen } = useModal(DRAW_DOWN_MODAL);
  const { postFundSeller, isLoading, isSuccess, isError } = usePostFundSeller();
  const { spendingLimit } = useGetBuyerSpendingLimit();
  const { currentFormalOfferDuration } = useCurrentFormalOfferDuration();

  // We can't use active spending limit as we are getting their spending limits before funding
  // the seller (as active spending limit doesn't update fast enough after funding). So their active
  // spending limit may be a different duration to the one they're using this time.
  const currentSpendingLimit = spendingLimit?.data.spending_limits.find(
    (spending_limit) =>
      spending_limit.duration_in_months === currentFormalOfferDuration,
  );

  const [redirectUrl, setRedirectUrl] = useState('');

  const isOverlay = useIsOverlay();

  const getDisplayType = () => {
    if (isOverlay) return DISPLAY_TYPE_ENUM.OVERLAY;
    if (redirectUrl) return DISPLAY_TYPE_ENUM.ECOMMERCE;

    return DISPLAY_TYPE_ENUM.DEFAULT;
  };

  const displayType = getDisplayType();

  const finishDrawDown = () => {
    navigateToAccountPages();
  };

  useEffect(() => {
    async function handlePostFundSeller() {
      if (!isOpen) return;

      try {
        const { redirect_url } = await postFundSeller();

        if (redirect_url) {
          setRedirectUrl(redirect_url);
        }
      } catch (error) {
        // Ignore the error because we're checking it via isError
      }
    }

    void handlePostFundSeller();
  }, [isOpen, postFundSeller, isOverlay]);

  const showOverlay = isLoading || isSuccess || isError;

  return (
    <>
      {showOverlay && <div className={styles.tintedOverlay} />}
      <DrawDownReactModal isOpen={isLoading} fadeOut>
        <Loading />
      </DrawDownReactModal>

      <DrawDownReactModal isOpen={isSuccess} fadeIn>
        <Success
          finishDrawDown={finishDrawDown}
          displayType={displayType}
          redirectUrl={redirectUrl}
          spendingLimit={currentSpendingLimit}
        />
      </DrawDownReactModal>

      <DrawDownReactModal isOpen={isError} fadeIn>
        <Failed finishDrawDown={finishDrawDown} />
      </DrawDownReactModal>
    </>
  );
};

const DrawDownReactModal = ({
  isOpen,
  children,
  fadeIn = false,
  fadeOut = false,
}: {
  isOpen: boolean;
  children: React.ReactNode;
  fadeIn?: boolean;
  fadeOut?: boolean;
}) => {
  return (
    <ReactModal
      className={{
        base: cn(styles.ProcessingPaymentModal, {
          [styles.modalBase]: fadeIn,
        }),
        afterOpen: cn({ [styles.modalAfterOpen]: fadeIn }),
        beforeClose: cn({ [styles.modalBeforeClose]: fadeOut }),
      }}
      overlayClassName={styles.modalOverlay}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      closeTimeoutMS={fadeOut ? 200 : 0}
    >
      <div className={styles.content}>{children}</div>
    </ReactModal>
  );
};
