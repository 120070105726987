import { TVisibilityFunction } from '../../routes';

export function isPersonalGuaranteeVisible({
  loanDocumentsRequiredIwocapay,
}: Parameters<TVisibilityFunction>[0]): ReturnType<TVisibilityFunction> {
  const personalGuaranteeLoanDocument =
    loanDocumentsRequiredIwocapay?.required_loan_documents.find(
      (required_loan_document) => required_loan_document.type === 'guarantee',
    );

  if (Boolean(personalGuaranteeLoanDocument)) return true;

  return false;
}
