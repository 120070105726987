import React from 'react';

import classnames from 'classnames';

import styles from './VerticalSplitLayout.module.css';

export const VerticalSplitLayout = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={classnames(styles.verticalSplitLayout, className)}>
      {children}
    </div>
  );
};

export const Column = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.column}>{children}</div>;
};
