import { InputHTMLAttributes } from 'react';

import { CheckBox as OrionCheckBox } from '@iwoca/orion';
import cn from 'classnames';
import { useField } from 'formik';

import styles from './Checkbox.module.css';
import { InputError } from '../../../components/InputError/InputError';

export const Checkbox = ({
  labelText,
  name,
  disabled,
}: {
  name: string;
  labelText: React.ReactNode;
  disabled?: boolean;
} & InputHTMLAttributes<HTMLInputElement>) => {
  const [field, { error, touched, value }] = useField(name);

  return (
    <div>
      <div
        className={cn(styles.inputContainer, {
          [styles.inputContainerDisabled]: disabled,
        })}
      >
        <OrionCheckBox
          {...field}
          name={name}
          id={name}
          checked={value}
          disabled={disabled}
          // @ts-expect-error
          label={labelText}
        />
      </div>
      <InputError isVisible={touched} error={error} />
    </div>
  );
};
