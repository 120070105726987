import { TAnonymousSplitTest, TBranch } from './SplitTest';

// function shuffleArray<T>(array: readonly T[]): T[] {
//   const newArray = [...array]; // Create a shallow copy to avoid modifying the original
//   return newArray.sort(() => Math.random() - 0.5);
// }

// Currently not implemented and only works for 50/50 split tests.
// Do not use for now.
export function runSplitTest(
  splitTest: TAnonymousSplitTest,
  randomNumber: number,
): TBranch {
  const { branches, defaultBranch } = splitTest;

  const roundedNumber = Math.round(randomNumber);

  if (roundedNumber === 1) {
    return branches[0];
  }

  return {
    branchName: defaultBranch,
    testPercentage: 0.5,
  };
}
