import styles from './ExistingPackageLinksTable.module.css';
import { MobileCard } from './MobileCard/MobileCard';
import { Card } from '../../../../Buyer/components/Card/Card';
import { getIwocaPayMeUrl } from '../../../../components/PayMeLink/getIwocaPayMeUrl';
import { PayMeLink } from '../../../../components/PayMeLink/PayMeLink';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableHeaderCell,
} from '../../../../components/Table/Table';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import { clipText } from '../../../../utils/ClipText';
import { displayCurrency } from '../../../../utils/DisplayCurrency';
import { getFormattedDate } from '../../../../utils/DisplayDate';
import { PackageLink } from '../PackageLinksPage.types';

const sanitiseText = clipText(30);

export const ExistingPackageLinksTable = ({
  existingPackageLinks,
  sellerHandle,
}: {
  existingPackageLinks: PackageLink[];
  sellerHandle?: string;
}) => {
  const isDesktop = useMediaQuery('(min-width:800px)');

  const tableData = existingPackageLinks.map((packageLink) => {
    return {
      id: packageLink.id,
      name: packageLink.name,
      reference: sanitiseText(packageLink.reference),
      amount: displayCurrency(packageLink.amount),
      dateCreated: getFormattedDate(packageLink.created_at),
      payLinkPackageUrl: getIwocaPayMeUrl(sellerHandle || '', packageLink.id),
    };
  });

  return (
    <div className={styles.existingPackageLinksTable}>
      {isDesktop ? (
        <Card>
          <Table>
            <TableHead>
              <TableRow>
                {() => (
                  <>
                    <TableCell>Name</TableCell>
                    <TableCell>Ref</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Date created</TableCell>
                    <TableCell>Your package link</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData.map((row) => (
                <TableRow key={row.id}>
                  {() => (
                    <>
                      <TableCell>{row.name}</TableCell>
                      <TableHeaderCell>{row.reference}</TableHeaderCell>
                      <TableCell>{row.amount}</TableCell>
                      <TableCell>{row.dateCreated}</TableCell>
                      <TableCell>
                        <PayMeLink payMeUrl={row.payLinkPackageUrl} />
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      ) : (
        <div>
          {tableData.map((row) => (
            <MobileCard
              key={row.id}
              reference={row.reference}
              amount={row.amount}
              dateCreated={row.dateCreated}
              payLinkPackageUrl={row.payLinkPackageUrl}
            />
          ))}
        </div>
      )}
    </div>
  );
};
