import { Frames } from 'frames-react';

import { CheckoutFormValues } from './CheckoutAddPaymentMethodForm.types';
import { FormValues } from './StripeAddPaymentMethodForm.types';

export const validateFormFields = (values: FormValues) => {
  const errors: { [k in keyof FormValues]?: string } = {};

  if (!values.cardNumber || values.cardNumber.empty) {
    errors.cardNumber = 'Card number is required';
  } else {
    if (values.cardNumber.error) {
      errors.cardNumber = values.cardNumber.error.message;
    }
  }

  if (!values.cardExpiry || values.cardExpiry.empty) {
    errors.cardExpiry = 'Expiry is required';
  } else {
    if (values.cardExpiry.error) {
      errors.cardExpiry = values.cardExpiry.error.message;
    }
  }

  if (!values.cardCvc || values.cardCvc.empty) {
    errors.cardCvc = 'CVC is required';
  } else {
    if (values.cardCvc.error) {
      errors.cardCvc = values.cardCvc.error.message;
    }
  }

  if (!values.billingPostcode) {
    errors.billingPostcode = 'Billing postcode is required';
  }

  return errors;
};

export const validateCheckoutAddCardForm = (values: CheckoutFormValues) => {
  const errors: { billingPostcode?: string; frames?: string } = {};

  if (!values.billingPostcode) {
    errors.billingPostcode = 'Billing postcode is required';
  }

  if (!Frames.isCardValid()) {
    errors.frames = 'There is an error with your card details';
  }
  return errors;
};
