import { InputHTMLAttributes } from 'react';

import { Input as OrionInput } from '@iwoca/orion';
import cn from 'classnames';
import { useField } from 'formik';
import { useFormContext } from 'react-hook-form';

import styles from './Input.module.css';
import { extractError } from '../../utils/ReactHookForm';
import { InputError } from '../InputError/InputError';

type TCompatInputType =
  | Parameters<typeof OrionInput>[0]['type']
  | 'hidden'
  | 'date-input';
export const Input = ({
  label,
  labelDescriptionText,
  name,
  className,
  Icon,
  showError = true,
  formatter,
  type,
  ...inputProps
}: {
  name: string;
  label?: string;
  labelDescriptionText?: string;
  className?: string;
  showError?: boolean;
  Icon?: React.ReactNode;
  formatter?: (value: string) => string | undefined;
  type?: TCompatInputType;
} & InputHTMLAttributes<HTMLInputElement>) => {
  const [field, { error, touched }, { setValue }] = useField(name);

  const hasIcon = Boolean(Icon);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    inputProps.onChange?.(e);
    field.onChange(e);
  };

  return (
    <div className={cn(className, styles.input)}>
      <div className={styles.inputContainer}>
        {hasIcon ? <span className={styles.icon}>{Icon}</span> : null}
        <OrionInput
          {...inputProps}
          {...field}
          // Expecting error due to orion not supporting "hidden"
          // @ts-expect-error
          type={type}
          label={label}
          description={labelDescriptionText}
          onChange={onChange}
          id={name}
          name={name}
          className={cn(styles.inputField, {
            [styles.hasIcon]: hasIcon,
          })}
          onKeyUp={async () => {
            if (!formatter) return;
            const formattedInput = formatter(field.value);

            if (formattedInput === undefined) return;

            await setValue(formattedInput);
          }}
        />
      </div>
      <InputError isVisible={touched && showError} error={error} />
    </div>
  );
};

export const HookFormInput = ({
  id,
  label,
  labelDescriptionText,
  name,
  className,
  Icon,
  showError = true,
  // TODO: Implement formatter when migrating
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formatter,
  onChange,
  type,
  ...inputProps
}: {
  id?: string;
  name: string;
  label?: string;
  labelDescriptionText?: string;
  className?: string;
  showError?: boolean;
  Icon?: React.ReactNode;
  iconLeft?: Parameters<typeof OrionInput>[number]['iconLeft'];
  formatter?: (value: string) => string | undefined;
  type?: TCompatInputType;
} & InputHTMLAttributes<HTMLInputElement>) => {
  const { register, formState } = useFormContext();
  const error = extractError(formState.errors[name]);

  const hasIcon = Boolean(Icon);

  const identifier = id || name;

  return (
    <div className={cn(className, styles.input)}>
      <div className={styles.inputContainer}>
        {hasIcon ? (
          <span className={styles.icon} aria-hidden={true}>
            {Icon}
          </span>
        ) : null}
        <OrionInput
          {...inputProps}
          // Expecting error due to orion not supporting "hidden"
          // @ts-expect-error
          type={type}
          label={label}
          description={labelDescriptionText}
          {...register(name, {
            onChange: (e) => {
              onChange?.(e);
            },
          })}
          id={identifier}
          className={cn(styles.inputField, {
            [styles.hasIcon]: hasIcon,
          })}
        />
      </div>
      {showError && <InputError isVisible={true} error={error} />}
    </div>
  );
};
