import { useContext, useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import { DefaultContent } from './DefaultContent/DefaultContent';
import { ErrorContent } from './ErrorContent/ErrorContent';
import {
  GetPaymentAccountsResponse,
  PaymentAccountPut,
} from './PaymenAccount.types';
import styles from './PaymentAccount.module.css';
import { buildQueryString } from '../../../Buyer/utils/queryParams';
import { putLendingApiJson } from '../../../Pages/lendingApiFetch';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { TryAgainError } from '../TryAgainError/TryAgainError';
import { SellerXeroContext } from '../Xero.context';
import { contextLoadedSuccessfully, getPaymentAccounts } from '../Xero.helpers';
import sellerXeroStyles from '../Xero.module.css';
import { DropdownOption } from '../Xero.types';

export function PaymentAccount() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryString = buildQueryString();

  const [paymentAccountOptions, setPaymentAccountOptions] =
    useState<DropdownOption[]>();
  const [selectedPaymentAccountOption, setSelectedPaymentAccountOption] =
    useState<DropdownOption>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<
    'getPaymentAccount' | 'putPaymentAccount'
  >();

  const sellerXeroContext = useContext(SellerXeroContext);
  const companyName =
    contextLoadedSuccessfully(sellerXeroContext) &&
    sellerXeroContext.organisationName;

  useEffect(() => {
    const setDropdownOptions = async () => {
      if (
        !sellerXeroContext.loading &&
        !sellerXeroContext.error &&
        !sellerXeroContext.refreshing
      ) {
        // TODO: Fix this mess! Switch to react query
        /**
         * Currently this gets wrapped into a try {} catch {} block
         * to avoid async requests leaking into the next test and
         * failing them.
         *
         * Otherwise this causes a "TypeError: Network request failed"
         * which is hard to debug
         */
        try {
          const response = await getPaymentAccounts(
            sellerXeroContext.stateKey!,
          );

          if (response.ok) {
            const { data } =
              (await response.json()) as GetPaymentAccountsResponse;

            const paymentAccountOptions: DropdownOption[] = data.accounts.map(
              (paymentAccount) => ({
                label: paymentAccount.name,
                value: paymentAccount.account_id,
              }),
            );

            setPaymentAccountOptions(paymentAccountOptions);
            return;
          }
        } catch (e) {}

        setError('getPaymentAccount');
      }
    };
    void setDropdownOptions();
  }, [sellerXeroContext]);

  const hasRefreshedContext = useRef(false);

  useEffect(() => {
    if (location?.state?.updateContext && !hasRefreshedContext.current) {
      hasRefreshedContext.current = true;
      sellerXeroContext.refresh();
    }
  }, [sellerXeroContext, location]);

  const handleSubmit = async () => {
    if (
      selectedPaymentAccountOption &&
      !sellerXeroContext.loading &&
      !sellerXeroContext.error
    ) {
      setIsSubmitting(true);

      const response = await saveIwocapaySellerAccount(
        selectedPaymentAccountOption.value,
        sellerXeroContext.stateKey!,
      );

      if (!response.ok) {
        setError('putPaymentAccount');
        setIsSubmitting(false);
        return;
      }
      navigate && navigate('/pay/xero/invoice-branding-theme/' + queryString);
    }
  };

  return (
    <div className={sellerXeroStyles.sellerXero}>
      <div
        className={classnames(
          sellerXeroStyles.contentContainer,
          styles.paymentAccountContainer,
        )}
      >
        <div className={sellerXeroStyles.innerContainer}>
          <h4 className={sellerXeroStyles.companyTypeHeader}>{companyName}</h4>
          <div className={sellerXeroStyles.contentSm}>
            <ProgressBar totalSteps={3} currentStep={2} />
            <div className={styles.contentInner}>
              {error === undefined && (
                <DefaultContent
                  paymentAccountOptions={paymentAccountOptions}
                  setSelectedPaymentAccountOption={
                    setSelectedPaymentAccountOption
                  }
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                />
              )}
              {error === 'putPaymentAccount' && (
                <ErrorContent
                  paymentAccountOptions={paymentAccountOptions}
                  setSelectedPaymentAccountOption={
                    setSelectedPaymentAccountOption
                  }
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                />
              )}
              {error === 'getPaymentAccount' && <TryAgainError />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function saveIwocapaySellerAccount(
  paymentAccountId: string,
  stateKey?: string,
) {
  return putLendingApiJson<PaymentAccountPut>({
    url: `/api/lending/edge/xero/iwocapay_seller/account/${stateKey}/`,
    body: {
      data: { payment_account_id: paymentAccountId },
    },
  });
}
