import {
  fetchPostSharedPayLinkPackagePayLink,
  fetchPostSharedPayLinkPayNow,
  fetchPostSharedUniversalPayLinkPayLink,
} from '@iwoca/lapi-client/iwocapay';
import { v4 as uuidv4 } from 'uuid';

import { encryptPayNowEmail } from './emailEncryption';

type TPayNowCustomerDetails = {
  emailAddress: string;
  fullName: string;
};
type TPayNowPayLinkDetails = {
  amount: number;
  reference: string;
};

export const payNowCheckoutPayLinkPackage = async ({
  payLinkPackageId,
  customerDetails,
  marketingConsent,
  isOverlay,
}: {
  payLinkPackageId: string;
  customerDetails: TPayNowCustomerDetails;
  marketingConsent: boolean;
  isOverlay: boolean;
}) => {
  const createdPayLink = await fetchPostSharedPayLinkPackagePayLink({
    payLinkPackageId,
    body: {},
  });
  return payNowCheckoutOnPayLink({
    payLinkId: createdPayLink.data.id,
    customerDetails,
    marketingConsent,
    isOverlay,
  });
};

export const payNowCheckoutUniversalLink = async ({
  sellerHandle,
  payLinkDetails,
  customerDetails,
  marketingConsent,
  isOverlay,
}: {
  sellerHandle: string;
  payLinkDetails: TPayNowPayLinkDetails;
  customerDetails: TPayNowCustomerDetails;
  marketingConsent: boolean;
  isOverlay: boolean;
}) => {
  const createdPayLink = await fetchPostSharedUniversalPayLinkPayLink({
    sellerHandle,
    body: {
      amount: payLinkDetails.amount,
      reference: payLinkDetails.reference,
    },
  });

  return payNowCheckoutOnPayLink({
    payLinkId: createdPayLink.data.id,
    customerDetails,
    marketingConsent,
    isOverlay,
  });
};

export const payNowCheckoutOnPayLink = async ({
  payLinkId,
  customerDetails,
  marketingConsent,
  isOverlay,
}: {
  payLinkId: string;
  customerDetails: TPayNowCustomerDetails;
  marketingConsent: boolean;
  isOverlay: boolean;
}) => {
  const idempotency_key = uuidv4();
  const redirectInfo = await fetchPostSharedPayLinkPayNow({
    payLinkId: payLinkId,
    body: {
      email_address: customerDetails.emailAddress,
      full_name: customerDetails.fullName,
      idempotency_key: idempotency_key,
      is_overlay: isOverlay,
    },
  });

  const { payment_provider_auth_uri } = redirectInfo.data;
  const encryptedEmail = await encryptPayNowEmail({
    emailAddress: customerDetails.emailAddress,
    authURI: payment_provider_auth_uri,
  });
  if (encryptedEmail) {
    localStorage.iwocaPayNowEnc = encryptedEmail;
    localStorage.iwocaPayNowMarketingConsent = marketingConsent;
  }
  window.location.href = payment_provider_auth_uri;
};
