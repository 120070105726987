import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { tracking } from '@iwoca/frontend-tracking-library';
import { PaymentOptionSubmittedProperties } from '@iwoca/frontend-tracking-library/lib/ampli';
import { CheckBox, Icon, Modal, Radio } from '@iwoca/orion';
import classnames from 'classnames';
import { set } from 'lodash';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import styles from './PaymentDetails.module.css';
import { TrustPilotBadge } from './TrustPilotBadge/TrustPilotBadge';
import { iwocapayTelDisplay } from '../../../../src/constants.json';
import {
  useGetSellerByHandle,
  useGetStateByStateKey,
} from '../../../api/lending/lapiHooks';
import { formatCurrency } from '../../../components/Input/formatCurrency';
import { HookFormInput } from '../../../components/Input/Input';
import IwocaPayIcon from '../../../components/svg/IwocaPayIcon.svg?react';
import { useGetStep0SplitTest } from '../../../SplitTest/useGetStep0SplitTest';
import { displayCurrency } from '../../../utils/DisplayCurrency';
import { getOrdinalDateFromNow } from '../../../utils/DisplayOrdinalDate';
import logger from '../../../utils/logger';
import { Button } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import {
  EmailFormInput,
  useEmailValidation,
} from '../../components/EmailInput/EmailInput';
import { TEnabledOptions } from '../../hooks/useEnabledTerms';
import { RepaymentSchedule } from '../components/RepaymentSchedule/RepaymentSchedule';
import { useCurrentAmount } from '../hooks/useCurrentAmount';
import { useGetANTFormalOffers } from '../hooks/useGetANTFormalOffers';
import { useHasInsufficientCredit } from '../hooks/useHasInsufficientCredit';
import { useRepaymentSchedule } from '../hooks/useRepaymentSchedule';
import {
  FormValues,
  TBuyerInterest,
  TDuration,
  TPricingOption,
  TPricingPromotions,
  TProductInterest,
} from '../utils/PayLinkLanding.types';

export const MAX_AMOUNT_PAY_LATER = 30000;
export const MIN_AMOUNT_PAY_LATER = 150;

const PRICING_OPTION_TO_TRACKING = {
  payNow: 'pay_now',
  payLater3: 'pay_in_3',
  payLater12: 'pay_in_12',
} as const satisfies Record<
  TPricingOption,
  PaymentOptionSubmittedProperties['payment_option']
>;

export const PaymentDetails = ({
  isPreview = false,
  sellerName,
  pricingPromotions,
  buyerInterest,
  onSubmit,
  initialValues,
  disableAmount = false,
  disableReference = false,
  setUrlState,
  enabledOptions = ['THREE_MONTHS', 'TWELVE_MONTHS', 'PAY_NOW'],
  payLinkType,
}: {
  isPreview: boolean;
  sellerName: string;
  pricingPromotions: TPricingPromotions;
  buyerInterest: TBuyerInterest;
  onSubmit?: ({
    values,
    stateKey,
  }: {
    values: FormValues;
    stateKey?: string;
  }) => Promise<void>;
  initialValues?: {
    amount?: string;
    reference?: string;
  };
  disableAmount: boolean;
  disableReference: boolean;
  setUrlState?: (key: string, value: string) => void;
  enabledOptions: TEnabledOptions;
  payLinkType: 'custom' | 'generic' | 'package' | 'order' | 'preview';
}) => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { state: customerState } = useGetStateByStateKey();
  const { offers: antOffers } = useGetANTFormalOffers();
  const coreANTOffers = antOffers.filter(
    (offer) => offer?.product_type !== 'iwocapay',
  );
  const [showSuperseedModal, setShowSuperseedModal] = useState<boolean>(false);

  const validateAmount = (amount?: FormValues['amount']) => {
    if (!amount || isNaN(parseFloat(amount))) {
      return 'Please enter an amount';
    } else if (parseFloat(amount) > 250000) {
      return 'Please enter an amount up to £250,000.00';
    } else if (parseFloat(amount) < 1) {
      return 'Please enter an amount from £1.00';
    }

    return undefined;
  };

  const validateReference = (reference?: FormValues['reference']) => {
    if (!reference) {
      return 'Please enter a reference';
    } else if (reference.length > 50) {
      return 'Please enter a reference up to 50 characters';
    }

    return undefined;
  };

  const validatePayNowFullName = async (
    fullName?: FormValues['payNowFullName'],
  ) => {
    const twoOrMoreWordsRegex = /\w+\s+\w+/;
    const fullNameRegex = /^[\p{L}\p{M}\s\-'.]+$/u;
    const fullNameSchema = Yup.string()
      .required('Full name is required')
      .matches(twoOrMoreWordsRegex, 'Full name must contain at least two words')
      .matches(fullNameRegex, 'Full name contains invalid characters');

    try {
      await fullNameSchema.validate(fullName);
      return undefined;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        return error.errors[0];
      }

      return 'Full name is invalid';
    }
  };

  const validatePayNowEmailAddress = async (
    emailAddress?: FormValues['payNowEmailAddress'],
  ) => {
    const emailAddressSchema = Yup.string()
      .email('Email address is invalid')
      .required('Email address is required');

    try {
      await emailAddressSchema.validate(emailAddress);
      return undefined;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        return error.errors[0];
      }

      return 'Email address is invalid';
    }
  };

  const validateEmail = useEmailValidation();

  const validate = async (values: FormValues) => {
    const errors: { [k: string]: string } = {};

    const referenceError = validateReference(values.reference);
    if (referenceError) errors.reference = referenceError;

    const amountError = validateAmount(values.amount);
    if (amountError) errors.amount = amountError;

    if (!values.paymentOption) {
      errors.paymentOption = 'Select an option to continue';
    }

    if (values.paymentOption?.includes('payLater')) {
      if (
        parseFloat(values.amount) > MAX_AMOUNT_PAY_LATER ||
        parseFloat(values.amount) < MIN_AMOUNT_PAY_LATER
      ) {
        const minAmountText = displayCurrency(MIN_AMOUNT_PAY_LATER, 0);
        const maxAmountText = displayCurrency(MAX_AMOUNT_PAY_LATER, 0);

        errors.paymentOption = `Sorry! Pay later is only available for amounts from ${minAmountText} up to ${maxAmountText}`;
      }

      const { errors: emailErrors } = await validateEmail(values);

      if (emailErrors?.emailAddress) {
        errors.emailAddress = emailErrors.emailAddress!;
      }
    }

    if (values.paymentOption?.includes('payNow')) {
      const payNowFullNameError = await validatePayNowFullName(
        values.payNowFullName,
      );
      if (payNowFullNameError) errors.payNowFullName = payNowFullNameError;

      const payNowEmailAddressError = await validatePayNowEmailAddress(
        values.payNowEmailAddress,
      );
      if (payNowEmailAddressError)
        errors.payNowEmailAddress = payNowEmailAddressError;
    }

    if (Object.keys(errors).length > 0) {
      return {
        values: {},
        errors,
      };
    }

    return {
      values,
      errors: {},
    };
  };

  const initialAmount = initialValues?.amount || '';
  const isInitialAmountValid =
    validateAmount(initialValues?.amount) === undefined;

  const initialReference = initialValues?.reference || '';
  const isInitialReferenceValid =
    validateReference(initialReference) === undefined;

  const initialFormValues: FormValues = {
    reference: isInitialReferenceValid ? initialReference : '',
    amount: isInitialAmountValid ? initialAmount : '',
    paymentOption: null,
    emailAddress: '',
    marketingOptIn: true,
    payNowFullName: '',
    payNowEmailAddress: '',
    payNowMarketingConsent: false,
  };

  const methods = useForm({
    defaultValues: initialFormValues,
    resolver: validate,
    mode: 'onChange',
  });

  useEffect(() => {
    // Sync to external state of initialFormValues
    // Allows form to reinitialise on change
    methods.reset(initialFormValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialReference, initialAmount]);

  const submitForm = async ({
    values,
    acceptedSuperseed = false,
  }: {
    values: FormValues;
    acceptedSuperseed?: boolean;
  }) => {
    if (!onSubmit) return;

    if (coreANTOffers.length > 0 && !acceptedSuperseed) {
      return setShowSuperseedModal(true);
    }

    try {
      await onSubmit({
        values,
        stateKey: customerState?.state_key,
      });

      if (payLinkType !== 'preview') {
        tracking.ampli.paymentOptionSubmitted({
          payment_option: PRICING_OPTION_TO_TRACKING[values.paymentOption!],
          // @ts-expect-error Can also be of type "order", library needs update
          pay_link_type: payLinkType,
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      logger.error(error.message, error);

      try {
        const errorMessage = JSON.parse(error.message);
        if (
          errorMessage.errors.some(
            (error: { code: string; detail: string }) =>
              error.detail ===
              'Sole trader cannot use pay later with non-ST seller.',
          )
        ) {
          return setSubmitError(
            'Pay later is not available for Sole Traders on this transaction',
          );
        }
      } catch (e) {
        return setSubmitError(
          `Oops something went wrong. Please try again or give us a call on ${iwocapayTelDisplay}.`,
        );
      }

      return setSubmitError(
        `Oops something went wrong. Please try again or give us a call on ${iwocapayTelDisplay}.`,
      );
    }
  };
  const onHookFormSubmit = methods.handleSubmit((values) =>
    submitForm({ values }),
  );

  const SuperseedAcceptance = () => {
    const acceptConditions = async () => {
      setShowSuperseedModal(false);
      await submitForm({
        values: methods.getValues(),
        acceptedSuperseed: true,
      });
    };

    return (
      <Modal
        isOpen={showSuperseedModal}
        onClose={() => setShowSuperseedModal(false)}
      >
        <h1 className={styles.creditFacilityWarningTitle}>
          Once you hit continue we’ll need to re-run your existing Flexi-Loan
          offer for £
          {formatCurrency(`${coreANTOffers[0]?.available_credit || 0}`)}
        </h1>
        <p className={styles.creditFacilityWarningText}>
          Don’t worry - you won’t need to give us any more documents to do this.
          To see your Flexi-Loan offer, go to your iwoca dashboard.
        </p>
        <div className={styles.creditFacilityButtonContainer}>
          <Button onClick={acceptConditions}>Continue with iwocaPay</Button>
          <Button
            onClick={() => setShowSuperseedModal(false)}
            variant="tertiary"
          >
            Go back
          </Button>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={onHookFormSubmit} noValidate>
          <SuperseedAcceptance />
          <PaymentDetailsForm
            isPreview={isPreview}
            sellerName={sellerName}
            pricingPromotions={pricingPromotions}
            buyerInterest={buyerInterest}
            enabledOptions={enabledOptions}
            disableAmount={disableAmount && !!initialFormValues.amount}
            disableReference={disableReference && !!initialFormValues.reference}
            submitError={submitError}
            setUrlState={setUrlState}
            onOptionChange={(paymentOption) => {
              if (payLinkType !== 'preview') {
                tracking.ampli.paymentOptionSelected({
                  payment_option: PRICING_OPTION_TO_TRACKING[paymentOption],
                  // @ts-expect-error Can also be of type "order", library needs update
                  pay_link_type: payLinkType,
                });
              }
            }}
          />
        </form>
      </FormProvider>
    </>
  );
};

const PAYMENT_OPTION_INPUT_ID = 'paymentOption';

const PaymentOptionField = ({
  isPreview = false,
  value,
  label,
  children,
  onChange,
}: {
  isPreview: boolean;
  value: TPricingOption;
  label: ReactNode;
  children?: ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { isInStep0SplitTest } = useGetStep0SplitTest({
    shouldRunTest: !isPreview,
  });
  const id = `${PAYMENT_OPTION_INPUT_ID}-${value}`;
  const { register, watch } = useFormContext();
  const watchPaymentOption = watch(PAYMENT_OPTION_INPUT_ID);
  const isChecked = watchPaymentOption === value;

  const { hasInsufficientCredit } = useHasInsufficientCredit({
    pricingOption: value,
  });

  return (
    <Card
      className={classnames(styles.paymentOption, {
        [styles.paymentOptionSelected]: isChecked,
        [styles.disabled]: hasInsufficientCredit,
        [styles.isIncludeTestRadio]: isInStep0SplitTest,
      })}
    >
      <label className={styles.paymentOptionHeader} htmlFor={id}>
        <div className={styles.paymentOptionsLabelWrapper}>
          <Radio
            {...register(PAYMENT_OPTION_INPUT_ID, {
              onChange: (e) => {
                onChange?.(e);
              },
            })}
            id={id}
            checked={isChecked}
            value={value}
            className={styles.paymentOptionsRadio}
            disabled={hasInsufficientCredit}
          />
          <div>
            <label className={styles.paymentOptionsLabel} htmlFor={id}>
              {label}
            </label>
            {hasInsufficientCredit && (
              <div className={styles.creditWarning}>
                Not enough limit available
              </div>
            )}
          </div>
        </div>
      </label>
      {!isInStep0SplitTest && children && isChecked && (
        <div className={styles.paymentOptionDescription}>{children}</div>
      )}
    </Card>
  );
};

const PaymentDetailsForm = ({
  isPreview = false,
  sellerName,
  pricingPromotions,
  buyerInterest,
  submitError,
  setUrlState,
  onOptionChange,
  enabledOptions = ['THREE_MONTHS', 'TWELVE_MONTHS', 'PAY_NOW'],
  disableAmount,
  disableReference,
}: {
  isPreview: boolean;
  sellerName: string;
  pricingPromotions: TPricingPromotions;
  buyerInterest: TBuyerInterest;
  submitError: string | null;
  setUrlState?: (key: string, value: string) => void;
  onOptionChange?: (paymentOption: TPricingOption) => void;
  enabledOptions: TEnabledOptions;
  disableAmount: boolean;
  disableReference: boolean;
}) => {
  const {
    formState,
    setValue: setFieldValue,
    trigger,
    watch,
    clearErrors,
  } = useFormContext<FormValues>();
  const initialValues = formState.defaultValues!;
  const errors = formState.errors;
  const paymentOption = watch('paymentOption');
  const { isInStep0SplitTest } = useGetStep0SplitTest({
    shouldRunTest: !isPreview,
  });

  const { state: customerState } = useGetStateByStateKey();
  const loggedInCustomerEmailAddress =
    customerState?.application?.people?.[0].emails?.[0].email;

  useEffect(() => {
    setFieldValue('emailAddress', loggedInCustomerEmailAddress || '');
  }, [loggedInCustomerEmailAddress, setFieldValue, trigger]);

  const isFormPrefilled =
    Boolean(initialValues.amount) && Boolean(initialValues.reference);

  return (
    <>
      <div className={styles.paymentDetailsContainer}>
        <div className="mb-l flex items-center justify-end text-s font-med">
          <Icon className="mr-xs fill-success-drk" icon="lockCloseOutline" />
          <span className="text-success-drk">100% Secure checkout</span>
        </div>
        <div>
          <h1 className="m-0 text-2xl">Your payment to {sellerName}</h1>
          <p className="mt-s flex items-center text-s">
            Powered by&nbsp;
            <IwocaPayIcon className="mt-xs w-[7rem] fill-core-65" />
          </p>
        </div>

        {isInStep0SplitTest && isFormPrefilled ? (
          <div className="flex gap-l bg-[#F4F7FA] p-l text-s">
            <div>
              Order amount:{' '}
              <span className="font-med">
                {displayCurrency(parseFloat(initialValues.amount!))}
              </span>
            </div>
            <div>
              Order reference:{' '}
              <span className="font-med">{initialValues.reference}</span>
            </div>
          </div>
        ) : (
          <>
            <div
              className={classnames(
                styles.paymentDetailsReferenceAmountContainer,
                styles.paymentDetailsLabel,
              )}
            >
              Enter a reference and amount
            </div>
            <div className={styles.paymentDetailsFields}>
              <div className={styles.fieldContainer}>
                <div className={styles.amountFieldContainer}>
                  <HookFormInput
                    label="Amount"
                    type="number"
                    min="1"
                    step="0.01"
                    max="250000"
                    name="amount"
                    id="paymentDetailsAmount"
                    disabled={disableAmount}
                    iconLeft="pound"
                    onChange={(ev) => setUrlState?.('amount', ev.target.value)}
                  />
                </div>
              </div>
              <div className={styles.fieldContainer}>
                <HookFormInput
                  label="Reference"
                  type="text"
                  name="reference"
                  placeholder="Invoice reference"
                  id="paymentDetailsReference"
                  disabled={disableReference}
                  onChange={(ev) => setUrlState?.('reference', ev.target.value)}
                />
              </div>
            </div>
          </>
        )}

        {isInStep0SplitTest ? (
          <>
            <div className="pb-m pt-xl">
              <div className="text-m font-med text-secondary-20">
                Choose a payment option
              </div>
            </div>
          </>
        ) : (
          <div
            className={classnames(
              styles.paymentDetailsLabel,
              styles.paymentDetailsPaymentOptionContainer,
            )}
          >
            Choose a payment option
          </div>
        )}

        <div className={styles.paymentOptionsContainer}>
          {enabledOptions.includes('PAY_NOW') && (
            <PaymentOptionField
              isPreview={isPreview}
              value="payNow"
              label={<PayNowRadioOptionLabel />}
              onChange={(e) => {
                onOptionChange?.(e.target.value as TPricingOption);
              }}
            />
          )}
          {enabledOptions.includes('THREE_MONTHS') && (
            <PayLaterOption
              isPreview={isPreview}
              pricingPromotions={pricingPromotions.THREE_MONTHS}
              productInterest={buyerInterest.THREE_MONTHS}
              duration={3}
              onChange={(e) => {
                clearErrors();
                onOptionChange?.(e.target.value as TPricingOption);
              }}
            />
          )}
          {enabledOptions.includes('TWELVE_MONTHS') &&
            buyerInterest.TWELVE_MONTHS && (
              <PayLaterOption
                isPreview={isPreview}
                pricingPromotions={pricingPromotions.TWELVE_MONTHS!}
                productInterest={buyerInterest.TWELVE_MONTHS}
                duration={12}
                onChange={(e) => {
                  clearErrors();
                  onOptionChange?.(e.target.value as TPricingOption);
                }}
              />
            )}
          {isInStep0SplitTest && errors.paymentOption && (
            <div className="text-s font-med text-error">
              {errors.paymentOption as unknown as string}
            </div>
          )}
        </div>

        {!paymentOption && (
          <NoSelection
            isDisabled={!isInStep0SplitTest}
            buttonText={
              !!loggedInCustomerEmailAddress
                ? 'Finish checkout'
                : 'Create account and check out'
            }
          />
        )}
        {paymentOption === 'payNow' && <PayNowSelected />}
        {paymentOption?.includes('payLater') && (
          <EmailFormInput loggedIn={!!loggedInCustomerEmailAddress} />
        )}
        {submitError && <p className={styles.fieldError}>{submitError}</p>}
        {isInStep0SplitTest ? (
          <TrustPilotBadge />
        ) : (
          <a
            className="block pt-l text-center align-middle text-xs font-med !text-structure-40 no-underline"
            href="https://uk.trustpilot.com/review/iwoca.co.uk"
            rel="noopener noreferrer"
            target="_blank"
          >
            Secure payments rated 4.8/5 on Trustpilot
          </a>
        )}
      </div>
    </>
  );
};

const PayNowRadioOptionLabel = () => {
  const amount = useCurrentAmount();
  const displayAmount = amount && displayCurrency(parseFloat(amount));

  return (
    <div className={styles.paymentOptionLabel}>
      {amount ? (
        <p>
          <b> Pay {displayAmount} </b> with an instant bank transfer
        </p>
      ) : (
        <p>
          <b> Pay Now </b> with an instant bank transfer
        </p>
      )}
    </div>
  );
};

function checkIsEligibleForPayLater(amount: number) {
  if (!amount) return true;

  if (amount >= MIN_AMOUNT_PAY_LATER && amount <= MAX_AMOUNT_PAY_LATER)
    return true;

  return false;
}

const PayLaterOption = ({
  isPreview = false,
  pricingPromotions,
  productInterest,
  onChange,
  duration,
}: {
  isPreview: boolean;
  pricingPromotions: string[];
  productInterest: TProductInterest;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  duration: TDuration;
}) => {
  const { sellerHandle } = useParams();
  const { seller } = useGetSellerByHandle(sellerHandle);
  const amount = useCurrentAmount();
  const debouncedAmount = useDebounce(amount, 500);
  const { isInStep0SplitTest } = useGetStep0SplitTest({
    shouldRunTest: !isPreview,
  });
  const { watch } = useFormContext();
  const watchPaymentOption = watch(PAYMENT_OPTION_INPUT_ID);
  const isChecked = watchPaymentOption === `payLater${duration}`;

  const { exampleMonthlyRepayment, totalInterest } = useRepaymentSchedule({
    amount: debouncedAmount,
    representativeInterest: productInterest.isSellerPays
      ? 0
      : productInterest.rate,
    duration,
    pricingPromotions,
  });

  const isEligibleForPayLater = checkIsEligibleForPayLater(
    parseInt(amount || ''),
  );

  if (!isEligibleForPayLater) {
    return (
      <PaymentOptionField
        isPreview={isPreview}
        value={`payLater${duration}`}
        label={
          <PayLaterRadioOptionLabel
            exampleMonthlyRepayment={exampleMonthlyRepayment}
            productInterest={productInterest}
            duration={duration}
          />
        }
        onChange={onChange}
      />
    );
  }

  return (
    <PaymentOptionField
      isPreview={isPreview}
      value={`payLater${duration}`}
      label={
        <>
          <PayLaterRadioOptionLabel
            exampleMonthlyRepayment={exampleMonthlyRepayment}
            productInterest={productInterest}
            duration={duration}
          />
          {isInStep0SplitTest && !seller?.capture_delay_hours && (
            <p className="text-s text-structure-40">
              Nothing to pay until {getOrdinalDateFromNow(30)}
            </p>
          )}
          {isInStep0SplitTest && !!seller?.capture_delay_hours && isChecked && (
            <CapturedPaymentNotice />
          )}
        </>
      }
      onChange={onChange}
    >
      <PayLaterFieldOption
        totalInterest={totalInterest}
        exampleMonthlyRepayment={exampleMonthlyRepayment}
        duration={duration}
      />
    </PaymentOptionField>
  );
};

const PayLaterRadioOptionLabel = ({
  exampleMonthlyRepayment,
  productInterest,
  duration,
}: {
  exampleMonthlyRepayment?: string;
  productInterest: TProductInterest;
  duration: TDuration;
}) => {
  const amount = useCurrentAmount();
  const isInterestFree = productInterest.isSellerPays;

  const interestType = () => {
    if (isInterestFree) {
      return 'interest-free';
    }
    return `${(productInterest.rate * 100).toFixed(2)}% /month ${
      productInterest.type === 'representative' ? 'rep.' : ''
    }`;
  };

  return (
    <div className={styles.paymentOptionLabel}>
      <PayLaterRadioOptionLabelText
        exampleMonthlyRepayment={exampleMonthlyRepayment}
        amount={amount}
        duration={duration}
      />
      <p className={styles.paymentOptionLabelInterestTypeSeperator}>|</p>
      <p
        className={classnames(styles.paymentOptionLabelInterestType, {
          [styles.interestFree]: isInterestFree,
        })}
      >
        {interestType()}
      </p>
    </div>
  );
};

const PayLaterRadioOptionLabelText = ({
  exampleMonthlyRepayment,
  amount,
  duration,
}: {
  exampleMonthlyRepayment?: string;
  amount?: string;
  duration: TDuration;
}) => {
  if (!amount) {
    return (
      <p>
        <b> Pay Later </b> for {duration} months
      </p>
    );
  }

  if (parseFloat(amount) < MIN_AMOUNT_PAY_LATER) {
    return (
      <p>
        <b> Pay over {duration} months </b> isn't available for under{' '}
        {displayCurrency(MIN_AMOUNT_PAY_LATER, 0)}
      </p>
    );
  }

  if (parseFloat(amount) > MAX_AMOUNT_PAY_LATER) {
    return (
      <p>
        <b> Pay over {duration} months </b> isn't available for over{' '}
        {displayCurrency(MAX_AMOUNT_PAY_LATER, 0)}
      </p>
    );
  }

  if (!exampleMonthlyRepayment) {
    return (
      <p>
        <b> Pay Later </b> for {duration} months
      </p>
    );
  }

  return (
    <p>
      <b> Pay {exampleMonthlyRepayment} </b> / month for {duration} months
    </p>
  );
};

const PayLaterFieldOption = ({
  totalInterest,
  exampleMonthlyRepayment,
  duration,
}: {
  totalInterest?: string;
  exampleMonthlyRepayment?: string;
  duration: TDuration;
}) => {
  const amount = useCurrentAmount();

  return (
    <>
      <div className={styles.payLaterDescription}>
        {(!amount || !totalInterest) &&
          `Split the cost into ${duration} automatic monthly payments, while your supplier gets paid today.`}

        {amount &&
          totalInterest === '£0.00' &&
          `Split the cost into ${duration} automatic monthly payments, while your supplier gets paid today.`}

        {amount &&
          totalInterest !== '£0.00' &&
          `Including ${totalInterest} interest for the full ${
            duration === 3 ? 90 : 365
          } days.`}
      </div>

      <RepaymentSchedule
        exampleMonthlyRepayment={exampleMonthlyRepayment}
        duration={duration}
      />

      <CapturedPaymentNotice />
    </>
  );
};

const CapturedPaymentNotice = () => {
  const { sellerHandle } = useParams();
  const { seller, loadingSeller } = useGetSellerByHandle(sellerHandle);

  if (loadingSeller || !seller || !seller.capture_delay_hours) return null;

  return (
    <div className={styles.capturedPaymentDescription}>
      <p className={styles.capturedPaymentSubheading}>
        Your payment schedule will begin once {seller.trading_name} has
        confirmed the order.{' '}
        <span className={styles.capturedPaymentSubheadingBold}>
          Your first payment will be 30 days after that date.
        </span>
      </p>
    </div>
  );
};

const NoSelection = ({
  buttonText,
  isDisabled = false,
}: {
  buttonText: string;
  isDisabled: boolean;
}) => {
  return (
    <div>
      <Button
        type="submit"
        className={styles.secureCheckoutButton}
        disabled={isDisabled}
      >
        {buttonText}
      </Button>
    </div>
  );
};

const PayNowSelected = () => {
  const { formState, trigger, register } = useFormContext();
  const { errors, touchedFields } = formState;
  const isSubmitting = formState.isSubmitting;
  const FULL_NAME_FIELD_ID = 'payNowFullName';
  const EMAIL_ADDRESS_FIELD_ID = 'payNowEmailAddress';
  const MARKETING_CONSENT_FIELD_ID = 'payNowMarketingConsent';

  const fieldsHaveBeenTouched =
    formState.touchedFields[FULL_NAME_FIELD_ID] &&
    formState.touchedFields[EMAIL_ADDRESS_FIELD_ID];

  const isSubmitDisabled =
    isSubmitting ||
    Object.keys(formState.errors).length !== 0 ||
    !fieldsHaveBeenTouched;

  return (
    <div>
      <div className="mb-l grid grid-cols-2 gap-l sm:grid-cols-1">
        <div>
          <HookFormInput
            label="Full name"
            type="text"
            name="payNowFullName"
            placeholder="Joe Bloggs"
            id={FULL_NAME_FIELD_ID}
            onChange={async () => {
              set(formState.touchedFields, FULL_NAME_FIELD_ID, true);
              await trigger(FULL_NAME_FIELD_ID);
            }}
            showError={false}
          />
          {touchedFields[FULL_NAME_FIELD_ID] && errors[FULL_NAME_FIELD_ID] && (
            <div className="mt-s text-s text-error">
              {(errors[FULL_NAME_FIELD_ID] as unknown as string) ||
                'Unknown error'}
            </div>
          )}
        </div>
        <div>
          <HookFormInput
            label="Email for receipt"
            type="email"
            name="payNowEmailAddress"
            placeholder="name@email.co.uk"
            id={EMAIL_ADDRESS_FIELD_ID}
            onChange={async () => {
              set(formState.touchedFields, EMAIL_ADDRESS_FIELD_ID, true);
              await trigger(EMAIL_ADDRESS_FIELD_ID);
            }}
            showError={false}
          />
          {touchedFields[EMAIL_ADDRESS_FIELD_ID] &&
            errors[EMAIL_ADDRESS_FIELD_ID] && (
              <div className="mt-s text-s text-error">
                {(errors[EMAIL_ADDRESS_FIELD_ID] as unknown as string) ||
                  'Unknown error'}
              </div>
            )}
        </div>
      </div>
      <CheckBox
        {...register(MARKETING_CONSENT_FIELD_ID)}
        id={MARKETING_CONSENT_FIELD_ID}
        label="Hear more about iwoca"
      />
      <p className="mb-xl mt-xl text-s text-structure-40">
        Email address will be used to send you your payment receipt
      </p>
      <Button
        type="submit"
        className={styles.secureCheckoutButton}
        disabled={isSubmitDisabled}
        loading={isSubmitting}
      >
        Finish checkout
      </Button>
    </div>
  );
};

function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      if (!isMountedRef) return;
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
