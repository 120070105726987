import { Accordion } from './Accordion/Accordion';
import styles from './HowWeUseYourInformation.module.css';
import { Button } from '../../../../components/Button/Button';
import BuildingsIcon from '../../../../components/svg/BuildingsIcon.svg?react';
import { InvoiceIcon } from '../../../../components/svg/InvoiceIcon';
import { BackButton } from '../../BackButton/BackButton';
import { ProgressBar } from '../../ProgressBar/ProgressBar';
import sellerXeroStyles from '../../Xero.module.css';

export const HowWeUseYourInformation = ({
  onNextClick,
  onBackClick,
}: {
  onNextClick: () => void;
  onBackClick: () => void;
}) => {
  return (
    <div className={sellerXeroStyles.contentContainer}>
      <BackButton onClick={onBackClick} />
      <div className={styles.content}>
        <ProgressBar totalSteps={3} currentStep={1} />
        <div className={styles.contentInner}>
          <div className={styles.textContent}>
            <h1>How we use your information</h1>
            <div className={styles.explanation}>
              We will access the following information stored on your Xero
              account for the purpose of your iwocaPay account:
            </div>
            <div className={styles.access}>
              <BuildingsIcon
                title="Business icon"
                className={styles.accessIcon}
              />
              Business details (inc.your business name, business address and
              contact details)
            </div>
            <div className={styles.access}>
              <InvoiceIcon title="Invoice icon" className={styles.accessIcon} />
              Invoice details (inc. your branding themes, invoice amounts and
              customer contact details)
            </div>
            <Accordion title="Will I be charged for this connection?">
              <p>
                <strong>You will not be charged for this connection,</strong>{' '}
                but Xero may receive a fee from iwocaPay for facilitating it. By
                continuing with this process, you are consenting to the payment
                by iwocaPay of such fee.
              </p>
            </Accordion>
            <Accordion title="How is my data stored?">
              <p>
                If you successfully connect iwocaPay with your Xero account, we
                will have access to the above data until you revoke our access
                through your Xero account.{' '}
                <strong>
                  Your credentials are encrypted and we will never store your
                  password.
                </strong>
              </p>
            </Accordion>
            <Accordion title="What data is shared?">
              <p>
                If you agree, we will share records of your iwocaPay account
                with Xero, including details of payments we process for you.{' '}
                <strong>
                  Any information shared with Xero will be retained in your Xero
                  account and treated in accordance with Xero’s standard terms
                  of use.
                </strong>
              </p>
            </Accordion>
            <Accordion title="Your agreement">
              <>
                <p>
                  <strong>
                    By continuing, you acknowledge that Xero’s role in iwoca’s
                    services is limited to providing a direct data feed to iwoca{' '}
                  </strong>
                  from your subscription to Xero to enable you to conveniently
                  use iwocaPay without manually retyping data, and to update
                  your Xero profile without manually retyping data.
                </p>
                <p>
                  <strong>
                    Xero is not responsible for, and has no liability to you or
                    iwoca in respect of, the accuracy of the data or the
                    suitability of any financial product{' '}
                  </strong>
                  or service provided by iwoca or a connected party for your
                  needs and has no other involvement in iwoca’s services other
                  than the provision of the data.
                </p>
              </>
            </Accordion>
          </div>
        </div>
        <Button className={sellerXeroStyles.button} onClick={onNextClick}>
          Connect your Xero account
        </Button>
      </div>
    </div>
  );
};
