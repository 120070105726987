import React, { useEffect } from 'react';

import {
  fetchGetStateByStateKey,
  GetStateResponse,
} from '@iwoca/lapi-client/edge';
import { useQuery } from '@tanstack/react-query';

import { useRedirectToLatestStep } from './useRedirectToLatestStep';
import { useStateKey } from '../../../hooks/useStateKey.hook';
import { normaliseStepData } from '../stateNormalisation';
import { useRedirectToMyAccount } from '../useRedirectToAccountPages';

export function useGetStateQuery<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SelectFnType extends (data: GetStateResponse) => any,
>(
  selectFn: SelectFnType,
  {
    onSuccess,
  }: {
    onSuccess?: (data: GetStateResponse) => void;
  } = {},
) {
  const { stateKey } = useStateKey();

  const { data, isLoading, isSuccess, refetch } = useQuery({
    queryKey: ['fetchGetState'],
    queryFn: () => fetchGetStateByStateKey({ stateKey: stateKey! }),
    select: selectFn,
    enabled: Boolean(stateKey),
  });

  useEffect(() => {
    if (isSuccess) {
      onSuccess?.(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return {
    data: data as ReturnType<SelectFnType>,
    refetch,
    isLoading,
  };
}

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;
export type NormalisedDataType = DeepPartial<
  ReturnType<typeof normaliseStepData>
>;

export const StepValidator = ({ children }: { children: React.ReactNode }) => {
  useRedirectToLatestStep();
  useRedirectToMyAccount();

  return <>{children}</>;
};
