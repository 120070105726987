import { SVGAttributes } from 'react';

export function ExclamationMarkCircledIcon(
  props: SVGAttributes<HTMLOrSVGElement>,
) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9" fill="#DC1005" stroke="#DC1005" />
      <path
        d="M10 5C10.5523 5 11 5.44772 11 6L11 12C11 12.5523 10.5523 13 10 13C9.44772 13 9 12.5523 9 12L9 6C9 5.44772 9.44772 5 10 5Z"
        fill="white"
      />
      <circle cx="10" cy="15" r="0.5" fill="white" stroke="white" />
    </svg>
  );
}
