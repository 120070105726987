import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './BusinessType.module.css';
import { businessTypeValidator } from './businessTypeValidator';
import { SoleTraderOption } from './SoleTraderOption/SoleTraderOption';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { SubmitButton } from '../../../components/Button/Button';
import { InlineButton } from '../../../components/InlineButton/InlineButton';
import { useSoleTraderPilot } from '../../../hooks/useSoletraderPilot';
import { HookFormRadioButton } from '../../components/RadioButton/RadioButton';
import { StepCard } from '../../components/StepCard/StepCard';
import { BUSINESS_DETAILS_STEP_ID, COMPANY_TYPE_STEP_ID } from '../Steps';
import { useNavigateToStep } from '../useNavigateToStep';
import { useStepData } from '../useStepData';
import { useStepStatus } from '../useStepStatus';

export const BUSINESS_TYPE_OPTIONS = [
  {
    label: 'Limited Company',
    value: 'limited_liability_company',
  },
  {
    label: 'Limited Liability Partnership',
    value: 'limited_liability_partnership',
  },
  { label: 'Sole Trader', value: 'sole_trader' },
] as const;

export const BusinessType = () => {
  const { stepData, updateStepData, isUpdatingStepData } =
    useStepData(COMPANY_TYPE_STEP_ID);
  const stepStatus = useStepStatus(COMPANY_TYPE_STEP_ID);
  const isActive = stepStatus === 'active';
  const isCompleted = stepStatus === 'complete';

  const goToCurrentStep = useNavigateToStep(COMPANY_TYPE_STEP_ID);
  const goToNextStep = useNavigateToStep(BUSINESS_DETAILS_STEP_ID);

  const methods = useForm({
    defaultValues: stepData,
    resolver: yupResolver(businessTypeValidator),
  });

  const companyType = methods.watch('companyType');
  const isSoleTrader = companyType === 'sole_trader';
  const isSoleTraderPilot = useSoleTraderPilot();
  const showSoleTraderPayNowButton = isSoleTrader && !isSoleTraderPilot;

  const handleSubmit = async (values: typeof stepData) => {
    await updateStepData(values);
    goToNextStep();
  };

  const onSubmit = methods.handleSubmit(handleSubmit);

  return (
    <StepCard title="1. Business type" isCompleted={isCompleted}>
      <FormProvider {...methods}>
        {isActive ? (
          <form onSubmit={onSubmit}>
            <HookFormRadioButton
              labelText="What type of business are you?"
              name="companyType"
              options={BUSINESS_TYPE_OPTIONS}
            />
            {showSoleTraderPayNowButton ? (
              <SoleTraderOption />
            ) : (
              <SubmitButton
                disabled={isUpdatingStepData}
                className={styles.button}
              >
                {!isUpdatingStepData ? (
                  'Continue to business info'
                ) : (
                  <LoadingSpinner />
                )}
              </SubmitButton>
            )}
          </form>
        ) : (
          <div>
            <div className={styles.summary}>
              {
                BUSINESS_TYPE_OPTIONS.find(({ value }) => value === companyType)
                  ?.label
              }
            </div>
            <InlineButton onClick={goToCurrentStep}>Edit</InlineButton>
          </div>
        )}
      </FormProvider>
    </StepCard>
  );
};
