import TrustPilotLogo from './assets/TrustPilotLogo.svg?react';
import TrustPilotStars from './assets/TrustPilotStars.svg?react';
import styles from './TrustPilotBadge.module.css';

export const TrustPilotBadge = () => {
  return (
    <div className={styles.trustPilotContainer}>
      <div className="flex flex-wrap items-center gap-s text-m font-med no-underline">
        Excellent
        <div>
          <TrustPilotStars />
          <TrustPilotLogo />
        </div>
        <span className="text-xs">· 7,000+ reviews</span>
      </div>
    </div>
  );
};
