import React from 'react';

import { Outlet } from 'react-router-dom';

import styles from './router.module.css';
import { AuthenticatedSellerTemplate } from './templates';
import {
  NewRoutesWrapperArrayType,
  NewRoutesWrapperType,
  SafeNavigate,
} from './utils';
import { DynamicPageTemplate } from '../components/PageTemplate/PageTemplate';
import { AccessUsers } from '../Seller/AccessUsers/AccessUsers';
import { Dashboard } from '../Seller/Dashboard/Dashboard';
import { IntegrationsDashboard } from '../Seller/Integrations/components/IntegrationsDashboard/IntegrationsDashboard';
import { WebhooksPage } from '../Seller/Integrations/components/Webhooks/Webhooks';
import { CredentialsPage } from '../Seller/Integrations/CredentialsPage/CredentialsPage';
import { ManageAccount } from '../Seller/ManageAccount/ManageAccount';
import { OneTimeLinksPage } from '../Seller/PayLinks/OneTimeLinksPage/OneTimeLinksPage';
import { PackageLinkPage } from '../Seller/PayLinks/PackageLinkPage/PackageLinkPage';
import { PayLinksNavigation } from '../Seller/PayLinks/PayLinksNavigation';
import { ReusablePaylinkPage } from '../Seller/PayLinks/ReusablePaylinkPage/ReusablePaylinkPage';
import { Payments } from '../Seller/Payments/Payments';
import { Rewards } from '../Seller/Rewards/Rewards';
import { SpendingLimitRequests } from '../Seller/SpendingLimitRequests/SpendingLimitRequests';
import { Account } from '../Seller/Xero/Account/Account';
import { Authorisation } from '../Seller/Xero/Authorisation/Authorisation';
import { Connect } from '../Seller/Xero/Connect/Connect';
import { InvoiceBrandingTheme } from '../Seller/Xero/InvoiceBrandingTheme/InvoiceBrandingTheme';
import { PaymentAccount } from '../Seller/Xero/PaymentAccount/PaymentAccount';
import { SuccessfullyLinked } from '../Seller/Xero/SuccessfullyLinked/SuccessfullyLinked';
import { EvaluateXeroStep } from '../Seller/Xero/Xero';

export const redirectRoutes = [
  {
    element: (
      <div className={styles.router}>
        <Outlet />
      </div>
    ),
    children: [
      {
        path: 'contract/update',
        element: <SafeNavigate to="/pay/pay-links/universal" />,
      },
      {
        path: 'onboarding/*',
        element: <SafeNavigate to="/pay/activation" />,
      },
      {
        path: 'pay-links',
        element: <SafeNavigate to="/pay/pay-links/universal" />,
      },
    ],
  },
];

const ACTIVATION_NOT_ONBOARDED = {
  wrapper: (
    <div className={styles.router}>
      <Outlet />
    </div>
  ),
  paths: [
    {
      path: 'activation',
      lazy: async () => {
        const { AccountActivationPage } = await import('./seller.pages');
        return {
          element: <AccountActivationPage />,
        };
      },
    },
    {
      path: 'seller/not-onboarded',
      lazy: async () => {
        const { NotOnboardedPage } = await import('./seller.pages');
        return {
          element: <NotOnboardedPage />,
        };
      },
    },
  ],
} as const satisfies NewRoutesWrapperType;

// OLD - TO BE CONVERTED TO ABOVE METHOD

const AUTHENTICATED_DASHBOARD = {
  wrapper: (
    <AuthenticatedSellerTemplate>
      <div className={styles.router}>
        <Outlet />
      </div>
    </AuthenticatedSellerTemplate>
  ),
  paths: [
    {
      path: 'dashboard',
      element: <Dashboard />,
    },
  ],
} as const satisfies NewRoutesWrapperType;

const AUTHENTICATED_SELLER_DASHBOARD_PAYLINKS = {
  wrapper: (
    <AuthenticatedSellerTemplate>
      <div className={styles.router}>
        <Outlet />
      </div>
    </AuthenticatedSellerTemplate>
  ),
  paths: [
    {
      path: 'payments',
      element: <Payments />,
    },
  ],
} as const satisfies NewRoutesWrapperType;

const AUTHENTICATED_SELLER_DASHBOARD_SPENDING_LIMIT = {
  wrapper: (
    <AuthenticatedSellerTemplate>
      <div className={styles.router}>
        <Outlet />
      </div>
    </AuthenticatedSellerTemplate>
  ),
  paths: [
    {
      path: 'spending-limits',
      element: <SpendingLimitRequests />,
    },
  ],
} as const satisfies NewRoutesWrapperType;

const AUTHENTICATED_SELLER_DASHBOARD_MANAGE_USERS = {
  wrapper: (
    <AuthenticatedSellerTemplate>
      <div className={styles.router}>
        <Outlet />
      </div>
    </AuthenticatedSellerTemplate>
  ),
  paths: [
    {
      path: 'manage-users',
      element: <AccessUsers />,
      accessibleByGroups: ['customers', 'iwocapay_seller_admin'],
    },
  ],
} as const satisfies NewRoutesWrapperType;

const AUTHENTICATED_REWARDS = {
  wrapper: (
    <AuthenticatedSellerTemplate>
      <div className={styles.router}>
        <DynamicPageTemplate wideContent={true}>
          <Outlet />
        </DynamicPageTemplate>
      </div>
    </AuthenticatedSellerTemplate>
  ),
  paths: [
    {
      path: 'rewards',
      element: <Rewards />,
    },
  ],
} as const satisfies NewRoutesWrapperType;

const AUTHENTICATED_PAYLINKS = {
  wrapper: (
    <AuthenticatedSellerTemplate>
      <div className={styles.router}>
        <DynamicPageTemplate wideContent={true}>
          <>
            <PayLinksNavigation />
            <Outlet />
          </>
        </DynamicPageTemplate>
      </div>
    </AuthenticatedSellerTemplate>
  ),
  paths: [
    {
      path: 'pay-links/universal',
      element: <ReusablePaylinkPage />,
    },
    {
      path: 'pay-links/package',
      element: <PackageLinkPage />,
    },
    {
      path: 'pay-links/custom',
      element: <OneTimeLinksPage />,
    },
  ],
} as const satisfies NewRoutesWrapperType;

const AUTHENTICATED_XERO = {
  wrapper: (
    <AuthenticatedSellerTemplate>
      <div className={styles.router}>
        <DynamicPageTemplate>
          <EvaluateXeroStep>
            <div className={styles.router}>
              <Outlet />
            </div>
          </EvaluateXeroStep>
        </DynamicPageTemplate>
      </div>
    </AuthenticatedSellerTemplate>
  ),
  paths: [
    {
      path: 'xero/connect',
      element: <Connect />,
    },
    {
      path: 'xero/authorisation',
      element: <Authorisation />,
    },
    {
      path: 'xero/payment-account',
      element: <PaymentAccount />,
    },
    {
      path: 'xero/invoice-branding-theme',
      element: <InvoiceBrandingTheme />,
    },
    {
      path: 'xero/account',
      element: <Account />,
    },
    {
      path: 'xero/success',
      element: <SuccessfullyLinked />,
    },
    {
      path: 'xero/introduction', // we rely on this path to redirect automatically
      element: <></>,
    },
  ],
} as const satisfies NewRoutesWrapperType;

const AUTHENTICATED_INTEGRATIONS = {
  wrapper: (
    <AuthenticatedSellerTemplate>
      <div className={styles.router}>
        <DynamicPageTemplate>
          <Outlet />
        </DynamicPageTemplate>
      </div>
    </AuthenticatedSellerTemplate>
  ),
  paths: [
    {
      path: 'integrations/:integrationParam',
      element: <IntegrationsDashboard />,
      accessibleByGroups: [
        'customers',
        'iwocapay_seller_admin',
        'iwocapay_seller_editor',
      ],
    },
    {
      path: 'integrations',
      element: <IntegrationsDashboard />,
      accessibleByGroups: [
        'customers',
        'iwocapay_seller_admin',
        'iwocapay_seller_editor',
      ],
    },
    {
      path: 'integrations/credentials',
      element: <CredentialsPage />,
      accessibleByGroups: [
        'customers',
        'iwocapay_seller_admin',
        'iwocapay_seller_editor',
      ],
    },
    {
      path: 'integrations/webhooks',
      element: <WebhooksPage />,
      accessibleByGroups: [
        'customers',
        'iwocapay_seller_admin',
        'iwocapay_seller_editor',
      ],
    },
  ],
} as const satisfies NewRoutesWrapperType;

const AUTHENTICATED_SELLER_MANAGE_ACCOUNT = {
  wrapper: (
    <AuthenticatedSellerTemplate>
      <div className={styles.router}>
        <DynamicPageTemplate wideContent={true}>
          <Outlet />
        </DynamicPageTemplate>
      </div>
    </AuthenticatedSellerTemplate>
  ),
  paths: [
    {
      path: 'manage-account',
      element: <ManageAccount />,
    },
  ],
} as const satisfies NewRoutesWrapperType;

export const SELLER_ROUTES = [
  AUTHENTICATED_SELLER_DASHBOARD_PAYLINKS,
  AUTHENTICATED_DASHBOARD,
  AUTHENTICATED_SELLER_DASHBOARD_SPENDING_LIMIT,
  AUTHENTICATED_PAYLINKS,
  AUTHENTICATED_REWARDS,
  AUTHENTICATED_XERO,
  AUTHENTICATED_INTEGRATIONS,
  AUTHENTICATED_SELLER_DASHBOARD_MANAGE_USERS,
  AUTHENTICATED_SELLER_MANAGE_ACCOUNT,
  ACTIVATION_NOT_ONBOARDED,
] as const satisfies NewRoutesWrapperArrayType;
