import React, { useState } from 'react';

import {
  LapiError,
  fetchPostSellerAccessUser,
} from '@iwoca/lapi-client/iwocapay';
import { Button } from '@iwoca/orion';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import styles from './AccessUserDetails.module.css';
import { useGetSellerAccessUsers } from '../../../../api/lending/lapiHooks';
import CrossIcon from '../../../../assets/CrossIcon.svg';
import TickIcon from '../../../../assets/TickIcon.svg';
import { Dropdown } from '../../../../components/Dropdown/Dropdown';
import { Input } from '../../../../components/Input/Input';
import { InputError } from '../../../../components/InputError/InputError';
import { useStateKey } from '../../../../hooks/useStateKey.hook';

const ACCESS_USER_INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  accessLevel: '',
};

const accessUserValidator = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  accessLevel: Yup.string().required('Access level is required'),
});

const isFeatureFlag = () => {
  return localStorage.viewerUser === 'true';
};

export const AccessUserForm = ({
  onSuccess,
  onCancel,
}: {
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const { stateKey } = useStateKey();
  const [emailErrors, setEmailErrors] = useState<string | null>(null);
  const { accessUsers } = useGetSellerAccessUsers();

  const validateAccessUserEmail = ({
    emailAddress,
  }: {
    emailAddress: string;
  }) => {
    const isEmailValid = Yup.string()
      .email()
      .required()
      .isValidSync(emailAddress);

    if (!isEmailValid) {
      setEmailErrors('Please enter a valid email address');
      return;
    }

    const accessUserEmailList = accessUsers.map((a) => a.email);
    if (accessUserEmailList.includes(emailAddress)) {
      setEmailErrors('This user is already added to this business');
      return;
    }

    setEmailErrors(null);
  };

  if (!stateKey) {
    onCancel();
    return null;
  }

  return (
    <div>
      <Formik
        initialValues={ACCESS_USER_INITIAL_VALUES}
        validationSchema={accessUserValidator}
        onSubmit={async (values, { resetForm }) => {
          try {
            await fetchPostSellerAccessUser({
              stateKey,
              body: {
                email_address: values.emailAddress,
                first_name: values.firstName,
                last_name: values.lastName,
                // @ts-expect-error - string doesn't satisfy lapi
                group_name: values.accessLevel,
              },
            });
            resetForm();
            onSuccess();
          } catch (err: unknown) {
            if (err instanceof LapiError) {
              const response = JSON.parse((err as LapiError).message);
              const firstError =
                response['errors'][0]['detail'] || 'Unknown error.';
              setEmailErrors(`Unable to add user: ${firstError}`);
            }
          }
        }}
      >
        <Form className={styles.form}>
          <Input
            className={styles.nameInput}
            name="firstName"
            label="First name"
          />
          <Input
            className={styles.nameInput}
            name="lastName"
            label="Last name"
          />
          <div className={styles.emailInput}>
            <Input
              name="emailAddress"
              label="Email address"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                validateAccessUserEmail({
                  emailAddress: e.target.value,
                })
              }
            />
            <InputError isVisible={emailErrors !== null} error={emailErrors} />
          </div>
          <Dropdown
            className={styles.nameInput}
            name="accessLevel"
            labelText="Access level"
            placeholder="Choose an option"
          >
            <option value="iwocapay_seller_admin">Admin</option>
            <option value="iwocapay_seller_editor">Editor</option>
            <option value="iwocapay_seller_contributor">Contributor</option>
            {isFeatureFlag() && (
              <option value="iwocapay_seller_viewer">Viewer</option>
            )}
          </Dropdown>
          <div className={styles.buttonsWrapper}>
            <Button
              type="submit"
              variant="tertiary"
              className={styles.submitButton}
              aria-label="tick-button"
              disabled={emailErrors !== null}
            >
              <img src={TickIcon} alt="Tick icon" />
            </Button>
            <Button
              variant="tertiary"
              className={styles.submitButton}
              aria-label="cross-button"
              onClick={onCancel}
            >
              <img src={CrossIcon} alt="Cross icon" />
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
