import MagentoLogo from './assets/magentoLogo.svg';
import QuickBooksLogo from './assets/quickBooksLogo.svg';
import ShopifyLogo from './assets/shopifyLogo.svg';
import WooLogo from './assets/wooLogo.svg';
import XeroLogo from './assets/xeroLogo.svg';
import { MagentoBody } from './components/Magento/MagentoBody';
import { QuickBooksBody } from './components/QuickBooks/QuickBooks';
import { ShopifyBody } from './components/Shopify/ShopifyBody';
import { WooCommerceBody } from './components/Woocommerce/WooCommerceBody';
import { XeroBody } from './components/Xero/XeroBody';
import { Integration } from './utils/integrations.types';

export const QuickBooks: Integration = {
  disabled: false,
  id: 'quickbooks',
  company_name: 'QuickBooks',
  company_type: 'Accounting',
  company_logo_svg: QuickBooksLogo,
  body: QuickBooksBody,
};

export const Xero: Integration = {
  disabled: false,
  id: 'xero',
  company_name: 'Xero',
  company_type: 'Accounting',
  company_logo_svg: XeroLogo,
  body: XeroBody,
};

export const WooCommerce: Integration = {
  disabled: false,
  id: 'woocommerce',
  company_name: 'WooCommerce',
  company_type: 'Ecommerce',
  company_logo_svg: WooLogo,
  body: WooCommerceBody,
};

export const Magento: Integration = {
  disabled: false,
  id: 'magento',
  company_name: 'Magento 2',
  company_type: 'Ecommerce',
  company_logo_svg: MagentoLogo,
  body: MagentoBody,
};

export const Shopify: Integration = {
  disabled: false,
  id: 'shopify',
  company_name: 'Shopify',
  company_type: 'Ecommerce',
  company_logo_svg: ShopifyLogo,
  body: ShopifyBody,
};

// These are the order they will be rendered
export const integrationsList = [
  Magento,
  Shopify,
  WooCommerce,
  QuickBooks,
  Xero,
];
