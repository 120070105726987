import { Button } from '../../../components/Button/Button';
import { IwocaXeroErrorGraphic } from '../../../components/svg/Graphics/IwocaXeroErrorGraphic';
import { iwocapayTelDisplay } from '../../../constants.json';
import sellerXeroStyles from '../Xero.module.css';

export function TryAgainError() {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <>
      <div className={sellerXeroStyles.textContent}>
        <h1>
          Sorry it seems like that didn't work, please try again or call us on{' '}
          {iwocapayTelDisplay}
        </h1>
      </div>
      <div className={sellerXeroStyles.errorGraphicContainer}>
        <IwocaXeroErrorGraphic />
      </div>
      <Button className={sellerXeroStyles.button} onClick={refreshPage}>
        Try Again!
      </Button>
    </>
  );
}
