import React from 'react';

import { Activation } from '../activation/Activation';
import { RequireAuthenticatedSeller } from '../auth/Auth';
import { IsSellerBlocked } from '../auth/IsSellerBlocked';
import { SellerXeroContextProvider } from '../Seller/Xero/Xero.context';

export const AuthenticatedSellerTemplate = ({
  children,
}: {
  children: JSX.Element;
}) => {
  return (
    <RequireAuthenticatedSeller>
      <Activation>
        <IsSellerBlocked>
          <SellerXeroContextProvider>{children}</SellerXeroContextProvider>
        </IsSellerBlocked>
      </Activation>
    </RequireAuthenticatedSeller>
  );
};
