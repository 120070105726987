import { definitions } from '@iwoca/lapi-client/edge';

import { useGetAccounts } from '../../../api/lending/lapiHooks';

export const useGetANTFormalOffers = () => {
  const { accounts, loadingAccounts } = useGetAccounts();

  const antOffers: definitions['loan_account']['latest_formal_offer'][] =
    accounts?.accounts.flatMap((account) => {
      if (account.loan_offer_id !== account.latest_formal_offer?.offer_id) {
        return account.latest_formal_offer?.is_valid
          ? account.latest_formal_offer
          : [];
      }
      return [];
    }) || [];

  return {
    offers: antOffers,
    isLoading: loadingAccounts,
  };
};
