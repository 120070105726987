import { yupResolver } from '@hookform/resolvers/yup';
import {
  fetchPostOnfidoStart,
  GetStateByStateKeyResponse,
  GetStateResponse,
} from '@iwoca/lapi-client/edge';
import { Button } from '@iwoca/orion';
import { cloneDeep, set } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import styles from './IDCheck.module.css';
import { CustomerPhone, CustomerStatus } from './IDCheckNotComplete';
import { ID_CHECK_PENDING_MODAL } from './IDCheckPendingModal';
import { phoneNumberValidator } from './PhoneNumberValidator';
import {
  useGetStateByStateKey,
  usePutState,
} from '../../../../api/lending/lapiHooks';
import { HookFormInput } from '../../../../components/Input/Input';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import Onfido from '../../../../components/svg/Onfido.svg';
import { useStateKey } from '../../../../hooks/useStateKey.hook';
import { useModal } from '../../../store/ModalProvider';

export const PhoneNumberForm = ({
  applicantUID,
  applicantStatus,
  onError,
}: {
  applicantUID: string | undefined;
  applicantStatus: CustomerStatus;
  onError: () => void;
}) => {
  const { stateKey } = useStateKey();
  const { state, refetchState } = useGetStateByStateKey();
  const { putState } = usePutState();
  const { openModal } = useModal(ID_CHECK_PENDING_MODAL);

  if (!state) {
    throw new Error('No state was provided');
  }
  const primaryPhone = getCustomerPrimaryPhone(state, applicantUID);

  const sendSmsButtonText =
    applicantStatus === 'error' ? 'Try again' : 'Send text';

  const addPhoneNumberToStateObject = (
    phoneNumber: string,
    customerUID: string | undefined,
    state: GetStateResponse['data'] | null,
  ) => {
    const newState = cloneDeep(state);

    if (!newState || !newState.application?.people) {
      throw new Error('Wrong customer state provided.');
    }

    const people = newState?.application?.people;
    const customerIndex = people?.findIndex(
      (person) => person.uid === customerUID,
    );

    if (customerIndex === undefined || customerIndex < 0) {
      throw new Error('Did not find the customer in state by his UID.');
    }
    const customer = people[customerIndex];
    const customerPhones = customer.phones || [];

    const existingPhoneNumber = customerPhones?.find(
      (phone) => phone.number === phoneNumber,
    );

    if (existingPhoneNumber) {
      return { customerPhone: existingPhoneNumber, newState };
    }

    const newPhoneObject: CustomerPhone = {
      number: phoneNumber,
      type: 'secondary',
      uid: uuidv4(),
    };

    const newPhones = [...customerPhones, newPhoneObject];
    set(newState, `application.people[${customerIndex}].phones`, newPhones);

    return { customerPhone: newPhoneObject, newState };
  };

  const sendSMSWithVerificationLink = async (
    stateKey: string | null,
    customerUID: string | undefined,
    phoneNumberUID: string | undefined,
  ) => {
    try {
      await fetchPostOnfidoStart({
        stateKey: stateKey!,
        body: {
          data: { person_uid: customerUID!, phone_uid: phoneNumberUID! },
        },
      });
      openModal();
    } catch (_) {
      onError();
    }
  };

  const handleSubmit = async ({ phoneNumber }: { phoneNumber: string }) => {
    const { data } = await refetchState();
    const { customerPhone, newState } = addPhoneNumberToStateObject(
      phoneNumber,
      applicantUID,
      data!.data,
    );

    await putState({ data: newState! });
    await sendSMSWithVerificationLink(
      stateKey,
      applicantUID,
      customerPhone?.uid,
    );
  };

  const methods = useForm({
    defaultValues: { phoneNumber: primaryPhone?.number || '' },
    resolver: yupResolver(phoneNumberValidator),
  });

  const onSubmit = methods.handleSubmit(handleSubmit);
  const { isSubmitting, isValidating } = methods.formState;

  return (
    <>
      <p className={styles.cardText}>
        You'll need to do this on your phone. Just pop your phone number below,
        and we'll text you a link.
      </p>
      <FormProvider {...methods}>
        <form
          onSubmit={onSubmit}
          autoComplete="off"
          noValidate
          className={styles.form}
        >
          <div className={styles.phoneNumberInput}>
            <HookFormInput
              name="phoneNumber"
              label="Mobile number"
              className={styles.phoneNumberInputField}
            />
          </div>
          <div className={styles.phoneNumberSubmit}>
            <Button
              variant="primary"
              type="submit"
              className={styles.desktopVerificationButton}
            >
              {!(isSubmitting || isValidating) ? (
                sendSmsButtonText
              ) : (
                <LoadingSpinner />
              )}
            </Button>
            <div className={styles.desktopOnfidoLabel}>
              Secured and powered by{' '}
              <img
                src={Onfido}
                alt="Onfido logo"
                className={styles.onfidoIcon}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export function getCustomerPrimaryPhone(
  state: GetStateByStateKeyResponse['data'] | null,
  applicantUID: string | undefined,
) {
  if (!state || !applicantUID) {
    return;
  }

  const people = state?.application?.people?.filter(
    (person) => person.uid === applicantUID,
  );
  const customerPhones = people && people[0].phones;
  const primaryPhone = customerPhones?.filter(
    (phone) => phone.type === 'primary',
  )[0];

  return primaryPhone;
}
