import { useContext, useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import classnames from 'classnames';
import { Form, Formik } from 'formik';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Select, { ActionMeta, components } from 'react-select';

import { useSelectXeroThemes } from './Account.hook';
import styles from './Account.module.css';
import { MultiDropdownStyle } from './MultiDropdown.style';
import { fetchGetXeroAccountsIwocapaySeller } from '../../../api/lending/edge';
import { buildQueryString } from '../../../Buyer/utils/queryParams';
import { Button } from '../../../components/Button/Button';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { LinkedIcon } from '../../../components/svg/LinkedIcon';
import { UnlinkedIcon } from '../../../components/svg/UnlinkedIcon';
import { useStateKey } from '../../../hooks/useStateKey.hook';
import { SellerXeroContext } from '../Xero.context';
import { contextLoadedSuccessfully } from '../Xero.helpers';
import sellerXeroStyles from '../Xero.module.css';
import { DropdownOption, PaymentAccount } from '../Xero.types';

export function Account() {
  const navigate = useNavigate();
  const { stateKey } = useStateKey();
  const [selectedPaymentAccount, setSelectedPaymentAccount] =
    useState<PaymentAccount>();
  const sellerXeroContext = useContext(SellerXeroContext);
  const {
    selectedThemeOptions,
    setSelectedThemeOptions,
    clearThemes,
    themeOptions,
    hasThemesSelectionChanged,
    saveSelectedThemes,
  } = useSelectXeroThemes();

  const themesAreClearable =
    selectedThemeOptions &&
    selectedThemeOptions.some((theme) => !theme.isFixed);

  const companyNameDisabledOption = contextLoadedSuccessfully(sellerXeroContext)
    ? {
        label: sellerXeroContext.organisationName,
        value: sellerXeroContext.organisationName,
      }
    : undefined;

  const selectedPaymentAccountId =
    sellerXeroContext?.xeroAccount?.payment_account?.payment_account_id;

  const { data: xeroPaymentAccounts } = useQuery({
    queryKey: ['fetchGetXeroAccountsIwocapaySeller'],
    queryFn: () => fetchGetXeroAccountsIwocapaySeller(stateKey!),
    enabled:
      !!stateKey &&
      !!selectedPaymentAccountId &&
      contextLoadedSuccessfully(sellerXeroContext),
  });

  useEffect(() => {
    const getSelectedPaymentAccount = () => {
      if (!contextLoadedSuccessfully(sellerXeroContext)) return;

      if (!selectedPaymentAccountId) return;

      if (!xeroPaymentAccounts) return;

      const selectedPaymentAccount = xeroPaymentAccounts.data.accounts.find(
        (account) => account.account_id === selectedPaymentAccountId,
      );

      setSelectedPaymentAccount(selectedPaymentAccount);
    };

    void getSelectedPaymentAccount();
  }, [selectedPaymentAccountId, sellerXeroContext, xeroPaymentAccounts]);

  const unlinkXero = async () => {
    if (
      contextLoadedSuccessfully(sellerXeroContext) &&
      sellerXeroContext.xeroAccount?.tenant
    ) {
      const response = await fetch(
        `/api/lending/edge/xero/connections/iwocapay_seller/${sellerXeroContext.stateKey}/`,
        {
          method: 'DELETE',
          credentials: 'same-origin',
          headers: { 'x-csrftoken': Cookies.get('csrftoken') as string },
        },
      );

      if (response.ok) {
        const queryString = buildQueryString();
        navigate && navigate('/pay/pay-links/' + queryString);
      }
    }
  };

  const handleXeroThemeSelectChange = (
    currSelectedXeroThemes: DropdownOption[],
    { action }: ActionMeta<unknown>,
  ) => {
    if (action === 'clear') {
      clearThemes();
    } else {
      setSelectedThemeOptions(currSelectedXeroThemes);
    }
  };

  return (
    <Formik onSubmit={saveSelectedThemes} initialValues={{ something: false }}>
      <Form>
        <div className={sellerXeroStyles.sellerXero}>
          <div className={styles.content}>
            <div className={styles.contentInner}>
              <div className={styles.information}>
                <div className={styles.heading}>
                  <LinkedIcon />
                  <h1>You're linked with Xero!</h1>
                </div>
                <div className={styles.informationText}>
                  You can update which invoice theme has a Pay with iwocaPay
                  option within Xero or by re-linking your account.{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className={sellerXeroStyles.link}
                    href="https://support.iwoca.co.uk/en/articles/4670005-connecting-your-xero-account-to-iwocapay"
                  >
                    Find out more in our Xero guide.
                  </a>
                </div>
                <div className={styles.selectContainer}>
                  <Dropdown
                    name="xeroAccountOwner"
                    labelText="Xero account owner"
                    className={classnames(
                      styles.dropdown,
                      sellerXeroStyles.dropdown,
                    )}
                    placeholder="Loading..."
                    disabled={true}
                    value={companyNameDisabledOption?.value}
                  >
                    <option value={companyNameDisabledOption?.value}>
                      {companyNameDisabledOption?.label}
                    </option>
                  </Dropdown>
                </div>
                <div className={styles.selectContainer}>
                  <Dropdown
                    name="linkedPaymentAccount"
                    labelText="Linked payment account"
                    className={classnames(
                      styles.dropdown,
                      sellerXeroStyles.dropdown,
                    )}
                    placeholder="Loading..."
                    disabled={true}
                    value={selectedPaymentAccount?.account_id}
                  >
                    <option value={selectedPaymentAccount?.account_id}>
                      {selectedPaymentAccount?.name}
                    </option>
                  </Dropdown>
                </div>
                <div className={styles.selectContainer}>
                  <h2>Linked invoice themes</h2>
                  <Select
                    isMulti
                    className={classnames(
                      styles.dropdown,
                      sellerXeroStyles.dropdown,
                    )}
                    placeholder={
                      themeOptions !== undefined
                        ? 'Select a theme'
                        : 'Loading...'
                    }
                    value={selectedThemeOptions}
                    styles={MultiDropdownStyle}
                    onChange={(selectedOptions, values) =>
                      handleXeroThemeSelectChange(
                        selectedOptions as DropdownOption[],
                        values,
                      )
                    }
                    options={themeOptions}
                    isClearable={themesAreClearable}
                    backspaceRemovesValue={false}
                    components={{
                      ClearIndicator: (props) => {
                        return (
                          <div title="Clear icon">
                            <components.ClearIndicator {...props} />
                          </div>
                        );
                      },
                    }}
                  />
                  <a
                    className={sellerXeroStyles.link}
                    href="https://central.xero.com/s/article/Add-edit-or-delete-custom-invoice-quote-templates-UK"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Add a new theme in Xero
                  </a>
                </div>
                <Button
                  className={classnames(styles.button)}
                  disabled={!hasThemesSelectionChanged}
                  onClick={saveSelectedThemes}
                >
                  Confirm
                </Button>
              </div>
              <div className={styles.unlink}>
                <div className={styles.heading}>
                  <UnlinkedIcon />
                  <h1>Un-link your account?</h1>
                </div>
                <Button
                  className={styles.unlinkButton}
                  onClick={unlinkXero}
                  type="button"
                >
                  I'd like to un-link Xero
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
}
