import { useContext } from 'react';

import { fetchPostSellerIntegrationsRegisterInterest } from '@iwoca/lapi-client/edge';
import { Button } from '@iwoca/orion';
import { useMutation } from '@tanstack/react-query';

import styles from './RegisterInterestButton.module.css';
import { ActivationContext } from '../../../../activation/Activation';
import { useGetIwocapayIntegrationInterest } from '../../../../api/lending/lapiHooks';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { LockedButton } from '../../../../components/LockedButton/LockedButton';
import { useGetCustomerEmail } from '../../../../hooks/useGetCustomerEmail';
import { useStateKey } from '../../../../hooks/useStateKey.hook';
import { useGetIntegrationStatus } from '../../hooks/useGetIntegrationStatus.hook';
import { integrationNameToPlatformNameMap } from '../../utils/integrationNameToPlatformNameMap';
import { IntegrationName } from '../../utils/integrations.types';
import { postInterestToHubspot } from '../../utils/postInterestToHubspot';

function useRegisterIntegrationInterest({
  integrationName,
}: {
  integrationName: IntegrationName;
}) {
  const { stateKey } = useStateKey();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () =>
      fetchPostSellerIntegrationsRegisterInterest({
        stateKey: stateKey!,
        body: {
          data: {
            integration_name: integrationName,
          },
        },
      }),
  });

  return {
    isLoadingRegisterInterest: isPending,
    submitRegisterInterest: mutateAsync,
  };
}

export const RegisterInterestButton = ({
  integrationName,
}: {
  integrationName: IntegrationName;
}) => {
  const { isOnboarded } = useContext(ActivationContext);
  const email = useGetCustomerEmail();

  const { iwocapayIntegrationInterest, loadingIwocapayIntegrationInterest } =
    useGetIwocapayIntegrationInterest();

  const { integrationStatus } = useGetIntegrationStatus({
    integrationId: integrationName,
  });
  const isConnected = integrationStatus === 'CONNECTED';

  const interestRegistered =
    iwocapayIntegrationInterest?.includes(integrationName);

  const { isLoadingRegisterInterest, submitRegisterInterest } =
    useRegisterIntegrationInterest({ integrationName });

  const { fetchIwocapayIntegrationInterest } =
    useGetIwocapayIntegrationInterest();

  const onSubmitRegisterInterest = async () => {
    await submitRegisterInterest();
    await fetchIwocapayIntegrationInterest();

    const hubspotPlaformName =
      integrationNameToPlatformNameMap[integrationName];

    if (hubspotPlaformName) {
      await postInterestToHubspot({
        email: email!,
        platform: hubspotPlaformName,
      });
    }
  };

  if (!isOnboarded) return <LockedButton />;
  if (loadingIwocapayIntegrationInterest) return null;
  if (interestRegistered) return null;
  if (isConnected) return null;

  return (
    <Button
      className={styles.connectionButton}
      variant="primary"
      onClick={onSubmitRegisterInterest}
    >
      {isLoadingRegisterInterest && <LoadingSpinner />}
      {!isLoadingRegisterInterest && 'Install plugin'}
    </Button>
  );
};
