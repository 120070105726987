import styles from './SpendingLimitTable.module.css';
import { TSpendingLimitRequests } from '../../../../api/iwocapay';
import { Card } from '../../../../Buyer/components/Card/Card';
import {
  RowAppearance,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '../../../../components/Table/Table';
import { clipText } from '../../../../utils/ClipText';
import { getFormattedDate } from '../../../../utils/DisplayDate';
import { SpendingLimitStatus } from '../SpendingLimitStatus/SpendingLimitStatus';
import { StatusInformation } from '../StatusInformation/StatusInformation';

const sanitiseText = clipText(30);

export const SpendingLimitTable = ({
  spendingLimitRequests,
}: {
  spendingLimitRequests: TSpendingLimitRequests;
}) => {
  if (!spendingLimitRequests) return null;

  const tableData = spendingLimitRequests.map((spendingLimitRequest) => {
    return {
      uuid: spendingLimitRequest.uuid,
      dateCreated: getFormattedDate(spendingLimitRequest.created),
      recipient: sanitiseText(
        spendingLimitRequest.buyer_name ? spendingLimitRequest.buyer_name : '-',
      ).toLowerCase(),
      status: spendingLimitRequest.status,
    };
  });

  return (
    <>
      <div
        className={styles.spendingLimitTable}
        data-testid="spending-limits-table"
      >
        <Card className={styles.spendingLimitTableUpper}>
          <Table>
            <TableHead>
              <TableRow>
                {() => (
                  <>
                    <TableCell>Date created</TableCell>
                    <TableCell>Company name</TableCell>
                    <TableCell>
                      Status
                      <StatusInformation isPayLink={false} />
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData.map((row) => (
                <TableRow
                  key={row.uuid}
                  defaultRowAppearance={RowAppearance.NORMAL}
                >
                  {() => (
                    <>
                      <div className={styles.firstColumnBody}>
                        <TableCell>{row.dateCreated}</TableCell>
                      </div>
                      <TableCell>{row.recipient}</TableCell>
                      <TableCell>
                        <SpendingLimitStatus slrStatus={row.status} />
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </div>
    </>
  );
};
