import * as Yup from 'yup';

import {
  companyNameValidator,
  tradingNameValidator,
  tradingStartDateValidator,
} from '../../../../utils/validators';

export const businessDetailsValidator = Yup.object().shape({
  companyName: companyNameValidator,
  companyNumber: Yup.string().when('companyType', {
    is: 'sole_trader',
    then: (schema) => schema,
    otherwise: (schema) => schema.required('Company number is required'),
  }),
  companyType: Yup.string().required('Company type is required'),
  last12MonthsTurnover: Yup.string().when(['companyType', 'isDirector'], {
    is: (companyType: string, isDirector: string) =>
      companyType === 'sole_trader' || isDirector !== 'false',
    then: (schema) => schema.required('Last 12 months turnover is required'),
    otherwise: (schema) => schema,
  }),
  tradingName: tradingNameValidator,
  tradingStartDate: tradingStartDateValidator,
  agreeSoleTraderPilotDeclaration: Yup.boolean().when('companyType', {
    is: (value: string) => value === 'sole_trader',
    then: (schema) =>
      schema.required('Agreement is required').isTrue('Agreement is required'),
    otherwise: (schema) => schema,
  }),
  isDirector: Yup.string().when('companyType', {
    is: (value: string) => value !== 'sole_trader',
    then: (schema) => schema.required('Director declaration is required'),
    otherwise: (schema) => schema,
  }),
  firstName: Yup.string().when(['companyType', 'isDirector'], {
    is: (companyType: string, isDirector: string) =>
      companyType !== 'sole_trader' && isDirector === 'false',
    then: (schema) => schema.required('First name is required'),
    otherwise: (schema) => schema,
  }),
  lastName: Yup.string().when(['companyType', 'isDirector'], {
    is: (companyType: string, isDirector: string) =>
      companyType !== 'sole_trader' && isDirector === 'false',
    then: (schema) => schema.required('Last name is required'),
    otherwise: (schema) => schema,
  }),
  title: Yup.string().when(['companyType', 'isDirector'], {
    is: (companyType: string, isDirector: string) =>
      companyType !== 'sole_trader' && isDirector === 'false',
    then: (schema) => schema.required('Title is required'),
    otherwise: (schema) => schema,
  }),
});
