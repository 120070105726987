import { useContext } from 'react';

import Select from 'react-select';

import { LoadingButton } from '../../../../components/LoadingButton/LoadingButton';
import { DetailedInvoiceIcon } from '../../../../components/svg/DetailedInvoiceIcon';
import { PillLink } from '../../PillLink/PillLink';
import { RefreshPillButton } from '../../RefreshPillButton/RefreshPillButton';
import { SellerXeroContext } from '../../Xero.context';
import sellerXeroStyles from '../../Xero.module.css';
import { DropdownOption } from '../../Xero.types';
import styles from '../InvoiceBrandingTheme.module.css';

export function DefaultContent({
  themeOptions,
  setSelectedThemeOptions,
  handleSubmit,
  isSubmitting,
}: {
  themeOptions?: DropdownOption[];
  setSelectedThemeOptions: (selection: DropdownOption[]) => void;
  handleSubmit: () => void;
  isSubmitting: boolean;
}) {
  const sellerXeroContext = useContext(SellerXeroContext);

  const placeHolderText =
    !sellerXeroContext.loading && sellerXeroContext.refreshing
      ? 'Loading...'
      : 'Choose your Xero branding themes';

  return (
    <>
      <div className={sellerXeroStyles.textContent}>
        <h1>Choose your invoice branding themes</h1>
        <p>
          Select the invoice branding theme you’d like to add a ‘pay with
          iwocaPay’ button to.
        </p>
      </div>
      <DetailedInvoiceIcon />
      <Select
        isMulti
        className={sellerXeroStyles.dropdown}
        options={themeOptions}
        onChange={(selectedOptions) =>
          setSelectedThemeOptions(selectedOptions as DropdownOption[])
        }
        placeholder={placeHolderText}
      />

      <div className={sellerXeroStyles.extraButtons}>
        <PillLink href="https://central.xero.com/s/article/Add-edit-or-delete-custom-invoice-quote-templates-UK">
          Add a new theme in Xero
        </PillLink>
        <RefreshPillButton onClick={sellerXeroContext.refresh} />
      </div>
      <LoadingButton
        className={styles.button}
        onClick={handleSubmit}
        isLoading={isSubmitting}
      >
        Confirm
      </LoadingButton>
    </>
  );
}
