import classnames from 'classnames';

import styles from './SellerDashboardHeader.module.css';
import { useGetProfile } from '../../../../api/lending/lapiHooks';
import { useUserGroups } from '../../../../hooks/useUserGroups';
import { useSellerDetails } from '../../hooks/useSellerDetails';

export const NameTag = () => {
  const { profile } = useGetProfile();
  const { isOwner, isStaff } = useUserGroups();
  const applicantDetails = useSellerDetails();

  let fullName: string = `${applicantDetails?.firstName} ${applicantDetails?.lastName}`;
  let initials: string = applicantDetails?.initials || '';

  if (!(isOwner || isStaff)) {
    fullName = profile.full_name;

    const nameParts: string[] = fullName.split(' ');
    if (nameParts.length > 1) {
      initials = `${nameParts[0].charAt(0)}${nameParts[1].charAt(0)}`;
    } else if (fullName.length > 0) {
      initials = fullName.charAt(0);
    }
  }

  const nameId = fullName.replaceAll(' ', '');
  const gradient = getGradientFromString(fullName);

  const companyNameDifferentToTradingName =
    applicantDetails?.companyName !== applicantDetails?.tradingName;

  return (
    <div className={styles.userDetailsContainer}>
      <div className={styles.initialsContainer}>
        <svg
          role="img"
          aria-label={nameId}
          width="32"
          height="32"
          viewBox="0 0 64 64"
          className={styles.initialsCircle}
        >
          <defs>
            <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id={nameId}>
              <stop stopColor={gradient[0]} offset="0%" />
              <stop stopColor={gradient[1]} offset="100%" />
            </linearGradient>
          </defs>
          <g stroke="none" strokeWidth="1" fill="none">
            <rect
              fill={`url(#${nameId})`}
              x="0"
              y="0"
              width="100%"
              height="100%"
            />
          </g>
        </svg>
        <div className={styles.initials}>{initials}</div>
      </div>
      <div
        className={classnames(
          styles.userDetails,
          'overflow-hidden text-ellipsis whitespace-nowrap',
        )}
      >
        <div>{fullName}</div>
        <div
          className={classnames(
            'overflow-hidden text-ellipsis whitespace-nowrap',
          )}
        >
          {applicantDetails?.tradingName}
        </div>
        {companyNameDifferentToTradingName && (
          <div
            className={classnames(
              styles.businessName,
              'overflow-hidden text-ellipsis whitespace-nowrap',
            )}
          >
            {applicantDetails?.companyName}
          </div>
        )}
      </div>
    </div>
  );
};

type THslColour = {
  h: number; // Between 0 and 360
  s: number; // Between 0 and 1
  l: number; // Between 0 and 1
};

function hash(str: string) {
  let hash = 5381;
  let i = str.length;

  while (i) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }

  /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
   * integers. Since we want the results to be always positive, convert the
   * signed int to an unsigned by doing an unsigned bitshift. */
  return hash >>> 0;
}

function hslToHex(colour: THslColour) {
  const { h, s, l } = colour;
  const a = s * 100 * (Math.min(l, 1 - l) / 100);
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const colour = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * colour)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

function polyad(colour: THslColour, number: number): THslColour[] {
  const result: THslColour[] = [colour];
  const step = 360 / number;
  for (let i = 1; i < number; i++) {
    result.push({
      h: (colour.h + i * step) % 360,
      s: colour.s,
      l: colour.l,
    });
  }
  return result;
}

type THslColourTriad = [THslColour, THslColour, THslColour];
function triad(colour: THslColour): THslColourTriad {
  return polyad(colour, 3) as THslColourTriad;
}

function getGradientFromString(str: string) {
  const triadColourIndex = (str.length % 2) + 1;
  const stringHash = hash(str);
  const colour: THslColour = { h: stringHash % 360, s: 0.95, l: 0.5 };
  const secondaryColour = triad(colour)[triadColourIndex];
  const primaryColourHex = hslToHex(colour);
  const secondaryColourHex = hslToHex(secondaryColour);

  return [primaryColourHex, secondaryColourHex];
}
