export function roundTo(value: number, digits: number) {
  let multiplier = '1';
  for (let i = 1; i <= digits; i++) {
    multiplier += '0';
  }

  const rounded =
    Math.round((value + Number.EPSILON) * parseInt(multiplier)) /
    parseInt(multiplier);

  return rounded;
}
