import { Icon } from '@iwoca/orion';

import { TOptionLink } from '../../ManageAccount';

export const ManageAccountBlock = ({
  title,
  subtitle,
  link,
  children,
}: {
  title: string;
  subtitle: JSX.Element[];
  link?: TOptionLink;
  children?: JSX.Element;
}) => {
  return (
    <div>
      <div
        className={
          link && 'grid w-full grid-cols-2 items-center sm:grid-cols-1 sm:gap-s'
        }
      >
        <h2 className="mb-0 mt-0">{title}</h2>
        {link && (
          <div className="text-right sm:text-left">
            <button
              onClick={link.onClick}
              className="bg-transparent p-0 text-s font-bold text-primary-40"
            >
              <Icon
                icon="penFilled"
                className="w-[20px] fill-primary-40 pr-2xs"
              />
              {link.title}
            </button>
          </div>
        )}
      </div>
      <div className="mt-s text-m font-reg">
        {subtitle.map((text, index) => (
          <p key={index} className="m-0">
            {text}
          </p>
        ))}
      </div>
      {children}
    </div>
  );
};
