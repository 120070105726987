import styles from './RepaymentPlan.module.css';
import { useGetDrawDownRepaymentScheduleIwocapay } from '../../../../api/lending/lapiHooks';
import { displayCurrency } from '../../../../utils/DisplayCurrency';
import { normaliseDate } from '../../../Signup/stateNormalisation';
import { useCurrentPayLink } from '../../hooks/useCurrentPayLink';

export const RepaymentPlan = () => {
  const { drawDownRepaymentScheduleIwocapay: repaymentSchedule } =
    useGetDrawDownRepaymentScheduleIwocapay();
  const { payLink } = useCurrentPayLink();
  if (!repaymentSchedule?.summary || !repaymentSchedule.schedule) return null;

  const { schedule, summary } = repaymentSchedule;

  return (
    <>
      {payLink?.capture_delay_hours ? <h3>Your example payment plan</h3> : null}
      <table className={styles.tableRepaymentSchedule}>
        <thead className={styles.tableHeader}>
          <th>{payLink?.capture_delay_hours ? 'Payment day' : 'Date'}</th>
          <th>Invoice amount</th>
          <th>Interest*</th>
          <th>Total</th>
        </thead>
        <tbody className={styles.tableBody}>
          {schedule.map((repayment, index) => (
            <tr className={styles.tableRow} key={repayment.date}>
              <td>
                {payLink?.capture_delay_hours
                  ? `Day ${30 * (index + 1)}`
                  : normaliseDate(repayment.date)}
              </td>
              <td>{displayCurrency(repayment.principal)}</td>
              <td className={styles.interestText}>
                {displayCurrency(repayment.interest)}
              </td>
              <td>{displayCurrency(repayment.total)}</td>
            </tr>
          ))}
          <tr className={styles.tableRow}>
            <td className={styles.totalText}>Total</td>
            <td>{displayCurrency(summary.total_credit_amount!)}</td>
            <td className={styles.interestText}>
              {displayCurrency(summary.total_interest!)}
            </td>
            <td>{displayCurrency(summary.total_payable!)}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
