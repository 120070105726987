export const UnlinkedIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Link with Xero</title>
      <path
        d="M27.5274 13.5258C29.6987 11.3938 30.1203 8.1026 28.5498 5.52554C28.5603 5.5152 28.5603 5.5152 28.5603 5.5152C28.2652 5.03917 27.9174 4.59407 27.5274 4.21113C26.4733 3.17618 25.0926 2.50348 23.5852 2.3275C21.5299 2.08946 19.5062 2.7726 18.0411 4.21113L15.9331 6.28103C14.2044 7.97838 13.551 10.4623 14.215 12.6875C14.2571 12.8945 14.763 14.2606 14.9527 14.3434C15.2374 14.8091 15.5641 15.2335 15.933 15.5957C16.6919 16.3408 17.5879 16.848 18.5365 17.1585V17.1792C18.5365 17.1792 19.8082 17.5046 20.6763 17.5046C21.5444 17.5046 22.8369 17.1378 22.8369 17.1378C23.775 16.8376 24.6709 16.3305 25.4193 15.5957L27.5274 13.5258ZM22.7843 13.0083C22.7843 13.0083 22.0223 13.7826 20.7656 13.8526C19.5089 13.9226 18.5682 12.9876 18.5682 12.9876C18.0411 12.4701 17.6934 11.7767 17.6512 11.0523C17.609 10.3277 17.8725 9.55157 18.5683 8.86843L20.6763 6.79853C21.8252 5.67044 23.7224 5.69113 24.8818 6.80887C24.8924 6.81922 24.8924 6.81922 24.903 6.82961C26.115 8.01975 26.0202 9.83103 24.8924 10.9384L22.7843 13.0083Z"
        fill="#699FE3"
      />
      <path
        d="M31.568 2.45882L25.4608 8.4371C24.8857 9.00011 23.9445 9.00011 23.3693 8.4371C22.7942 7.87409 22.7942 6.95277 23.3693 6.38976L29.4765 0.411436C30.0369 -0.137145 31.0076 -0.137145 31.568 0.411436C32.1432 0.974447 32.1432 1.89581 31.568 2.45882Z"
        fill="#699FE3"
      />
      <path
        d="M4.24164 18.5873C2.07034 20.7193 1.64874 24.0104 3.21927 26.5875C3.20873 26.5978 3.20873 26.5978 3.20873 26.5978C3.50388 27.0739 3.85169 27.519 4.24169 27.9019C5.29571 28.9369 6.67647 29.6096 8.18379 29.7855C10.2391 30.0236 12.2629 29.3404 13.7279 27.9019L15.836 25.832C17.5646 24.1347 18.2181 21.6507 17.5541 19.4255C17.5119 19.2185 17.006 17.8525 16.8163 17.7696C16.5317 17.3039 16.2049 16.8796 15.836 16.5174C15.0771 15.7722 14.1812 15.2651 13.2326 14.9546L13.2326 14.9338C13.2326 14.9338 11.9608 14.6084 11.0927 14.6084C10.2247 14.6084 8.93214 14.9752 8.93214 14.9752C7.99404 15.2754 7.09812 15.7825 6.34973 16.5174L4.24164 18.5873ZM8.98471 19.1048C8.98471 19.1048 9.74675 18.3304 11.0034 18.2604C12.2601 18.1904 13.2009 19.1254 13.2009 19.1254C13.7279 19.6429 14.0757 20.3363 14.1179 21.0608C14.16 21.7853 13.8965 22.5615 13.2008 23.2446L11.0927 25.3145C9.94387 26.4426 8.04666 26.4219 6.8872 25.3042C6.87667 25.2938 6.87666 25.2938 6.86608 25.2834C5.654 24.0933 5.74886 22.282 6.87661 21.1747L8.98471 19.1048Z"
        fill="#BFD4E0"
      />
      <path
        d="M0.431474 29.5412L6.53869 23.5629C7.11383 22.9999 8.05501 22.9999 8.63016 23.5629C9.20531 24.1259 9.20531 25.0472 8.63016 25.6102L2.523 31.5886C1.96259 32.1371 0.991932 32.1371 0.431523 31.5886C-0.143675 31.0256 -0.143675 30.1042 0.431474 29.5412Z"
        fill="#BFD4E0"
      />
    </svg>
  );
};
