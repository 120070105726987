import { Button, ButtonProps } from '../Button/Button';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

type LoadingButtonProps = {
  isLoading: boolean;
} & ButtonProps;

export function LoadingButton({
  isLoading,
  children,
  ...props
}: LoadingButtonProps) {
  return (
    <Button {...props}>
      {isLoading ? (
        <>
          <LoadingSpinner /> Loading...
        </>
      ) : (
        children
      )}
    </Button>
  );
}
