import styles from './InputError.module.css';

export const InputError = ({
  error,
  isVisible,
}: {
  error?: string | null;
  isVisible: boolean;
}) => {
  return isVisible && error ? <p className={styles.error}>{error}</p> : null;
};
