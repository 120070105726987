import { Button } from '@iwoca/orion';

import styles from './SoleTraderOption.module.css';
import { useGetPayLinkByPayLinkId } from '../../../../../api/lending/lapiHooks';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import AlertIcon from '../../../../../components/svg/AlertIcon.svg?react';
import { iwocaTelDisplay } from '../../../../../constants.json';
import { useQueryParam } from '../../../../../hooks/useQueryParam';
import { displayCurrency } from '../../../../../utils/DisplayCurrency';

export const SoleTraderOption = () => {
  const payLinkId = useQueryParam('payLinkId');
  const { payLink, loadingPayLink } = useGetPayLinkByPayLinkId({
    payLinkId: payLinkId!,
  });

  const requestedAmountText =
    'Pay ' + displayCurrency(payLink?.amount || 0) + ' via bank transfer';

  const redirectToPayLinkLanding = () => {
    window.location.href = `/pay/to/${payLink?.seller_handle}/${payLinkId}/`;
  };

  return (
    <div className={styles.soleTrader}>
      <div className={styles.soleTraderInfoBox}>
        <div>
          <AlertIcon className={styles.alertIcon} />
        </div>
        <div className={styles.soleTraderText}>
          <p>If you’re a Sole Trader you can only use Pay Now.</p>
          <p>We’re working to make Pay Later open to Sole Traders.</p>
        </div>
      </div>
      {payLinkId &&
        (requestedAmountText ? (
          <Button
            className={styles.payNowButton}
            type="button"
            onClick={redirectToPayLinkLanding}
          >
            {loadingPayLink ? <LoadingSpinner /> : <>{requestedAmountText}</>}
          </Button>
        ) : (
          <p className={styles.error}>
            We were unable to establish your application, please try to refresh
            the page or call us on {iwocaTelDisplay}
          </p>
        ))}
    </div>
  );
};
