import { tracking } from '@iwoca/frontend-tracking-library';

import { TEnabledOptions } from './useEnabledTerms';
import { useOnce } from '../../hooks/useOnce';
import { OPTIONS_TO_TRACKING } from '../../utils/tracking';
import { PayLink } from '../PayLinkLanding/utils/PayLinkLanding.types';

export function useTrackLandingPageView({
  payLinkType,
  visibleOptions,
  productPricing,
  payLinkId,
  sellerId,
  loadingApis,
}: {
  payLinkType: 'generic' | 'custom' | 'spending_limit' | 'package';
  visibleOptions: TEnabledOptions;
  productPricing?: PayLink['product_pricing'];
  payLinkId?: string;
  sellerId?: string;
  loadingApis: boolean;
}) {
  const visible_payment_options = visibleOptions.map(
    (option) => OPTIONS_TO_TRACKING[option],
  );

  const product_pricing_3m =
    productPricing?.THREE_MONTHS.representative_interest === 0
      ? 'seller_pays'
      : 'buyer_pays';

  const product_pricing_12m =
    productPricing?.TWELVE_MONTHS?.representative_interest === 0
      ? 'seller_pays'
      : 'buyer_pays';

  return useOnce({
    conditionFn:
      visible_payment_options.length > 0 &&
      !loadingApis &&
      productPricing !== undefined,
    fn: () =>
      tracking.ampli.payLinkLandingPageViewed({
        pay_link_type: payLinkType,
        visible_payment_options,
        product_pricing_3m,
        ...(productPricing?.TWELVE_MONTHS && {
          product_pricing_12m,
        }),
        ...(payLinkId && { pay_link_id: payLinkId }),
        ...(sellerId && { seller_id: sellerId }),
      }),
  });
}
