import React from 'react';

import { Table } from '@iwoca/orion';
import { TableProps } from '@iwoca/orion/lib/components/table/Table';

import CrossIcon from '../../../../assets/CrossIcon.svg';
import TickIcon from '../../../../assets/TickIcon.svg';

const ACCESS_LEVELS_PERMISSIONS: {
  feature: string;
  admin: boolean;
  editor: boolean;
  contributor: boolean;
}[] = [
  {
    feature: 'View payment statuses',
    admin: true,
    editor: true,
    contributor: true,
  },
  {
    feature: 'View spending limit statuses',
    admin: true,
    editor: true,
    contributor: true,
  },
  {
    feature: 'Download payment data',
    admin: true,
    editor: true,
    contributor: true,
  },
  {
    feature: 'Create pay links',
    admin: true,
    editor: true,
    contributor: true,
  },
  {
    feature: 'Manage Pay Later payment terms',
    admin: true,
    editor: true,
    contributor: false,
  },
  {
    feature: 'Connect and manage integrations',
    admin: true,
    editor: true,
    contributor: false,
  },
  {
    feature: 'Add and manage users',
    admin: true,
    editor: false,
    contributor: false,
  },
  {
    feature: 'View and make loan applications',
    admin: false,
    editor: false,
    contributor: false,
  },
];

const getAccessIcon = (hasAccess: boolean) => {
  return hasAccess ? (
    <img src={TickIcon} alt="Tick icon" />
  ) : (
    <img src={CrossIcon} alt="Cross icon" />
  );
};

export const AccessLevelsTable = () => {
  const tableData = ACCESS_LEVELS_PERMISSIONS.map((accessLevelsPermission) => {
    return {
      feature: accessLevelsPermission.feature,
      admin: accessLevelsPermission.admin,
      editor: accessLevelsPermission.editor,
      contributor: accessLevelsPermission.contributor,
    };
  });

  const columns: TableProps<typeof tableData>['columns'] = [
    {
      id: 'feature',
      accessorKey: 'feature',
      header: 'Feature',
    },
    {
      id: 'admin',
      accessorKey: 'admin',
      header: 'Admin',
      cell: ({ getValue }) => {
        return getAccessIcon(getValue());
      },
    },
    {
      id: 'editor',
      accessorKey: 'editor',
      header: 'Editor',
      cell: ({ getValue }) => {
        return getAccessIcon(getValue());
      },
    },
    {
      id: 'contributor',
      accessorKey: 'contributor',
      header: 'Contributor',
      cell: ({ getValue }) => {
        return getAccessIcon(getValue());
      },
    },
  ];

  return (
    <div data-testid="access-levels-table">
      {/*@ts-expect-error*/}
      <Table columns={columns} data={tableData} />
    </div>
  );
};
