import { SVGAttributes } from 'react';

export const DetailedInvoiceIcon = (props: SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <svg
      width={75}
      height={100}
      viewBox="0 0 75 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M55.435 40.548h-35.87a1.627 1.627 0 110-3.252h35.87a1.627 1.627 0 110 3.252zM55.435 60.06h-35.87a1.627 1.627 0 110-3.252h35.87a1.627 1.627 0 110 3.252z"
        fill="#EEF3F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.565 60.06h35.87a1.627 1.627 0 100-3.252h-35.87a1.627 1.627 0 100 3.252zm0-19.512h35.87a1.627 1.627 0 100-3.252h-35.87a1.627 1.627 0 100 3.252z"
        fill="#EEF3F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.37 0H1.63C.73 0 0 .728 0 1.626v96.522C0 99.171.831 100 1.857 100h71.286C74.17 100 75 99.17 75 98.148V1.626C75 .728 74.27 0 73.37 0zM19.565 60.06h35.87a1.627 1.627 0 100-3.252h-35.87a1.627 1.627 0 100 3.252zm0-19.512h35.87a1.627 1.627 0 100-3.252h-35.87a1.627 1.627 0 100 3.252z"
        fill="#EEF3F7"
      />
      <path
        d="M47.773 30H28.236A1.234 1.234 0 0127 28.764V9.236C27 8.552 27.552 8 28.236 8h19.528C48.447 8 49 8.552 49 9.236v19.528A1.22 1.22 0 0147.773 30zm-18.292-2.48h17.057V10.461H29.48V27.52z"
        fill="#FB534A"
      />
      <path
        d="M43 18.631v-3.39c0-.17-.216-.287-.42-.224l-2.398.791c-.114.036-.182.126-.182.225v5.933c0 .099.068.18.182.225l2.398.79c.204.064.42-.044.42-.224v-4.126zM36 16.033v5.933c0 .099-.068.18-.182.225l-2.398.79c-.204.064-.42-.044-.42-.224v-7.515c0-.17.216-.288.42-.225l2.398.791c.102.036.182.126.182.225zM14 81.857c0-.473.413-.857.922-.857h45.156c.51 0 .922.384.922.857v4.286c0 .473-.413.857-.922.857H14.922c-.51 0-.922-.384-.922-.857v-4.286z"
        fill="#FB534A"
      />
      <path
        d="M14 42h47v-3H14v3zM14 52h47v-3H14v3zM14 62h47v-3H14v3zM14 72h47v-3H14v3z"
        fill="#BFD4E0"
      />
    </svg>
  );
};
