import { useCurrentPayLink } from '../../../hooks/useCurrentPayLink';
import styles from '../DrawDownModal.module.css';
import { IwocaToBankIllustration } from '../IwocaToBankIllustration/IwocaToBankIllustration';

export const Loading = () => {
  const { payLink } = useCurrentPayLink();

  return (
    <div className={styles.loadingWrapper}>
      <div className={styles.title}>
        {payLink ? (
          <>
            {payLink?.capture_delay_hours
              ? `Confirming your payment plan...`
              : ` We're making a payment of ${payLink?.amount} to ${payLink?.seller_trading_name}'s
        bank account...`}
          </>
        ) : (
          'Loading...'
        )}
      </div>
      <IwocaToBankIllustration
        icon={<span className={styles.processingDirection} />}
      />
    </div>
  );
};
