import { Spinner } from '@iwoca/orion';

import { CheckoutComAddPaymentMethodForm } from './CheckoutComAddPaymentMethodForm';
import { StripeAddPaymentMethodForm } from './StripeAddPaymentMethodForm';
import { useGetSelectPaymentProcessor } from '../../../../../api/lending/lapiHooks';

export const AddPaymentMethodForm = () => {
  const { paymentProcessor, loadingSelectPaymentProcessor } =
    useGetSelectPaymentProcessor();

  if (loadingSelectPaymentProcessor) {
    return (
      <div className="m-xl flex w-full justify-center">
        <Spinner />
      </div>
    );
  }

  return paymentProcessor === 'Checkout' ? (
    <CheckoutComAddPaymentMethodForm />
  ) : (
    <StripeAddPaymentMethodForm />
  );
};
