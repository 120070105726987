import { useEffect, useRef } from 'react';

export function useOnce({
  fn,
  conditionFn = true,
}: {
  conditionFn?: boolean | ((...ags: unknown[]) => boolean);
  fn: () => void;
}) {
  const mountedRef = useRef(false);

  useEffect(() => {
    if (mountedRef.current === true) {
      return;
    }

    const shouldRun =
      typeof conditionFn === 'function' ? conditionFn() : conditionFn;
    if (!shouldRun) {
      return;
    }

    fn();
    mountedRef.current = true;
  }, [fn, conditionFn]);
}
