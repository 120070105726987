import React from 'react';

import {
  useGetApplication,
  useGetFormalOffers,
  useGetFundingRequirement,
  useGetStateByStateKey,
} from '../../../../api/lending/lapiHooks';
import { LoadingCard } from '../../../components/LoadingCard/LoadingCard';
import { ModalProvider } from '../../../store/ModalProvider';
import { SECURITY_CHECK_ROUTE_CONFIG } from '../../routes';
import { CheckoutLayout } from '../CheckoutLayout/CheckoutLayout';
import { DrawDownModal } from '../DrawDownModal/DrawDownModal';

export const SecurityChecks = ({ children }: { children: React.ReactNode }) => {
  const { application, loadingApplication } = useGetApplication();
  const { state, loadingState } = useGetStateByStateKey();
  const { formalOffers, loadingFormalOffers } = useGetFormalOffers();
  const { fundingRequirement, loadingFundingRequirement } =
    useGetFundingRequirement();

  const hasLoaded =
    application &&
    !loadingApplication &&
    state &&
    !loadingState &&
    formalOffers &&
    !loadingFormalOffers &&
    fundingRequirement &&
    !loadingFundingRequirement;

  return (
    <ModalProvider>
      <CheckoutLayout routes={SECURITY_CHECK_ROUTE_CONFIG}>
        {hasLoaded ? (
          <>
            <>{children}</>
            <DrawDownModal />
          </>
        ) : (
          <LoadingCard />
        )}
      </CheckoutLayout>
    </ModalProvider>
  );
};
