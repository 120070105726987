import { SuggestedProductType } from '../utils/product';

export enum DecisionErrorTypes {
  COMPANY_NOT_FOUND_ERROR = 'companyNotFound',
  DIRECTORS_COMPANY_MISMATCH_ERROR = 'directorsCompanyMismatch',
  NO_DIRECTORS_ERROR = 'noDirectors',
  INCOMPLETE_CREDIT_CHECK_ERROR = 'incompleteCreditCheck',
  DUPLICATE_GUARANTORS_ERROR = 'duplicateGuarantors',
  TECHNICAL_ERROR = 'technical',
  SOLE_TRADER_ADDITIONAL_GUARANTORS = 'soleTraderAdditionalGuarantors',
  NO_AFFORDABILITY_ASSESSMENT = 'noAffordabilityAssessment',
  COMPANY_DETAILS_INCORRECT = 'companyDetailsError',
}

const DECISION_ERROR_MAP = {
  '125': DecisionErrorTypes.COMPANY_NOT_FOUND_ERROR,
  '137': DecisionErrorTypes.DIRECTORS_COMPANY_MISMATCH_ERROR,
  '138': DecisionErrorTypes.NO_DIRECTORS_ERROR,
  '134': DecisionErrorTypes.INCOMPLETE_CREDIT_CHECK_ERROR,
  '136': DecisionErrorTypes.DUPLICATE_GUARANTORS_ERROR,
  '140': DecisionErrorTypes.SOLE_TRADER_ADDITIONAL_GUARANTORS,
  '106': DecisionErrorTypes.NO_AFFORDABILITY_ASSESSMENT,
  '107': DecisionErrorTypes.NO_AFFORDABILITY_ASSESSMENT,
  '141': DecisionErrorTypes.COMPANY_DETAILS_INCORRECT,
  '000': DecisionErrorTypes.TECHNICAL_ERROR,
} as const;

// Order of priority, starting with the highest
export const KNOWN_ERROR_CODE_PRIORITY = [
  '125',
  '137',
  '138',
  '134',
  '136',
  '141',
  '106',
  '107',
  '000',
] as const;

export function getApprovalRequirementErrors(
  suggestedProduct: NonNullable<SuggestedProductType>,
) {
  const errors = new Set<string>();

  for (const requirement of suggestedProduct.data_requirements) {
    if (!requirement.resolution_methods?.length) continue;

    for (const resolutionMethod of requirement.resolution_methods) {
      if (!resolutionMethod.errors?.length) continue;

      for (const error of resolutionMethod.errors) {
        if (!error.code) continue;

        // @ts-expect-error
        const errorCode = DECISION_ERROR_MAP[error.code] ? error.code : '000';
        // @ts-expect-error
        const errorType = DECISION_ERROR_MAP[errorCode];

        errors.add(errorType);
      }
    }
  }

  return errors;
}

export function selectApprovalRequirementErrorWithHighestPriority(
  approvalRequirementError: ReturnType<typeof getApprovalRequirementErrors>,
) {
  for (const knownErrorCode of KNOWN_ERROR_CODE_PRIORITY) {
    const hasErrorCode = approvalRequirementError.has(
      DECISION_ERROR_MAP[knownErrorCode],
    );

    if (hasErrorCode) return DECISION_ERROR_MAP[knownErrorCode];
  }

  return DECISION_ERROR_MAP['000'];
}
