import { tracking } from '@iwoca/frontend-tracking-library';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import styles from './Offer.module.css';
import { useFixedSumLoanAgreement } from './useFixedSumLoanAgreement';
import {
  useGetDrawDownRepaymentScheduleIwocapay,
  useGetIwocapayPaymentLater,
} from '../../../../api/lending/lapiHooks';
import { roundTo } from '../../../../utils/roundTo';
import { SubmitButton } from '../../../components/Button/Button';
import { Card } from '../../../components/Card/Card';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { DecisionBreakdownTable } from '../../../components/DecisionBreakdownTable';
import { InlineButton } from '../../../components/InlineButton/InlineButton';
import { normaliseDate } from '../../../Signup/stateNormalisation';
import { Divider } from '../../components/Divider/Divider';
import { DuplicatePaylinkBanner } from '../../components/DuplicatePaylinkBanner/DuplicatePaylinkBanner';
import { RepaymentPlan } from '../../components/RepaymentPlan/RepaymentPlan';
import { useCurrentPayLink } from '../../hooks/useCurrentPayLink';
import { useInterestRate } from '../../hooks/useInterestRate';
import { useNavigateToNextRequirement } from '../../hooks/useNavigateToNextRequirement';

const SIGN_FIXED_SUM_LOAN_AGREEMENT_ID = 'agreeTermsAndConditions';

export const Offer = () => {
  const interestRate = useInterestRate();
  const { payLink } = useCurrentPayLink();
  const { goToNextRequirement } = useNavigateToNextRequirement();

  const { drawDownRepaymentScheduleIwocapay } =
    useGetDrawDownRepaymentScheduleIwocapay();
  const rawFirstRepaymentDate =
    drawDownRepaymentScheduleIwocapay?.schedule?.[0].date;
  const firstRepaymentDate = rawFirstRepaymentDate
    ? normaliseDate(rawFirstRepaymentDate)
    : null;

  const {
    downloadFixedSumLoanAgreement,
    signFixedSumLoanAgreement,
    canSignFixedSumLoanAgreement,
    isFixedSumLoanAgreementSigned,
  } = useFixedSumLoanAgreement();

  const displayInterest =
    interestRate !== undefined ? roundTo(interestRate * 100, 2) : 'XX';

  const { buyerPayLinks } = useGetIwocapayPaymentLater();

  const handleCheckout = async () => {
    if (!isFixedSumLoanAgreementSigned) {
      await signFixedSumLoanAgreement();
    }

    tracking.ampli.clickedButtonToPaySeller();
    goToNextRequirement();
  };

  const duplicatePaylinks = buyerPayLinks?.filter((link) => {
    const hoursSince =
      link.funded_at !== undefined
        ? (Date.now() - new Date(link.funded_at).getTime()) / 3600000
        : null;

    return link.amount === payLink?.amount && hoursSince && hoursSince < 24;
  });

  return (
    <>
      {duplicatePaylinks && duplicatePaylinks?.length > 0 && (
        <DuplicatePaylinkBanner />
      )}
      <Card title="Confirm your payment plan">
        <DecisionBreakdownTable />

        {payLink?.capture_delay_hours ? (
          <p className={styles.timeframeBreakdown}>
            Your first payment is due <b>30 days after</b>
            {` ${payLink?.seller_trading_name} `} has confirmed the order.
          </p>
        ) : (
          <p className={styles.timeframeBreakdown}>
            Your first payment is due on <b>{firstRepaymentDate}</b>
          </p>
        )}

        <RepaymentPlan />
        <span>*Your interest rate is {displayInterest}% per month.</span>
        <Divider />
        {canSignFixedSumLoanAgreement && (
          <Formik
            initialValues={{ [SIGN_FIXED_SUM_LOAN_AGREEMENT_ID]: false }}
            onSubmit={handleCheckout}
            validationSchema={signFixedSumLoanAgreementValidator}
            validateOnChange={true}
          >
            {({ isValid, dirty, isSubmitting }) => {
              const isSubmitDisabled = !isValid || !dirty || isSubmitting;

              return (
                <Form>
                  {payLink?.capture_delay_hours ? (
                    <p className={styles.offerActionDescription}>
                      Check the box and hit the button and we will send
                      {` ${payLink?.seller_trading_name} `} a confirmation that
                      you have completed checkout and that they can continue to
                      fulfil your order.
                    </p>
                  ) : (
                    <p className={styles.offerActionDescription}>
                      Check the box and hit the button and we'll pay{' '}
                      {payLink?.seller_trading_name} in full instantly.
                    </p>
                  )}

                  <Checkbox
                    name={SIGN_FIXED_SUM_LOAN_AGREEMENT_ID}
                    labelText={
                      <>
                        I've read and agreed to the{' '}
                        <InlineButton
                          onClick={async () => {
                            await downloadFixedSumLoanAgreement();
                          }}
                        >
                          Terms & Conditions
                        </InlineButton>
                      </>
                    }
                  />
                  {payLink?.capture_delay_hours ? (
                    <p className={styles.offerButtonDescription}>
                      Selecting this button will send a confirmation to
                      {` ${payLink?.seller_trading_name} `} and your payment
                      schedule will begin 30 days after
                      {` ${payLink?.seller_trading_name} `} has confirmed the
                      order.
                    </p>
                  ) : (
                    <p className={styles.offerButtonDescription}>
                      Selecting this button will send the funds straight to{' '}
                      {payLink?.seller_trading_name} and your payment schedule
                      will begin.
                    </p>
                  )}

                  <SubmitButton disabled={isSubmitDisabled}>
                    Confirm plan and complete checkout
                  </SubmitButton>
                </Form>
              );
            }}
          </Formik>
        )}
      </Card>
    </>
  );
};

const signFixedSumLoanAgreementValidator = Yup.object().shape({
  [SIGN_FIXED_SUM_LOAN_AGREEMENT_ID]: Yup.boolean()
    .required('Please agree to the Terms & Conditions')
    .isTrue('Please agree to the Terms & Conditions'),
});
