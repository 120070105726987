import { fetchPostLogout } from '@iwoca/lapi-client/edge';

import {
  useGetContext,
  useGetProfile,
  useGetStateByStateKey,
} from '../../api/lending/lapiHooks';

export const LoginBanner = ({ onClick }: { onClick: () => void }) => {
  const { refetchState } = useGetStateByStateKey();
  const {
    context: { isLoggedIn },
    getContext,
  } = useGetContext();
  const {
    profile: { email_address, full_name },
    getProfile,
  } = useGetProfile();

  const logout = async () => {
    await fetchPostLogout({ body: {} });
    await Promise.all([getContext(), getProfile(), refetchState()]);
  };

  if (isLoggedIn) {
    return (
      <div className="w-100 flex flex-col rounded-t-s px-l pb-l pt-xl">
        <h1 className="text-bold m-0 text-xl">
          👋 Welcome back {full_name.split(' ')[0]}
        </h1>
        <div className="flex items-center gap-xs text-s">
          <span className="font-med">{email_address}</span>
          <span className="pl-xs font-reg">Not you?</span>
          <button
            onClick={logout}
            className="bg-transparent p-0 font-med text-primary-40  hover:underline"
          >
            Log out
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="w-100 flex items-center rounded-t-s bg-secondary-95 px-l pb-l pt-xl">
      Used iwocaPay before?{' '}
      <button
        onClick={onClick}
        className="cursor-pointer bg-transparent text-m text-primary-40 hover:underline"
      >
        Log in
      </button>
    </div>
  );
};
