import React from 'react';

import { Disclosure } from '@headlessui/react';
import classnames from 'classnames';

import styles from './Accordion.module.css';
import { PlusIcon } from '../../../components/svg/PlusIcon';
import QuestionIcon from '../../../components/svg/QuestionIcon.svg';

export const Accordion = ({
  title,
  className,
  children,
}: {
  title: string;
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <div className={classnames(className, styles.accordionContainer)}>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className={styles.accordionButton}>
              <span className={styles.accordionButtonTitle}>
                <img
                  src={QuestionIcon}
                  className={styles.questionIconImage}
                  alt="Question icon"
                />
                <span>{title}</span>
              </span>
              <PlusIcon
                className={classnames(styles.plusIcon, { [styles.open]: open })}
              />
            </Disclosure.Button>
            <Disclosure.Panel className={styles.accordionPanel}>
              {children}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
