export function Circle({ title = 'Circle', ...props }) {
  return (
    <svg
      {...props}
      width="752"
      height="719"
      viewBox="0 0 752 719"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <circle opacity="0.5" cx="376" cy="343" r="376" fill="#EEF3F7" />
    </svg>
  );
}
