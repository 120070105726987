import styles from './IDCheck.module.css';
import { IDCheckCompleteIcon } from '../../../../components/svg/IDCheckCompleteIcon';
import { Button } from '../../../components/Button/Button';
import { Card } from '../../../components/Card/Card';
import { useNavigateToNextRequirement } from '../../hooks/useNavigateToNextRequirement';

export const IDCheckComplete = () => {
  const { nextRequirementName, goToNextRequirement } =
    useNavigateToNextRequirement();

  return (
    <Card title="">
      <div className={styles.checkCompleteScreen}>
        <h2 className={styles.checkCompleteLogo}>
          <IDCheckCompleteIcon className={styles.checkCompleteIcon} />
          ID Check complete
        </h2>
        <p className={styles.checkCompleteMessage}>
          Everything looks good, you can now continue.
        </p>
        <Button
          className={styles.checkCompleteButton}
          onClick={goToNextRequirement}
        >
          Continue to {nextRequirementName}
        </Button>
      </div>
    </Card>
  );
};
