import { createContext } from 'react';

import styles from './Activation.module.css';
import { useActivationSteps } from './useActivationSteps.hook';
import { LinkWithQuery } from '../components/LinkWithQuery/LinkWithQuery';
import { LoadingScreen } from '../components/LoadingScreen/LoadingScreen';
import Arrow from '../components/svg/Arrow.svg?react';

export const ActivationContext = createContext<{
  isOnboarded: boolean | undefined;
  isLoading: boolean;
}>({
  isOnboarded: undefined,
  isLoading: false,
});

export const Activation = ({ children }: { children: React.ReactNode }) => {
  const { progress, isOnboarded, isLoading } = useActivationSteps();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <ActivationContext.Provider value={{ isOnboarded, isLoading }}>
      {children}
      {!isOnboarded && <ActivationModal progress={progress} />}
    </ActivationContext.Provider>
  );
};

function ActivationModal({ progress }: { progress: number }) {
  return (
    <div className={styles.activationModal}>
      <div className={styles.textContent}>
        <p>Start using iwocaPay today</p>
        <LinkWithQuery to="/pay/activation/" className={styles.link}>
          <span>Finish setup</span>
          <Arrow className={styles.arrow} />
        </LinkWithQuery>
      </div>
      <div className={styles.progressBar}>
        <div
          className={styles.progressBarAnimator}
          style={{ transform: `scaleX(${progress / 100})` }}
        ></div>
      </div>
    </div>
  );
}
