import cn from 'classnames';

import styles from './Policy.module.css';

export const Policy = ({ className }: { className?: string }) => {
  return (
    <div className={cn(className, styles.policy)}>
      <h3 className={styles.title}>How we use your data</h3>
      <div>
        <p>
          Here's how we use your personal information and that of your company
          directors, beneficial owners or guarantors. (Please make them aware of
          this before you provide their information to us)
        </p>
        <ul>
          <li className={styles.listItem}>
            We'll share it with credit reference agencies to run soft credit and
            identity checks. This won't affect your (or their) personal credit
            scores (note: we may ask you to provide a personal guarantee, so
            we'll only conduct credit checks on you and any other guarantors)
          </li>
          <li className={styles.listItem}>
            We'll share it with fraud prevention agencies if we do spot any
            fraud, you could be refused services, finance, or employment.
          </li>
          <li className={styles.listItem}>
            We might record your calls with us.
          </li>
        </ul>
        <p>
          If you'd like to find out more, check our{' '}
          <a
            className={styles.privacyPolicyLink}
            href="/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy.
          </a>
        </p>
        <p>iwoca Ltd is the data controller.</p>
      </div>
    </div>
  );
};
