import { tracking } from '@iwoca/frontend-tracking-library';

import { useOnce } from '../../hooks/useOnce';
import { FormalOffer } from '../../Seller/PayLinks/utils/Paylinks.types';

export function useTrackViewedOffers({
  payLinkId,
  formalOffers,
  loadingApis,
}: {
  payLinkId?: string;
  formalOffers?: FormalOffer[];
  loadingApis: boolean;
}) {
  const offerIds = formalOffers?.map((offer) => offer.offer_id!);

  return useOnce({
    conditionFn: !loadingApis && offerIds !== undefined,
    fn: () =>
      tracking.ampli.viewedOffers({
        pay_link_id: payLinkId,
        offer_ids: offerIds!,
        choose_offer_page: 'choose_product_page',
      }),
  });
}
