import { useEffect, useState, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { DefaultContent } from './DefaultContent/DefaultContent';
import { ErrorContent } from './ErrorContent/ErrorContent';
import styles from './InvoiceBrandingTheme.module.css';
import { buildQueryString } from '../../../Buyer/utils/queryParams';
import { BackButton } from '../BackButton/BackButton';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { TryAgainError } from '../TryAgainError/TryAgainError';
import { SellerXeroContext } from '../Xero.context';
import { contextLoadedSuccessfully, saveXeroThemes } from '../Xero.helpers';
import sellerXeroStyles from '../Xero.module.css';
import { DropdownOption } from '../Xero.types';

export function InvoiceBrandingTheme() {
  const navigate = useNavigate();
  const queryString = buildQueryString();

  const [themeOptions, setThemeOptions] = useState<DropdownOption[]>();
  const [selectedThemeOptions, setSelectedThemeOptions] =
    useState<DropdownOption[]>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<
    'getBrandingThemes' | 'postBrandingThemes'
  >();

  const sellerXeroContext = useContext(SellerXeroContext);
  const companyName =
    contextLoadedSuccessfully(sellerXeroContext) &&
    sellerXeroContext.organisationName;

  useEffect(() => {
    const setDropdownOptions = () => {
      if (
        !sellerXeroContext.loading &&
        !sellerXeroContext.error &&
        sellerXeroContext.xeroThemes !== undefined
      ) {
        const themeOptions: DropdownOption[] = sellerXeroContext.xeroThemes.map(
          (theme) => ({
            label: theme.name,
            value: theme.branding_theme_id,
          }),
        );
        setThemeOptions(themeOptions);
        return;
      } else if (!sellerXeroContext.loading) {
        setError('getBrandingThemes');
      }
    };
    void setDropdownOptions();
  }, [sellerXeroContext]);

  const handleSubmit = async () => {
    if (
      selectedThemeOptions &&
      !sellerXeroContext.loading &&
      !sellerXeroContext.error
    ) {
      const apiThemeIds = selectedThemeOptions.map((theme) => ({
        branding_theme_id: theme.value,
      }));

      setIsSubmitting(true);

      const response = await saveXeroThemes(
        apiThemeIds,
        sellerXeroContext.stateKey!,
      );

      if (!response.ok) {
        setError('postBrandingThemes');
        setIsSubmitting(false);
        return;
      }
      navigate && navigate('/pay/xero/success/' + queryString);
    }
  };

  const handleBackClick = () => {
    if (navigate) {
      navigate('/pay/xero/payment-account/' + queryString);
    }
  };

  return (
    <div className={sellerXeroStyles.sellerXero}>
      <div className={sellerXeroStyles.contentContainer}>
        <BackButton onClick={handleBackClick} />
        <div className={sellerXeroStyles.innerContainer}>
          <h4 className={sellerXeroStyles.companyTypeHeader}>{companyName}</h4>
          <div className={sellerXeroStyles.contentSm}>
            <ProgressBar totalSteps={3} currentStep={3} />
            <div className={styles.contentInner}>
              {error === undefined && (
                <DefaultContent
                  themeOptions={themeOptions}
                  setSelectedThemeOptions={setSelectedThemeOptions}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                />
              )}
              {error === 'getBrandingThemes' && <TryAgainError />}
              {error === 'postBrandingThemes' && (
                <ErrorContent
                  themeOptions={themeOptions}
                  setSelectedThemeOptions={setSelectedThemeOptions}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
