import { Menu } from '@headlessui/react';
import { Button, Icon } from '@iwoca/orion';
import { Icons } from '@iwoca/orion/lib/components/icon/Icon';
import cn from 'classnames';

import styles from './ThreeDotOption.module.css';

type ThreeDotOption = {
  onPress: () => void;
  title: string;
  isAlert?: boolean;
};

type ThreeDotOptions = ThreeDotOption[];

export const ThreeDotOptionDropdown = ({
  options,
  className,
  iconName = 'dots',
  theme = 'grey',
}: {
  options: ThreeDotOptions;
  className?: string;
  iconName?: (typeof Icons)[number];
  theme?: 'grey' | 'white';
}) => {
  return (
    <div className={cn(className, styles.upperContainer)}>
      <Menu>
        <Menu.Button
          className={cn(styles.buttonContainer)}
          data-testid="three-dot-option"
        >
          <Button
            variant="tertiary"
            type="button"
            size="small"
            iconOnly
            className={cn(styles.threeDotButton, {
              [styles.threeDots]: theme === 'grey',
              [styles.moreHorizontal]: theme === 'white',
            })}
          >
            <Icon icon={iconName} />
          </Button>
        </Menu.Button>
        <Menu.Items
          className={cn({
            [styles.optionContainer]: theme === 'grey',
            [styles.whiteOptionContainer]: theme === 'white',
          })}
        >
          {options.map(({ onPress, title, isAlert = false }) => (
            <Menu.Item key={title}>
              {() => (
                <button
                  className={cn({
                    [styles.menuItemButton]: theme === 'grey',
                    [styles.whiteMenuItemButton]: theme === 'white',
                    [styles.menuItemButtonIsAlert]: isAlert,
                  })}
                  onClick={onPress}
                  type="button"
                >
                  {title}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  );
};
