import React from 'react';

import cn from 'classnames';

import styles from './Card.module.css';

export const Card = ({
  title,
  children,
  icon,
  className,
  ...props
}: {
  title?: React.ReactNode;
  children: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
  props?: React.HTMLProps<HTMLElement>;
}) => {
  return (
    <section {...props} className={cn(styles.Card, className)}>
      <CardHeading icon={icon}>{title}</CardHeading>
      {children}
    </section>
  );
};

const CardHeading = ({
  children,
  icon,
}: {
  children: React.ReactNode;
  icon: React.ReactNode;
}) => {
  if (!children) return null;

  let headerContent: React.ReactNode = children;

  if (typeof children === 'string') {
    headerContent = <h2 className={styles.heading}>{children}</h2>;
  }

  return (
    <header className={styles.header}>
      {icon ? icon : null}
      {headerContent}
    </header>
  );
};
