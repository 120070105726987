import { useReducer, useRef, useState } from 'react';

import { tracking } from '@iwoca/frontend-tracking-library';
import { Label } from '@iwoca/orion';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { Form, Formik } from 'formik';

import styles from './AddPaymentMethodForm.module.css';
import { Auth3DSecureModal } from './Auth3DSecureModal/Auth3DSecureModal';
import { FormValues } from './StripeAddPaymentMethodForm.types';
import { StripeProvider } from './StripeProvider';
import { stripeStyles } from './stripeStyles';
import { submitForm } from './submitForm';
import { validateFormFields } from './validateFormFields';
import { useGetFundingRequirement } from '../../../../../api/lending/lapiHooks';
import { Input } from '../../../../../components/Input/Input';
import { InputError } from '../../../../../components/InputError/InputError';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { useStateKey } from '../../../../../hooks/useStateKey.hook';
import { SubmitButton } from '../../../../components/Button/Button';
import { useNavigateToNextRequirement } from '../../../hooks/useNavigateToNextRequirement';

export const StripeAddPaymentMethodForm = () => {
  const { goToNextRequirement } = useNavigateToNextRequirement();
  const forceUpdate = useReducer((x) => x + 1, 0)[1];
  const { fetchFundingRequirements } = useGetFundingRequirement({
    queryOptions: {
      enabled: false,
    },
  });
  const { stateKey } = useStateKey();
  const [submitError, setSubmitError] = useState<string | null>();
  const [stripe3DSecureRedirectUrl, setStripe3DSecureRedirectUrl] = useState<
    string | null
  >();
  const confirmStripe3DSecureCompleteRef = useRef<() => void>();

  const handleStripe3DSecureRequired = (url: string): Promise<void> => {
    return new Promise((resolve) => {
      setStripe3DSecureRedirectUrl(url);
      confirmStripe3DSecureCompleteRef.current = () => resolve();
    });
  };

  const handleStripe3DSecureComplete = () => {
    confirmStripe3DSecureCompleteRef.current!();
    setStripe3DSecureRedirectUrl(null);
  };

  const initialValues: FormValues = {
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null,
    billingPostcode: '',
  };

  return (
    <StripeProvider>
      {({ stripe, elements }) => (
        <Formik
          initialValues={initialValues}
          validate={(values) => validateFormFields(values)}
          onSubmit={async (values) => {
            setSubmitError(null);

            try {
              await submitForm({
                stateKey: stateKey!,
                billingPostcode: values.billingPostcode,
                stripe: stripe!,
                cardNumberElement: elements!.getElement('cardNumber')!,
                onStripe3DSecureRequired: handleStripe3DSecureRequired,
              });
              await fetchFundingRequirements();
              forceUpdate();

              tracking.ampli.linkedRepaymentCard();

              goToNextRequirement();
            } catch (error) {
              // @ts-expect-error
              setSubmitError(error.message);
            }
          }}
        >
          {({
            errors,
            setFieldValue,
            setFieldTouched,
            touched,
            isValid,
            isValidating,
            dirty,
            isSubmitting,
          }) => (
            <Form>
              <div className={styles.cardFields}>
                <div>
                  <Label className={styles.cardLabel}>Card number</Label>
                  <CardNumberElement
                    onChange={async (event) => {
                      await setFieldTouched('cardNumber', true, false);
                      await setFieldValue('cardNumber', event);
                    }}
                    options={{
                      style: stripeStyles,
                      showIcon: true,
                    }}
                  />
                  <InputError
                    isVisible={touched.cardNumber || false}
                    error={errors.cardNumber}
                  />
                </div>

                <div>
                  <Label className={styles.cardLabel}>Expiry</Label>
                  <CardExpiryElement
                    onChange={async (event) => {
                      await setFieldTouched('cardExpiry', true, false);
                      await setFieldValue('cardExpiry', event);
                    }}
                    options={{
                      style: stripeStyles,
                    }}
                  />
                  <InputError
                    isVisible={touched.cardExpiry || false}
                    error={errors.cardExpiry}
                  />
                </div>

                <div>
                  <Label className={styles.cardLabel}>CVC</Label>
                  <CardCvcElement
                    onChange={async (event) => {
                      await setFieldTouched('cardCvc', true, false);
                      await setFieldValue('cardCvc', event);
                    }}
                    options={{
                      style: stripeStyles,
                    }}
                  />
                  <InputError
                    isVisible={touched.cardCvc || false}
                    error={errors.cardCvc}
                  />
                </div>
              </div>

              <div className={styles.billingSpacer}>
                <Input
                  label="Billing postcode"
                  name="billingPostcode"
                  placeholder="e.g. N6 6NG"
                  showError={false}
                />
                <InputError
                  isVisible={touched.billingPostcode || false}
                  error={errors.billingPostcode}
                />
              </div>

              <SubmitButton
                disabled={isValidating || isSubmitting || !(isValid && dirty)}
                className={styles.submitButton}
              >
                {isSubmitting ? <LoadingSpinner /> : 'Add debit card now'}
              </SubmitButton>

              <InputError
                isVisible={submitError !== undefined}
                error={submitError || ''}
              />

              {stripe3DSecureRedirectUrl && (
                <Auth3DSecureModal
                  auth3DSRedirectUrl={stripe3DSecureRedirectUrl}
                  onAuth3DSecureComplete={handleStripe3DSecureComplete}
                />
              )}
            </Form>
          )}
        </Formik>
      )}
    </StripeProvider>
  );
};
