import { useQueryParam } from '../../hooks/useQueryParam';

export function useIsOverlay({ payNow = false }: { payNow?: boolean } = {}) {
  const isOverlay = useQueryParam('overlay');

  if (payNow) {
    // We can't rely on the overlay param for pay now as it's removed by truelayer. Instead we use a specifc overlay path.
    return window.location.pathname.split('?')[0].includes('/overlay');
  }

  if (!isOverlay) return false;

  return parseInt(isOverlay) === 1;
}
