import classnames from 'classnames';
import ReactModal from 'react-modal';

import {
  getApplicantFromGuarantors,
  getGuarantorsFromFundingRequirements,
} from './IDCheck';
import styles from './IDCheck.module.css';
import { useGetFundingRequirement } from '../../../../api/lending/lapiHooks';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { IDCheckIcon } from '../../../../components/svg/IDCheckIcon';
import { useModal } from '../../../store/ModalProvider';
export const ID_CHECK_PENDING_MODAL = 'IDCheckPendingModal';

export const IDCheckPendingModal = ({
  onClickForceShowOnfidoSMS,
  showOnfidoSMSInput = false,
}: {
  onClickForceShowOnfidoSMS: () => void;
  showOnfidoSMSInput?: boolean;
}) => {
  const REFETCH_INTERVAL = 10000;
  const { isOpen, closeModal } = useModal(ID_CHECK_PENDING_MODAL);

  useGetFundingRequirement({
    queryOptions: {
      refetchInterval: (query) => {
        const { data } = query.state;
        // @ts-expect-error Currently returntype of data is unknown, this needs fixing
        const guarantors = getGuarantorsFromFundingRequirements(data.data);
        const applicant = getApplicantFromGuarantors(guarantors);

        if (!['completed', 'error'].includes(applicant!.status) && isOpen) {
          return REFETCH_INTERVAL;
        }

        return false;
      },
    },
  });

  return (
    <ReactModal
      className={styles.modal}
      overlayClassName={styles.tintedOverlay}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
    >
      <div className={styles.idCheckPendingLogo}>
        <IDCheckIcon className={styles.idCheckIcon} />
        ID Check
      </div>
      <p className={classnames(styles.overlayMessage, styles.bottomSpacer)}>
        {showOnfidoSMSInput && (
          <>
            We have texted you a link to complete your ID check. <br />
            Please complete the ID check.
          </>
        )}
        {!showOnfidoSMSInput && (
          <>
            You have an ID check in progress in another tab. <br />
            Please complete the ID check.
          </>
        )}
      </p>
      <div className={styles.overlaySeparator}></div>
      <p className={classnames(styles.overlayMessage, styles.bottomSpacer)}>
        Waiting for ID Check results...
      </p>
      <LoadingSpinner />
      <div className={styles.mobileOverride}>
        <p>Can't see ID check?</p>
        <div className={styles.overrideText}>
          If it doesn’t load{' '}
          <button
            className={styles.overrideButton}
            onClick={() => {
              onClickForceShowOnfidoSMS();
              closeModal();
            }}
          >
            Send yourself a text
          </button>
        </div>
      </div>
      <p className={styles.asterixText}>
        *This may take a few minutes to update
      </p>
    </ReactModal>
  );
};
