import React from 'react';

import { Button, Icon } from '@iwoca/orion';

import { buildQueryString } from '../../Buyer/utils/queryParams';

export const LockedButton = () => {
  const queryString = buildQueryString();

  return (
    <a href={`/pay/activation/${queryString}`}>
      <Button variant="primary">
        <Icon icon="lockCloseFilled" />
        Finish setup to unlock
      </Button>
    </a>
  );
};
