import classnames from 'classnames';

import styles from './PageNotFound.module.css';
import { ServerErrorIcon } from '../../components/svg/ServerErrorIcon';
import { iwocapayTelDisplay, iwocapayTelLink } from '../../constants.json';

export const GenericError = ({
  name,
  errorText,
  className,
}: {
  name?: string;
  errorText: string;
  className?: string;
}) => {
  return (
    <div className={classnames(styles.failurePage, className)}>
      <div className={styles.failureMessage}>
        <div className={styles.failureHeading}>
          Sorry, {name} {errorText}
        </div>
        <div
          className={classnames(
            styles.failureText,
            styles.failureImportantText,
          )}
        >
          Please give us a call on{' '}
          <a href={iwocapayTelLink} className={styles.link}>
            {iwocapayTelDisplay}
          </a>
        </div>
      </div>
      <ServerErrorIcon className={styles.failureIcon} />
    </div>
  );
};
