import React from 'react';

import { Button } from '@iwoca/orion';
import classNames from 'classnames';
import ReactModal from 'react-modal';

import { useCurrentPayLink } from '../../../Checkout/hooks/useCurrentPayLink';
import { useModal } from '../../../store/ModalProvider';
import {
  CapturedPaymentDrawdownStatus,
  useCapturedPaymentDrawdown,
} from '../../CapturedPaymentDrawdownProvider';
import styles from '../DecisionModal/DecisionModal.module.css';
import {
  DecisionModalIcon,
  DecisionModalIconType,
} from '../DecisionModal/DecisionModalIcon';

export const CAPTURED_PAYMENT_MODAL_ID = 'capturedPaymentModal';

export const CapturedPaymentDrawdownModal = () => {
  const { isOpen, closeModal } = useModal(CAPTURED_PAYMENT_MODAL_ID);
  const { decisionState } = useCapturedPaymentDrawdown();
  const { payLink } = useCurrentPayLink();

  return (
    <ReactModal
      className={classNames(styles.Modal)}
      overlayClassName={styles.Overlay}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
    >
      {(() => {
        if (decisionState === CapturedPaymentDrawdownStatus.APPROVED) {
          return (
            <>
              <DecisionModalIcon type={DecisionModalIconType.APPROVAL} />

              <p className={styles.approved}>Confirmed</p>
              <p className={styles.description}>Redirecting to overview...</p>
            </>
          );
        }

        if (decisionState === CapturedPaymentDrawdownStatus.ACKNOWLEDGED) {
          return (
            <>
              <DecisionModalIcon type={DecisionModalIconType.LOADING} />

              <p className={styles.description}>
                Sending {payLink?.seller_trading_name || 'loading...'}{' '}
                confirmation
              </p>
            </>
          );
        }

        if (decisionState === CapturedPaymentDrawdownStatus.ERROR) {
          return (
            <>
              <DecisionModalIcon type={DecisionModalIconType.ERROR} />

              <p className={styles.description}>
                Unfortunately, we have not been able to process the payment.
              </p>

              <p className={styles.description}>Please try again.</p>

              <Button className={styles.continueButton} onClick={closeModal}>
                Close
              </Button>
            </>
          );
        }

        return (
          <>
            <DecisionModalIcon type={DecisionModalIconType.LOADING} />
            <div className={styles.descriptionWrapper}>
              <p className={styles.description}>
                Confirming your payment plan...
              </p>
            </div>
          </>
        );
      })()}
    </ReactModal>
  );
};
