type temporaryDurationType = 'THREE_MONTHS' | 'TWELVE_MONTHS' | null;
export const productDurationToReadable = (
  productDuration: temporaryDurationType,
): string | null => {
  if (!productDuration) return null;
  return (
    {
      THREE_MONTHS: '3 months',
      TWELVE_MONTHS: '12 months',
    } as Record<Exclude<temporaryDurationType, null>, string>
  )[productDuration];
};
