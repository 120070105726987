import React from 'react';

import styles from './CheckoutLayout.module.css';
import { CancelButton } from '../../../components/CancelButton/CancelButton';
import { HelpBox } from '../../../components/HelpBox/HelpBox';
import { useCurrentPayLink } from '../../hooks/useCurrentPayLink';
import { RequirementsRouteConfig } from '../../routes';
import { CheckoutProgressIndicator } from '../CheckoutProgressIndicator';

export const CheckoutLayout = ({
  children,
  routes,
}: {
  children: React.ReactNode;
  routes: RequirementsRouteConfig[];
}) => {
  const { payLink } = useCurrentPayLink();

  return (
    <>
      <div className={styles.Checkout}>
        <CheckoutProgressIndicator routes={routes} />
        <div className={styles.requirement}>
          {payLink?.cancel_url && (
            <CancelButton className={styles.cancelButton} payLink={payLink} />
          )}
          {children}
          <HelpBox />
        </div>
      </div>
    </>
  );
};
