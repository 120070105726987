import { SellerContextValues, XeroTheme } from './Xero.types';
import {
  lendingApiFetchJson,
  postLendingApiJson,
} from '../../Pages/lendingApiFetch';

export function isIwocapayApplied(theme: XeroTheme): boolean {
  return (
    theme.payment_services.filter(
      (service) => service.payment_service_name === 'iwocaPay',
    ).length > 0
  );
}

export function contextLoadedSuccessfully(context: SellerContextValues) {
  return !context.loading && !context.error;
}

export function getPaymentAccounts(stateKey?: string) {
  return lendingApiFetchJson(
    `/api/lending/edge/xero/accounts/iwocapay_seller/${stateKey}/?account_type=payment`,
  );
}

export function saveXeroThemes(
  apiThemeIds: { branding_theme_id: string }[],
  stateKey: string,
) {
  return postLendingApiJson({
    url: `/api/lending/edge/xero/iwocapay_seller/branding_themes/${stateKey}/payment_services/`,
    body: {
      data: {
        branding_themes: apiThemeIds,
      },
    },
  });
}
