import React from 'react';

import { Table } from '@iwoca/orion';
import { TableProps } from '@iwoca/orion/lib/components/table/Table';

import { mapWebhookEventTypeToFriendly } from './webhookEventTypeMaps';
import { useGetSellerWebhooks } from '../../../../api/lending/lapiHooks';
import { useStateKey } from '../../../../hooks/useStateKey.hook';

export const WebhooksTable = () => {
  const { stateKey } = useStateKey();
  const { sellerWebhooks, loadingSellerWebhooks } = useGetSellerWebhooks();

  if (!stateKey || loadingSellerWebhooks || sellerWebhooks.length === 0)
    return null;
  const tableData = sellerWebhooks.map((webhook) => {
    return {
      uuid: webhook.uuid,
      customerId: webhook.customer_id,
      eventType: webhook.event_type,
      deliveredAt: webhook.delivered_at,
      body: webhook.body,
    };
  });

  const columns: TableProps<typeof tableData>['columns'] = [
    {
      id: 'uuid',
      accessorKey: 'uuid',
      header: 'Webhook ID',
    },
    {
      id: 'customerId',
      accessorKey: 'customerId',
      header: 'Customer ID',
    },
    {
      id: 'eventType',
      accessorKey: 'eventType',
      header: 'Event Type',
      cell: ({ getValue }) => {
        return mapWebhookEventTypeToFriendly(getValue());
      },
    },
    {
      id: 'deliveredAt',
      accessorKey: 'deliveredAt',
      header: 'Delivered At',
    },
    {
      id: 'body',
      accessorKey: 'body',
      header: 'Body',
    },
  ];

  return (
    <Table /*@ts-expect-error*/
      columns={columns}
      data={tableData}
      maxHeight={500}
      data-testid="webhooks-table"
    />
  );
};
