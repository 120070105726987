import { HubspotPlatformName, IntegrationName } from './integrations.types';

export const integrationNameToPlatformNameMap = {
  woocommerce: 'WooCommerce',
  magento: 'Magento 2',
  xero: null,
  quickbooks: null,
  opencart: null,
  shopify: 'Shopify',
} as const satisfies {
  [key in IntegrationName]: HubspotPlatformName | null;
};
