import RefreshIcon from '../../../components/svg/RefreshIcon.svg?react';
import styles from '../Xero.module.css';

export function RefreshPillButton({ onClick }: { onClick: () => void }) {
  return (
    <button className={styles.pill} onClick={onClick}>
      Refresh
      <RefreshIcon className={styles.buttonIcon} />
    </button>
  );
}
