import React from 'react';

import { Spinner } from '@iwoca/orion';

import { PayLinkRow } from './components/PayLinkRow/PayLinkRow';
import styles from './MobilePaylinks.module.css';
import { PayLinkColumns } from '../PayLinkTable/PayLinkTable';

export const MobilePaylinks = ({
  paylinks,
  isLoading,
}: {
  paylinks: PayLinkColumns[];
  isLoading: boolean;
}) => {
  if (isLoading)
    return (
      <div className={styles.loadingSpinner}>
        <Spinner />
      </div>
    );

  return (
    <div className={styles.mobileCards}>
      {paylinks.map((payLink: PayLinkColumns) => (
        <PayLinkRow key={payLink.paylink_id} payLink={payLink} />
      ))}
    </div>
  );
};
