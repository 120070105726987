import { useCurrentOfferId } from './useCurrentOfferId';
import { useCurrentPayLink } from './useCurrentPayLink';
import { useGetFormalOffers } from '../../../api/lending/lapiHooks';
import { FormalOffer } from '../../../Seller/PayLinks/utils/Paylinks.types';
import { getDurationInMonths } from '../../utils/product';

export function useInterestRate() {
  const { payLink } = useCurrentPayLink();
  const offerId = useCurrentOfferId();
  const { formalOffers } = useGetFormalOffers();

  const formalOffer = formalOffers?.formal_offers.find(
    (offer) => offer.offer_id === offerId,
  );

  const payLinkPricingInterest =
    getDurationFromFormalOffer(formalOffer) === 12
      ? payLink?.product_pricing?.TWELVE_MONTHS?.representative_interest
      : payLink?.product_pricing?.THREE_MONTHS.representative_interest;

  if (payLinkPricingInterest === 0) {
    return 0;
  }

  return formalOffer?.interest_rates?.[0].rate;
}

function getDurationFromFormalOffer(formalOffer?: FormalOffer) {
  if (!formalOffer?.duration) return null;

  const durationInMonths = getDurationInMonths(formalOffer.duration);

  return durationInMonths;
}
