import { useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDebounce<F extends any[], R>(
  fn: (...args: F) => R,
  delay = 500,
) {
  type RejectFunctionType = (result?: R) => void;
  const cancelRef = useRef<null | RejectFunctionType>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const currentValue = useRef<any>(null);

  const debouncedFn = (...args: F): Promise<Awaited<R>> => {
    if (cancelRef.current !== null) {
      cancelRef.current(currentValue.current);
    }

    return new Promise((resolve, reject) => {
      const timeout = setTimeout(async () => {
        let result;
        try {
          result = await fn(...args);
          cancelRef.current = null;
        } catch (e) {
          return reject(result);
        }

        currentValue.current = result;
        resolve(result);
      }, delay);

      cancelRef.current = (result?: R) => {
        clearTimeout(timeout);
        reject(result);
      };
    });
  };

  useEffect(() => {
    return () => {
      if (cancelRef.current === null) return;
      cancelRef.current();
    };
  }, []);

  return debouncedFn;
}
