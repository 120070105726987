import React from 'react';

import styles from './BreakdownTable.module.css';

export const BreakdownTable = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.BreakdownTable}>{children}</div>;
};

export const BreakdownTableCell = ({
  title,
  value,
}: {
  title: string;
  value: string | null;
}) => {
  return (
    <div className={styles.BreakdownCell}>
      <div className={styles.cellTitle}>{title}</div>
      <div className={styles.cellValue}>{value}</div>
    </div>
  );
};
