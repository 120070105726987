import React, { useEffect, useState } from 'react';

import { fetchPostIwocapayBuyerSpendingLimitRequest } from '@iwoca/lapi-client/edge';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useGetStateByStateKey } from '../../../../api/lending/lapiHooks';
import { iwocapayTelDisplay } from '../../../../constants.json';
import { createBuyerAccount } from '../../../../Pages/stateApi';
import logger from '../../../../utils/logger';
import {
  EmailFormInput,
  useEmailValidation,
} from '../../../components/EmailInput/EmailInput';
import styles from '../../../PayLinkLanding/PaymentDetails/PaymentDetails.module.css';
import { SIGNUP_BASE_PATH } from '../../../Signup/routes';
import { createState } from '../../../utils/customerState';
import { buildQueryString } from '../../../utils/queryParams';

export type FormValues = {
  emailAddress: string;
  marketingOptIn: boolean;
};

export const EmailDetails = () => {
  const { sellerHandle } = useParams();
  const validate = useEmailValidation();
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { state: customerState } = useGetStateByStateKey();

  const initialFormValues: { emailAddress: string; marketingOptIn: boolean } = {
    emailAddress: '',
    marketingOptIn: true,
  };

  const methods = useForm({
    defaultValues: initialFormValues,
    resolver: validate,
    mode: 'onChange',
  });

  const onSubmit = async ({
    values,
    stateKey,
  }: {
    values: FormValues;
    stateKey?: string;
  }) => {
    if (!sellerHandle) {
      throw Error('No seller handle found');
    }
    if (!stateKey) {
      await submitSpendingLimitNewCustomer(
        values.emailAddress,
        values.marketingOptIn,
        sellerHandle,
      );
    } else {
      await submitSpendingLimitReturningCustomer(stateKey, sellerHandle);
    }
  };

  const onHookFormSubmit = methods.handleSubmit(async (values) => {
    await onSubmit({
      values,
      stateKey: customerState?.state_key,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }).catch((error: any) => {
      setSubmitError(
        `Oops something went wrong. Please try again or give us a call on ${iwocapayTelDisplay}.`,
      );
      logger.error(error.message, error);
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onHookFormSubmit} noValidate>
        <EmailDetailsForm submitError={submitError} />
      </form>
    </FormProvider>
  );
};

export const EmailDetailsForm = ({
  submitError,
}: {
  submitError: string | null;
}) => {
  const { setValue: setFieldValue, trigger } = useFormContext<FormValues>();

  const { state: customerState } = useGetStateByStateKey();
  const loggedInCustomerEmailAddress =
    customerState?.application?.people?.[0].emails?.[0].email;

  useEffect(() => {
    setFieldValue('emailAddress', loggedInCustomerEmailAddress || '');
  }, [loggedInCustomerEmailAddress, setFieldValue, trigger]);

  return (
    <div>
      <EmailFormInput
        loggedIn={!!loggedInCustomerEmailAddress}
        isSpendingLimit
      />
      {submitError && <p className={styles.fieldError}>{submitError}</p>}
    </div>
  );
};

const createSpendingLimitRequest = async ({
  stateKey,
  sellerHandle,
}: {
  stateKey: string;
  sellerHandle: string;
}) => {
  if (!stateKey) {
    throw new Error('No stateKey was provided.');
  }
  return await fetchPostIwocapayBuyerSpendingLimitRequest({
    stateKey,
    body: {
      data: { seller_handle: sellerHandle },
    },
  });
};

async function submitSpendingLimitNewCustomer(
  emailAddress: string,
  marketingOptIn: boolean,
  sellerHandle: string,
) {
  const customerState = await createBuyerAccount(
    createState(emailAddress, marketingOptIn),
  );
  const stateKey = customerState.data!.state_key;
  const spendingLimitResponse = await createSpendingLimitRequest({
    stateKey: stateKey,
    sellerHandle: sellerHandle,
  });
  const spending_limit_request_uuid = spendingLimitResponse.data!.uuid;
  window.location.href = `${SIGNUP_BASE_PATH}${buildQueryString({
    spending_limit_request_uuid: spending_limit_request_uuid,
  })}`;
}

async function submitSpendingLimitReturningCustomer(
  stateKey: string,
  sellerHandle: string,
) {
  const spendingLimitResponse = await createSpendingLimitRequest({
    stateKey: stateKey,
    sellerHandle: sellerHandle,
  });
  const spending_limit_request_uuid = spendingLimitResponse.data!.uuid;
  window.location.href = `${SIGNUP_BASE_PATH}${buildQueryString({
    spending_limit_request_uuid: spending_limit_request_uuid,
  })}`;
}
