import { GetPayLinkResponse } from '@iwoca/lapi-client/edge';
import Cookies from 'js-cookie';

import {
  createBuyerAccount,
  CustomerState,
  getState,
  putState,
} from '../../../../Pages/stateApi';
import logger from '../../../../utils/logger';
import { createState } from '../../../utils/customerState';
import { TPricingOption } from '../../utils/PayLinkLanding.types';

export async function submitBuyerStepZeroNewCustomer(
  emailAddress: string,
  marketingOptIn: boolean,
  payLink: GetPayLinkResponse,
  paymentOption: TPricingOption,
) {
  const customerState = await createBuyerAccount(
    createState(emailAddress, marketingOptIn, payLink.id),
  );
  await postPayLinkApplication({
    payLink: payLink.id,
    paymentOption: paymentOption,
  });

  return customerState;
}

export async function submitBuyerStepZeroReturningCustomer(
  stateKey: string,
  payLink: GetPayLinkResponse,
  paymentOption: TPricingOption,
) {
  const { data }: { data: CustomerState } = await getState(stateKey!);

  const newUserState = {
    ...data,
    ui: { ...data.ui, iwocapay_paylink_id: payLink.id },
  };

  await putState(newUserState);
  await postPayLinkApplication({
    payLink: payLink.id,
    paymentOption: paymentOption,
  });
}

async function postPayLinkApplication({
  payLink,
  paymentOption,
}: {
  payLink: string;
  paymentOption: TPricingOption;
}) {
  const res = await fetch(
    `/api/lending/edge/pay_link/${payLink}/application/`,
    {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        'content-type': 'application/json',
        'x-csrftoken': Cookies.get('csrftoken') as string,
      },
      body: JSON.stringify({
        data: {
          requested_duration:
            paymentOption === 'payLater12' ? 'TWELVE_MONTHS' : 'THREE_MONTHS',
        },
      }),
    },
  );
  if (!res.ok) {
    const loggerFunction = res.status >= 500 ? logger.error : logger.warn;
    loggerFunction('POST to pay link application API failed', {
      payLinkId: payLink,
      statusCode: res.status,
    });
    throw new Error(
      `POST to pay link application API failed ${res.status} ${res.statusText}`,
    );
  }
}
