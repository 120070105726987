import { Dispatch, SetStateAction } from 'react';

import * as Yup from 'yup';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateForm = async <T extends { [key: string]: any }>(
  form: HTMLFormElement,
  formSchema: Yup.ObjectSchema<T>,
  setErrors: Dispatch<SetStateAction<{ [key: string]: string }>>,
) => {
  try {
    const data = await formSchema.validate(
      Object.fromEntries(new FormData(form).entries()),
      { abortEarly: false },
    );
    setErrors({});
    return data;
  } catch (err: unknown) {
    if (err instanceof Yup.ValidationError) {
      const errors: { [key: string]: string } = {};
      err.inner.forEach((e) => {
        errors[e.path!] = e.message;
      });
      setErrors(errors);
    } else {
      throw err;
    }
  }
};
