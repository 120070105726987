import { AlertBox, Button, Icon, Modal } from '@iwoca/orion';

import { HideFor } from '../../../components/ConditionalDisplay/ConditionalDisplay';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { getIwocaPayMeUrl } from '../../../components/PayMeLink/getIwocaPayMeUrl';
import { PayMeLink } from '../../../components/PayMeLink/PayMeLink';
import { SafeLink } from '../../../components/SafeLink/SafeLink';
import { useSellerHandle } from '../../../hooks/useSellerHandle.hook';
import { useSellerPricing } from '../../../hooks/useSellerPricing';
import { CheckoutPreview } from '../components/CheckoutPreview/CheckoutPreview';
import { PricingOptionDescriptions } from '../components/PricingOptionDescriptions/PricingOptionDescriptions';
import {
  Column,
  VerticalSplitLayout,
} from '../components/VerticalSplitLayout/VerticalSplitLayout';
import { usePayIn12 } from '../hooks/usePayIn12';
import payLinksPageStyles from '../PayLinks.module.css';
import { usePreview } from '../usePreview';

export const ReusablePaylinkPage = () => {
  const { previewValues } = usePreview();

  const LeftSide = () => {
    const { sellerHandle } = useSellerHandle();
    const { sellerPricing } = useSellerPricing();
    const {} = usePayIn12();
    if (!sellerHandle || sellerPricing === null) return null;
    const payMeUrl = getIwocaPayMeUrl(sellerHandle);

    return (
      <div className="mt-2xl grid grid-cols-1 gap-xl">
        <div>
          <h1 className="mb-0 mt-0 text-2xl font-bold text-primary-10">
            A reusable pay link for any customer
          </h1>
          <p className="mb-0 mt-s text-m font-reg text-primary-10">
            To be added anywhere you want to take payments of different amounts.
          </p>
        </div>
        <AlertBox
          variant="connect"
          className="grid grid-cols-[max-content,1fr] items-center gap-s text-s font-reg text-primary-10"
        >
          <Icon icon="infoCircleOutline" />
          Your customer enters the amount and reference, and chooses to Pay Now
          or Pay Later.
        </AlertBox>
        <div>
          <h2 className="mb-0 mt-0 text-l font-bold text-primary-10">
            Choose who pays for Pay Later via Universal links
          </h2>
          <p className="mb-0 mt-s text-m font-reg text-primary-10">
            These settings will also apply to your integrations and API
            transactions.
          </p>
        </div>
        <div className="grid grid-cols-1 gap-m">
          <PricingOptionDescriptions />
        </div>
        <HideFor editor contributor>
          <SafeLink
            to="/pay/manage-account"
            className="flex items-center bg-none p-[0px] text-s font-bold text-primary-40 no-underline"
          >
            Change these settings
            <Icon icon="penFilled" className="ml-s fill-primary-40" />
          </SafeLink>
        </HideFor>
        <div className="sm:fixed sm:bottom-0 sm:left-0 sm:z-[1000000] sm:w-full sm:border-t-[1px] sm:border-t-secondary-90 sm:bg-[#F5F8FA] sm:p-l sm:pb-4xl">
          <h2 className="m-0 mb-s p-0 text-m text-primary-10">
            Your universal paylink
          </h2>
          <PayMeLink payMeUrl={payMeUrl} />
        </div>
      </div>
    );
  };

  return (
    <VerticalSplitLayout className={payLinksPageStyles.PayLinksPage}>
      <Column>
        <LeftSide />
      </Column>
      <Column>
        {!!previewValues.pricing_3m ? (
          <CheckoutPreview
            sellerName={previewValues.sellerName}
            pricingPromotions={{
              THREE_MONTHS: previewValues.pricing_3m.promotions,
              TWELVE_MONTHS: previewValues.pricing_12m?.promotions,
            }}
            representativeInterest={{
              THREE_MONTHS: previewValues.pricing_3m.representativeInterest,
              TWELVE_MONTHS: previewValues.pricing_12m?.representativeInterest,
            }}
          />
        ) : (
          <div>Loading preview...</div>
        )}

        {/* This is to account for the height of the fixed footer on mobile - this should be
        refactored before launch when the other paylink pages are updated */}
        <div className="sm:h-[200px]"></div>
      </Column>
    </VerticalSplitLayout>
  );
};

export const ConfirmationModal = ({
  isOpen,
  isSubmitting,
  onConfirm,
  onCancel,
}: {
  isOpen: boolean;
  isSubmitting: boolean;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      title="Change your Pay Later pricing settings for Universal links"
      subTitle="It’ll update for everyone who uses universal links or checks out with any of our integrations - unless they’ve already started signing up with us."
    >
      <div className="flex gap-m">
        <Button onClick={onConfirm} variant="primary" disabled={isSubmitting}>
          {!isSubmitting ? <>Yes, update</> : <LoadingSpinner />}
        </Button>
        {!isSubmitting && (
          <Button variant="tertiary" onClick={onCancel}>
            Discard changes
          </Button>
        )}
      </div>
    </Modal>
  );
};
