import {
  BreakdownTable,
  BreakdownTableCell,
} from './BreakdownTable/BreakdownTable';
import { displayCurrency } from '../../utils/DisplayCurrency';
import { roundTo } from '../../utils/roundTo';
import { useCurrentPayLink } from '../Checkout/hooks/useCurrentPayLink';
import { useInterestRate } from '../Checkout/hooks/useInterestRate';
import { useTotalInterest } from '../Checkout/hooks/useTotalInterest';

export const DecisionBreakdownTable = () => {
  const interestRate = useInterestRate();
  const totalInterest = useTotalInterest();

  const { payLink } = useCurrentPayLink();

  const displayInterest =
    interestRate !== undefined ? roundTo(interestRate * 100, 2) : 'XX';

  return (
    <BreakdownTable>
      <BreakdownTableCell
        title="Amount"
        value={payLink?.amount ? displayCurrency(payLink.amount) : null}
      />
      <BreakdownTableCell
        title="Interest rate"
        value={`${displayInterest}% per month`}
      />
      <BreakdownTableCell
        title="Total interest"
        value={totalInterest !== null ? displayCurrency(totalInterest) : null}
      />
    </BreakdownTable>
  );
};
