import Cookies from 'js-cookie';

import { HubspotPlatformName } from './integrations.types';

export async function postInterestToHubspot({
  email,
  platform,
}: {
  email: string;
  platform: HubspotPlatformName;
}) {
  if (window.location.origin !== 'https://www.iwoca.co.uk') return; // Only submit in prod

  const HUBSPOT_FORM_URL =
    'https://api.hsforms.com/submissions/v3/integration/submit/6334445/6fa92536-2f08-4d51-bba5-b79cc2c974df';

  const response = await fetch(HUBSPOT_FORM_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    body: JSON.stringify({
      fields: [
        { name: 'email', value: email },
        { name: 'e_commerce_platform', value: platform },
      ],
      context: {
        hutk: Cookies.get('hubspotutk'),
      },
    }),
  });

  return response;
}
