import { SVGAttributes } from 'react';

export const IDCheckIcon = (props: SVGAttributes<HTMLOrSVGElement>) => {
  return (
    <svg
      {...props}
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5 14C28.5 15.9891 27.7098 17.8968 26.3033 19.3033C24.8968 20.7098 22.9891 21.5 21 21.5C19.0109 21.5 17.1032 20.7098 15.6967 19.3033C14.2902 17.8968 13.5 15.9891 13.5 14C13.5 12.0109 14.2902 10.1032 15.6967 8.6967C17.1032 7.29018 19.0109 6.5 21 6.5C22.9891 6.5 24.8968 7.29018 26.3033 8.6967C27.7098 10.1032 28.5 12.0109 28.5 14ZM25.5 14C25.5 12.8065 25.0259 11.6619 24.182 10.818C23.3381 9.97411 22.1935 9.5 21 9.5C19.8065 9.5 18.6619 9.97411 17.818 10.818C16.9741 11.6619 16.5 12.8065 16.5 14C16.5 15.1935 16.9741 16.3381 17.818 17.182C18.6619 18.0259 19.8065 18.5 21 18.5C22.1935 18.5 23.3381 18.0259 24.182 17.182C25.0259 16.3381 25.5 15.1935 25.5 14ZM6 33.5C6 27.5 9.0015 24.5 15.006 24.5H27C33 24.5 36 27.5 36 33.5C36 35.4995 34.9995 36.5 33 36.5H9C7.0005 36.5 6 35.4995 6 33.5ZM15 27.5C10.9995 27.5 9 29.4995 9 33.5H33C33 29.4995 31.0005 27.5 27 27.5H15Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 39.5L3 34.5H0V39.5V42.5H3H8V39.5H3Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39 39.5L39 34.5H42V39.5V42.5H39H34V39.5H39Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3.5L3 8.5H0V3.5V0.5H3H8V3.5H3Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39 3.5L39 8.5H42V3.5V0.5H39H34V3.5H39Z"
        fill="white"
      />
    </svg>
  );
};
