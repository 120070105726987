import { GetSellerWebhooksResponse } from '@iwoca/lapi-client/iwocapay';

export const WEBHOOK_EVENT_TYPES = {
  iwocapay_order_status_changed: 'Order Status Changed',
  shopify_payment_resolve: 'Shopify Payment Resolved',
  shopify_payment_reject: 'Shopify Payment Rejected',
  shopify_refund_resolve: 'Shopify Payment Resolved',
  unknown: 'Unknown',
} as const;

export type TWebhookEventType =
  (typeof WEBHOOK_EVENT_TYPES)[keyof typeof WEBHOOK_EVENT_TYPES];

export const mapWebhookEventTypeToFriendly = (
  eventType: GetSellerWebhooksResponse['data'][number]['event_type'],
): TWebhookEventType => {
  if (!eventType) {
    return WEBHOOK_EVENT_TYPES.unknown;
  }
  // @ts-expect-error
  return WEBHOOK_EVENT_TYPES[eventType] || WEBHOOK_EVENT_TYPES.unknown;
};
