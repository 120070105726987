import { ButtonHTMLAttributes, useEffect, useRef, useState } from 'react';

import classnames from 'classnames';

import styles from './CopyButton.module.css';
import { RoundedArrow } from '../svg/RoundedArrow';

type CopyButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick: () => void;
};

export const CopyButton = ({
  onClick,
  children,
  className,
  ...props
}: CopyButtonProps) => {
  const [isCopiedTextVisible, setIsCopiedTextVisible] = useState(false);
  const isMountedRef = useRef(false);

  const handleClick = () => {
    setIsCopiedTextVisible(true);

    onClick();
  };

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  });

  useEffect(() => {
    if (isCopiedTextVisible) {
      const copyTimeout = setTimeout(() => {
        if (!isMountedRef.current) return;

        setIsCopiedTextVisible(false);
      }, 1000);

      return () => {
        clearTimeout(copyTimeout);
      };
    }
  }, [isCopiedTextVisible]);

  return (
    <button
      {...props}
      className={classnames(styles.CopyButton, className)}
      type="button"
      onClick={handleClick}
    >
      {children}
      {isCopiedTextVisible ? (
        <div className={styles.copyLinkPopup}>
          <RoundedArrow className={styles.RoundedArrow} />
          Copied!
        </div>
      ) : null}
    </button>
  );
};
