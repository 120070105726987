export const PlusIcon = ({ title = 'Plus icon', ...props }) => {
  return (
    <svg
      {...props}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0C6.44772 0 6 0.447716 6 1V6H1C0.447715 6 0 6.44771 0 7C0 7.55228 0.447716 8 1 8H6V13C6 13.5523 6.44772 14 7 14C7.55228 14 8 13.5523 8 13V8H13C13.5523 8 14 7.55228 14 7C14 6.44772 13.5523 6 13 6H8V1C8 0.447715 7.55228 0 7 0Z"
        fill="white"
      />
    </svg>
  );
};
