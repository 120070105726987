import { useGetStateByStateKey } from '../api/lending/lapiHooks';

export const useGetCustomerEmail = (): string | undefined => {
  const { state: customerState } = useGetStateByStateKey();

  const people = customerState?.application?.people;
  if (!people) return;

  const applicant = people.find((person) =>
    person.roles?.includes('applicant'),
  );
  if (!applicant) return;

  const emails = applicant?.emails;
  if (!emails) return;

  return emails.find((email) => email.type === 'primary')?.email;
};
