import { TGetFundingRequirementsResponse } from '../../../api/lending/edge';

export const getRequirementByResolutionMethod = (
  requirements: TGetFundingRequirementsResponse['data'],
  resolution_method: TGetFundingRequirementsResponse['data'][number]['recommended_resolution_method'],
) => {
  return requirements?.find(
    (requirement) =>
      requirement.recommended_resolution_method === resolution_method,
  );
};

const getIsVisible = (
  requirement: TGetFundingRequirementsResponse['data'][number],
) => {
  return requirement.status !== 'completed';
};

export const checkRequirementVisible = (
  fundingRequirement: TGetFundingRequirementsResponse['data'],
  resolution_method: TGetFundingRequirementsResponse['data'][number]['recommended_resolution_method'],
) => {
  const requirement = getRequirementByResolutionMethod(
    fundingRequirement,
    resolution_method,
  );

  if (!requirement) return false;

  return getIsVisible(requirement);
};
