import { GetSellerPayLinkResponse } from '@iwoca/lapi-client/iwocapay';

import { displayCurrency } from '../../../utils/DisplayCurrency';
import { getFormattedDate } from '../../../utils/DisplayDate';
import { productDurationToReadable } from '../../../utils/ProductDuration';
import { PayLinkColumns } from '../components/PayLinkTable/PayLinkTable';

export const normalisePayLinkTableData = ({
  payLinks,
  sellerHandle,
  selectedRows,
  handleAddSelectedRow,
  handleRemoveSelectedRow,
  refreshTable = () => {},
}: {
  payLinks: GetSellerPayLinkResponse['data'];
  sellerHandle: string;
  selectedRows: string[];
  handleAddSelectedRow: (payLinkId: string[]) => void;
  handleRemoveSelectedRow: (payLinkIds: string[]) => void;
  refreshTable?: () => void;
}): PayLinkColumns[] => {
  return payLinks.map((payLink) => {
    return {
      paylink_id: payLink.id,
      date: getFormattedDate(payLink.created),
      amount: displayCurrency(payLink.amount),
      source: payLink.source,
      status: payLink.status,
      reference: payLink.reference,
      buyerName: payLink.buyer_name,
      declineDate: payLink.decline_date,
      payLinkLink: `${window.location.protocol}//${window.location.host}/pay/to/${sellerHandle}/${payLink.id}`,
      capture_by: payLink.capture_by,
      productDuration: productDurationToReadable(payLink.product_duration),
      selectedRows: selectedRows,
      shopify_payment_id: payLink.shopify_payment_id,
      funded_via: payLink.funded_via,
      formal_offer_expiry_date: payLink.formal_offer_expiry_date,
      handleAddSelectedRow: handleAddSelectedRow,
      handleRemoveSelectedRow: handleRemoveSelectedRow,
      refreshTable: refreshTable,
    };
  });
};
