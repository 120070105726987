import { Fragment } from 'react';

import { Combobox } from '@headlessui/react';
import { fetchPostSharedUserAction } from '@iwoca/lapi-client/iwocapay';
import { Input as OrionInput } from '@iwoca/orion';
import cn from 'classnames';
import { Form, Formik, useField } from 'formik';

import styles from './DirectorInfo.module.css';
import { directorInfoValidator } from './directorInfoValidator';
import { Policy } from './Policy/Policy';
import {
  AssociatedPerson,
  useGetAssociatedPeople,
} from '../../../../api/lending/lapiHooks';
import { AddressInput } from '../../../../components/AddressInput/AddressInput';
import { Dropdown } from '../../../../components/Dropdown/Dropdown';
import { formatDate } from '../../../../components/Input/formatDate';
import { Input } from '../../../../components/Input/Input';
import { InputError } from '../../../../components/InputError/InputError';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { useStateKey } from '../../../../hooks/useStateKey.hook';
import { Accordion } from '../../../components/Accordion/Accordion';
import { SubmitButton } from '../../../components/Button/Button';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { useSoleTraderPilot } from '../../../hooks/useSoletraderPilot';
import { RadioButton } from '../../components/RadioButton/RadioButton';
import { StepCard } from '../../components/StepCard/StepCard';
import { useDecision } from '../../DecisionProvider';
import { DIRECTOR_INFO_STEP_ID } from '../Steps';
import { useStepData } from '../useStepData';
import { useStepStatus } from '../useStepStatus';

export const DirectorInfo = () => {
  const { stateKey } = useStateKey();
  const { stepData, updateStepData, isUpdatingStepData } = useStepData(
    DIRECTOR_INFO_STEP_ID,
  );

  const { requestDecision } = useDecision();

  const stepStatus = useStepStatus(DIRECTOR_INFO_STEP_ID);
  const isActive = stepStatus === 'active';
  const isSoleTraderPilot = useSoleTraderPilot();

  const handleSubmit = async (values: typeof stepData) => {
    await updateStepData(values);

    await fetchPostSharedUserAction({
      stateKey: stateKey!,
      body: { user_action: 'FINISH_SIGNUP' },
    });

    await requestDecision();
  };

  return (
    <StepCard title="3. Your details">
      {isActive && (
        <>
          <Accordion title="Why am I providing this info?">
            <h3 className={styles.accordionPanelTitle}>Why we need this</h3>
            We need personal details for fraud & anti money laundering checks.
            These details are also required for our credit checks.
          </Accordion>
          <Formik
            initialValues={stepData}
            onSubmit={handleSubmit}
            validationSchema={directorInfoValidator}
          >
            {({ values }) => {
              const isSoleTrader = values.companyType === 'sole_trader';
              const showSoleTraderFlow = isSoleTrader && isSoleTraderPilot;
              return (
                <Form className={styles.form} autoComplete="off">
                  <div className={styles.firstRow}>
                    <Dropdown name="title" labelText="Title">
                      <option value=""></option>
                      <option value="mr">Mr</option>
                      <option value="ms">Ms</option>
                      <option value="mrs">Mrs</option>
                      <option value="miss">Miss</option>
                      <option value="sir">Sir</option>
                      <option value="professor">Professor</option>
                      <option value="doctor">Doctor</option>
                      <option value="lord">Lord</option>
                      <option value="lady">Lady</option>
                      <option value="baron">Baron</option>
                      <option value="baroness">Baroness</option>
                      <option value="reverend">Reverend</option>
                    </Dropdown>
                    <NameLookupInput />
                    <Input label="Last name" name="lastName" />
                  </div>
                  <Input
                    label="Mobile number"
                    labelDescriptionText="We'll use your mobile number if we need to contact you about your application. Don't worry – it won't be shared with anyone else."
                    name="phoneNumber"
                    className={styles.signupInput}
                  />
                  <Input
                    name="dateOfBirth"
                    label="Date of birth (DD/MM/YYYY)"
                    placeholder="DD/MM/YYYY"
                    className={styles.signupInput}
                    formatter={formatDate}
                  />
                  <AddressInput address={values.address} />
                  {!showSoleTraderFlow && (
                    <RadioButton
                      labelText="Are you a major shareholder of this company?"
                      labelDescriptionText="A major shareholder is someone that owns 25% or more of the company's shares."
                      name="isShareholder"
                      options={SHAREHOLDER_OPTIONS}
                      className={styles.radioButton}
                    />
                  )}
                  <div>
                    <Policy />
                    <Checkbox
                      name="privacyPolicyAgreed"
                      labelText="I confirm that I am happy with this"
                    />
                  </div>
                  <SubmitButton className={styles.button}>
                    {!isUpdatingStepData ? 'Submit' : <LoadingSpinner />}
                  </SubmitButton>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </StepCard>
  );
};

const NameLookupInput = () => {
  const [firstNameField, { error, touched }, { setValue: setFirstName }] =
    useField('firstName');
  const [, , { setValue: setLastName }] = useField('lastName');

  const { associatedPeople } = useGetAssociatedPeople();

  const getPeopleOptions = () => {
    const firstNameInput: string = firstNameField.value;

    if (!firstNameInput) return [];

    return associatedPeople.filter((person) => {
      const { first_name } = person;

      const firstNameInputLowerCase = firstNameInput.toLowerCase();
      return (
        first_name && first_name.toLowerCase().includes(firstNameInputLowerCase)
      );
    });
  };

  return (
    <Combobox
      value={firstNameField.value}
      onChange={async (person: AssociatedPerson) => {
        await setFirstName(person.first_name);
        await setLastName(person.last_name);
      }}
    >
      <div>
        <div className={styles.lookupInput}>
          <Combobox.Input
            onChange={(e) => setFirstName(e.target.value)}
            as={Fragment}
          >
            <OrionInput
              label="First name"
              data-testid="firstName"
              name="firstName"
              className="fs-mask"
            />
          </Combobox.Input>
          <InputError isVisible={touched} error={error} />
          {getPeopleOptions().length > 0 && (
            <Combobox.Options className={cn(styles.options, 'fs-mask')}>
              {getPeopleOptions().map((person, index) => (
                <Combobox.Option
                  className={({ active }) =>
                    cn(styles.option, {
                      [styles.active]: active,
                    })
                  }
                  key={index}
                  value={person}
                >
                  {person.first_name} {person.last_name} (
                  {person.month_of_birth?.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                  })}
                  /{person.year_of_birth})
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </div>
    </Combobox>
  );
};

const SHAREHOLDER_OPTIONS = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
] as const;
