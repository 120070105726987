import { useGetFormalOffers } from '../../../api/lending/lapiHooks';

export const useGetValidIwocapayFormalOffers = () => {
  const { formalOffers, loadingFormalOffers } = useGetFormalOffers();
  const validFormalOffers = formalOffers?.formal_offers.filter(
    (formalOffer) => {
      return (
        formalOffer.product_type === 'iwocapay' && formalOffer.is_valid === true
      );
    },
  );

  return {
    formalOffers: validFormalOffers || [],
    loadingFormalOffers,
  };
};
