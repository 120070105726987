import { useEffect, useState } from 'react';

import { GetSingleImmediatePaymentResponse } from '@iwoca/lapi-client/edge';
import { fetchPostSharedPayNowConfirmationEmail } from '@iwoca/lapi-client/iwocapay';
import { Spinner } from '@iwoca/orion';

import { PaymentError } from './PaymentError/PaymentError';
import { PaymentStatusLoading } from './PaymentStatusLoading/PaymentStatusLoading';
import styles from './PayNowConfirmation.module.css';
import { ReturnToSellerCTA } from './ReturnToSellerCTA/ReturnToSellerCTA';
import { StatusTemplate } from './StatusTemplate/StatusTemplate';
import { useGetPaymentStatus } from './useGetPaymentStatus';
import { usePaymentId } from './usePaymentId';
import { LinkWithQuery } from '../../components/LinkWithQuery/LinkWithQuery';
import HappyPennyCharacter from '../../components/svg/Critters/HappyPennyWithSign.svg?react';
import UnhappyCritter from '../../components/svg/Critters/UnhappyCritter.svg?react';
import LoadingSVG from '../../components/svg/Graphics/LoaderGraphic.svg?react';
import IwocaPayIcon from '../../components/svg/iwocaPayLogo.svg';
import { displayCurrency } from '../../utils/DisplayCurrency';
import { getPayLinkUrlPath } from '../../utils/getPayLinkUrl';
import { ECOM_REDIRECT_DELAY_IN_SECONDS } from '../Checkout/components/DrawDownModal/Success/Success';
import { useEcomPostMessage } from '../hooks/useEcomPostMessage';
import { useIsOverlay } from '../hooks/useIsOverlay';
import { decryptPayNowEmail } from '../PayNow/emailEncryption';

export const PayNowConfirmation = () => {
  const paymentId = usePaymentId();
  const { isLoading, error, paymentData, isEcommerce } =
    useGetPaymentStatus(paymentId);

  const isOverlay = useIsOverlay({ payNow: true });

  const dataIsValid = validatePaymentData(paymentData);

  const redirectUrl = paymentData?.order?.redirect_url;

  const shouldAutoRedirect =
    isEcommerce &&
    !isOverlay &&
    redirectUrl &&
    paymentData?.status === 'complete';

  useEffect(() => {
    const processEmailReceipt = async (
      encrypted_string: string,
      include_marketing: string,
    ) => {
      const decryptedEmail = await decryptPayNowEmail({
        paymentId,
        encryptedString: encrypted_string,
      });
      if (!decryptedEmail) {
        localStorage.iwocaPayNowEnc = encrypted_string;
        localStorage.iwocaPayNowMarketingConsent = include_marketing;
        return;
      }

      return await fetchPostSharedPayNowConfirmationEmail({
        paymentId,
        body: {
          email_address: decryptedEmail,
          include_marketing: include_marketing == 'true',
        },
      });
    };

    if (paymentData?.status === 'complete') {
      const { iwocaPayNowEnc, iwocaPayNowMarketingConsent } = localStorage;
      if (!iwocaPayNowEnc) return;
      delete localStorage.iwocaPayNowEnc;
      delete localStorage.iwocaPayNowMarketingConsent;
      void processEmailReceipt(iwocaPayNowEnc, iwocaPayNowMarketingConsent);
    }
  }, [paymentId, paymentData?.status]);

  useEcomPostMessage({
    enabled: isOverlay && paymentData?.status === 'complete',
    status: 'SUCCESSFUL',
    redirectUrl,
  });

  const [redirectCount, setRedirectCount] = useState(
    ECOM_REDIRECT_DELAY_IN_SECONDS,
  );

  useEffect(() => {
    if (!shouldAutoRedirect) return;

    if (redirectCount === 0) {
      window.location.href = redirectUrl;
      return;
    }

    const redirectTimout = setTimeout(() => {
      setRedirectCount((prevCount) => prevCount - 1);
    }, 1000);
    return () => clearTimeout(redirectTimout);
  }, [shouldAutoRedirect, redirectUrl, redirectCount]);

  if (!paymentId) {
    return (
      <CircleContainer>
        <PaymentError />
      </CircleContainer>
    );
  }

  if (isLoading) {
    return (
      <CircleContainer>
        <PaymentStatusLoading />
      </CircleContainer>
    );
  }

  if (error || !paymentData || !dataIsValid) {
    return (
      <CircleContainer>
        <PaymentError />
      </CircleContainer>
    );
  }

  return (
    <CircleContainer>
      <StatusTemplate>
        {paymentData.status === 'complete' && (
          <>
            <div className={styles.thankYou}>
              {paymentData.seller_trading_name}
            </div>
            <div className={styles.paymentRef}>{paymentData.reference}</div>
            <div className={styles.poweredByText}>
              Powered by
              <img
                className={styles.iwocaPayLogo}
                src={IwocaPayIcon}
                alt="iwocaPay logo"
              />
            </div>
            <hr />
            <h2>Thank you!</h2>
            <div className={styles.invoiceAmountContainer}>
              <HappyPennyCharacter className={styles.image} />
              <div
                className={
                  paymentData.amount > 9999.99
                    ? styles.largeInvoiceAmount
                    : styles.smallInvoiceAmount
                }
              >
                {displayCurrency(paymentData.amount)} PAID
              </div>
            </div>
            <div className={styles.explanationText}>
              We've sent '{paymentData.seller_trading_name}' an email to let
              them know.
            </div>
            {isOverlay && (
              <div className={styles.explanationText}>
                You can now safely close this window and return to{' '}
                {paymentData.seller_trading_name}.
              </div>
            )}
            {shouldAutoRedirect && (
              <div className={styles.redirectLoader}>
                <span className={styles.redirectText}>
                  Redirecting you to the merchant... {redirectCount}s
                </span>
                <Spinner />
              </div>
            )}
          </>
        )}
        {paymentData.status === 'failed' && (
          <>
            <h2>
              Sorry, but we weren’t able to complete your payment this time
            </h2>
            <UnhappyCritter />
            {!isEcommerce ? (
              <LinkWithQuery
                className={styles.tryAgainLink}
                to={getPayLinkUrlPath(
                  paymentData.seller_name,
                  paymentData.pay_link_id!,
                )}
              >
                Try again
              </LinkWithQuery>
            ) : (
              <ReturnToSellerCTA />
            )}
          </>
        )}
        {paymentData.status === 'pending' && (
          <>
            <h1>You’re all done! </h1>
            <p className={styles.information}>
              We're processing your payment now, please check back later
            </p>
            <LoadingSVG className={styles.svg} />
          </>
        )}
      </StatusTemplate>
    </CircleContainer>
  );
};

const CircleContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div className={styles.circleContainer}>
        <div className={styles.leftCircle} />
        <div className={styles.topRightCircle} />
        <div className={styles.bottomRightCircle} />
      </div>
      {children}
    </>
  );
};

type RequiredFieldsType = keyof NonNullable<
  GetSingleImmediatePaymentResponse['data']
>;
const REQUIRED_FIELDS: readonly RequiredFieldsType[] = [
  'pay_link_id',
  'amount',
  'reference',
  'seller_name',
  'status',
];

function validatePaymentData(
  paymentData: ReturnType<typeof useGetPaymentStatus>['paymentData'],
) {
  if (!paymentData) return false;

  for (const requiredFieldName of REQUIRED_FIELDS) {
    if (!paymentData[requiredFieldName]) return false;
  }

  return true;
}
