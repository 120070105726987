import { useEffect, useState, useContext } from 'react';

import { useStateKey } from '../../../hooks/useStateKey.hook';
import { SellerXeroContext } from '../Xero.context';
import {
  contextLoadedSuccessfully,
  isIwocapayApplied,
  saveXeroThemes,
} from '../Xero.helpers';
import { DropdownOption, SellerContextValues } from '../Xero.types';

export function useSelectXeroThemes() {
  const sellerXeroContext = useContext(SellerXeroContext);
  const { stateKey } = useStateKey();

  const [selectedOptions, setSelectedOptions] = useState<DropdownOption[]>([]);
  const [options, setOptions] = useState<DropdownOption[]>();

  useEffect(() => {
    const themeOptions = getThemeOptions(sellerXeroContext);
    setOptions(themeOptions);
    setSelectedOptions(themeOptions.filter((theme) => theme.isFixed));
  }, [sellerXeroContext]);

  const saveSelectedThemes = async () => {
    if (!selectedOptions || !contextLoadedSuccessfully(sellerXeroContext)) {
      return;
    }

    if (!stateKey) {
      throw new Error('No Statekey provided to save Xero themes');
    }

    const apiThemeIds = selectedOptions.map((option) => ({
      branding_theme_id: option.value,
    }));

    await saveXeroThemes(apiThemeIds, stateKey);
    sellerXeroContext.refresh();
  };

  const clearThemes = () => {
    const fixedOptions = selectedOptions.filter((option) => option.isFixed);
    setSelectedOptions(fixedOptions);
  };

  return {
    selectedThemeOptions: selectedOptions,
    setSelectedThemeOptions: setSelectedOptions,
    clearThemes,
    themeOptions: options,
    hasThemesSelectionChanged:
      selectedOptions && selectedOptions.some((theme) => !theme.isFixed),
    saveSelectedThemes,
  };
}

function getThemeOptions(sellerXeroContext: SellerContextValues) {
  let options: DropdownOption[] = [];

  if (contextLoadedSuccessfully(sellerXeroContext)) {
    options =
      sellerXeroContext.xeroThemes?.map((theme) => {
        return {
          label: theme.name,
          value: theme.branding_theme_id,
          isFixed: isIwocapayApplied(theme),
        };
      }) ?? [];
  }

  return options;
}
