import styles from './GiveUsACall.module.css';
import { isGiveUsACallVisible } from './visible';
import {
  iwocapayTelDisplay,
  iwocapayTelLink,
} from '../../../../../src/constants.json';
import { useGetFundingRequirement } from '../../../../api/lending/lapiHooks';
import { useOnMount } from '../../../../hooks/useOnMount';
import { Card } from '../../../components/Card/Card';
import { useNavigateToNextRequirement } from '../../hooks/useNavigateToNextRequirement';

export const GiveUsACall = () => {
  const { goToNextRequirement } = useNavigateToNextRequirement();
  const { fundingRequirement } = useGetFundingRequirement();

  useOnMount(() => {
    if (isGiveUsACallVisible({ fundingRequirement: fundingRequirement! }))
      return;

    goToNextRequirement();
  });

  return (
    <Card title="One last thing - give us a quick call">
      <p className={styles.giveUsACall}>
        Call your account manager to finish the last step.
      </p>
      <a className={styles.phoneNumber} href={iwocapayTelLink}>
        {iwocapayTelDisplay}
      </a>
    </Card>
  );
};
