import classNames from 'classnames';

import styles from './DuplicatePaylinkBanner.module.css';
import WarningIconSVG from '../../../../components/svg/WarningTriangle.svg';

export const DuplicatePaylinkBanner = () => {
  return (
    <section
      className={styles.bannerContainer}
      data-testid="double-transaction-warning"
    >
      <div className={styles.headerContainer}>
        <img src={WarningIconSVG} className={styles.warningIcon} alt="" />
        <h2 className={styles.header}>
          You might have completed this payment before...
        </h2>
      </div>
      <p className={classNames(styles.description, styles.warningBody)}>
        You have completed a payment very similar to this in the last 24 hours.
      </p>
      <p className={styles.description}>Please check before you continue.</p>
    </section>
  );
};
