import { Button } from '@iwoca/orion';

import styles from './PermissionDenied.module.css';
import LockSVG from '../../../assets/Lock.svg';
import { DynamicPageTemplate } from '../../../components/PageTemplate/PageTemplate';
import { useSafeNavigate } from '../../../hooks/useSafeNavigate';

export const PermissionDenied = () => {
  const { navigate } = useSafeNavigate();

  return (
    <>
      <DynamicPageTemplate>
        <div className={styles.container}>
          <img src={LockSVG} alt="A padlock" className={styles.padlock} />
          <h1 className={styles.headline}>
            You’ll need to update your access level to do that
          </h1>
          <p className={styles.subtitle}>
            Contact your iwocaPay admin or IT team to update your access level.{' '}
          </p>
          <Button
            onClick={() => navigate({ to: '/pay/dashboard' })}
            className={styles.button}
          >
            Go to Dashboard
          </Button>
        </div>
      </DynamicPageTemplate>
    </>
  );
};
