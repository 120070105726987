import { GetSellerPricingResponse } from '@iwoca/lapi-client/iwocapay';

export const exampleSellerPricing: GetSellerPricingResponse['data'] = {
  product_pricing_options: {
    THREE_MONTHS: [
      {
        is_enabled: true,
        pricing_type: 'SELLER_PAYS_BUYER_FREE',
        buyer: {
          representative_interest: 0,
          promotions: [],
        },
        seller: {
          fee: 0.04,
        },
      },
      {
        is_enabled: false,
        pricing_type: 'SELLER_FREE_BUYER_PAYS',
        buyer: {
          representative_interest: 0.025,
          promotions: [],
        },
        seller: {
          fee: 0,
        },
      },
    ],
  },
};
