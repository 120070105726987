export const crossSellSteps = [
  'INTRODUCTION',
  'BUSINESS_INFORMATION',
  'APPLICANT_INFORMATION',
  'PRIVACY_POLICY',
] as const;

export type CrossSellSteps = (typeof crossSellSteps)[number];

export const stepIndex = (step: CrossSellSteps) => {
  return crossSellSteps.indexOf(step);
};

export const incrementStep = (step: CrossSellSteps) => {
  return step === 'PRIVACY_POLICY'
    ? 'PRIVACY_POLICY'
    : crossSellSteps[stepIndex(step) + 1];
};

export const decrementStep = (step: CrossSellSteps) => {
  return step === 'INTRODUCTION'
    ? 'INTRODUCTION'
    : crossSellSteps[stepIndex(step) - 1];
};
