import { useState } from 'react';

import { AlertBox } from '@iwoca/orion';
import cn from 'classnames';

import { iwocapayTelDisplay } from '../../../../constants.json';
import downVector from '../../assets/downVector.svg';
import exclamationTriangle from '../../assets/exclamationTriangle.svg';
import stepOneSVG from '../../assets/stepOne.svg';
import stepTwoSVG from '../../assets/stepTwo.svg';
import { useGetIntegrationStatus } from '../../hooks/useGetIntegrationStatus.hook';
import { Magento } from '../../Integrations';
import styles from '../EcommerceSteps.module.css';
import { IntegrationTickAlert } from '../IntegrationTickAlert/IntegrationTickAlert';

export const MagentoStep1 = () => {
  const { integrationStatus } = useGetIntegrationStatus({
    integrationId: Magento.id,
  });

  const isStepComplete =
    integrationStatus &&
    ['GENERATED_KEYS', 'CONNECTED'].includes(integrationStatus);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.stepIconContainer}>
        <img
          src={stepOneSVG}
          alt="Step One"
          className={cn(styles.stepIcon, { [styles.faded]: isStepComplete })}
        />
      </div>
      <div className={styles.stepTitleContainer}>
        <div
          className={cn(styles.stepTitle, { [styles.faded]: isStepComplete })}
        >
          <div className={styles.stepTitleBold}>Step 1</div>
          <div className={styles.stepTitleBoldHyphen}>-</div>
          <div>We'll be in touch soon</div>
        </div>
        {isStepComplete && <IntegrationTickAlert text="Step complete" />}
      </div>
      <div className={styles.stepBodyContainer}>
        <p
          className={cn(styles.stepBody, {
            [styles.bodyComplete]: isStepComplete,
          })}
        >
          Your account manager will run through the process, answer any
          questions and kick things off. Something urgent? You can contact us
          now on {iwocapayTelDisplay}.
        </p>
      </div>
    </div>
  );
};

export const MagentoStep2 = () => {
  const [showBody, setShowBody] = useState(false);
  const handleClick = () => {
    setShowBody(!showBody);
  };

  const { integrationStatus } = useGetIntegrationStatus({
    integrationId: Magento.id,
  });

  const isStepComplete = integrationStatus && integrationStatus === 'CONNECTED';

  const isActiveStep =
    integrationStatus && ['GENERATED_KEYS'].includes(integrationStatus);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.stepIconContainer}>
        <img
          src={stepTwoSVG}
          alt="Step Two"
          className={cn(styles.stepIcon, { [styles.faded]: isStepComplete })}
        />
      </div>
      <div className={styles.stepTitleContainer}>
        <div
          className={cn(styles.stepTitle, { [styles.faded]: isStepComplete })}
        >
          <div className={styles.stepTitleBold}>Step 2</div>
          <div className={styles.stepTitleBoldHyphen}>-</div>
          <div>Add iwocaPay to your {Magento.company_name} store</div>
        </div>
        {isStepComplete && <IntegrationTickAlert text="Step complete" />}
      </div>
      <div className={styles.stepBodyContainer}>
        {!isActiveStep && !isStepComplete && (
          <button
            className={cn(styles.walkthroughButton, {
              [styles.walkthroughButtonClicked]: showBody,
            })}
            onClick={handleClick}
          >
            {showBody
              ? 'Close install walkthrough'
              : 'Go to install walkthrough'}
            <img
              src={downVector}
              alt="Down Arrow"
              className={cn(styles.downVectorSVG, {
                [styles.downVectorSVGOpened]: showBody,
              })}
            />
          </button>
        )}
        {(isActiveStep || showBody) && <MagentoStep3Body />}
      </div>
    </div>
  );
};

const MagentoStep3Body = () => {
  return (
    <div className={styles.stepBody}>
      <AlertBox variant="failure" className={styles.alertBox}>
        <img src={exclamationTriangle} alt="Warning Icon" />
        These are technical instructions and you might need a developer. You
        should back up your database before making changes to Magento, and run
        the instructions in maintenance mode.
      </AlertBox>
      <div className={styles.largeSpacer} />
      <div className={styles.subHeading}>Follow these simple steps</div>
      <ul className={styles.list}>
        <li className={styles.listItem}>
          <div className={styles.listItemTitle}>
            SSH into your Magento 2 server
          </div>
          <div>
            Make sure you are in the directory of your magento installation,
            i.e.:
          </div>

          <div className={styles.instructionsCodeBlock}>
            cd /path/to/magento
          </div>
        </li>
        <li className={styles.listItem}>
          <div className={styles.listItemTitle}>
            Run the following command to add iwoca as a repository (so that
            composer can find the iwocaPay Magento 2 Plugin)
          </div>
          <div className={styles.instructionsCodeBlock}>
            composer config repositories.iwoca git
            "git@github.com:iwoca/iwocapay-magento-payment-gateway.git"
          </div>
        </li>
        <li className={styles.listItem}>
          <div className={styles.listItemTitle}>
            Run the following to get the files for the plugin
          </div>
          <div className={styles.instructionsCodeBlock}>
            composer require iwoca/iwocapay-magento:^1.0
          </div>
        </li>
        <li className={styles.listItem}>
          <div className={styles.listItemTitle}>
            Run the following to enable and install the plugin in your Magento 2
            installation
          </div>
          <div className={styles.instructionsCodeBlock}>
            magento module:enable Iwoca_Iwocapay
          </div>
          <div className={styles.instructionsCodeBlock}>
            magento setup:upgrade
          </div>
        </li>
        <li className={styles.listItem}>
          <div className={styles.listItemTitle}>Configure the plugin</div>
          <ul className={styles.list}>
            <li>
              Login to your store in the admin panel (using found at `/admin`)
            </li>
            <li>
              Go to Stores {'>'} Configuration {'>'} Sales {'>'} Payment Methods
            </li>
            <li>Select the iwocaPay Payment Method</li>
            <li>Set Enabled to "Yes"</li>
            <li>Set your API Key</li>
            <li>Set your Seller ID</li>
            <li>Set Mode to Production mode</li>
            <li>Click Save Config</li>
          </ul>
        </li>
        <li className={styles.listItem}>
          <div className={styles.listItemTitle}>
            Expire your caches by running the following command
          </div>
          <div className={styles.instructionsCodeBlock}>
            cmagento cache:flush
          </div>
        </li>
        <li className={styles.listItem}>
          <div className={styles.listItemTitle}>Try it out! </div>
          <ul className={styles.list}>
            <li>Add some items to your basket and go to checkout</li>
            <li>You should see iwocaPay as a payment option</li>
            <li>
              Select iwocaPay and check if you land on a https://www.iwoca.co.uk
              url
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};
