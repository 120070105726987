import { useState } from 'react';

import cn from 'classnames';

import styles from './Table.module.css';

export const Table = ({ children }: { children: React.ReactNode }) => {
  return <table className={styles.table}>{children}</table>;
};

export const TableHead = ({ children }: { children: React.ReactNode }) => {
  return <thead className={styles.tableHead}>{children}</thead>;
};

export const TableBody = ({ children }: { children: React.ReactNode }) => {
  return <tbody className={styles.tableBody}>{children}</tbody>;
};

export enum RowAppearance {
  NORMAL = 'normal',
  HIGHLIGHTED = 'highlighted',
  CANCEL = 'cancel',
  CANCELLED = 'cancelled',
}
export const TableRow = ({
  defaultRowAppearance = RowAppearance.NORMAL,
  children,
}: {
  defaultRowAppearance?: RowAppearance;
  children(
    setRowAppearance: React.Dispatch<React.SetStateAction<RowAppearance>>,
    rowAppearance: RowAppearance,
  ): React.ReactNode;
}) => {
  const [rowAppearance, setRowAppearance] =
    useState<RowAppearance>(defaultRowAppearance);

  return (
    <tr
      className={cn(styles.tableRow, {
        [styles.highlightedTableRow]:
          rowAppearance === RowAppearance.HIGHLIGHTED,
        [styles.dangerTableRow]: rowAppearance === RowAppearance.CANCEL,
        [styles.cancelledTableRow]: rowAppearance === RowAppearance.CANCELLED,
      })}
    >
      {children(setRowAppearance, rowAppearance)}
    </tr>
  );
};

export const TableCell = ({ children }: { children?: React.ReactNode }) => {
  return <td className={styles.tableCell}>{children}</td>;
};

export const TableHeaderCell = ({
  children,
  scope = 'column',
}: {
  children?: React.ReactNode;
  scope?: 'row' | 'column';
}) => {
  return (
    <th className={styles.tableCell} scope={scope}>
      {children}
    </th>
  );
};
