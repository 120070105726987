import React, { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { Icon } from '@iwoca/orion';

export const Draw = ({
  open,
  close,
  title,
  children,
}: {
  open: boolean;
  close: () => void;
  title: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-structure-0/30" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="transform translate-y-full"
          enterTo="transform translate-y-0"
          leave="ease-in duration-200"
          leaveFrom="transform translate-y-0"
          leaveTo="transform translate-y-full"
        >
          <Dialog.Panel className="z-11 fixed bottom-0 max-h-[80vh] w-full overflow-auto rounded-t-l bg-structure-100 pb-3xl text-primary-10">
            <button
              type="button"
              onClick={close}
              className="absolute right-s top-s inline-flex h-[40px] w-[40px] items-center justify-center rounded-m bg-transparent hover:bg-structure-80"
            >
              <Icon icon="timesDefault" className="text-l" />
            </button>
            {title && <h1 className="m-l text-xl">{title}</h1>}
            <div className="p-l pt-0">{children}</div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};
