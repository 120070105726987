import React from 'react';

import styles from './BenefitItem.module.css';
import TickIcon from '../../../../assets/TickIcon.svg';

export const BenefitItem = ({ children }: { children: React.ReactNode }) => {
  return (
    <li className={styles.benefit}>
      <img src={TickIcon} className={styles.tickIcon} alt="Tick icon" />
      <div className={styles.benefitHeading}>{children}</div>
    </li>
  );
};
