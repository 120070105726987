import { useCurrentOfferId } from './useCurrentOfferId';
import { useGetFormalOffers } from '../../../api/lending/lapiHooks';
import { getDurationInMonths } from '../../utils/product';

export const useCurrentFormalOfferDuration = () => {
  const offerId = useCurrentOfferId();
  const { formalOffers, loadingFormalOffers } = useGetFormalOffers();

  const currentFormalOffer = formalOffers?.formal_offers.find(
    (offer) => offer.offer_id === offerId,
  );

  if (!currentFormalOffer?.duration)
    return { currentFormalOfferDuration: null, loadingFormalOffers };

  const duration = getDurationInMonths(currentFormalOffer?.duration);

  return { currentFormalOfferDuration: duration, loadingFormalOffers };
};
