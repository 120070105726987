import { useState } from 'react';

import { AlertBox, Button, Icon, Modal } from '@iwoca/orion';

import {
  iwocapayTelDisplay,
  iwocapayTelLink,
} from '../../../../src/constants.json';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { LoginPanel } from '../../LoginPanel/LoginPanel';
import { Draw } from '../Draw';

export const LoginLink = () => {
  const [accountExplainerOpen, setAccountExplainerOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);

  return (
    <>
      <div className="mt-m px-l pb-xl pt-l shadow-black-m">
        <div className="flex flex-col items-stretch">
          <Icon
            icon="infoCircleOutline"
            fill="#FFBF00"
            className="mx-auto mb-l text-xl"
          />
          <div className="mb-xl text-center text-l font-med">
            We already have an account using that email address
          </div>
          <Button type="button" onClick={() => setLoginOpen(true)}>
            Log in to check out
          </Button>

          <button
            onClick={() => setAccountExplainerOpen(true)}
            type="button"
            className="mt-2xl bg-transparent text-primary-40 hover:underline"
          >
            I don’t remember signing up
          </button>
        </div>
      </div>
      <AccountExplainer
        open={accountExplainerOpen}
        close={() => setAccountExplainerOpen(false)}
      />
      <LoginPanel open={loginOpen} close={() => setLoginOpen(false)} />
    </>
  );
};

const AccountExplainer = ({
  open,
  close,
}: {
  open: boolean;
  close: () => void;
}) => {
  const { isMobile } = useIsMobile();

  const explanation = (
    <>
      <p className="mt-0">
        You'll have an account with us if you've ever used iwocaPay or iwoca in
        the past.
      </p>
      <AlertBox className="flex flex-wrap items-center" variant="connect">
        <Icon icon="phoneOutline" className="text-xl" />
        <span className="ml-s mr-xs font-bold">Still not sure?</span> - Call us
        on{' '}
        <a className="mx-xs" href={iwocapayTelLink}>
          {iwocapayTelDisplay}
        </a>{' '}
        and we can help.
      </AlertBox>
    </>
  );

  return isMobile ? (
    <Draw
      open={open}
      close={close}
      title="Why you might already have an account"
    >
      {explanation}
    </Draw>
  ) : (
    <Modal
      isOpen={open}
      onClose={close}
      closeIcon
      title="Why you might already have an account"
      headerBar
    >
      {explanation}
    </Modal>
  );
};
