import { useState } from 'react';

import { Icon } from '@iwoca/orion';
import classnames from 'classnames';

import styles from './PageTemplate.module.css';
import { SellerNavBar } from '../SellerNavBar/SellerNavBar';
import iwocaPayLogo from '../svg/iwocaPayLogo.svg';

export const DynamicPageTemplate = ({
  children,
  wideContent = false,
}: {
  children: JSX.Element | JSX.Element[];
  wideContent?: boolean;
}) => {
  const [isSideBarVisible, setIsSideBarVisible] = useState<boolean>(false);

  return (
    <>
      <div className={styles.mobileHeader}>
        <img src={iwocaPayLogo} className={styles.logo} alt="iwocaPay logo" />
        <button
          className={styles.mobileMenuButton}
          onClick={() => setIsSideBarVisible(!isSideBarVisible)}
        >
          Menu
          <Icon
            icon={!isSideBarVisible ? 'hamburgerMenu' : 'timesDefault'}
            className={styles.mobileXButton}
          />
        </button>
      </div>
      <div
        className={classnames({
          [styles.sellerSplitScreen]: isSideBarVisible,
          [styles.sellerSingleScreen]: !isSideBarVisible,
        })}
      >
        <header
          className={classnames(styles.sellerNavigationSplit, {
            [styles.sellerNavigationMobileHide]: !isSideBarVisible,
          })}
        >
          <SellerNavBar />
        </header>
        <main
          className={classnames(styles.sellerContentSplit, {
            [styles.sellerNavigationMobileHide]: isSideBarVisible,
            [styles.sellerContentWide]: wideContent,
          })}
        >
          {children}
        </main>
      </div>
    </>
  );
};
