import { QueryClient } from '@tanstack/react-query';

export class IwQueryClient {
  static client: QueryClient | null = null;

  static getOrCreate() {
    if (!IwQueryClient.client) {
      IwQueryClient._create();
    }

    return IwQueryClient.client as QueryClient;
  }

  static _create() {
    IwQueryClient.client = new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: Infinity,
          refetchInterval: false,
          refetchOnWindowFocus: false,
          refetchOnReconnect: false,
          refetchOnMount: false,
          retry: false,
          retryOnMount: false,
        },
      },
    });
  }

  static destroy() {
    IwQueryClient.client = null;
  }
}
