import { useGetSingleImmediatePayment } from '../../api/lending/lapiHooks';
import logger from '../../utils/logger';

const REFETCH_INTERVAL = 1400;

export const useGetPaymentStatus = (paymentId: string | null) => {
  const {
    singleImmediatePayment,
    singleImmediatePaymentError,
    loadingSingleImmediatePayment,
  } = useGetSingleImmediatePayment({
    paymentId,
    onError: (error) => {
      logger.error('Error fetching payment status', {
        errorMessage: error.message,
        paymentId: paymentId,
      });
    },
    refetchInterval: (query) => {
      const { data } = query.state;
      // @ts-expect-error Currently returntype of data is unknown, this needs fixing
      if (data?.data?.status === 'pending') return REFETCH_INTERVAL;
      return false;
    },
  });

  const isEcommerce = !!singleImmediatePayment?.order;

  return {
    isLoading: loadingSingleImmediatePayment,
    error: singleImmediatePaymentError,
    paymentData: singleImmediatePayment,
    isEcommerce: isEcommerce,
  };
};
