import { useFormContext } from 'react-hook-form';

import { FormValues } from '../utils/PayLinkLanding.types';

export function useCurrentAmount() {
  const { watch, formState } = useFormContext<FormValues>();
  const errors = formState.errors;
  const amountWatch = watch('amount');
  const amount = !errors.amount ? amountWatch : undefined;

  return amount;
}
