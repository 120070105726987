import { tracking } from '@iwoca/frontend-tracking-library';
// @ts-expect-error - unexported types
import { Login as ExternalLogin } from '@iwoca/login';

import { LOGIN_COPY } from './LoginCopy';

export const Login = ({
  redirectURL = `${window.location.pathname}${window.location.search}`,
  onSuccessfulLogin,
  onSuccessfulLinkSent,
}: {
  redirectURL?: string;
  onSuccessfulLogin?: () => void;
  onSuccessfulLinkSent?: () => void;
}) => {
  return (
    <ExternalLogin
      redirectURL={redirectURL}
      onSuccessfulLogin={onSuccessfulLogin}
      onSuccessfulLinkSent={() => {
        tracking.ampli.loginLinkRequested();
        onSuccessfulLinkSent?.();
      }}
      copy={LOGIN_COPY}
      styles={{
        passwordless: {
          form: '[&+hr]:hidden',
        },
        password: {
          form: '[&+hr]:hidden',
        },
      }}
    />
  );
};
