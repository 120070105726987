import { ComponentProps } from 'react';

import { Button as OrionButton } from '@iwoca/orion';
import cn from 'classnames';

import styles from './Button.module.css';

export const Button = ({
  className,
  variant = 'primary',
  ...props
}: Partial<ComponentProps<typeof OrionButton>>) => {
  return (
    <OrionButton
      {...props}
      variant={variant}
      className={cn(styles.Button, className)}
    />
  );
};

export const SubmitButton = (
  props: Omit<ComponentProps<typeof Button>, 'type'>,
) => {
  const buttonProps = props as ComponentProps<typeof Button>;

  return <Button {...buttonProps} type="submit" />;
};
