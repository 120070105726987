import { GetSellerOnboardingRequirementsResponse } from '@iwoca/lapi-client/edge';

import {
  useGetContext,
  useGetSellerOnboardingRequirements,
} from '../api/lending/lapiHooks';
import { useStateKey } from '../hooks/useStateKey.hook';

const STEPS = {
  DIRECTORS_PROFILE: 'directorsProfile',
  BUSINESS_PROFILE: 'businessProfile',
  BANK_DETAILS: 'bankDetails',
  RISK_ASSESSMENT: 'riskAssessment',
  IDENTITY_CHECK: 'identityCheck',
  CONTRACT: 'contract',
} as const;

type TStepNames = (typeof STEPS)[keyof typeof STEPS];

type TStepStatus = {
  directorsProfile: boolean;
  businessProfile: boolean;
  bankDetails: boolean;
  riskAssessment: boolean;
  identityCheck?: boolean;
  contract: boolean;
};

export function useActivationSteps() {
  const { loadingContext, contextError } = useGetContext();
  const { stateKey } = useStateKey();
  const {
    sellerOnboardingRequirements: data,
    loadingSellerOnboardingRequirements,
  } = useGetSellerOnboardingRequirements();

  /** If context is loading -> Loading is true
   * If contexterror -> User not signed up
   *  -> No need to wait for other -> Loading is false
   * otherwise wait for data and sellerOnboardingRequirements
   */
  const isLoading = loadingContext
    ? true
    : contextError
      ? false
      : !data || loadingSellerOnboardingRequirements;

  if (stateKey === null) {
    return {
      currentStep: STEPS.DIRECTORS_PROFILE,
      isLoading,
      progress: 0,
      isOnboarded: false,
    };
  }

  const stepStatus = getCompletedSteps(data?.data);
  const progress = getProgress(stepStatus);
  const isOnboarded = progress !== undefined ? progress === 100 : undefined; // because progress can theoretically be 0 we need to this as it's falsy

  return {
    currentStep: getCurrentStep(stepStatus),
    progress: progress || 0,
    completedSteps: stepStatus,
    isOnboarded: isOnboarded,
    isLoading,
  };
}

function getProgress(stepStatus?: TStepStatus) {
  const initialProgress = 10;

  if (stepStatus === undefined) {
    return;
  }

  const totalCompletedSteps = Object.keys(stepStatus).filter(
    (step) => stepStatus[step as TStepNames],
  ).length;

  const totalSteps = Object.keys(stepStatus).length;

  const progress =
    initialProgress +
    (100 - initialProgress) * (totalCompletedSteps / totalSteps);

  return parseInt(progress.toFixed(0));
}

function getCompletedSteps(
  requirements?: GetSellerOnboardingRequirementsResponse['data'],
) {
  if (!requirements) {
    return;
  }

  const completedDirectorsProfile = requirements.find(
    (req) => req.requirement === 'completed_directors_profile',
  );

  const completedBusinessProfile = requirements.find(
    (req) => req.requirement === 'completed_business_profile',
  );

  const verifiedBankDetailsLimitedCompany = requirements.find(
    (req) => req.requirement === 'verified_bank_details_limited_company',
  );

  const verifiedBankDetailsSoleTrader = requirements.find(
    (req) => req.requirement === 'verified_bank_details_sole_trader',
  );

  const identityChecked = requirements.find(
    (req) => req.requirement === 'identity_checked',
  );

  const completedRiskAssessment = requirements.find(
    (req) => req.requirement === 'completed_risk_assessment',
  );

  const signedContract = requirements.find(
    (req) => req.requirement === 'seller_agreement_contract',
  );

  if (verifiedBankDetailsSoleTrader) {
    return {
      directorsProfile: completedDirectorsProfile?.status === 'completed',
      businessProfile: completedBusinessProfile?.status === 'completed',
      bankDetails: verifiedBankDetailsSoleTrader?.status === 'completed',
      identityCheck: identityChecked?.status === 'completed',
      riskAssessment: completedRiskAssessment?.status === 'completed',
      contract: signedContract?.status === 'completed',
    };
  }

  return {
    directorsProfile: completedDirectorsProfile?.status === 'completed',
    businessProfile: completedBusinessProfile?.status === 'completed',
    bankDetails: verifiedBankDetailsLimitedCompany?.status === 'completed',
    riskAssessment: completedRiskAssessment?.status === 'completed',
    contract: signedContract?.status === 'completed',
  };
}

function getCurrentStep(stepStatus?: TStepStatus) {
  if (!stepStatus) {
    return;
  }

  if (!stepStatus.directorsProfile) {
    return STEPS.DIRECTORS_PROFILE;
  }

  if (!stepStatus.businessProfile) {
    return STEPS.BUSINESS_PROFILE;
  }

  if (!stepStatus.bankDetails) {
    return STEPS.BANK_DETAILS;
  }

  if (stepStatus?.identityCheck && !stepStatus.identityCheck) {
    return STEPS.IDENTITY_CHECK;
  }

  if (!stepStatus.riskAssessment) {
    return STEPS.RISK_ASSESSMENT;
  }

  if (!stepStatus.contract) {
    return STEPS.CONTRACT;
  }
}
