import React from 'react';

import BankSVG from '../../../../../components/svg/Bank.svg';
import ShortLogo from '../../../../../components/svg/ShortLogo.svg';
import styles from '../DrawDownModal.module.css';

export const IwocaToBankIllustration = ({
  icon,
}: {
  icon: React.ReactNode;
}) => (
  <div className={styles.processingWrapper}>
    <span>
      <img alt="Iwoca" src={ShortLogo} className={styles.iwocaLogo} />
    </span>

    {icon}

    <span>
      <img alt="Bank" src={BankSVG} className={styles.BankSVG} />
    </span>
  </div>
);
