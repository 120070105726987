import { useEffect } from 'react';

import { useGetContext } from '../../api/lending/lapiHooks';
import { SECURITY_CHECKS_BASE_PATH } from '../Checkout/routes';
import { buildQueryString } from '../utils/queryParams';

export const redirectToMyAccount = () => {
  // Redirects via django's my_account endpoint
  const queryString = buildQueryString();

  window.location.href = `/my_account/${queryString}`;
};

export const redirectToAccountPages = () => {
  // Redirects straight to pandora - bypassing django. This is needed because my_account breaks the popup journey
  const queryString = buildQueryString();

  return (window.location.href = `/account/${queryString}`);
};

export const redirectToSecurityChecks = (offerId: string) => {
  const queryString = buildQueryString({ offerId: offerId });

  // todo make this useNavigate
  window.location.href = `${SECURITY_CHECKS_BASE_PATH}/${queryString}`;
};

export function useRedirectToMyAccount() {
  const { context } = useGetContext();

  useEffect(() => {
    if (context.user_signup_finished) {
      redirectToMyAccount();
    }
  }, [context.user_signup_finished]);
}
