import React, { useState } from 'react';

import { GetSellerPayLinkResponse } from '@iwoca/lapi-client/iwocapay';
import { Button, ContentSwitcher } from '@iwoca/orion';
import classnames from 'classnames';
import { useSearchParams } from 'react-router-dom';

import styles from './PayLinkContentSwitcher.module.css';
import { PayLinkStatus } from '../../../hooks/usePaylinks.hook';
import { FilterDrawer } from '../FilterDrawer/FilterDrawer';
import { PayLinkStatusModal } from '../PayLinkTable/components/PayLinkStatusModal/PayLinkStatusModal';

type TSwitcherStatus = {
  status: TExpandedPayLinkStatus;
  pipContent: number | undefined;
  pipClassName?: string;
  label: string;
};

type TExpandedPayLinkStatus = PayLinkStatus | 'ALL' | 'ARCHIVED';

export const PayLinkContentSwitcher = ({
  onChange,
  metadata,
  archivedMetadata,
  isMobile,
}: {
  onChange: () => void;
  metadata: GetSellerPayLinkResponse['meta'] | undefined;
  archivedMetadata: GetSellerPayLinkResponse['meta'] | undefined;
  isMobile: boolean;
}): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentStatusFilter = searchParams.get('status') || 'ALL';
  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);
  const [showStatuses, setShowStatuses] = useState<boolean>(false);

  const statuses: TSwitcherStatus[] = [
    {
      status: 'PAID',
      pipContent: metadata?.totals_by_status?.PAID || 0,
      pipClassName: styles.success,
      label: 'Paid',
    },
    {
      status: 'DECLINED',
      pipContent: metadata?.totals_by_status?.DECLINED || 0,
      pipClassName: styles.declined,
      label: 'Declined',
    },
    {
      status: 'ARCHIVED',
      pipContent: archivedMetadata?.count || 0,
      label: 'Archived',
    },
  ];

  const handleSwitch = (status: TExpandedPayLinkStatus) => {
    setSearchParams((params) => {
      if (status === 'ALL') {
        params.delete('status');
      } else {
        params.set('status', status);
      }

      return params;
    });

    onChange();
  };

  return (
    <>
      <FilterDrawer
        isOpen={filterDrawerOpen}
        setIsOpen={setFilterDrawerOpen}
        selectedStatusFilter={currentStatusFilter}
        setSelectedStatusFilter={(status) =>
          handleSwitch(status as unknown as TExpandedPayLinkStatus)
        }
      />
      {filterDrawerOpen && <div className={styles.filterDrawerBackdrop}></div>}
      <div className={styles.contentSwitcher}>
        <ContentSwitcher
          value={currentStatusFilter}
          className={classnames({ [styles.contentSwitcherMobile]: isMobile })}
        >
          <ContentSwitcher.Button
            id="ALL"
            onClick={() => handleSwitch('ALL')}
            className={classnames(styles.contentSwitcherButton, {
              [styles.contentSwitcherMobileButton]: isMobile,
            })}
          >
            {isMobile && (
              <div
                className={classnames(
                  styles.paylinkPip,
                  {
                    [styles.mobile]: isMobile,
                  },
                  styles.all,
                )}
              >
                {metadata?.count}
              </div>
            )}
            {isMobile ? 'All' : 'All paylinks'}
          </ContentSwitcher.Button>
          {statuses.map((status) => (
            <ContentSwitcher.Button
              key={status.status}
              id={status.status}
              onClick={() => handleSwitch(status.status)}
              className={classnames(styles.contentSwitcherButton, {
                [styles.contentSwitcherMobileButton]: isMobile,
              })}
            >
              <div
                className={classnames(
                  styles.paylinkPip,
                  status.pipClassName || null,
                  {
                    [styles.mobile]: isMobile,
                  },
                )}
              >
                {status.pipContent}
              </div>
              {status.label}
            </ContentSwitcher.Button>
          ))}
        </ContentSwitcher>
        <div className="flex">
          <Button
            variant="tertiary"
            onClick={() => setFilterDrawerOpen(!filterDrawerOpen)}
            className={classnames(styles.moreStatuses, 'grow', {
              [styles.mobileStatuses]: isMobile,
            })}
          >
            More statuses
          </Button>
          {isMobile && (
            <Button
              variant="tertiary"
              onClick={() => setShowStatuses(true)}
              className={classnames(styles.moreStatuses, 'grow', {
                [styles.mobileStatuses]: isMobile,
              })}
            >
              What are the statuses?
            </Button>
          )}
        </div>
        <PayLinkStatusModal
          open={showStatuses}
          close={() => setShowStatuses(false)}
        />
      </div>
    </>
  );
};
