import checkMarkIconSource from './CheckMarkIcon.svg';
import styles from './DecisionModal.module.css';
import exclamationTriangleOutlinedIconSource from './ExclamationTriangleOutlinedIcon.svg';
import questionMarkIconSource from './QuestionMarkIcon.svg';

export enum DecisionModalIconType {
  LOADING = 'loading',
  ERROR = 'error',
  UNKNOWN = 'unknown',
  DECLINE = 'decline',
  APPROVAL = 'approval',
}

export const DecisionModalIcon = ({
  type,
}: {
  type: DecisionModalIconType;
}) => {
  if (type === DecisionModalIconType.APPROVAL)
    return (
      <div className={styles.statusIconWrapper}>
        <img
          src={checkMarkIconSource}
          height="100"
          width="100"
          alt="Checkmark"
        />
      </div>
    );

  if (type === DecisionModalIconType.DECLINE) return null;

  if (type === DecisionModalIconType.UNKNOWN)
    return (
      <div className={styles.statusIconWrapper}>
        <img
          src={questionMarkIconSource}
          height="100"
          width="100"
          alt="Questionmark"
        />
      </div>
    );

  if (type === DecisionModalIconType.LOADING)
    return (
      <div className={styles.statusIconWrapper}>
        <LoadingSpinner />
      </div>
    );

  if (type === DecisionModalIconType.ERROR)
    return (
      <div className={styles.statusIconWrapper}>
        <img
          src={exclamationTriangleOutlinedIconSource}
          height="100"
          width="100"
          alt="Questionmark"
        />
      </div>
    );

  return null;
};

const LoadingSpinner = () => {
  return (
    <div className={styles.LoadingSpinner}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
