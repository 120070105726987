export const InvoiceIcon = ({ title = 'Invoice icon', ...props }) => {
  return (
    <svg
      {...props}
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        d="M41.1973 1.99994L7.19727 1.99994C6.66683 1.99994 6.15812 2.21065 5.78305 2.58573C5.40798 2.9608 5.19727 3.46951 5.19727 3.99994L5.19727 44.9999C5.19724 45.181 5.24636 45.3586 5.33939 45.5139C5.43242 45.6693 5.56587 45.7964 5.7255 45.8818C5.88513 45.9672 6.06495 46.0077 6.24578 45.9989C6.42661 45.9901 6.60167 45.9324 6.75227 45.8319L12.1973 42.1999L17.6423 45.8299C17.8066 45.9396 17.9997 45.9981 18.1973 45.9981C18.3948 45.9981 18.5879 45.9396 18.7523 45.8299L24.1973 42.1999L29.6423 45.8299C29.8066 45.9396 29.9997 45.9981 30.1973 45.9981C30.3948 45.9981 30.5879 45.9396 30.7523 45.8299L36.1973 42.1999L41.6423 45.8299C41.7927 45.9303 41.9676 45.988 42.1483 45.9969C42.3289 46.0058 42.5086 45.9655 42.6682 45.8803C42.8277 45.7951 42.9612 45.6683 43.0544 45.5132C43.1476 45.3582 43.1969 45.1808 43.1973 44.9999V3.99994C43.1973 3.46951 42.9866 2.9608 42.6115 2.58573C42.2364 2.21065 41.7277 1.99994 41.1973 1.99994Z"
        fill="#EEF3F7"
      />
      <path
        d="M26.1973 32.9999H14.1973C13.932 32.9999 13.6777 32.8946 13.4902 32.707C13.3026 32.5195 13.1973 32.2652 13.1973 31.9999C13.1973 31.7347 13.3026 31.4804 13.4902 31.2928C13.6777 31.1053 13.932 30.9999 14.1973 30.9999H26.1973C26.4625 30.9999 26.7168 31.1053 26.9044 31.2928C27.0919 31.4804 27.1973 31.7347 27.1973 31.9999C27.1973 32.2652 27.0919 32.5195 26.9044 32.707C26.7168 32.8946 26.4625 32.9999 26.1973 32.9999Z"
        fill="#699FE3"
      />
      <path
        d="M34.1973 32.9999H32.1973C31.932 32.9999 31.6777 32.8946 31.4902 32.707C31.3026 32.5195 31.1973 32.2652 31.1973 31.9999C31.1973 31.7347 31.3026 31.4804 31.4902 31.2928C31.6777 31.1053 31.932 30.9999 32.1973 30.9999H34.1973C34.4625 30.9999 34.7168 31.1053 34.9044 31.2928C35.0919 31.4804 35.1973 31.7347 35.1973 31.9999C35.1973 32.2652 35.0919 32.5195 34.9044 32.707C34.7168 32.8946 34.4625 32.9999 34.1973 32.9999Z"
        fill="#699FE3"
      />
      <path
        d="M26.1973 26.9999H14.1973C13.932 26.9999 13.6777 26.8946 13.4902 26.707C13.3026 26.5195 13.1973 26.2652 13.1973 25.9999C13.1973 25.7347 13.3026 25.4804 13.4902 25.2928C13.6777 25.1053 13.932 24.9999 14.1973 24.9999H26.1973C26.4625 24.9999 26.7168 25.1053 26.9044 25.2928C27.0919 25.4804 27.1973 25.7347 27.1973 25.9999C27.1973 26.2652 27.0919 26.5195 26.9044 26.707C26.7168 26.8946 26.4625 26.9999 26.1973 26.9999Z"
        fill="#699FE3"
      />
      <path
        d="M34.1973 27H32.1973C31.932 27 31.6777 26.8946 31.4902 26.7071C31.3026 26.5196 31.1973 26.2652 31.1973 26C31.1973 25.7348 31.3026 25.4804 31.4902 25.2929C31.6777 25.1054 31.932 25 32.1973 25H34.1973C34.4625 25 34.7168 25.1054 34.9044 25.2929C35.0919 25.4804 35.1973 25.7348 35.1973 26C35.1973 26.2652 35.0919 26.5196 34.9044 26.7071C34.7168 26.8946 34.4625 27 34.1973 27Z"
        fill="#699FE3"
      />
      <rect
        x="19.1484"
        y="8.37439"
        width="10.0972"
        height="10.0972"
        rx="1"
        fill="#FB534A"
      />
    </svg>
  );
};
