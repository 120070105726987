import React, { useState } from 'react';

import { GetSellerPayLinkResponse } from '@iwoca/lapi-client/iwocapay';
import { CheckBox, Table } from '@iwoca/orion';
import { ColumnDef } from '@iwoca/orion/lib/components/table/Table';
import classNames from 'classnames';

import { PayLinkSource } from './components/PayLinkSource/PayLinkSource';
import { PayLinkStatus } from './components/PayLinkStatus/PayLinkStatus';
import { PayLinkStatusModal } from './components/PayLinkStatusModal/PayLinkStatusModal';
import styles from './PayLinkTable.module.css';
import { copy } from '../../../../components/util/copy';

export type PayLinkColumns = {
  paylink_id: string;
  date: string;
  amount: string;
  source: GetSellerPayLinkResponse['data'][number]['source'];
  status: GetSellerPayLinkResponse['data'][number]['status'];
  reference: string;
  buyerName: string;
  payLinkLink: string;
  productDuration: string | null;
  capture_by: string | undefined;
  selectedRows: string[];
  declineDate: string | undefined;
  funded_via: GetSellerPayLinkResponse['data'][number]['funded_via'];
  formal_offer_expiry_date: GetSellerPayLinkResponse['data'][number]['formal_offer_expiry_date'];
  shopify_payment_id: string | undefined;
  handleAddSelectedRow: (payLinkIds: string[]) => void;
  handleRemoveSelectedRow: (payLinkIds: string[]) => void;
  refreshTable: () => void;
};

export const PayLinkTable = ({
  payLinkColumns,
  loadingState = false,
}: {
  payLinkColumns: PayLinkColumns[];
  loadingState: boolean;
}) => {
  const [showStatuses, setShowStatuses] = useState<boolean>(false);

  const columns: ColumnDef<PayLinkColumns>[] = [
    {
      id: 'checkboxRow',
      header: (info) => (
        <CheckBox
          checked={
            info.table.getRowModel().rows.length > 0 &&
            info.table.getRowModel().rows.length ===
              info.table.getRowModel().rows[0].original.selectedRows.length
          }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const allRows = info.table.getRowModel().rows;
            const isChecked = event.target.checked;

            if (isChecked) {
              const allPayLinkIds = allRows.map(
                (row) => row.original.paylink_id,
              );
              allRows[0].original.handleAddSelectedRow(allPayLinkIds);
            } else {
              const allPayLinkIds = allRows.map(
                (row) => row.original.paylink_id,
              );
              allRows[0].original.handleRemoveSelectedRow(allPayLinkIds);
            }
          }}
        />
      ),
      cell: (info) => (
        <CheckBox
          checked={info.row.original.selectedRows.includes(
            info.row.original.paylink_id,
          )}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const checked = event.target.checked;
            if (checked) {
              info.row.original.handleAddSelectedRow([
                info.row.original.paylink_id,
              ]);
            } else {
              info.row.original.handleRemoveSelectedRow([
                info.row.original.paylink_id,
              ]);
            }
          }}
        />
      ),
      className: styles.checkboxRow,
    },
    {
      accessorKey: 'date',
      header: 'Date',
      className: classNames(styles.standardTableRow, styles.dateRow),
    },
    {
      cell: (info) => (
        <div className={styles.refClient}>
          <div className={styles.ref}>{info.row.original.reference}</div>
          <div className={styles.companyName}>
            {info.row.original.buyerName || ''}
          </div>
        </div>
      ),
      header: 'Ref & Client',
      className: classNames(styles.standardTableRow, styles.refRow),
    },
    {
      cell: (info) => (
        <div>
          <div className={styles.paylinkAmount}>{info.row.original.amount}</div>
          {info.row.original.productDuration && (
            <div className={styles.paylinkDuration}>
              {info.row.original.productDuration}
            </div>
          )}
        </div>
      ),
      header: 'Amount',
      className: classNames(styles.standardTableRow, styles.amountRow),
    },
    {
      cell: (info) => <PayLinkSource payLink={info.row.original} />,
      header: 'Pay Link Type',
      className: classNames(styles.standardTableRow, styles.typeRow),
    },
    {
      id: 'Status',
      cell: (info) => (
        <PayLinkStatus
          payLinkColumn={info.row.original}
          onCopyLink={() => copy(info.row.original.payLinkLink)}
          onRefreshTable={() => info.row.original.refreshTable()}
        />
      ),
      header: () => (
        <div>
          Status
          <button
            className="cursor-pointer border-none bg-transparent pl-m text-xs text-primary-40 underline"
            onClick={() => setShowStatuses(!showStatuses)}
          >
            What are the statuses?
          </button>
          <PayLinkStatusModal
            open={showStatuses}
            close={() => setShowStatuses(false)}
          />
        </div>
      ),
      className: classNames(styles.standardTableRow, styles.statusRow),
    },
  ];

  return (
    <Table
      data={payLinkColumns}
      columns={columns}
      loading={loadingState}
      className={styles.table}
      rowClassName={(row) => {
        return classNames(styles.allRows, {
          [styles.greenRow]: row.original.status === 'PAID',
          [styles.redRow]: row.original.status === 'DECLINED',
          [styles.yellowRow]: row.original.status === 'SCHEDULED_FOR_CAPTURE',
          [styles.cancelledRow]: row.original.status === 'CANCELLED',
        });
      }}
    />
  );
};
