export const CrossIcon = ({ title = 'Cross icon', ...props }) => {
  return (
    <svg
      {...props}
      height="36px"
      width="36px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path d="M1.41421 6.18174e-08L12.7279 11.3137L11.3137 12.7279L0 1.41421L1.41421 6.18174e-08Z"></path>
      <path d="M0 11.3137L11.3137 0L12.7279 1.41421L1.41421 12.7279L0 11.3137Z"></path>
    </svg>
  );
};
