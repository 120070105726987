import styles from './ReturnToSellerCTA.module.css';
import { useEcomPostMessage } from '../../hooks/useEcomPostMessage';
import { useIsOverlay } from '../../hooks/useIsOverlay';
import { useGetPaymentStatus } from '../useGetPaymentStatus';
import { usePaymentId } from '../usePaymentId';

export const ReturnToSellerCTA = () => {
  const paymentId = usePaymentId();
  const { paymentData } = useGetPaymentStatus(paymentId);
  const redirectUrl = paymentData?.order?.redirect_url || '';

  const isFailure = paymentData?.status === 'failed';
  const isOverlay = useIsOverlay({ payNow: true });

  useEcomPostMessage({
    enabled: isFailure && isOverlay,
    status: 'UNSUCCESSFUL',
    redirectUrl,
  });

  const handleClick = () => {
    if (isOverlay) {
      window.close();
      return;
    }

    window.location.href = redirectUrl;
  };

  return (
    <button className={styles.returnToSellerCTA} onClick={handleClick}>
      Return to {paymentData?.seller_trading_name}{' '}
      {isFailure && <div>and try again</div>}
    </button>
  );
};
