import { useEffect } from 'react';

import { windowHasOpener } from '../utils/windowHasOpener';

type TEcomPostMessageStatus = 'SUCCESSFUL' | 'UNSUCCESSFUL';

export const useEcomPostMessage = ({
  enabled,
  status,
  redirectUrl,
}: {
  enabled: boolean;
  status: TEcomPostMessageStatus;
  redirectUrl?: string;
}) => {
  useEffect(() => {
    if (!enabled || !windowHasOpener() || !redirectUrl) return;

    const url = new URL(redirectUrl);
    const domain = url.origin;
    const orderId = url.searchParams.get('orderId');

    window.opener.postMessage({ status, orderId: orderId }, domain);
  }, [status, enabled, redirectUrl]);
};
