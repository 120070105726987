import styles from './PaymentStatusLoading.module.css';
import LoadingSVG from '../../../components/svg/Graphics/LoaderGraphic.svg?react';
import { StatusTemplate } from '../StatusTemplate/StatusTemplate';

export function PaymentStatusLoading() {
  return (
    <StatusTemplate>
      <div className={styles.svgContainer}>
        <LoadingSVG className={`${styles.svg} ${styles.background}`} />
        <div className={`${styles.foreground} ${styles.text}`}>Loading...</div>
      </div>
    </StatusTemplate>
  );
}
