import styles from './PageNotFound.module.css';
import { NotFoundIcon } from '../../components/svg/NotFoundIcon';

export const PayLinkNotFound = () => {
  return (
    <div>
      <div className={styles.failureIcon}>
        <NotFoundIcon />
      </div>
      <div className={styles.failureMessage}>
        <div className={styles.failureHeading}>
          Sorry, we can't find that pay link
        </div>
        <div className={styles.failureText}>
          Oops, we can’t seem to find that pay link. Double check you’ve copied
          it correctly or get in touch with your supplier for a new one.
        </div>
      </div>
    </div>
  );
};
