import React from 'react';

import { Button, Card } from '@iwoca/orion';
import RiveComponent, { Alignment, Fit, Layout } from '@rive-app/react-canvas';

import styles from './SecurityChecksSuccess.module.css';
// @ts-ignore
import AllSetCelebrationRive from '../../../../assets/AllSetCelebrationRive.riv';
import PayLaterIllustration from '../../../../assets/PayLaterIllustration.svg';
import { LoadingScreen } from '../../../../components/LoadingScreen/LoadingScreen';
import { displayCurrency } from '../../../../utils/DisplayCurrency';
import { redirectToMyAccount } from '../../../Signup/useRedirectToAccountPages';
import { useFormalOfferWithDuration } from '../../hooks/useFormalOfferWithDuration';

export const SecurityChecksSuccess = () => {
  const { formalOffer, loadingFormalOffer } = useFormalOfferWithDuration(3);
  if (loadingFormalOffer) {
    return <LoadingScreen />;
  }
  if (!formalOffer) {
    redirectToMyAccount();
  }

  const availableCredit = formalOffer?.available_credit;

  return (
    <Card className={styles.centerContent}>
      <div className={styles.container}>
        <span className={styles.celebrationIcon}>
          <RiveComponent
            src={AllSetCelebrationRive}
            layout={
              new Layout({
                fit: Fit.Contain,
                alignment: Alignment.TopCenter,
              })
            }
          />
        </span>
        <div>
          <h5 className={styles.heading}>
            Your {availableCredit && displayCurrency(availableCredit) + ' '}
            spending limit is ready to use!
          </h5>
          <p className={styles.subheading}>
            To use it just choose to Pay Later anywhere that offers iwocaPay to
            checkout in a few clicks
          </p>
          <img
            src={PayLaterIllustration}
            className={styles.illustration}
            alt="Pay Later Illustration"
          />
        </div>
        <Button className={styles.goToButton} onClick={redirectToMyAccount}>
          Go to dashboard
        </Button>
      </div>
    </Card>
  );
};
