import successfullyLinkedGif from './SuccessfullyLinked.gif';
import styles from './SuccessfullyLinked.module.css';
import { LinkWithQuery } from '../../../components/LinkWithQuery/LinkWithQuery';
import sellerXeroStyles from '../Xero.module.css';

export const SuccessfullyLinked = () => {
  return (
    <div className={sellerXeroStyles.sellerXero}>
      <div className={sellerXeroStyles.contentSm}>
        <div className={styles.contentInner}>
          <h3 className={styles.heading}>You’re successfully linked!</h3>
          <img
            className={styles.successSignupGif}
            src={successfullyLinkedGif}
            alt="Success animation"
          />
          <LinkWithQuery
            to="/pay/pay-links/"
            className={sellerXeroStyles.button}
          >
            Go to my dashboard
          </LinkWithQuery>
        </div>
      </div>
    </div>
  );
};
