import { useState } from 'react';

import { AlertBox } from '@iwoca/orion';
import cn from 'classnames';

import { iwocapayTelDisplay } from '../../../../constants.json';
import downVector from '../../assets/downVector.svg';
import exclamationTriangle from '../../assets/exclamationTriangle.svg';
import stepOneSVG from '../../assets/stepOne.svg';
import stepTwoSVG from '../../assets/stepTwo.svg';
import { useGetIntegrationStatus } from '../../hooks/useGetIntegrationStatus.hook';
import styles from '../EcommerceSteps.module.css';
import { IntegrationTickAlert } from '../IntegrationTickAlert/IntegrationTickAlert';

const WOOCOMMERCE_ID = 'woocommerce';

export const WooCommerceStep1 = () => {
  const { integrationStatus } = useGetIntegrationStatus({
    integrationId: WOOCOMMERCE_ID,
  });

  const isComplete =
    integrationStatus &&
    ['GENERATED_KEYS', 'CONNECTED'].includes(integrationStatus);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.stepIconContainer}>
        <img
          src={stepOneSVG}
          alt="Step One"
          className={cn(styles.stepIcon, { [styles.faded]: isComplete })}
        />
      </div>
      <div className={styles.stepTitleContainer}>
        <div className={cn(styles.stepTitle, { [styles.faded]: isComplete })}>
          <div className={styles.stepTitleBold}>Step 1</div>
          <div className={styles.stepTitleBoldHyphen}>-</div>
          <div>We'll be in touch soon</div>
        </div>
        {isComplete && <IntegrationTickAlert text="Step complete" />}
      </div>
      <div className={styles.stepBodyContainer}>
        <p
          className={cn(styles.stepBody, {
            [styles.bodyComplete]: isComplete,
          })}
        >
          Your account manager will run through the process, answer any
          questions and kick things off. Something urgent? You can contact us
          now on {iwocapayTelDisplay}.
        </p>
      </div>
    </div>
  );
};

export const WooCommerceStep2 = () => {
  const [showBody, setShowBody] = useState(false);
  const handleClick = () => {
    setShowBody(!showBody);
  };

  const { integrationStatus } = useGetIntegrationStatus({
    integrationId: WOOCOMMERCE_ID,
  });

  const isComplete =
    integrationStatus && ['CONNECTED'].includes(integrationStatus);

  const isActiveStep =
    integrationStatus && ['GENERATED_KEYS'].includes(integrationStatus);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.stepIconContainer}>
        <img
          src={stepTwoSVG}
          alt="Step Two"
          className={cn(styles.stepIcon, { [styles.faded]: isComplete })}
        />
      </div>
      <div className={styles.stepTitleContainer}>
        <div className={cn(styles.stepTitle, { [styles.faded]: isComplete })}>
          <div className={styles.stepTitleBold}>Step 2</div>
          <div className={styles.stepTitleBoldHyphen}>-</div>
          <div>Add iwocaPay to your WooCommerce store</div>
        </div>
        {isComplete && <IntegrationTickAlert text="Step complete" />}
      </div>
      <div className={styles.stepBodyContainer}>
        {!isActiveStep && !isComplete && (
          <button
            className={cn(styles.walkthroughButton, {
              [styles.walkthroughButtonClicked]: showBody,
            })}
            onClick={handleClick}
          >
            {showBody && 'Close install walkthrough'}
            {!showBody && 'Go to install walkthrough'}
            <img
              src={downVector}
              alt="Down Arrow"
              className={cn(styles.downVectorSVG, {
                [styles.downVectorSVGOpened]: showBody,
              })}
            />
          </button>
        )}
        {(isActiveStep || showBody) && <WooCommerceStep2Body />}
      </div>
    </div>
  );
};

const WooCommerceStep2Body = () => {
  return (
    <div className={styles.stepBody}>
      <div className={styles.subHeading}>Watch the walkthrough</div>
      <div className={styles.videoContainer}>
        <iframe
          className={styles.videoPlayer}
          src="https://www.youtube.com/embed/X9p3HdZLQmc"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture fullscreen"
          allowFullScreen
        ></iframe>
      </div>
      <div className={styles.subHeading}>Or follow these simple steps</div>
      <ul className={styles.list}>
        <li className={styles.listItem}>
          Login to your Wordpress account and click <b>‘Plugins’</b> on the left
          panel then <b>‘Add New’</b>.
        </li>
        <li className={styles.listItem}>
          Type iwocaPay in the search bar to find it then click{' '}
          <b>‘Install Now’</b>.
        </li>
        <li className={styles.listItem}>
          Once it’s installed click <b>‘Activate’</b> to make it available to
          configure in your settings.
        </li>
        <li className={styles.listItem}>
          Now iwocaPay should appear in the <b>Settings</b> area of your
          WooCommerce store under <b>Payments</b>.
        </li>
        <li className={styles.listItem}>
          Click <b>‘Finish Set Up’</b> then tick the <b>‘Enable’</b> box.
        </li>
        <li className={styles.listItem}>
          Select which payment options you’d like to show (Pay Later and Pay Now
          or just Pay Later).
        </li>
        <li className={styles.listItem}>
          Enter your <b>API key and Seller ID</b> in the input fields and hit
          <b>&nbsp;‘Save Changes’</b>.
        </li>
      </ul>
      <div>
        And you’re done! Your store checkout will now have iwocaPay as a payment
        option.
      </div>
      <div className={styles.largeSpacer} />
      <AlertBox variant="failure" className={styles.alertBox}>
        <img src={exclamationTriangle} alt="Warning Icon" />
        <div>
          <b>Can’t find your API key? -</b> You’ll have an email from us with
          your API key. It’ll have the subject line ‘iwocaPay API key’ - have a
          look in your spam if you can’t see it in your main inbox.
          <br />
          <br />
          If you haven’t received your key, give us a call on{' '}
          {iwocapayTelDisplay} and we’ll sort it.
        </div>
      </AlertBox>
    </div>
  );
};
