import React from 'react';

import cn from 'classnames';

import styles from './InlineButton.module.css';

export const InlineButton = ({
  children,
  className,
  ...props
}: {
  children: React.ReactNode;
  type?: 'reset' | 'button' | 'submit' | undefined;
} & React.HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      type="button"
      {...props}
      className={cn(styles.InlineButton, className)}
    >
      {children}
    </button>
  );
};
