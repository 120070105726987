import React from 'react';

import { Button, Card, Input, Link } from '@iwoca/orion';

import {
  useGetIwocapayReferral,
  useGetIwocapayReferrals,
} from '../../api/lending/lapiHooks';
import { copy } from '../../components/util/copy';
import { createToast } from '../../store/IwToast';

const REFERRAL_URL = 'iwoca.co.uk/iwocapay-sellers/?referral_code=';

export const Rewards = () => {
  return (
    <div className="mb-4xl max-w-2xl text-primary-10">
      <div className="text-left sm:pl-m sm:pr-m">
        <h1 className="text-4xl">Rewards</h1>
        <h2>Know anyone who needs to get paid?</h2>
        <h3>Pass it on and share £200</h3>
        <p>
          Refer a business to iwocaPay and once they’re set up to offer iwocaPay
          you’ll both get a £100 voucher of your choice each.
        </p>
        <p>
          We’ll include your business name in your referral link, so your
          referrals know who to thank. By sending the referral you are letting
          us know you are happy with that.
        </p>
        <Link
          href="https://support.iwoca.co.uk/en/articles/9453539-referring-a-supplier-to-iwocapay"
          target="_blank"
        >
          Read our FAQ's
        </Link>
        <Card className="mt-2xl">
          <ReferralCount />
          <ReferralShareBox />
        </Card>
      </div>
    </div>
  );
};

const ReferralShareBox = () => {
  const { iwocapayReferral } = useGetIwocapayReferral();

  const referralCode = iwocapayReferral?.referral_code || '';
  const referralCodeURL = REFERRAL_URL + referralCode;

  return (
    <div className="grid w-full max-w-xl grid-cols-[1fr_min-content] gap-l m:grid-cols-1">
      <Input disabled={true} value={referralCodeURL} />
      <Button
        onClick={() => {
          void copy(referralCodeURL);
          createToast('Referral code copied');
        }}
      >
        Copy link
      </Button>
    </div>
  );
};

const ReferralCount = () => {
  const { iwocapayReferrals } = useGetIwocapayReferrals();
  const referralCount = iwocapayReferrals?.referral_count || 0;
  return <p className="mb-4 mt-0 p-0">Previous referrals: {referralCount}</p>;
};
