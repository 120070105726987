import styles from './OrderLinkIsNotValid.module.css';
import { EmailEnvelopeIcon } from '../../components/svg/EmailEnvelopeIcon';

export const OrderLinkIsNotValid = () => {
  return (
    <div>
      <div className={styles.failureIcon}>
        <EmailEnvelopeIcon />
      </div>
      <div className={styles.failureMessage}>
        <div className={styles.failureHeading}>
          Oops - you can only click this link once
        </div>
        <div className={styles.failureText}>
          <p>
            We do this to make sure you don’t accidentally pay for an order
            twice.
          </p>
          <p>
            To go back to your checkout or account page,
            <br />
            <b>use the link we sent you in your sign up email.</b>
          </p>
        </div>
      </div>
    </div>
  );
};
