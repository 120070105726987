import { useContext } from 'react';

import Select from 'react-select';

import { LoadingButton } from '../../../../components/LoadingButton/LoadingButton';
import UnhappyLaptopIcon from '../../../../components/svg/UnhappyLaptopIcon.svg?react';
import { PillLink } from '../../PillLink/PillLink';
import { RefreshPillButton } from '../../RefreshPillButton/RefreshPillButton';
import { SellerXeroContext } from '../../Xero.context';
import sellerXeroStyles from '../../Xero.module.css';
import { DropdownOption } from '../../Xero.types';
import styles from '../PaymentAccount.module.css';

export function ErrorContent({
  paymentAccountOptions,
  setSelectedPaymentAccountOption,
  handleSubmit,
  isSubmitting,
}: {
  paymentAccountOptions?: DropdownOption[];
  setSelectedPaymentAccountOption: (selection: DropdownOption) => void;
  handleSubmit: () => void;
  isSubmitting: boolean;
}) {
  const sellerXeroContext = useContext(SellerXeroContext);

  const placeHolderText =
    !sellerXeroContext.loading && sellerXeroContext.refreshing
      ? 'Loading...'
      : 'Select a Xero Payment Account';

  return (
    <>
      <div className={sellerXeroStyles.textContent}>
        <h1>Hmm, something didn't work that time!</h1>
        <p>
          Choose the Xero account you’d like your iwocaPay payments to be
          recorded in. This should be either a bank account or a chart of
          accounts with payments enabled.
        </p>
      </div>

      <UnhappyLaptopIcon
        className={styles.laptopIcon}
        title="Error laptop icon"
      />
      <Select
        className={sellerXeroStyles.dropdown}
        options={paymentAccountOptions}
        placeholder={placeHolderText}
        onChange={(selection) => {
          setSelectedPaymentAccountOption(selection as DropdownOption);
        }}
      />
      <div className={sellerXeroStyles.extraButtons}>
        <PillLink href="https://central.xero.com/s/article/Add-a-bank-account-or-credit-card-account-UK#Addabankaccount">
          Add an account in Xero
        </PillLink>
        <RefreshPillButton onClick={sellerXeroContext.refresh} />
      </div>
      <LoadingButton
        className={styles.button}
        onClick={handleSubmit}
        isLoading={isSubmitting}
      >
        Let's try again
      </LoadingButton>
    </>
  );
}
