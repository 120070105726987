import * as Yup from 'yup';

import { addressValidator } from '../../../../components/AddressInput/addressValidator';

export const directorInfoValidator = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .test(
      'is-phoneNumber',
      'Not a valid phone number',
      (value) =>
        !!(
          value &&
          value.length > 9 &&
          value.length < 14 &&
          /^\+[0-9]*|^0[0-9]*$/.test(value)
        ),
    ),
  dateOfBirth: Yup.string()
    .required('Date of birth is required')
    .test(
      'is-DOB',
      'Not a valid date of birth',
      (value) =>
        !!(value && /^([0-3][0-9])\/([0-1][0-9])\/([0-9]{4})$/.test(value)),
    ),
  privacyPolicyAgreed: Yup.boolean()
    .required('Agreement is required')
    .isTrue('Agreement is required'),
  address: addressValidator,
  title: Yup.string().required('Title is required'),
  isShareholder: Yup.string().when('companyType', {
    is: 'sole_trader',
    then: (schema) => schema,
    otherwise: (schema) => schema.required('Shareholder answer required'),
  }),
});
