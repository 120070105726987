import { useGetValidIwocapayFormalOffers } from '../../PayLinkLanding/hooks/useGetValidIwocapayFormalOffers';
import { TDuration } from '../../PayLinkLanding/utils/PayLinkLanding.types';
import { getDurationInMonths } from '../../utils/product';

export function useFormalOfferWithDuration(duration: TDuration) {
  const { formalOffers, loadingFormalOffers } =
    useGetValidIwocapayFormalOffers();

  const formalOffer = formalOffers.find(
    (offer) =>
      offer.duration && getDurationInMonths(offer.duration) === duration,
  );

  return {
    formalOffer,
    loadingFormalOffer: loadingFormalOffers,
  };
}
