import ReactModal from 'react-modal';

import styles from './Modal.module.css';
import { CrossIcon } from '../svg/CrossIcon';

export const Modal = ({
  testPrefix = '',
  isClosable = true,
  isModalOpen,
  closeModal,
  children,
}: {
  closeModal?: () => void;
  isModalOpen: boolean;
  children: React.ReactElement;
  testPrefix: string;
  isClosable: boolean;
}) => {
  return (
    <ReactModal
      className={styles.Modal}
      overlayClassName={styles.Overlay}
      isOpen={isModalOpen}
      shouldCloseOnOverlayClick={isClosable}
      onRequestClose={closeModal}
      ariaHideApp={false}
    >
      <div data-testid={`${testPrefix}Modal`} className={styles.modalContent}>
        {isClosable && (
          <CrossIcon
            className={styles.closeButton}
            onClick={closeModal}
            data-testid={`${testPrefix}Close`}
          />
        )}
        {children}
      </div>
    </ReactModal>
  );
};
