import { usePaymentMethod } from '../../../../hooks/usePaymentMethod';
import { GridField } from '../GridField/GridField';

export const PaymentMethod = () => {
  const { bankDetails, isLoading } = usePaymentMethod();

  if (isLoading) return <></>;

  const formatSortCode = (number: string): string => {
    // note: you need to parse the sort code as there are differing formats e.g. with and without dashes
    const numericString = number.replace(/\D/g, '');
    return numericString.replace(/(..)(..)(..)/, '$1-$2-$3');
  };

  return (
    <div className="mt-l grid grid-cols-2 gap-m">
      <GridField
        header="Account number"
        value={bankDetails.account_number || '-'}
      />
      <GridField
        header="Sort code"
        value={
          bankDetails.sort_code ? formatSortCode(bankDetails.sort_code) : '-'
        }
      />
    </div>
  );
};
